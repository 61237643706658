.mooc-preview-wrapper{
    width: 100%;
}
.academy-wapper-c{
    width: 80% !important;
}
.mooc-banner{
    width: 100%;
    display: flex;
    justify-content: center;
}
.mooc-banner-box{
    width: 100%;
    height: 350px;
    background-position: center;
    background-size: cover;
    background-image: url('../../../images/MOOC_filming4.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.mooc-banner-content{
    width: 100%;
    z-index: 100;
}
.mooc-banner-content h1{
    font-size: 45px;
    margin-bottom: 20px;
}
.mooc-banner-content h4{
    font-size: 22px;
    margin-bottom: 10px;
}

.mooc-preview-banner-box{
    width: 100%;
    height: 287px;
    background-position: center;
    background-size: cover;
    background-image: url('../../../images/index_mooc.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.mooc-preview-banner-content{
    width: 100%;
    z-index: 100;
}
.mooc-preview-banner-content h2{
    font-size: 35px;
    margin-bottom: 10px;
}
.mooc-preview-banner-content h4{
    font-size: 22px;
}
.mooc-map{
    display: flex;
    margin-bottom: 30px;
}
.mooc-work-title h2{
    font-size: 35px;
}
.mooc-video{
    width: 50%;
}
.mooc-preview-example a{
    color: #777777;
    border-bottom: 1px solid #777777;
}
.mooc-preview-example a:hover{
    text-decoration: none;
}
.mooc-preview-item:hover{
    cursor: pointer;
}
.mooc-preview-item:hover .promotion-box p{
    color: #5b9aef;
}
.mooc-preview-item:last-child:hover{
    cursor: default;
    color: #777777;
}
.mooc-tutor{
    width: 100%;
    display: flex;
    justify-content: center;
}
.mooc-tutor-box{
    width: 60%;
    margin: 0 auto;
}
.mooc-tutor-content{
    width: 100%;
    display: flex;
    justify-content: center;
}
.mooc-tutor-content-intro{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mooc-tutor-content-intro h2{
    font-size: 35px;
    margin-bottom: 20px;
}
.mooc-tutor-content-intro p{
    font-size: 22px;
}
.mooc-tutor-content-image{
    width: 50%;
    display: flex;
    justify-content: center;
}
.mooc-tutor-content-image-box{
    width: 70%;
    padding-top: 30px;
}
.mooc-tutor-content-image-box img{
    width: 100%;
    height: 100%;
}

.mooc-apply-banner-box{
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: cover;
    background-image: url('../../../images/resources.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.mooc-apply-banner-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
.mooc-apply-banner-content h2{
    font-size: 35px;
    margin-bottom: 10px;
}
.mooc-apply-banner-content p{
    text-align: left;
    font-size: 22px;
}

.mooc-fee{
    width: 100%;
    justify-content: center;
    align-items: center;
}
.mooc-fee-box{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mooc-fee-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mooc-fee-content h2{
    font-size: 35px;
    margin-bottom: 10px;
}
.mooc-fee-content p{
    text-align: left;
    font-size: 22px;
}
.mooc-fee-content button{
    cursor: pointer;
}

.item-mooc-btn{
    padding : 5px 15px;
    font-size: 20px;
    border : 1px solid #66afe9;
    border-radius: 3px;
    cursor: pointer;
}
.item-mooc-btn:hover{
    background: #66afe9;
    color : white;
}

@media screen and (max-width: 991px){
    .mooc-tutor-box{
        width: 80%;
    }
}
@media screen and (max-width: 767px){
    .mooc-map{
        flex-direction: column;
    }
    .mooc-video{
        width: 100%;
    }
    .mooc-preview-banner-content{
        width: 90%;
    }
    .mooc-tutor-box{
        width: 90%;
    }
    .mooc-apply-banner-content{
        width: 90%;
    }
    .mooc-fee-box{
        width: 90%;
        margin: 0 auto
    }
    .mooc-tutor-content{
        flex-direction: column;
    }
    .mooc-tutor-content-intro, .mooc-tutor-content-image{
        width: 100%;
    }
    .mooc-tutor-content-intro{
        margin-top: 20px;
    }
}