.vacation-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.vacation-wrapper a:hover{
    text-decoration: none;
}
.vacation-subject-btn{
    background: transparent;
    border-radius: 10px;
    margin: 0 3px;
    padding: 5px;
}
.display-row{
    display: flex;
    flex-direction: row;
}
.hover-sub:hover {
    color: #5b9aef !important;
}
/* vacation banner section */
.vacation-banner{
    width: 100%;
    height: 400px;
    background-image: url("../../../images/academy/books_collection.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vacation-banner-box{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vacation-banner-main{
    z-index: 100;
}
.vacation-banner-main p, .vacation-banner-main a{
    color: #ffffff;
}

/* vacation contents */
.vacation-content{
    width: 100%;
    text-align: center;
}
.vacation-content-box, .vacation-content-schedule-box{
    width: 60%;
    margin: 0 auto;
}
.vacation-content-title{
    font-weight: bold;
    color: #5b9aef;
    margin-bottom: 30px;
}
.vacation-content-main{
    width: 100%;
    margin: 0 auto;
}
.vacation-content-main button{
    border-radius: 10px;
    background: transparent;
    padding: 5px;
    margin: 0 3px;
}
.vacation-class-type{
    width: 50%;
    margin: 0 auto;
}
.vacation-class-type p{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
}
.vacation-schedule table{
    width: 100% !important;
}
.vacation-line{
    width: 20%;
    border-top: 3px solid #eee;
    margin-top: 40px;
    margin-bottom: 40px;
}
.vacation-youtube{
    width: 50%;
    margin: 0 auto;
}
/* responsive */
@media(max-width: 767px) {
    .vacation-banner-main{
        width: 80%;
    }
    .vacation-content-box{
        width: 95%;
    }
    .vacation-class-type{
        width: 100%;
    }
    .vacation-class-type p{
        width: 100%;
    }
    .display-row{
        flex-direction: column;
    }
}