.main_wr_wrapper{
    width: 100%;
    height : 100%;
    overflow : hidden;
}

.default-main-div{
    width: 100%;
    height : 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.default-main-inner{
    display: inline-block;
    width : 350px;
    margin : 90px 10%;
    text-align: center;
    border-radius: 3px;
    background: rgba(0,0,0,0.5);
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.default-main-inner div{
    flex : 1;
}
.default-main-inner h2{
    flex : 1;
    color : white;
    box-sizing: border-box;
}
.default-main-inner h2 > span{
    display: inline-block;
    width : 100%;
    font-size: 28px;
    font-weight: bold;
}

.default-main-inner p{
    flex : 1;
    color : white;
    line-height: 40px;
}
.default-main-inner button{
    width: 30%;
    margin: 0 auto;
    background : #5b9aef;
    border: 2px solid #5b9aef;
    opacity: .8;
    color: #fff;
    margin-bottom: 10px;
    padding: 5px 15px;
    border-radius: 13px;
    font-size: 17px;
}
.default-main-inner button:hover{
    cursor: pointer;
}



/*  Standard 1200 */
@media screen and (max-width:1200px) {

    .default-main-inner{
        width : 350px;
        margin : 90px 10%;
    }
    .default-main-inner h2{
        font-size: 2.3rem;
    }

}

/*  Standard 768 */
@media screen and (max-width:767px) {
    .default-main-inner{
        width : 95%;
        margin : 0 auto;
    }
    .default-main-inner h2{
    }
    .default-main-inner h2 span{
        width : auto;
        padding : 5px;
        font-size: 2.5rem;
    }
    .default-main-inner button{
        overflow: hidden;
        border-radius: 10px;
        width : 130px;
        border : 0;
    }
}
@media screen and (max-width:454px) {

    .default-main-inner button{
        line-height: 10px;
        padding : 10px 20px;
    }
}