.online-tuition-touchpad-wrapper{
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
}
.online-tuition-touchpad-wrapper li, .online-tuition-touchpad-wrapper a{
    list-style: inside;
}
.online-tuition-touchpad-wrapper h2{
    font-size: 30px;
    font-weight: 500;
}

.online-tuition-touchpad-pic{
    display: flex;
    width: 100%;
}
.online-touchpad-pic-box{
    width: 20%;
    margin-right: 10px;
}
.touchpad-pic{
    width: 100%;
}
.second-sec-title{
    margin-top: 20px;
    font-size: 24px;
}
@media(max-width: 991px) {
    .online-touchpad-pic-box{
        width: 30%;
    }
    .big-md{
        width: 60%;
    }
}
@media(max-width: 767px){
    .online-tuition-touchpad-wrapper{
        width: 90%;
    }
    .online-touchpad-pic-box{
        width:50%
    }
    .big-md{
        width: 100%;
    }
}