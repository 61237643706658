.default-wrapper{
    /* margin-top :21px; */
}
.fix-nav-menu{
}
.fix-nav-menu li{
  border : 1px solid rgb(245, 245, 245);
}
#unreadMessageCount{
  background-color: #5b9aef;
  color: #fff;
  white-space: nowrap;
  min-width: 10px;
  padding: 3px 7px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 10px;
  font-size: 12px;
  margin: 0px !important;
}


li {
    list-style: none;
}

.container-check {
    display: block;
    position: relative;
    padding-left: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;
}
  
  /* Hide the browser's default checkbox */
.container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.container-check p {
    margin-left : 5px;
    font-family: 'Spoqa Han Sans',sans-serif!important;
    color : rgb(181, 181, 182);
      /* font-family:  */
}

  
  /* Create a custom checkbox */
  .default-checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 14px;
    width: 14px;
    margin-right : 8px;
    margin-left : -3px;
    border: 1px solid #c2c2c3;
    border-radius: 2px;
    background-color: white;

  }
.default-checked{
  background-color: #2196F3;
}
.default-checked::after{
  display: block;
}
  /* On mouse-over, add a grey background color */
  .container-check:hover input ~ .default-checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-check input:checked ~ .default-checkmark {
    background-color: #2196F3;
  }
  
  /* Create the default-checkmark/indicator (hidden when not checked) */
  .default-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the default-checkmark when checked */
  .container-check input:checked ~ .default-checkmark:after {
    display: block;
  }
  
  /* Style the default-checkmark/indicator */
  .container-check .default-checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }