.online-tuition-wrapper{
    width: 100%;
}

/* online-tuition-banner sec */
.online-tuition-banner{
    width: 100%;
    height: 287px;
    background-image: url('../../images/index_carousel_1.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top;
}
.online-tuition-banner-box{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.online-tuition-banner-main{
    z-index: 100;
}
.online-tuition-banner-main h2,.online-tuition-banner-main h4{
    color: #ffffff;
    word-break: break-all;
    text-align: center;
}
.online-tuition-banner-main h4{
    font-size: 18px;
}

/* online-tuition-content sec */
.online-tuition-content{
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 40px 0px;
}
.online-tuition-content-box{
    width: 70%;
    margin: 0 auto;
}
.online-tuition-title{
    width: 100%;
    text-align: center;
}
.online-tuition-title h2{
    font-size: 30px;
    color: #5b9aef;
    font-weight: bold;
}
.online-tuition-title h4{
    font-size: 18px;
}
#online-tuition-board{
    flex-direction: column;
}
.online-tuition-content-main{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 40px 0px;
}
.online-tuition-content-main h3{
    text-align: center;
}
.online-tuition-content-main h4{
    text-align: center;
    color: #000000;
    font-size: 18px;
}
.online-tuition-subjects{
    width: 100%;
    display: flex;
    justify-content: center;
}
.online-tuition-subjects h4:first-child{
    width: 45%;
}
.online-tuition-subjects h4:last-child{
    width: 40%;
    text-align: right;
}
.online-tuition-pics{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.online-tuition-pics-box{
    width: 48%;
    margin-top: 20px;
    margin: 0px 5px;
}
.online-tuition-pics-box img{
    width: 100%;
    height: 100%;
}
.online-tuition-pics-box h3{
    font-size: 24px;
    color: #000000;
    text-align: center;
    margin-top: 15px;
}
.online-tuition-concerned{
    width: 30%;
}
.online-tuition-solution{
    width: 30%;
}
#online-tuition-reason{
    flex-direction: column;
    text-align: center;
    margin: 20px 0px;
}
#online-tuition-reason p{
    font-size: 18px;
}

/* online-tuition-touchpad-banner */
.online-tuition-touchpad-banner{
    width: 100%;
    height: 272px;
    background-image: url('../../images/online-tuition/touchpad-banner.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.online-tuition-touchpad-banner-box{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.online-tuition-touchpad-banner-main{
    z-index: 100;
    text-align: center;
}
.online-tuition-touchpad-banner-main h2,.online-tuition-touchpad-banner-main h4{
    color: #ffffff;
    text-align: center;
}
.online-tuition-touchpad-banner-main h4{
    font-size: 18px;
    margin-top: 30px;
}
.online-tuition-touchpad-btn{
    font-size: 30px;
    font-weight: bold;
    border: 5px solid #ffffff;
    color: #ffffff;
    border-radius: 13px;
    background: transparent;
    padding: 30px 60px;
}
#online-tuition-demo .gurume-blue-back-btn{
    font-size: 30px;
    font-weight: bold;
    margin: 10px;
    padding: 10px 20px;
}
#online-tuition-demo h4{
    color: #6c767f;
}
@media(max-width: 1069px) {
    .online-tuition-content-box{
        width: 90%;
    }
}
@media(max-width: 767px) {
    .online-tuition-banner-main h2{
        font-size: 34px !important;
    }
    .online-tuition-subjects{
        flex-direction: column;
        align-items: center;
    }
    .online-tuition-subjects h4:first-child{
        width: 100%;
    }
    .online-tuition-subjects h4:last-child{
        width:100%;
        text-align: center;
    }
    .online-tuition-pics{
        flex-direction: column;
    }
    .online-tuition-pics-box{
        width: 100%;
        margin: 20px 0px;
    }
    .online-tuition-content-main{
        flex-direction: column;
        align-items: center;
    }
    .online-tuition-concerned{
        width: 100%;
    }
    .online-tuition-solution{
        width: 100%;
    }
}