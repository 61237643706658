
.credit-withdraw-wapper{
    min-height: 600px;
    margin-bottom: 100px;
}

.credit-withdraw-resize{
}

.credit-withdraw-head{
    min-height: 20px;
    padding : 15px;
    padding-bottom: 5px;
    box-shadow: 1px 2px 3px #6c767f;
    margin-bottom: 20px;
}
.credit-withdraw-head > div{
    color : #6c767f;
    position: relative;
    min-height: 35px;
}

.credit-withdraw-head > div > span{
    display: inline-block;
    float: right;
}


.credit-withdraw-pannel{
    position: absolute;
    top : 0;
    left : 0;
    width : 100%;
    height: 100%;
    background : rgba(0,0,0,0.5);
    z-index: 9999999;
}

.credit-withdraw-pannel-card {
    width : 80%;
    max-width: 900px;
    min-height: 550px;
    background : white;
    margin : 0 auto;
    margin-top : 100px;
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    padding : 15px;
    border-radius: 6px;
}

.credit-withdraw-pannel-card > h5{
    font-size: 18px;
    font-weight: 400;
    padding : 10px;
    border-bottom: 1px solid #ddd;
}

.credit-withdraw-table2{
    height: 100%;
    max-height: 400px;
}

.credit-withdraw-table2-header{
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;
}
.credit-withdraw-table2-header > span{
    flex : 1;
    padding : 1;
    text-align: center;
}

.credit-withdraw-table2-body{
    margin-top: -23px;
    height: 400px;
    overflow: auto;
}
.credit-withdraw-table2-body ul{
    padding : 0;
}
.credit-withdraw-table2-body ul li{
    padding : 10px 1px;
    border-bottom: 1px solid #ddd;
    display: flex;
}
.credit-withdraw-table2-body ul li:nth-child(2n -1){
    background: rgba(223,223,223,.2);
}
.credit-withdraw-table2-body ul li > span{
    flex : 1;
    text-align: center;
}


.credit-withdraw-table2-btns{
    border-top: 1px solid #ddd;
}
.credit-withdraw-table2-btns > button {
    margin-top: 28px;
    float: right;
}

.withdrawBtns{
}
.withdrawBtns > button{
    float: right;
}