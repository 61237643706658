.group-class-container .group-class-bg {
    background-image: url('/images/header-bg/teacher-list-header-long-bg.jpg');
}

.group-class-container .blue-text,
.group-class-show-container .blue-text,
.group-class-log-container .blue-text {
    color: #5b9aef;
}

.group-class-list-container {
}

.r-group-title{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 7px;
}
.r-group-tutor{
    text-align: center;
    font-size: 14px;
}
.group-class-list-container .card-body {
    padding: 0;
    padding-top: 1rem;
}

.group-class-list-container .group-class-list-img {
    height: 150px;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: all 0.3;
}
.group-class-img{
    -webkit-perspective: 1;
}
/* .group-class-list-container .r-group-class-anim-img:hover > img{
    transition: all 0.3;
    transform: scale(1.2);
} */
.group-class-list-container .r-group-class-anim-img img{
    transition: all ease .32s;
    width : 100%;
    height : 100%;
}

.group-class-show-container .group_class_jumbotron {
    background: rgba(223, 223, 223, .63);
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.group-class-show-container .dotted-line {
    margin: 20px auto;
    border-top: dotted 1px;
}

.group-class-show-container .teacher-info-p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

.group-class-show-container .cancel-btn,
.group-class-edit-container .cancel-btn {
    font-size: 1.2rem;
}

.group-class-show-container .group_class_jumbotron {
    padding: 20px;
}

.group-class-edit-container .youtube-title-img {
    width: 60px;
}

.group-class-edit-container .delete-subject-img {
    padding: 0;
}

.group-class-edit-container .react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input:disabled,
.react-datepicker__input-container input[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.group-class-log-container .homepage-mooc *,
.group-class-log-container .email-us * {
    color: #fff;
}
.group-class-list-item{
    margin-bottom: 25px !important;
}
.group-class-list-item > .card{
    padding : 10px;
    min-height: 280px;
    box-shadow: 2px 2px 3px #ddd, -2px 0px 3px #ddd;
}
.group-class-list-item > .card:hover{
    box-shadow: 0 1px 20px #5b9aef;
    cursor: pointer;
}

@media (min-width: 768px) {
    .group-class-container .modal-dialog {
        width: auto;
    }
}
@media (max-width: 992px) {
    .group-class-list-item > .card{
        padding : 0px;
    }
    .r-group-title{
        text-align: center;
    }
    .card-text{
        text-align: center;
    }
}

