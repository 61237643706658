.ta-wra-super{
    z-index: 9999999999;
    position: absolute;
    top : 0px;
    width : 100%;
    height : 100%;
    background: rgba(0,0,0,0.3);
    overflow: scroll;
    overflow-x: hidden;
    padding-bottom: 50px;
}

.tapply-inner-wrapper{
    padding-top: 100px;
    width : 80%;
    max-width: 1000px;
    margin : 0 auto;
}
.tapply-wrapper{
    background: white;
    border-radius: 7px;
    padding : 30px;
}
.tapply-wrapper h4{
    color : #5b9aef;
    text-indent: 5px;
}


.ta-good-wrapper{
    background-color: #f5f5f5;
    border-color: #ddd;
    height: 80px;
    border-radius: 7px;
    border : 1px solid #ddd;
    border-bottom: 3px solid #ddd;
}
.ta-good-wrapper > h3{
    color : #5b9aef;
    height: 100%;
    text-align: center;
    line-height: 75px;
    cursor: pointer;
}

.tapply-msg-ul ul{
    margin : 0;
    padding : 0;
    min-height: 50px;
    margin-top : 5px;
    display: flex;
}
.tapply-wrapper ul li{
    margin : 5px;
    padding : 10px;
    flex : 1;
    border : 2px solid #ddd;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
}
.tapply-wrapper ul li:hover{
    border : 2px solid #5b9aef !important;
    cursor: pointer;
    box-shadow: 0 2px 15px #5b9aef;
    text-decoration: underline;
}
.tapply-wrapper ul li div:nth-child(1){
}
.tapply-wrapper ul li div:nth-child(1) div:nth-child(2){
    letter-spacing: -1px;
}
.tapply-wrapper ul li div:nth-child(1) div:nth-child(1) > span:nth-child(1){
    color : #5b9aef;
    font-size: 15px;
    display: inline-block;
    margin-left: 5px;
    transform: rotate3d(0, 2, 2, 25deg);
}
.tapply-wrapper ul li div:nth-child(1) div:nth-child(1) > span:nth-child(2){
    color : #6c767f;
    font-weight: 500;
    font-size: 14px;
    padding-left: 10px;
    letter-spacing: -.3px;
}

.tapply-wrapper ul li div:nth-child(1) div:nth-child(2) > span:nth-child(1){
    color : #5b9aef;
    font-size: 15px;
    display: inline-block;
    margin-left: 5px;
    transform: rotate3d(0, 2, 2, 25deg);
}
.tapply-wrapper ul li div:nth-child(1) div:nth-child(2) > span:nth-child(2){
    color : #6c767f;
    font-size: 14px;
    padding-left: 10px;
}
.tapply-wrapper ul li > div{
    position: relative;
    min-height: 30px;
}


.td-extra-info{
    position: relative;
}
/* .td-extra-info > div{
    color : white;
    background: #bfdb9f;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    width : 100%;
    text-align: center;
    border-radius: 2px;
    font-size: 15px;
} */
.td-extra-info div:hover{
    color : #333;
}
.t-apply-msgs-extra-b{
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
.t-apply-msgs-extra-b > button{
    width: 100% !important;
    background: #bfdb9f !important;
    color: white;
    max-height: 33px;
    max-width: 44px;
    border-radius: 10px;
    text-align: center;
}
.t-apply-msgs-extra-b:hover{
   color: #333;
}
.t-apply-msgs-extra-b:active{
    border : 0;
    box-sizing: border-box;
}
.t-apply-msgs-extra-b-ms{
    border-bottom: 1px solid #333;
}
.t-apply-msgs-extra-b-ms:hover{
    background: #ccc;
}
.t-apply-msgs-extra-b-ms:active{
    border : 0;
}
.apply-msg-wrapper{
    display: flex;
}
.apply-msg-wrapper > div:nth-child(1){
    width: 80%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.t-apply-msgs-extra-a{
    display: flex;
    border : 2px solid #ccc !important;
}
.t-apply-msgs-extra-a-ms{
    opacity: .5;
}
.t-apply-msgs-extra-a-ms:hover{
    opacity: 1;
}

.sossosoosos{
    border : 2px solid #5b9aef !important;
}

.t-apply-msgs{
    margin-right: 5px;
}


.ta-body-rank{
    cursor: pointer;
}
.ta-body-rank:hover h4{
    color : black !important;
}
.ta-body-rank ul{
    margin : 0;
    padding : 0;
}
.ta-body-rank ul li{
}
.ta-body-rank ul li > div:nth-child(1){
}
.ta-body-rank ul li > div:nth-child(1) > span{
    display: inline-block;
    float : left;
    width : 50px;
    height : 50px;
    box-sizing: border-box;
}
.ta-body-rank ul li > div:nth-child(1) > span > img{
    width : 50px;
    height : 50px;
}
.ta-body-rank ul li > div:nth-child(1) > span:nth-child(1){
    margin-right: 10px;
    border-radius: 50%;
}
.ta-body-rank ul li > div:nth-child(1) > span:nth-child(2){
    min-width : 150px;
    font-size: 20px;
    line-height: 75px;
}
.ta-body-rank ul li > div:nth-child(1) > span:nth-child(3){
    float: right;
    text-align: right;
    min-width: 200px;
    color : #ccc;
}

.ta-body-rank ul li > div:nth-child(2){
    padding-left : 50px;
    border-bottom: 1px solid #ddd;
}
.ta-body-rank ul li > div:nth-child(3){
    padding-left: 50px;
}


.ta-god-rank{
    margin : 0;
    padding : 0;
    margin-top: 20px;
}
.ta-god-rank > textarea{
    display: inline-block;
    width : 100%;
    height : 200px;
    padding : 10px;
    font-size: 18px;
}

.ta-god-rank > div{
    text-align: right;
}
.ta-god-rank > div button{
    min-width : 100px;
    border-radius: 10px;
    background: #5b9aef;
    color : white;
    font-size: 18px;
}

.ta-god-rank > div button:hover{
    color : black;
}

@media screen and (max-width:991px){
    .tapply-msg-ul ul{
        flex-direction: column;
    }
}
@media screen and (max-width:767px){
    .tapply-inner-wrapper{
        width: 90%;
    }
}