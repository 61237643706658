.newsletter-wrapper{
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 9999999;
    top: 0;
    justify-content: center;
}
.newsletter-box{
    width: 100%;
    display: flex;
    justify-content: center;
}
.align-right{
    text-align: right;
    margin-right: 10px;
}