#discount-box{
    display: none;
}
#discount-box span{
    width: 50px;
    padding: 0.49rem 0.75rem;
    font-size: 1rem;
    line-height: 20px;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    
}
#discount-box input{
    width: 30%;
    height: 33.5px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.mooc-wrapper{
    width : 100%;
    max-width: 1200px;
    margin : 0 auto;
}

.mooc-left{
    flex : .9;
    padding : 15px;
    transition: all 1s;
}
.mooc-form-item {
    margin-bottom: 20px;
}
.mooc-form-item-tutor{
    width : 30%;
}
.mooc-form-item-category{
}
.mooc-form-item-category > div{
    display: flex;
}
.mooc-form-item-category > div > select{
    flex : 1;
    margin-right: 15px;
}

.mooc-small{
    font-size: 12px !important;
}
.mooc-form-item-explanation{
    padding : 5px;
}

.input-group-append{
    width : 360px;
}


.mooc-form{
    width: 100%;
    display: flex;
}
.mooc-right{
    flex : 0.4;
    padding : 0 30px;
    padding-left: 10px;
    border-left: 1px solid #ddd;
}
.moocnew-preview{
    padding : 10px;
}
.mooc-recommedation{
    padding : 10px;
}

/* mooc preview card */
.moocnew-preview-card{
    width: 260px;
    margin : 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}
.moocnew-preview-card-top{
    width: 100%;
    padding: 20px 10px;
    background-color: #fff;
    border: 4px solid #5b9aef;
}
.moocnew-preview-card h3{
    font-size: 20px;
    color: #5b9aef;
    text-align: center;
    font-weight: bold;
}
.moocnew-preview-tutor{
    width: 95%;
    margin: 0 auto;
    display: flex;
}
.moocnew-preview-tutor-name{
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
}
.moocnew-preview-tutor-name p{
    font-size: 16px;
    color: #5b9aef;
    font-weight: bold;
}
.moocnew-preview-tutor-pic{
    display: flex;
    width: 40%;
    justify-content: flex-end;
    margin-bottom: -50px;
}
.moocnew-preview-tutor-pic-box{
    width: 70px;
    height: 70px;
    z-index: 1000;
}
.moocnew-preview-tutor-pic-box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
.moocnew-preview-card-bottom{
    width: 100%;
    padding: 20px 0px;
    background-color: #fff;
}
.moocnew-preview-price{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 5px 0px;
}
.moocnew-preview-original-price{
    color: #5b9aef;
    font-size: 14px;
}
.moocnew-preview-now-price{
    color: #5b9aef;
    font-size: 16px;
}
.moocnew-preview-apply-btn{
    background-color: #5b9aef;
    color: white;
    border-radius: 5px;
    border: none;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    width: 70%;
}
.moocnew-preview-time{
    text-align: center;
    color: #666;
    padding-top: 5px;
}



.mooc-recommedation > i > ol{
    padding : 0;
    padding-left: 35px;
}
.mooc-recommedation > i > ol > li{
    font-size: 14px;
    letter-spacing: .1px;
    list-style-type: decimal;
    text-indent: 5px;
}


/* mooc lecture */
.moocnew-lecture{
    width: 100%;
    margin: 20px 0px;
}
.moocnew-lecture-list{
    width: 100%;
}
.moocnew-lecture-title{
    width: 100%;
    display: flex;
}
.moocnew-lecture-title p:nth-child(2){
    width: 15%;
    margin-left: 30px;
}
.moocnew-lecture-title p:nth-child(3){
    width: 33%;
}
.moocnew-lecture-video{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.moocnew-lecture-item{
    width: 100%;
    padding: 5px 0px;
}
.moocnew-lecture-dnd{
    width: 100%;
}
.moocnew-dnd-input{
    width: 100%;
    float: left;
}
.moocnew-dnd-input p{
    width: 7%;
    float: left;
    text-align: center;
    font-weight: bold;
}
.moocnew-input-wrapper{
    width: 80%;
    float: left;
}
.moocnew-input-wrapper > #drop-area{
    color: transparent !important;
    border: 2px dashed transparent !important;
}
.moocnew-input-wrapper input{
    width: 40%;
    float: left;
}
.moocnew-input-wrapper input:first-child{
    width: 15% !important;
}
.moocnew-input-wrapper input:first-child, .moocnew-input-wrapper input:nth-child(2){
    margin-right: 2%;
}
.moocnew-button-wrapper{
    width: 13%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.moocnew-cancel-btn, .moocnew-dnd-btn{
    border: none;
    font-size: 20px;
    color: #6c767f;
    display: flex;
}
.moocnew-dnd-btn:hover .moocnew-lecture-item{
    background-color: #5b9aef;
}
#drop-area::before{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    content: "Drop here";
    text-align: center;
    color: #5b9aef;
    border: 2px dashed #5b9aef;
    border-radius: 10px;
}
#drop-area::after{
}
.moocnew-lecture-add-btn{
    text-align: center;
    color: #000;
    background-color: #c4c4c4;
    border: 1px solid #c4c4c4;
    padding: 15px 50px;
}
.mooc-submit{
    text-align: center;
    position: relative;
}

/* responsive work */
@media(max-width: 991px) {
    .mooc-form{
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
    }
    .mooc-right{
        padding: 0px;
        border-left: none;
    }
    .moocnew-lecture{
        width: 100%;
    }
    
}
@media screen and (max-width: 767px) {
    .mooc-form{
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
    }
}
@media screen and (max-width: 500px) {
    .mooc-left{
        padding: 5px;
    }
    .moocnew-lecture-title p:nth-child(2){
        width: 15%;
        margin-left: 10px;
    }
}