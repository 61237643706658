.VMainWrapper{
}

.main_marquee{
    font-weight: 400;
    font-size: 14px;
}
.main_marquee_active{
    animation: MAIN_MARQUEE_START 6s linear 0s infinite;
    -webkit-animation: MAIN_MARQUEE_START 8s linear 0s infinite;
    position: absolute;
}
.main_marquee_wrap{
    position : relative;
    overflow: hidden;
}

.main_2_rev-wp{
  margin : 0 auto;
}

/* RECOMANDATION TEACHERS */
.s_main_start{
}
.s_main_start > h1{
  color : #6c767f;
}
.main-review{
  width: 100%;
  justify-content: center;
}
.main-banner{
  width: 100%;
  cursor: pointer;
}
.main-banner > img{
  width: 100%;
  height: 100%;
}
/* .main-review-box{
  width: 85%;
  margin: 0 auto;
} */
.main-review-box > h1{
  color : #6c767f;
}
.main-review-content{
  width: 100%;
  display: flex;
  justify-content: center;
}

.main-recommend{
  width: 100%;
  background: rgba(223, 223, 223, 0.63);
  padding: 60px 0px;
}
.main-recommend-box{
  width: 95%;
  margin: 0 auto;
}
.typing-effect{
  text-align: center;
  font-size: 35px !important;
  color: #6c767f;
}
.border-blink{
  -webkit-animation: borderBlink 1s linear infinite;
  -moz-animation: borderBlink 1s linear infinite;
  -o-animation: borderBlink 1s linear infinite;
  animation: borderBlink 1s linear infinite;
  border-width: 3px;
  border-style: solid;
  border-color: #5ECAC6;
}
.bg-premium-purple{
  background-color: #5ECAC6;
}
@keyframes borderBlink{
  0%{border-color: #fff;}
  100%{border-color: #5ECAC6;} 
}

.main-premium-card-main{
  width:40%;
  background: #fff;
  margin: 0px 10px;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  border : 4px double #5ECAC6;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.main-premium{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.main-premium-card-main > div{
  width: 100%;
}
.main-premium-title{
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.main-premium-title button{
    font-size: 1.2rem;
    border-radius: 5px;
    background: #5b9aef;
    color: white;
    border: 0;
    width: 55%;
}
.main-premium-profile{
  width: 95%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.main-blog_wsa{
  height : 430px;
  width : 100%;
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    /* bottom, image */
    url("/images/index_blog.jpg");
  background-size:cover;
}
.main-blog-box{
  width: 85%;
  margin : 0 auto;
}


.main_ws_q {
  display: inline-block;
  width : 100%;
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    /* bottom, image */
    url("/images/index_mooc.jpg");
  background-size:cover;
  max-width: 100%;
}

@media screen and (max-width:767px){
  .main-premium-profile {
    width: 100%;
    padding-left: 0% !important;
  }
  .main-premium-card-main{
    width: 100% !important;
    margin: 10px 0px;
  }
}
@media screen and (max-width:991px){
  .main-review-box{
    width: 95% !important;
  }
  .main-review-more-btn{
    width: 95% !important;
  }
  .main-blog-box{
    width: 95% !important;
  }
}
@media screen and (max-width:1300px) {
  .main-premium{
    flex-direction: column;
  }
  .main-premium-title{
    width: 95%;
    align-items: center;
    margin: 0 auto;
  }
  .main-premium-profile{
    flex-wrap: wrap;
    width: 95%;
    margin: 0 auto;
    justify-content: flex-start !important;
    padding-left: 4%;
  }
  .main-premium-card-main{
    width: 45%;
    margin: 10px 10px;
  }
  
}
@-webkit-keyframes MAIN_MARQUEE_START {
    0% {
        left : 350px;
    }
    100% {
        left : -550px;
    }
}
@keyframes MAIN_MARQUEE_START {
    0% {
        left : 350px;
    }
    100% {
        left : -590px;
    }
}