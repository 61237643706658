.how-it-works-wapper{
    min-height: 500px;
    padding : 30px;
    width: 100%;
}
.how-it-works-tuition-body{
    width : 900px;
    margin : 0 auto;
    transition: all 0.5s;
}
.how-it-works-tuition-body > div{
    width : 100%;
}

.how-it-works-section{
    width: 80%;
    margin: 0 auto;
}
.how-it-works-footer{
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 30px !important;
}
.section-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.section-contents{
    width: 100%;
    display: flex;
    align-items: center;
}
.section-left{
    width: 13%;
    display : flex;
    flex-direction: column;
    justify-content: center;
}
.section-img{
    width: 100%;
}
.section-img img{
    width: 100%;
}
.section-line{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}
.section-line img{
    width: 15%;
    margin: 10px 0px;
}
.section-right{
    width: 87%;
    padding-left: 20px;
}
.section-right p{
    font-size: 16px;
}
.section-table{
    width: 85%;
    margin: 0 auto;
}
.section-table table {
    text-align: center;
}
.section-right-sub p{
    font-size: 16px;
}
.section-right-sub img{
    width: 7%;
}
.table-imp-text{
    color: red;
    font-size: 14px !important;
    text-align: right;
}

.do-img-list{
    display: flex;
}
.do-img-list div img{
    width : 100%;
}

.summ-how-it-header{
    border-radius: 3px;
    border : 1px solid #ddd;
    margin-top: 20px;
}
.summ-how-it-header h4{
    padding : 10px 15px;
    background: #f5f5f5;
    font-size: 16px;
    cursor: pointer;
}
.summ-how-it-header h4 > span{
    padding : 5px;
}


.summ-how-its-body{
    min-height: 100px;
    padding : 15px;
    border : 1px solid #ddd;
}


.how-it-inner-there{
    margin-top: 20px;
    display: inline-block;
    width : 100%;
    padding : 10px 15px;
    border : 1px solid #ddd;
    background: #f5f5f5;
    color : #6c767f;
    font-size: 14px;
    cursor: pointer;
}



/* grid here */

.gpot-great{
    display: flex;
    flex-direction: column;
}
.gpot-great div{
    display : flex;
    /* text-align: center;
    justify-content: center; */
}
.gpot-great div.gpot-good div{
    border : 1px solid #ddd;
}
.gpot-great div.gpot-good div:nth-child(1){
    justify-content: center;
    line-height: 150px;
    background: #5b9aef;
    color : white;
}
.gpot-great div.gpot-good div:nth-child(2){
    padding : 10px;
}
.gpot-great div.gpot-godgod div:nth-child(1){
    display: flex;
    flex-direction: column;
    background: #5b9aef;
    color : white;
}
.gpot-great div.gpot-godgod div:nth-child(1) span{
    flex : 1;
    text-align: center;
    line-height: 50px;
    border : 1px solid #ddd;
}
.gpot-great div.gpot-godgod div:nth-child(2){
    border : 1px solid #ddd;
    padding : 10px;
}




.gpot-great div:nth-child(1){
    flex : 1;
}
.gpot-great div:nth-child(2){
    flex : 1.5;
}




.how_it_works_fixed-body{
    width : 100%;
}
.how_it_works_fixed-body img{
    width : 100%;
}



.how-it-style-thead{
    color : white;
    background: #5b9aef;
}

.how-it-magic-day{
    color : #333;
}
.how-it-green-light{
    color : #5b9aef !important;
}


/* Standard 768 -> this will change 900 */
@media screen and (max-width:1200px) {
    .how-it-works-tuition-body{
        width : 1200px;
    }
    .how-it-works-section{
        width: 95%;
    }
}
@media screen and (max-width:991px){
    .how-it-works-section{
        width: 100%;
    }
    .section-left{
        width: 20%
    }
    .section-right{
        width: 80%;
    }
}
@media screen and (max-width:767px){
    .section-contents{
        flex-direction: column;
        margin-bottom: 35px;
    }
    .section-left{
        width: 100%;
        align-items: center;
    }
    .section-img{
        width: 30%;
        margin-bottom: 10px;
    }
    .section-right{
        width: 100%;
        align-items: center;
        text-align: center;
    }
    .section-line{
        display: none;
    }
}
@media screen and (max-width:900px) {
    .how-it-works-wapper{
        width : 100%;
    }
    .how-it-works-tuition-body{
        width : 100%;
    }
    .do-img-list{
        flex-direction: column;
    }
    .do-img-list div{
    }
    .do-img-list div img{
        width : 100%;
    }
}
