.pagination{
    justify-content: center !important;
    margin: 5px 0px !important;
}
/* mooc card item */
.moocshow-card-item{
    width: 90%;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
}
.moocshow-card{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}
.moocshow-card-top{
    width: 100%;
    padding: 20px 10px;
    background-color: #fff;
    border: 4px solid #5b9aef;
}
.moocshow-card h3{
    font-size: 20px;
    color: #5b9aef;
    text-align: center;
    font-weight: bold;
}
.moocshow-tutor{
    width: 95%;
    margin: 0 auto;
    display: flex;
}
.moocshow-tutor-name{
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: -30px;
}
.moocshow-tutor-name p{
    font-size: 16px;
    color: #5b9aef;
    font-weight: bold;
}
.moocshow-tutor-pic{
    display: flex;
    width: 50%;
    justify-content: flex-end;
    margin-bottom: -50px;
    z-index: 1000;
}
.moocshow-tutor-pic-box{
    width: 70px;
    height: 70px;
}
.moocshow-tutor-pic-box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
.moocshow-card-bottom{
    width: 100%;
    padding: 20px 0px;
    background-color: #fff;
}
.moocshow-price-box{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 5px 0px;
}
.moocshow-original-price{
    color: #5b9aef;
    font-size: 14px;
}
.moocshow-now-price{
    color: #5b9aef;
    font-size: 16px;
}
.moocshow-apply-btn{
    background-color: #5b9aef;
    color: white;
    border-radius: 5px;
    border: none;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    width: 70%;
}
.moocshow-time{
    text-align: center;
    color: #666;
    padding-top: 5px;
}

.moocshow-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0px;
    background-color: #ebebeb;
}
.moocshow-box{
    display: flex;
    max-width: 1200px;
    width: 80%;
    margin: 0 auto;
}
.moocshow-box-left{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.moocshow-box-right{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.moocshow-title{
    width: 1200px;
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.moocshow-title h1{
    width: 95%;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
}
.moocshow-poster{
    width: 95%;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 5px;
    padding: 30px 0px;
}
.moocshow-poster-img{
    width: 90%;
    margin: 0 auto;
    border: 1px solid #ebebeb;
}
.moocshow-poster-img img{
    width: 100%;
}
.moocshow-curriculum{
    width: 95%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px 0px;
}
.moocshow-curriculum-header{
    display: flex;
    justify-content: center;
    align-items: center;
}
.moocshow-curriculum-header h3{
    text-align: center;
    font-size: 20px;
}
.moocshow-curriculum-header svg{
    font-size: 20px;
    color: #6c767f;
    display: flex;
    margin-left: 20px;
}
.moocshow-curriculum-header svg:hover{
    cursor: pointer;
}
.moocshow-curriculum-textbook{
    width: 80%;
    margin: 0 auto;
    color: #6c767f;
}
.moocshow-curriculum-textbook span{
    font-size: 16px;
}
.moocshow-curriculum-textbook:hover span{
    font-weight: bold;
    cursor: pointer;
}
.moocshow-curriculum-list{
    width: 80%;
    display: flex;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.moocshow-curriculum-list p{
    font-size: 16px;
}
.moocshow-curriculum-title{
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.moocshow-curriculum-title svg{
    font-size: 14px;
    color: #6c767f;
}
.moocshow-curriculum-title:hover p{
    font-weight: bold;
    cursor: pointer;
}
.moocshow-curriculum-time{
    width: 25%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.moocshow-curriculum-time-clock{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    color: #6c767f;
}
.moocshow-curriculum-time-text{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}
.moocshow-curriculum-time-text p > svg{
    font-size: 14px;
}
.moocshow-price{
    width: 95%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px 10px;
    margin-bottom: 30px;
}
.moocshow-price p{
    font-size: 14px;
    text-align: center;
}
.moocshow-original-price{
    color: #5b9aef;
    font-size: 14px;
}
.moocshow-price button{
    background-color: #5b9aef;
    color: white;
    border-radius: 5px;
    border: none;
    margin: 0 auto;
    width: 70%;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    margin-bottom: 10px;
}
.moocshow-price .blink-price-button{
    border-width: 3px;
    border-style: solid;
    border-color: #5ECAC6;
    -webkit-animation: borderBlink 1s linear infinite;
    -moz-animation: borderBlink 1s linear infinite;
    -o-animation: borderBlink 1s linear infinite;
    animation: borderBlink 1s linear infinite;
}
.moocshow-price-time-box{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
}
.moocshow-sample{
    width: 95%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px 0px;
    margin-bottom: 30px;
}
.moocshow-sample-box{
    width: 85%;
    margin: 0 auto;
}
.moocshow-interested{
    width: 95%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px 0px;
}
.moocshow-sample h2, .moocshow-interested h2{
    font-size: 16px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
}
.moocshow-interested-box{
    width: 95%;
    margin: 0 auto;
}
.mooc-show-inner-wapper{
    min-height: 450px;
}

.mooc-show-subc-bg{
    background: #efefef;
}
.mooc-show-subscription{
    width : 100%;
    max-width : 1280px;
    min-height: 600px;
    margin : 0 auto;
    padding : 20px;
}
.mooc-show-subscription > h3{
    margin-bottom: 15px;
    text-indent: 5px;
}

.mooc-show-top-list{
    border-bottom: 1px solid white;
}
.mooc-show-top-list ul{
    margin : 0;
    padding : 10px;
    display: flex;
}
.mooc-show-top-list ul li{
    flex : 1;
    text-align: center;
}
.mooc-show-top-list ul li > div{
    width : 70px;
    height : 70px;
    border-radius: 50%;
    margin : 0 auto;
    cursor: pointer;
}
.mooc-show-top-list ul li > div.active{
    background: white;
}
.mooc-show-top-list ul li > div.active i{
    color : #5ca2e9;
    opacity: 1;
}
.mooc-show-top-list ul li > div.active span{
    color : #333;
    opacity: 1;
}

.mooc-show-top-list ul li > div > i {
    color : #66afe9;
    margin-top: 10px;
    position: relative;
    opacity: .8;
}
.mooc-show-top-list ul li > div > span{
    display: inline-block;
    width : 100%;
    color : #6c767f;
    opacity: .8;
}
.mooc-show-top-list ul li > div:hover i{
    color : #5ca2e9;
    opacity: 1;
}
.mooc-show-top-list ul li > div:hover span{
    color : #333;
    opacity: 1;
}



.mooc-show-top-contents{
    margin-bottom: 70px;
    transition: all .5s;
}
.mooc-show-top-contents ul{
    padding : 0;
    margin : 0;
    display: flex;
    flex-wrap: wrap;
}
.mooc-show-top-contents ul li{
    width : 100%;
    max-width : 380px;
    min-height : 200px;
    margin : 1.2%;
    border : 1px solid #ddd;
    border-radius: 2px;
    box-shadow: 1px 2px 3px #ddd;
    transition: all .7s;
    background: white;
    display: flex;
    box-shadow: 1px 2px 3px #ddd;
}
.mooc-show-top-contents ul li > div{
    flex : 1;
}
.mooc-show-top-contents ul li > div:nth-child(1){
    flex : 0.3;
    display: flex;
    flex-direction: column;
}
.mooc-show-top-contents ul li > div:nth-child(1) > span{
    flex : 1;
    text-align: center;
    font-size: 18px;
}
.mooc-show-top-contents ul li > div:nth-child(1) > span:nth-child(1){
    padding : 20px;
}
.mooc-show-top-contents ul li > div:nth-child(1) > span > img{
    width : 55px;
    height: 55px;
    border-radius: 50%;
}

.mooc-show-top-contents ul li > div:nth-child(2){
    padding : 20px;
    border-left: 1px solid #ccc;
    display : flex;
    flex-direction: column;
}
.mooc-show-top-contents ul li > div:nth-child(2) > div{
    margin-bottom: 5px;
}
.mooc-show-top-contents ul li > div:nth-child(2) > div:nth-child(1){
    flex : 1;
    color : #6c767f;
    font-size: 18px;
    letter-spacing: -0.1px;
    text-indent: 10px;
    vertical-align: text-bottom;
}

.mooc-show-top-contents ul li > div:nth-child(2) > div:nth-child(2){
    flex : 1;
}

.mooc-show-top-contents ul li > div:nth-child(2) > div:nth-child(2) > span{
    display: inline-block;
    border : 1px solid #ddd;
    border-radius: 5px;
    padding : 1px 10px;
    margin : 5px 2px 3px 1px;
    font-size: 12px;
    cursor: pointer;
    letter-spacing: -.07px;
}
.mooc-show-top-contents ul li > div:nth-child(2) > div:nth-child(2) > span:hover{
    background: #ddd;
    color : #333;
}
.mooc-links{
    width: 100%;
    display: flex;
    justify-content: center;
}
.mooc-links-btn{
    border-right: 1px solid #5b9aef;
    padding: 0px 4px;
}
.mooc-links-btn i{
    margin-right: 2px;
}
.mooc-links-btn:last-child{
    border-right: 1px solid transparent;
}
.mooc-show-top-contents ul li > div:nth-child(2) > div:nth-child(3){
    width: 100%;
    border: 1px solid #5b9aef;
    color : #5b9aef;
    cursor: pointer;
    text-align: center;
    padding: 6px 3px;
    border-radius: 4px;
}
.dark-bg{
    position : absolute;
    top : 50%;
    left : 50%;
    width : 0px;
    height: 0px;
    background: rgba(0, 0, 0, .9);
    z-index: 9999999999;
    transition: all 1s;
    opacity: 0;
}
.dark-bg-active{
    padding : 100px 100px;
    top : 0;
    left : 0;
    opacity: 1;
}
#appendM{
    width: 100%;
    position: relative;
}
.dark-active {
    width : 100%;
    height: 100%;
    opacity: 1;
}
.dark-inner{
    width : 0px;
    height: 0px;
    max-width: 900px;
    max-height: auto;
    margin : 0 auto;
    transition: all 1s;
    transition-delay: .5s;
    opacity: 1;
}
.dark-inner > span{
    color : white;
}
.dark-close{
    border : 0;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    top : 5%;
    right : 5%;
    width : 30px;
    height: 30px;
    font-size: 35px;
    line-height: 0px;
    font-family: Arial, Baskerville, monospace;
    text-align: center;
    vertical-align: middle !important;
    color: white;
}
.dark-close:hover{
    color : #ddd;
}
.dark-close:active{
    color : #ccc;
}

.watermark-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start !important;
}
#dark-watermark{
    position: absolute;
    z-index: 99999999;
    display: none;
    width: 100%;
    padding-top: 25px;
    padding-right: 7px;
}
#dark-watermark p {
    color: #fff;
    font-size: 16px;
    text-align: right;
}
.mooc-iframe-wrapper{
    width: 100%;
}
#darkFrame{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex !important;
    border: none;
}
button{
    cursor: pointer;
}
.mooc-show-btn{
    color : white !important;
    cursor: pointer;
}
.mooc-show-btn:hover{
    color : #212529 !important;
}
.mooc-show-btn:active{
    border : 1px solid #212529;
}


.mooc-cover{
    position: relative;
    text-align: center;
}

.mooc-show-tooltips-wr{
    top : -110px;
    right: -110px;
    width : 270px;
    height: 105px;
    position : absolute;
    background: white;
    padding : 10px;
    box-shadow: 1px 2px 3px #ddd;
}

.mooc-show-tooltips{
    width: 250px;
    height: 88px;
    background: white;
    color: #6c767f;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-align: left;
}
.mooc-show-tooltips > div {
    margin-top: 10px;
    display: flex;
    padding : 0 30px;
}
.mooc-show-tooltips > div > button {
    height: 34px;
    color: #fff;
    background-color: #5b9aef;
    border-color: #5ca2e9;
    display: inline-block;
    padding: 6px 12px;
    margin : 0;
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 0px;
}
.mooc-show-tooltips > div > button:nth-child(1){
}
.mooc-show-tooltips > div > button:nth-child(2){
    background: #ccc;
}

#moocCoin{
    box-shadow: 1px 2px 3px #6c767f;
    opacity: 1;
}





.mooc-show-top-note{
    width : 80%;
    min-height: 500px;
    margin : 0 auto;
    padding : 15px;
    color : #6c767f;
    text-align: center;
}
.mooc-show-top-note > iframe{
    width : 100%;
    min-height: 700px;
    border : 0;
    box-shadow: 1px 2px 3px #6c767f;
}
@keyframes borderBlink{
    0%{border-color: #fff;}
    100%{border-color: #5ECAC6;} 
  }
@media screen and (max-width:991px) {
    .moocshow-curriculum-time-clock{
        width: 65%;
    }
    .moocshow-curriculum-time-text{
        width: 35%;
    }
    .moocshow-box{
        flex-direction: column;
        width: 100%;
    }
    .moocshow-box-left {
        width: 100%;
    }
    .moocshow-box-right{
        width: 100%;
    }
    .moocshow-title{
        width: 100%;
        text-align: center;
    }
    .moocshow-title h1{
        text-align: center;
    }
    .moocshow-poster{
        margin: 0 auto;
        box-shadow: 0 2px 3px #ccc;
    }
    .moocshow-curriculum{
        margin: 0 auto;
        margin-top: 10px;
        box-shadow: 0 2px 3px #ccc;
    }
    .moocshow-price{
        width: 95%;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
        box-shadow: 0 2px 3px #ccc;
    }
    .moocshow-interested{
        width: 95%;
        margin: 0 auto;
        box-shadow: 0 2px 3px #ccc;
    }
    .moocshow-curriculum-list, .moocshow-curriculum-textbook{
        width: 90%;
    }
}
@media screen and (max-width:767px) {
    .moocshow-title h1 {
        font-size: 20px;
        font-weight: bold;
    }
    .moocshow-curriculum h3 {
        text-align: center;
        font-size: 18px;
    }
    .moocshow-curriculum-time-clock{
        width: 35%;
    }
    .moocshow-curriculum-time-text{
        width: 65%;
    }
    #dark-watermark{
        padding-top: 20px;
    }
    #dark-watermark p {
        font-size: 14px;
    }
    .dark-bg-active{
        padding: 100px 50px;
    }
}
@media screen and (max-width:480px) {
    #dark-watermark p {
        font-size: 13px;
    }

}

