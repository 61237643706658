

.subject-select-btns{

}
.subject-select-fix{
    height : 250px;
    border : 1px solid red;
}


.subject-select-btns ul {
    margin : 0;
    padding : 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items : stretch;
}
.subject-select-btns ul li {
    flex : 1;
    margin: 0 1% 0 0;
}
.subject-select-btns ul li button {
    font-size: 14px;
}
.subject-select-btns ul li:nth-child(3){
    margin: 0;
}
.tutorreg-subject-ul{
    text-align: left;
}

.sub-sel-arrow{
    padding : 20px;
    padding-left : 50%;

}
.sub-sel-arrow > div{
    margin : 0;
}


.selected-area-ul {
    margin : 0;
    padding : 5px;
}

.selected-area-ul > li {
    box-sizing: border-box;
    display: flex;
    margin-bottom: 15px;
}
.selected-area-ul > li > span {
    box-sizing: border-box;
    /* display: inline-block; */
    height : 100%;

}
.selected-area-ul li > span:nth-child(1) {
    /* background: rgba(247, 175, 103, 0.94); */
    color : white;
    text-align: center;
    border-radius: 10px;
    width : 35%;
    margin-right: 10px;
}
.selected-area-ul li > span:nth-child(2) {
    flex: 1;
}
.selected-area-ul li > span:nth-child(3) {
}


.base-select {
}
.with-search-input {
}


.select-area{
}
.sub-sel-list-wrapper {
}

@media screen and (min-width:991px) {
    .select-area{
        width : 223px;
    }
    .sub-sel-list-wrapper {
    }
    .sub-sel-list-wrapper > div.sub-sel-resize {
        padding : 10px;
        display: flex;
        margin : 0 auto;
        text-align: center;
    }
    .sub-sel-list-wrapper > div.sub-sel-resize > div{
        
    }
    .base-select {
        width : 100px;
    }
    .selected-area {
        width : 300px;
    }
    .sub-sel-arrow{
        box-sizing: border-box;
        padding : 0 15px;
    }
    .sub-sel-arrow > div{
        margin-top : 130px;
    }
    .subject-select-btns ul{
        width : 230px;
        margin-left : 10px;
    }
}