.card{
    width: 98%;
    margin: 0 auto;
}
.blogs-container{
    display: inline-block !important;
    width : 100%;
    min-height: 600px;
}
.blogindex-container{
    max-width: 1200px !important;
}
.blogindex-container .article-item-image {
    height: 150px;
    width: 100%;
}

.blogindex-container .catalogue-line {
    margin: 3px 0px 3px 0px;
    border-top: dotted 1px;
}

.blogindex-container .blog-catalogue-btn {
    padding: 2px 3px;
}

.blogindex-container .blog-filter-btn {
    font-size: 16px;
}

.blogindex-container .article-card {
    min-height: 366px;
    box-shadow: 2px 2px 3px #ddd, -2px 0px 3px #ddd;
}
.art-area{
    display: flex;
    flex-direction: column;
}
.blogindex-container .art-area:hover * {
    text-decoration: underline;
}

.blogindex-container .art-area-item {
    font-size: 18px;
    color: #6c767f;
    font-weight: bold;
}

.blogindex-container .art-area-info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.blogindex-container .card_thumbnail>div>div>img {
    width: auto;
}

.blogindex-container .article-item-image img {
    width : 100% !important;
    height: 100%;

}

.blogshow-container .comment-item-pic {
    width: 24px;
    height: 24px;
}

.blogshow-container .btn-blue-border,
.blogshow-container .btn-blue-bg {
    font-size: 1.2rem;
}

.blogedit-container .resizenone {
    resize: none;
}

.blogedit-container .submit-btn {
    margin: 0;
}

.blog_hidden_class{
    overflow: hidden;
    /* display: none; */
}
.blog-iframe{
    width : 100%;
    height: 100%;
}

.blog-over-cover{
    height : 100%;
    width :100%;
    position: absolute;
    top : 0;
}

.embed-responsive-16by9 > .embed-responsive-item{
    height: 100% !important;
}
.blog-comment-not-login{
    border: none;
    font-size: 14px;
    color: #333;
}
.blog-comment-not-login:hover{
    text-decoration: underline;
    color: #337ab7;
}
.blogs-comments{
    margin-top: 10px;
    padding-top: 30px;
    border-top: 1px solid #ddd;
}
.greybackground{
    display: inline-block !important;
    width : 100%;
    height: 100% !important;
}
/* table{
    width: 0px !important;
} */
.blog-back-btn{
    font-size: 17px !important;
    background-color: #fff !important;
    border: 2px solid #5b9aef !important;
    opacity: .8 !important;
    padding: 5px 15px 5px 15px !important;
    border-radius: 13px !important;
    cursor: pointer;
}
.blog-show-card{
    margin-top: 20px;
}
@media screen and (max-width : 991px){
    .blog-show-card-md{
        margin-top: 20px;
    }
}
@media screen and (max-width : 767px){
    .card{
        width: 100% !important;
    }
}