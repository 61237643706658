.sign-up-area {
  margin-top: 40px;
  margin-bottom: 60px;
}

.sign-up-area > h1 {
  text-align: center;
  margin-bottom: 20px;
}

.userreg-one-line div p {
  font-size: 2rem;
}

.userreg-one-line div input {
  width: 100%;
  margin-top: 10px;
}

.userreg-submit {
  /*margin-top: 20px;*/
}

.userreg-pic {
  width: 60%;
}

.userreg-tutor-btn {
  margin-top: 15px;
  color: white;
  padding: 2px 10px;
  width: 100%;
}

.userreg-student-btn {
  margin-top: 15px;
  color: white;
  padding: 2px 10px;
  width: 100%;
}




/* 小屏幕（平板，大于等于 768px） */
@media (min-width: 768px) {
  .userreg-one-line div p {
    text-align: right;
  }

  .userreg-one-line div input {
    margin-top: 0;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 992px) {

}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 1200px) {

}




















.form-area {
  margin-top: 15px;
}

.form-area > input {
  border-color: #299cd3;
  border-width: 2px;
  border-radius: 15px;
}

.form-area > label {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.btn-area {
  margin-top: 40px;
  text-align: center;
}

.userreg-blue-color {
  width: 180px;
  color: white;
  background-color: #299CD3;
  opacity: 0.75;
  margin: 5px 20px;
  border-radius: 15px;
}
