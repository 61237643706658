.credit-history-wapper{
    padding-bottom: 100px;
}

.credit-history-head{
    min-height: 20px;
    padding : 15px;
    padding-bottom: 5px;
    box-shadow: 1px 2px 3px #6c767f;
    margin-bottom: 20px;
}
.credit-history-head > h3{
    margin-bottom: 0;
}
.credit-history-head-won{
    height: 100%;
    display: flex;
}

.credit-history-head-won > div{
    flex : 1;
}

.credit-history-head-won > div:nth-child(1){
    color: #6c767f;
    font-size: 23px;
    letter-spacing: -1px;
}
.credit-history-head-won > div:nth-child(1) > span{
    font-size: 36px;
    letter-spacing: -1px;
    font-weight: 700;
}
.credit-history-head-won > div:nth-child(2) > div{
    width : 200px;
    height: 100%;
    float: right;
    display: flex;
}
.credit-history-head-won > div:nth-child(2) > div > span{
    flex : 1;
}
.credit-history-head-won > div:nth-child(2) > div > span > div:nth-child(1){
    text-indent: 2px;
}
.credit-history-head-won > div:nth-child(2) > div > span:nth-child(1) > div > select{
    width : 100%;
    height: 33px;
    color: #555;
    font-size: 14px;
    background: white;
}



.t-dash-right-body{
    box-sizing: border-box;
    width : 75%;
    min-height: 300px;
    padding : 20px 10px;
    z-index: 1;
}

.td-list-body{
    padding : 20px;
}





.tdashboard-td-invoice{
    position: absolute;
    top : 0;
    left : 0;
    width : 100%;
    height : 100%;
    background: rgba(0,0,0,0.3);
    z-index: 9999999999;
    overflow : scroll;
    overflow-x: hidden;
    padding-bottom: 100px;
}
.tdash-normal-state{
    display: inline-block;
    width : 100%;
    height: 100%;
    border-radius: 5px;
    text-align: center;
    padding: 50px 0;
    margin-bottom: 100px;
}
.tdash-normal-state > div{
    display: inline-block;
    width : 50%;
    box-shadow: 1px 2px 13px #333;
    background: white;
    border-radius: 5px;
    margin : 0 auto;
    text-align: center;
    padding : 20px;
    animation: showingTdash .7s ease;
}
.tdash-normal-state > div > textarea{
    width : 100%;
    min-height: 180px;
    padding : 15px;
    border: 5px dashed #ddd;
    font-size: 15px;
}
.tdash-common-state > h3{
    font-size: 18px;
    text-align: left;
}
.tdash-common-state > p{
    font-size: 14px;
    text-align: left;
}
.tdash-common-state > div{
    font-size: 14px;
    color: #6c767f;
}
.tdash-common-state > .modal-header h3{
    text-align: center !important;
    font-size: 18px !important;
}
.tdash-common-state > div > button{
    padding : 5px 20px;
    margin : 5px;
    border-radius: 13px;
    border : 2px solid #5b9aef;
    font-size: 18px;
}
.detail-invoice-btn{
    text-align: center !important;
}
.tdash-common-state > div > button:hover{
    color: #333;
}
.modal-header > h3{
    text-align: center !important;
}
.modal-header button{
    border: none !important;
}
.teacher-dashboard-invoice-modal{
    border : 2px solid #ccc !important;
    text-align: left;
}


.tdash-left-contact-s{
    display: flex;
}
.tdash-left-contact-s > div{
    margin : 0 auto;
}
.tdash-left-contact-s > div > div{
    display: inline-block;
    min-width : 500px;
    padding : 5px;
}
.tdash-left-contact-s > div > div > span{
    display: inline-block;
    width : 200px;
    height : 100%;
    box-sizing: border-box;
}
.tdash-left-contact-s > div > div > span:nth-child(1){
    text-align: right;
}
.tdash-left-contact-s > div > div > span:nth-child(1) img{
    width : 35px;
    height : 35px;
}
.tdash-left-contact-s > div > div > span:nth-child(2){
    width : 300px;
    text-align: left;
    text-indent: 25px;
}

.tdash-left-common-advice{
    margin : 5px 0;
    background: #5b9aef;
    color : white;
}

@media screen and (max-width: 991px){
    .tdash-normal-state > div{
        width: 60%;
    }
    .tdash-tapply-wapper ul{
        flex-direction: column;
    }
    .tdash-tapply-wapper ul li{
        margin: 0 auto;
        margin-bottom: 15px;
        width: 95%;
    }
    .t-dash-right-body{
        margin: 0 auto;
        width: 100%;
    }
}
@media screen and (max-width: 767px){
    .tdash-normal-state > div{
        width: 90%;
    }
}
@keyframes showingTdash{
    0%{
        /* border : 500px solid #5b9aef; */
        opacity: 0;
        transform: translateY(100px);
    }
    50%{
        opacity: 1;
    }
    100%{
        transform: translateY(0px);
    }
}