.admission-wrapper{
    width: 100%;
}
/* admission country sec */
.admission-country{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.admission-country h2{
    font-size: 2.2rem;
}
.admission-country-box{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.admission-country-item{
    width: 35%;
    margin: 0% 1.3%;
}
.admission-country-item-text{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}
.admission-country-item-text img{
    width: 100%;
    height: 100%;
}
.admission-country-item-text h2{
    font-size: 2.3rem;
    position: absolute;
}

/* admission consultant sec */
.admission-consultant{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.admission-consultant-box{
    width: 60%;
    margin: 0 auto;
}
.admission-consultant-content{
    width: 100%;
}
.admission-consultant-content h2{
    font-size: 2.2rem;
}
.admission-consultant-content i{
    font-size: 1rem;
}

/* admission pictures sec */
.admission-pictures{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.admission-picture-item{
    width: 25%;
    margin: 0 auto;
}
.admission-picture-item img{
    width: 100%;
}

/* admisssion progress sec */
.admission-progress{
    width: 100%;
    display: flex;
    justify-content: center;
}
.admission-progress-box{
    width: 70%;
    margin: 0 auto;
}
.admission-progress-content{
    width: 100%;
}
.admission-progress-content h2{
    font-size: 2.2rem;
}
.admission-progress-content span{
    font-size: 1.2rem;
}


/* responsive */
@media screen and (max-width:767px) {
    .admission-country-box{
        width: 97%;
    }
    .admission-country-item{
        width: 100%;
    }

    .admission-consultant-box{
        width: 95%;
    }

    .admission-picture-item{
        width: 50%;
    }

    .admission-progress-box{
        width: 95%;
    }
}