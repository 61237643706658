
.main-networking-wr{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
}
.main-networking-wr .main-mooc-box{
    width: 85%;
    display: flex;
    margin: 0 auto;
}
.main-mooc-ment{
    width: 50%;
    margin-right: 20px;
    text-align: right;
}
.main-mooc-ment button{
    padding : 5px 20px;
    font-size: 1.2rem;
    border-radius: 5px;
    background: #5b9aef;
    color : white;
    border : 0;
}
.main-networking-wr div h1{
    font-weight: 700;
    margin-top: 30px;
}
.main-networking-wr div h4{
    font-size: 18px;
}
.main-mooc-img{
    width: 50%;
}
.main-mooc-img:hover{
    cursor: pointer;
}
.main-mooc-img > img{
    width: 100%;
    height: 100%;
}

/*  Standard 768 */
@media screen and (max-width:767px) {
    .main-networking-wr .main-mooc-box{
        width: 90%;
    }
}
@media screen and (max-width:767px) {
    .main-networking-wr .main-mooc-box{
        flex-direction: column;
    }
    .main-mooc-ment{
        text-align: left;
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0px;
    }
    .main-mooc-img{
        width: 100%;
    }
}