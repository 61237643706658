.ot-wrapper{
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 20px;
}
.ot-video-wrapper{
    width: 60%;
    margin: 0 auto;
}
.ot-video-button-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
}
.ot-video-button-wrapper button{
    margin: 20px;
    padding: 5px 20px;
    border: 2px solid #5b9aef;
    border-radius: 10px;
    background-color: #5b9aef;
    font-size: 17px;
    color: white;
    text-align: center;
    display: flex;
    margin-top: 10px;
}
.ot-video-button-wrapper button:hover{
    border: 2px solid #5b9aef;
    background-color: white;
    color: #5b9aef;
}
.ot-video-modal{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
#confirmOTModal{
    width: 100%;
    justify-content: center;
}
#watchLaterModal{
    width: 100%;
    justify-content: center;
}
#openConfirmOTModal{
    display: none;
}
.modal-open .modal {
    display: flex!important;
    justify-content: center!important;
}
