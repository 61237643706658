
/*** HELLENA ***/

/* admissionUs banner sec */
.admissionUs-banner{
    width: 100%;
    display: flex;
    justify-content: center;
}
.admissionUs-banner-box{
    width: 100%;
    height: 287px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.admissionUs-banner-content{
    width: 100%;
    z-index: 100;
}
.admissionUs-banner-content h2{
    font-size: 2.5rem;
}
.admissionUs-banner-content h4{
    font-size: 1.2rem;
}

/* admissionUs map sec */
.admissionUs-map{
    width: 100%;
    display: flex;
    justify-content: center;
}
.admissionUs-map-box{
    width: 95%;
    margin: 0 auto
}
.admissionUs-map-title{
    width: 100%;
    display: flex;
    justify-content: center;
}
.admissionUs-map-title h2{
    font-size: 1.9rem;
}
.admissionUs-map-content{
    margin-top: 2%;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
}
.admissionUs-map-item{
    width: 25%;
}

/* admissionUs field sec */
.admissionUs-field{
    width: 100%;
    display: flex;
    justify-content: center;
}
.admissionUs-field-box{
    width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 40px;
}
.admissionUs-field-title{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.admissionUs-field-title h2{
    font-size: 1.9rem;
}
.admissionUs-field-title p{
    font-size: 0.9rem;
}
.admissionUs-field-content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.admissionUs-item-wrapper{
    width: 30%;
    margin: 0.5% 0.8%;
}
.admissionUs-field-item{
    width: 100%;
    padding: 0% 5%;
}
.admissionUs-field-item h2{
    font-size: 1.9rem;
}
.admissionUs-field-item h5{
    font-size: 0.85rem;
}

/* admission mentUs sec */
.admissionUs-ment{
    width: 100%;
    display: flex;
    justify-content: center;
}
.admissionUs-ment-box{
    width: 100%;
    height: 313px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admissionUs-ment-content{
    width: 100%;
    z-index: 100;
}
.admissionUs-apply button:focus{
    outline: none;
}

/* responsive */
@media screen and (max-width:991px) {
    .admissionUs-field-box{
        width: 70%;
        margin: 0 auto;
    }
    .admissionUs-field-content{
        flex-direction: column;
    }
    .admissionUs-item-wrapper{
        width: 100%;
        margin: 1.3% 0%;
    }
}
@media screen and (max-width:767px) {
    .admissionUs-field-box{
        width: 90%;
        margin: 0 auto;
    }
    .admissionUs-map-content{
        flex-direction: column;
    }
    .admissionUs-map-item{
        width: 100%;
    }
}