.title {
  margin-top: 60px;
}

.message {
  font-size: 1.6rem;
}

.book-record-area {
  min-height: 300px;
  margin-top: 30px;
  margin-bottom: 100px;
}

.record-box {
  border: solid rgba(41, 156, 211, 0.76);
  border-top: none;
}

.record-box:first-child {
  border: solid rgba(41, 156, 211, 0.76);
}

.book-detail-title {
  margin-bottom: 15px;
}

.book-detail-message {
  margin-bottom: 18px;
}

.book-detail-p {
  margin-bottom: 2px;
}

.book-hour {
  color: rgba(41, 156, 211, 0.76);
  margin-left: 20px;
}

.book-btn-area {
  text-align: center;
}

.book-btn {
  background-color: rgba(41, 156, 211, 0.75);
  border: none;
  padding: 8px 15px;
  border-radius: 10px;
  color: white;
  margin-top: 45px;
}
