/* html, body {
  font-family: 'Spoqa Han Sans',sans-serif!important;
} */

.App {
    font-family: 'Spoqa Han Sans',sans-serif !important;
    min-height: 600px;
}
.cut_gurume{
    position: fixed;
    top : 0;
}
.slideDownNav{
  animation: slideDownNav 0.2s 1 linear;
}

.showNotify-pivot {
  position: absolute;
  right : 50px;
  top : 20px;
  width : 0.1px;
  height : 0.1px;
  z-index: 9999999999999;

}
.showNotify-pivot > .showNotify {
  width : 350px;
  height : 0.1px;
  float: right;
}
.showNotify-pivot > .showNotify > div{
  display: block;
  padding : 10px;
  padding-top: 14px;
  text-indent: 3px;
  border-radius: 7px;
  font-size: 15px;
  animation: slideDownShowNotify 3.9s 1 linear;
}
.showNotify-pivot > .showNotify > div > i {
  margin-right : 5px;
}
.showNotify-pivot > .showNotify > div.type-positive{
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  box-shadow: 0 3px 5px rgba(69, 76, 139, 0.3);
}
.showNotify-pivot > .showNotify > div.type-negative{
  color: #923a1c;
  background-color: #ffbca6;
  border-color: #dbc5bd;
  box-shadow: 0 3px 5px rgba(94, 56, 56, 0.3);
}
.showNotify-pivot > .showNotify > div.type-info{
  color: white;
  background-color: #bfdb9f;
  border-top : 1px solid white;
  box-shadow: 0 3px 5px #a4cf72;
}



input.comm-btn-style{
  border : 0.5px solid #ccc;
  border-radius: 5px;
  font-size: 11px;
  padding : 2px 13px;
  color : #6c767f;
  margin-right: 10px;
  background : white;
}
button.comm-btn-style{
  border : 0.5px solid #ccc;
  border-radius: 5px;
  font-size: 11px;
  padding : 2px 13px;
  color : #6c767f;
  margin-right: 10px;
  background : white;
}
input {
  border : 0.5px solid #ccc;
}
button {
  border : 0.5px solid #ccc;
}
select{
  border : 0.5px solid #ccc;
  background-color: none;
}
span{
  font-family: 'Spoqa Han Sans', sans-serif !important;
}

@keyframes slideDownNav {
  0% {
    transform: translateY(-55px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}


@keyframes slideDownShowNotify {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  5% {
    transform: translateY(5px);
    opacity: 1;
  }
  80% {
    transform: translateY(5px);
    opacity: 1;
  }
  100% {
    transform: translateY(5px);
    opacity: 0;
  }
}


*:focus {
  outline: none;
}
