.userreg-wrapper{
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}
.userreg-part-a{
    width : 100%;
    box-sizing: border-box;
}
.userreg-component-a-header{
    margin-top : 10px;
    width : 100%;
}
.userreg-component-a-header > p{
}
h2.userreg-part-a-h2{
    display: inline-block;
    color : #5b9aef;
    width : 100%;
    text-align: center;
    margin : 20px 0;
}
.userreg-part-a-sub-wrapper{
    width : 50%;
    margin: 0 auto;
}
.userreg-part-a-component-a{
    display: block;
    width : 100%;
    height : 100%;
    margin-bottom: 20px;
}
.userreg-component-a-header {
    margin-bottom: 10px;
}
.userreg-component-a-header-b {
    display: block;
    width : 50%;
    height : 100%;
    float: left;
    margin-bottom: 10px;
}
.padding-left-20{
    padding-left: 15px;
}
.userreg-input-area {
    display: inline-block;
    width : 100%;
}
.userreg-input-area > div {
    width : 50%;
    box-sizing: border-box;
    float: left;
    font-size: 14px;
}
.userreg-input-area > div > span{
    width : 100%;
    display: inline-block;
    text-align: left !important;
    text-indent: 5px;
}

.userreg-input-area-email{
    display: inline-block;
    width : 100%;
}
.userreg-input-area-email > div {
    width : 100%;
    box-sizing: border-box;
    float: left;
    font-size: 14px;
}


.userreg-input-area > div > input{
    font-size: 14px;
}
.userreg-input-area > div:nth-child(1){
    padding-right : 15px;
}
.userreg-input-area > div:nth-child(2){
    padding-left : 15px;
}


.userreg-part-a-component-sex {
    text-align: center;
    color : #6c767f;
    font-size: 14px;    
    margin-top: 15px;
    margin-bottom: 35px;
}
.userreg-part-a-component-sex > div{
    display: inline-block;
}
.userreg-part-a-component-sex > div:nth-child(1){
    font-size: 20px;
    margin-right: 20px;
}
.userreg-part-a-component-sex > div:nth-child(2){
}
/* .userreg-part-a-component-sex > span > input{
    border : 1px solid red;
    width : 30px;
    height: 30px;
} */

.userreg-flex{
}
.userreg-flex ul{
    padding : 0;
    width : 300px;
    display: flex;
}
.userreg-flex > ul > li{
    flex: 1;
    display: flex;
}
.userreg-flex > ul > li > label{
    width : 50px;
    margin : 0;
    
}
.userreg-flex > ul > li > span{
    margin : 0;
}

.checkmark{
    position: relative;
}



.user-btns-wrapper{
    text-align: center;
    font-size: 14px;
}
.user-btns-wrapper > div > button{
    text-align: center;
    font-size: 14px;

}
.user-btns-explain{
    margin-bottom: 80px;
}
.user-btns-explain > div {
    margin-top : 25px;
    color : #898989;
}


.row {
}


/* common userreg */

.userreg-input{
    width : 50%;
}




@media  (min-width: 100px) and (max-width : 767px) {
    .userreg-part-a-sub-wrapper{
        width : 90%;
        margin: 0 auto;
    }
}
