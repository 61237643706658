.subject-teach .col-md-6 .col-sm-4, .subject-teach .col-md-6 .col-sm-8 {
  padding: 2px;
}

.student-reg-contact-one {
  margin-top: 5px;
}

.student-reg-contact-input {
  border-radius: 8px !important;
  border: solid 2px #5b9aef;
  float:left;
}

.student-reg-contact-pic {
  margin-right: 5px;
  width: 35px;
  /*float: left;*/
}

.student-reg-submit-btn {
  margin-top: 40px;
  margin-bottom: 40px;
  min-width: 180px;
  padding: 3px;
  color: white;
  font-size: 2rem;
}

.student-about-myself {
  margin-top: 40px;

}

.stureg-reg-area {
  margin-top: 20px;
  margin-bottom: 40px;
}

.stureg-reg-area > h1 {
  color: #299cd3;
  opacity: 0.75;
}


.stureg-form-control {
  border-color: #299cd3;
  border-width: 2px;
  border-radius: 15px;
}

.stureg-curriculum-col {
  text-align: center;
}

.stureg-curriculum-input {
  display: none;
}

.stureg-btn {
  background-color: white;
  margin: 5px 0;
  width: 100%;
  border-radius: 20px;
  border-width: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.stureg-blue-btn {
  border-color: #299cd3;
}

.stureg-blue-btn:hover {
  background-color: #299cd3;
}

.stureg-blue-selected-btn {
  background-color: #299cd3;
  color: white;
}

.stureg-orange-btn {
  border-color: #E8841E;
}

.stureg-orange-btn:hover {
  background-color: #E8841E;
}

.stureg-orange-selected-btn {
  background-color: #E8841E;
  color: white;
}

.stureg-green-btn {
  border-color: #80b73f;
}

.stureg-green-btn:hover {
  background-color: #80b73f;
}

.stureg-green-selected-btn {
  background-color: #80b73f;
  color: white;
}

.stureg-dropdown-menu {
  width: 100%;
  border: none;
  box-shadow: none;
}

.stureg-conditions {
  color: rgba(41, 156, 211, 0.75);
}

.stureg-conditions:hover {
  text-decoration: none;
}

.stureg-btn-reg {
  color: white;
  background-color: #299cd3;
}
