.tp-profile-children{
}
.tp-profile-children ul{
    padding : 0;
    margin : 0;
    display: inline-block;
    width : 100%;
    height: 100%;
    border-top: 2px solid #ddd;
}
.tp-profile-children ul li{
    display: flex;
    border-bottom: 0.5px solid #ddd;
    margin-bottom: 5px;
    color : #ccc;
    align-items: center;
    padding: 5px 0px;
}
.tp-profile-children ul li div{
    flex : 1 1;
    padding : 0px;
}
.tp-profile-children ul li div:nth-child(1){
    flex : 0.3;
    text-align: left;
    margin-left: 5px;
}
.tp-profile-children ul li div:nth-child(2){
    margin-right: 5px;
}
.tp-profile-children ul li div:nth-child(1) > span {
    display: block;
    padding : 0px;
}

.tp-profile-children ul li div:nth-child(1) > span:nth-child(2) {
    margin-top : 5px;
    font-size: 17px;
    color: #6c767f;
}
.tp-profile-children ul li div:nth-child(1) > span:nth-child(3) {
    color : #6c767f;
    font-size: 13px;
}

.tp-profile-children ul li div:nth-child(1) > span > img{
    width : 25px;
    height : 25px;
}
.tp-profile-children ul li div:nth-child(2) > span:nth-child(1){
    display: block;
    text-align: justify;
    font-size: 14px;
    color : #6c767f;
}
.tp-profile-children ul li div:nth-child(2) > span:nth-child(2){
    display: block;
    float: right;
    cursor: pointer;
    color : #6c767f;
}




/* ADMIN */
.tp-profile-children-admin{
}

.tp-profile-children-admin ul{
    padding : 0;
    margin : 0;
    display: inline-block;
    width : 100%;
    height: 100%;
    border-top: 2px solid #ddd;
}
.tp-profile-children-admin ul li{
    display: flex;
    border-bottom: 0.5px solid #ddd;
    margin-bottom: 5px;
    align-items: center;
    padding: 5px 0px;
}
.tp-profile-children-admin ul li div{
    flex : 1 1;
}
.tp-profile-children-admin ul li div:nth-child(1){
    flex : 0.3;
    position : relative;
    text-align: left;
    margin-left: 5px;
}
.tp-profile-children-admin ul li div:nth-child(2){
}

.tp-profile-children-admin ul li div:nth-child(1) > span {
    font-size: 13px;
    display: block;
}
.tp-profile-children-admin ul li div:nth-child(1) > span:nth-child(1) {
    font-size: 14px;
    padding : 0;
}
.tp-profile-children-admin ul li div:nth-child(1) > span:nth-child(2) {
    color : #6c767f;
    margin-top: 5px;
    font-size: 17px;
}

.tp-profile-children-admin ul li div:nth-child(1) > span > img{
    width : 25px;
    height : 25px;
}
.tp-profile-children-admin ul li div:nth-child(2) > span:nth-child(1){
    display: block;
    text-align: justify;
    font-size: 14px;
    color : #6c767f;
}
.tp-profile-children-admin ul li div:nth-child(2) > span:nth-child(2){
    display: block;
    text-align: right;
    cursor: pointer;
}


@media screen and (max-width:992px) {

    .tp-profile-children ul li div:nth-child(1){
        flex : 0.3;
        position : relative;
    }
    .tp-profile-children ul li div:nth-child(2) > span:nth-child(1){
        font-size: 14px;
    }
    .tp-profile-children-admin ul li div:nth-child(2) > span:nth-child(1){
        font-size: 14px;
    }
}