.main-blog-wapper{
    width : 430px;
    min-height: 180px;
    margin-top: 100px;
    float: right;
    transition: all 1s;
    padding-right : 10px;
}

.main-blog-wapper > h1{
    text-align: right;
}
.main-blog-wapper > h4{
}
.main-blog-wapper > h4 > span{
    display: inline-block;
    width : 100%;
    text-align: right;
}
.main-blog-wapper > h4 > span:nth-child(1){
    font-size: 1.5rem;
}
.main-blog-wapper > h4 > span:nth-child(2){
    text-indent: 30px;
}
.main-blog-wapper > button{
    float: right;
    margin : 10px;
    padding : 5px 20px;
    font-size: 1.2rem;
    border-radius: 5px;
    background: #5b9aef;
    color : white;
    border : 0;
}

.main-blog-wapper > button:hover{
   cursor: pointer;
}




/*  Standard 768 */
@media screen and (max-width:767px) {

}