.native-tutors-banner{
    height: 220px;
}

.native-tutors-box{
    padding : 20px;
    padding-top: 90px;
}

.native-tutors-content{
}

.native-col-white{
    color : white;
}
.native-col-blue{
    color: #66afe9;
}
.native-col-blue:hover{
    color : white;
}


.native-tutors-cons{
    min-height: 300px;
    padding : 0px 50px;
}

.native-tutors-cons > span{
}
.native-tutors-header{
    padding-bottom: 10px;
    padding-left : 1%;
}
.native-tutors-header > span{
    color: #6c767f;
    font-size: 24px;
    font-weight : 700;
    border-bottom: 3px solid rgba(102, 175, 233, 1);
}

.margin-top-100{
}

.native-tutors-cona{
    max-width: 1000px;
    min-width : 1000px;
    margin : 0 auto;
}

.native-tutors-cona ul{
    padding : 0;
    display: flex;
}
.native-tutors-cona ul li{
    flex : 1;
    min-height: 380px;
    margin : 5px 10px;
    padding: 15px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 3px #ccc;
}
.native-tutors-cona ul li > div{
    flex : 1;
}
.native-tutors-cona ul li > div:nth-child(1){
    padding : 15px;
    margin : 0 auto;
}
.native-tutors-cona ul li > div:nth-child(1) > img{
    display: inline-block;
    width : 120px;
    height: 120px;
    border-radius: 50%;
    margin : 0 auto;
}
.native-tutors-cona ul li > div:nth-child(2){
    flex : 4;
}
.native-tutors-cona ul li > div:nth-child(3){
}

.light-c{
    font-weight: 200 !important;
}
.native-tutors-profile-btn{
    border : 1px solid #ccc;
    border-radius: 3px;
    float: right;
    margin : 5px;
    padding : 6px 12px;
}
.paddingG{
    padding : 50px 0;
    font-size: 28px;
    text-align: center;
    color : #66afe9;
}
.paddingG > span{
    color : black;
}