.modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000;
    opacity: 0.4;
    z-index: 800;
}
.modal-wrapper {
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 9999999;
    top: 0;
    left: 25px;
}
.modal-dialog-wrapper {
    width: 30%;
    display: flex;
    justify-content: center;
}
.modal-dialog-wrapper .modal-dialog {
    max-width: 350px;
    margin-right: 10px;
}
.modal-dialog-wrapper #main-modal-1 {
    width: 100%;
    margin: 0 auto;
}
.modal-dialog-wrapper .modal-header {
    border-bottom: 1px solid transparent !important;
    padding: 0px 10px !important;
    padding-top: 10px !important;
}
.modal-dialog-wrapper .modal-header .modal-close-btn {
    width: 100%;
    text-align: right;
    font-weight: bold;
}
.modal-dialog-wrapper .modal-body {
    padding-top: 0px !important;
    padding-bottom: 10px !important;
}
#main-modal-1 .modal-img {
    display: none;
}
#main-modal-2 .modal-footer button{
    display: none;
}
.modal-img {
    width: 100%;
}
.modal-img > img {
    width: 100%;
    height: 100%;
}
.modal-dialog-wrapper .modal-footer {
    border-top: 1px solid transparent !important;
    padding: 0px 10px !important;
    padding-bottom: 10px !important;
    width: 100%;
    justify-content: center;
}
.modal-dialog-wrapper .modal-footer button {
    width: 100%;
    background-color: #efefef;
    border-radius: 5px;
    border: none;
    color: #333;
}
.modal-dialog-wrapper .do-not-open-modal {
    margin-left: 10px;
}
@media screen and (max-width: 991px) {
    .modal-dialog-wrapper {
        width: 50%;
    }
}
@media screen and (max-width: 767px) {
    .modal-wrapper {
        left: 0px;
    }
    .modal-dialog-wrapper {
        width: 100%;
        flex-direction: column;
        margin: 0 auto;
        justify-content: center;
    }
    .modal-dialog-wrapper .modal-dialog {
        margin: 0 auto;
        margin-top: 20px;
    }
    .modal-dialog-wrapper .modal-dialog:last-child {
        margin-bottom: 20px;
    }
}
