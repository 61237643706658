/* createHtml */
.tuitions_bill-html-wapper {
    width : 70%;
    /* max-width: 1000px; */
    margin : 0 auto;
}


/* createPartB */
.tuition_bill-part-b-wapper{
    position: relative;
}

.tuition_bill-part-b-wapper > h4 {
    font-family: 'Spoqa Han Sans',sans-serif!important;
}
.tuition_bill-part-b-wapper > h3 {
    font-family: 'Spoqa Han Sans',sans-serif!important;
    font-size: 24px;
}




/* tuitions_bill checkbox */
.tuitions_bill-part-b-grid {
    display: inline-block;
    width : 100%;
}

.tuitions_bill-part-b-grid > ul{
    padding : 0;
    display: flex;
}
.tuitions_bill-part-b-grid > ul li{
    padding : 0;
    margin-right: 10px;
    color : #333333;
}



/* callCalenders START */
.tuitions_bill-calender-wapper{
    display: inline-block;
    width : 100%;
    box-sizing: border-box;;
}
.tuitions_bill-calender-wapper > div.tuitions_bill-calender-innerwapper{
    display: inline-block;
    box-sizing: border-box;
    width : 50%;
    padding : 5px;
}
.tuitions_bill-part-b-calendar {
    position: relative;
    width : 100%;
    display: flex;
}
.tuitions_bill-part-b-line-fixer {
    background : white;
}
.react-calendar{
    z-index: 99999;
}
.tuitions_bill-part-b-input-span{
    flex : 1 auto;
}
.tuitions_bill-part-b-input-span > input{
    height: 100%;
}
.react-calendar {
    position: absolute;
}
/* callCalenders END */





.tuition_bill-part-b-hour-wapper {
    display: flex;
}

.tuition_bill-part-b-hour-wapper > input {
    display: inline-block;
    width : 33%;
    height: 34px;
}
.tuition_bill-part-b-hour-wapper > select {
    margin-left: 7px;
    background: white;
}




/* tuitions_bill-teaching-list-wapper */

.tuitions_bill-teaching-list-wapper {
    display: flex;
    box-sizing: border-box;
}
.tuitions_bill-teaching-list-wapper ul{
    border : 2px solid rgb(69, 157, 242);
    height: 270px;
    border-radius: 10px;
    flex: 1;
    margin : 5px;
    padding : 15px;
    box-sizing: border-box;
    overflow: scroll;
    overflow-x: hidden;
}
.tuitions_bill-teaching-list-wapper ul:nth-child(1){
    flex-grow: 4.5;
}
.tuitions_bill-teaching-list-wapper ul:nth-child(2){
    padding : 0;
    border : 0;
    overflow: hidden;
}
.tuitions_bill-teaching-list-wapper ul:nth-child(2) li{
    display: inline-block;
    width : 100%;
    height : 100%;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    padding: 110px 0;
}
.tuitions_bill-teaching-list-wapper ul:nth-child(2) li > div{
    display: inline-block;
    padding: 0;
}
.tuitions_bill-teaching-list-wapper ul:nth-child(3){
    flex-grow: 5.5;
}
.tuitions_bill-teaching-list-wapper ul li{
    /* font-size: 1.2em; */
    display: inline-block;
    width : 100%;
    font-size: 14px;
    color : #6c767f;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 1px;
}

.tuitions_bill-teaching-list-wapper ul li span.tuitions_bill-major-span{
    display: inline;
    padding : 3px 5px;
    margin-right: 5px;
    border-radius: 3px;
    box-sizing: border-box;
}

.tuitions_bill-teaching-list-wapper ul:nth-child(2) li{
    cursor: not-allowed;
}

.tuitions_bill-teaching-list-wapper ul:nth-child(1) li:hover{
    /* color : #333; */
    color : white;
    font-weight: bolder;
}
.tuitions_bill-teaching-list-wapper ul:nth-child(1) li.admission:hover{
    background : #ffa183;
    padding: 0px 5px;
}
.tuitions_bill-teaching-list-wapper ul:nth-child(1) li.english:hover{
    background : #b6d693;
    padding: 0px 5px;
}
.tuitions_bill-teaching-list-wapper ul:nth-child(1) li.test:hover{
    background : #f8b470;
    padding: 0px 5px;
}
/* .tuitions_bill-teaching-list-wapper ul:nth-child(3) li.admission:hover{
    background : #ffa183;
}
.tuitions_bill-teaching-list-wapper ul:nth-child(3) li.english:hover{
    background : #b6d693;
}
.tuitions_bill-teaching-list-wapper ul:nth-child(3) li.test:hover{
    background : #f8b470;
} */

/* PAYMENT CALCULATION - START */
/* 
.tuitions_bill-payment-wapper{
}
*/
.tuitions_bill-payment-wapper > table{
    width: 100% !important;
}
.tuitions_bill-payment-wapper > table > thead{
}
.tuitions_bill-payment-wapper > table > thead > tr{
} 

/* PAYMENT CALCULATION - END */


/* Common */

.marginBottom30{
    margin-bottom: 30px;
}
.marginBottom15{
    margin-bottom: 15px;
}
.marginLeft3{
    margin-left: 3px;
}
.text-aline-left {
    text-align: left;
}
.text-aline-left > label {
    width : 20%;
    box-sizing: border-box;
    float: left;
    text-align: center;
    vertical-align: middle;
}
.text-aline-left > p{
    display: inline-block;
    width : 78%;
    box-sizing: border-box;
    padding-left : 5px;
    border-left: .5px dotted #333;

}

.cust-arrow-right {
    background-image: url("../../../images/arrow-buttom.png");
    background-size: cover;
    width: 35px;
    height: 30px;
    transform: rotateZ(-90deg);
}


.tuition_bill-fix-font-size {
    display: inline-block;
    font-size: 1em;
    padding-bottom: 10px;
}

.tuition_bill-hours-wrapper{
    display: inline-block;
    width : 100%;
}

.tuition_bill-hours-div {
    width : 50%;
    float: left;
    box-sizing: border-box;
}
.tuition_bill-label-wapper {
    width : 40%;
    font-size: 0.9em;
    display: inline-block;
    box-sizing: border-box;
    padding : 11px;
}

.tuition_bill-label {
    background : #f0ad4e;
    color : white;
    font-weight: bolder;
    padding : 1.7px 5.8px;
    border-radius: 3px;
    font-size: 0.9em;
    margin-right: 5px;
}

.tuitions_bill_btns{
    padding : 10px;
    margin-bottom: 10px;
    text-align: center;
}
.tuitions_bill_btns > div{
    display: inline-block;
    margin : 0 auto;
}

.gurume-red{
    color : #ffa183;
}

.gurume-blue-tuitions{
    background : #5b9aef;
    color : white;
}



@media screen and (max-width:991px) {


    .tuitions_bill-teaching-list-wapper {
        display: inline-block;
        width : 100%;
    }
    .tuitions_bill-teaching-list-wapper ul{
        width : 100%;
        height : 220px;
        border-radius: 10px;
        margin : 0;
        padding : 0;
        box-sizing: border-box;
    }

    .tuitions_bill-teaching-list-wapper ul:nth-child(2){
        height: 60px;
    }
    .tuitions_bill-teaching-list-wapper ul:nth-child(2) li{
        box-sizing: border-box;
        display: inline-block;
        position: relative;
        width : 100%;
        height : 100%;
        padding : 0;
        overflow: hidden;
    }

    .tuitions_bill-teaching-list-wapper ul:nth-child(2) li > div{
        display: inline-block;
        padding: 0;
        margin-top : 15px;
        transform: rotateZ(0deg);
    }

    .tuitions_bill-teaching-list-wapper ul li{
        padding : 7px 10px;
    }
    @media screen and (max-width:767px) {
        .tuitions_bill-html-wapper {
            width: 95%;
        }
    }
}