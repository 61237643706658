

.credit-approve-wapper{
    padding-bottom: 100px;
}

.credit-approve-header-btns{
}

.credit-approve-header-btns > button{
    padding : 5px 15px;
    border: 2px solid #5b9aef;
    border-radius: 5px;
    opacity: .8;
    background-color: #fff;
    padding: 5px 15px;
    margin-right: 5px;
}
.credit-approve-header-btns > button:hover{
    color : black;
}
.credit-approve-header-btns > button:active{
    box-shadow: -1px -1px 0px #888;
}

.credit-drawing-pannel{
    position: absolute;
    top : 0;
    left : 0;
    width : 100%;
    height: 100%;
    background : rgba(0,0,0,0.5);
    z-index: 9999999;
}

.credit-drawing-pannel-card {
    width : 80%;
    max-width: 900px;
    background : white;
    margin : 0 auto;
    margin-top : 100px;
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    padding : 15px;
    border-radius: 6px;
}
.credit-adjust-height_1{
    min-height: 570px;
}
.credit-adjust-height_2{
    min-height: 470px;

}

.credit-drawing-pannel-card > h5{
    font-size: 18px;
    font-weight: 400;
    padding : 10px;
    border-bottom: 1px solid #ddd;
}


.credit-drawing-card{
    text-align: center;
    margin-bottom: 25px;
}

.credit-drawing-card > select {
    width : 100%;
    height: 34px;
    background: white;
    border : 2px solid #5b9aef;
    color : #6c767f;
    font-size: 14px;
}
.credit-drawing-card > input {
    width : 100%;
    border : 2px solid #5b9aef;
    border-radius: 5px;
    padding : 5px 15px;
}

.credit-drawing-card div > label{
    cursor: pointer;
    margin-top: 10px;
}

.credit-drawing-card div > label > input{
    margin : 0 10px;
}

.disablemode{
    border : 1px solid red;
    background-color: #eee;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}


.credit-drawing-table2-btns{
    border-top: 1px solid #ddd;
}
.credit-drawing-table2-btns > button {
    margin-top: 28px;
    margin-left: 5px;
    float: right;
}