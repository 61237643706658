
.credit-default-resize{
    max-width: 950px !important;
}


.credit-default-body{
    padding : 15px;
    padding-top: 15px;
    box-shadow: 1px 2px 3px #6c767f;
}
.credit-default-table{
    margin-top: 20px;
}



.credit-bt-header{
    display: flex;
    border-bottom: 1px solid #ddd;
}
.credit-bt-header > span{
    text-align: center;
    padding : 5px;
    color : #333;
    padding : 5px 20px;
    position: relative;
}
.credit-bt-header > span > i{
    padding : 0;
    position: absolute;
    top : 25%;
    left : 45%;
    color: #6c767f;
}
.credit-bt-header > span:nth-child(1){
    flex : 0.1;
}
.credit-bt-header > span:nth-child(2){
    flex : 0.3;
    text-align: left;
}
.credit-bt-header > span:nth-child(3){
    flex : 1.4;
}
.credit-bt-header > span:nth-child(4){
    flex : 0.7;
    text-align: right;
}
.credit-bt-header > span:nth-child(5){
    flex : 0.5;
    text-align: right;
}


.credit-bt-bodies{
}
.credit-bt-bodies ul{
    padding : 0;
    margin : 0;
}
.credit-bt-bodies ul li{
    display: flex;
    min-height: 47px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.credit-bt-bodies ul li:nth-child(2n-1){
    background: rgba(223,223,223,.2);
}
.credit-bt-bodies ul li:hover{
    background: #5b9aef;
    color : white;
}

.credit-bt-bodies ul li > span{
    flex : 1;
    text-align: center;
    line-height: 47px;
    padding : 0 20px;
    position: relative;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.credit-bt-bodies ul li > span > img{
    border-radius: 50%;
}
.credit-bt-bodies ul li > span > i{
    padding : 0;
    position: absolute;
    top : 20%;
    left : 30%;
}
.credit-bt-bodies ul li > span:nth-child(1){
    flex : 0.1;
}
.credit-bt-bodies ul li > span:nth-child(2){
    flex : 0.3;
    text-align: left;
}
.credit-bt-bodies ul li > span:nth-child(3){
    flex : 1.4;
}
.credit-bt-bodies ul li > span:nth-child(4){
    flex : 0.7;
    text-align: right;
}
.credit-bt-bodies ul li > span:nth-child(5){
    flex : 0.5;
    text-align: right;
}
.credit-bt-bodies ul li > span:nth-child(1) > img{
    width : 30px;
    height : 30px;
}
.credit-bt-bodies ul li > span:nth-child(2) > span{
}


.credit-default-btn{
    border-radius: 3px;
    padding : 5px 10px;
    color : white;
}
.credit-default-btn:hover{
    color : #333;
}
.credit-default-btn:active{
    border : 1px solid #555;
    border-top : 0;
    color : white;
}
.credit-default-blue-btn{
    border : .5px solid #5b9aef;
    background : #5b9aef;
}
.credit-default-red-btn{
    border : .5px solid #f9b462;
    background : #f9b462;
}


.credit-default-input-box{
}

.credit-default-input-box > label {
    display: inline-block;
    height: 100%;
    padding : 5px 15px;
    cursor: pointer;
    margin : 0;
}

.credit-default-input-box > span {
    display: inline-block;
    height: 100%;
    padding : 5px 15px;
    float: right;
}
.credit-default-input-box-reponsive{
    border-top: 1px solid #ddd;
    overflow: hidden;
    transition: all ease 1s;
}
.credit-default-input-box-show{
    height: auto;
    padding : 10px 20px;
}
.credit-default-input-box-hide{
    height: 0;
    padding : 0;
}

.credit-default-input-box-reponsive > div{
    color: #6c767f;
}
.credit-default-input-box-reponsive > textarea{
    width : 100%;
    min-height: 50px;
    border : 1px solid #6c767f;
    border-radius: 5px;
    padding : 10px;
    transition: all ease .3s;
}
.credit-default-input-box-reponsive > textarea:focus{
    min-height: 100px;
    font-size: 1.2rem;
}
.credit-default-input-box-reponsive > input{
    width : 300px;
    border : 1px solid #6c767f;
    border-radius: 5px;
    padding : 3px 7px;
    transition: all ease .3s;
}
.credit-default-input-box-reponsive > input:focus{
    width : 100%;
    padding : 10px;
    font-size: 1.2rem;
}
.credit-default-show{
    
}
.credit-default-hide{
}










.credit-bt-header2{
    display: flex;
    border-bottom: 1px solid #ddd;
}
.credit-bt-header2 > span{
    text-align: center;
    padding : 5px;
    color : #333;
    padding : 5px 20px;
    position: relative;
}
.credit-bt-header2 > span{
    flex : 1;
}
.credit-bt-header2 > span:nth-child(1){
    flex : 1.6;
}
.credit-bt-header2 > span:nth-child(4){
    flex : 2.5;
}

.credit-bt-bodies2{
}
.credit-bt-bodies2 ul{
    padding : 0;
    margin : 0;
}
.credit-bt-bodies2 ul li{
    display: flex;
    min-height: 47px;
    border-bottom: 1px solid #ddd;
}
.credit-bt-bodies2 ul li:nth-child(2n-1){
    background: rgba(223,223,223,.2);
}
.credit-bt-bodies2 ul li > span{
    flex : 1;
    text-align: center;
    line-height: 47px;
    padding : 0 20px;
    position: relative;
    font-size: 15px;
}
.credit-bt-bodies2 ul li > span:nth-child(1){
    flex : 1.6;
}
.credit-bt-bodies2 ul li > span:nth-child(4){
    flex : 2.5;
}

.credit-bt-bodies2 ul li > span > button{
    height: 32px;
    line-height: 12px;
    padding : 3px 10px;
    background: white !important;
    border-radius: 5px;
    font-size: 13.5px;
}