.recruit-wrapper{
    width: 100%;
}
.recruit-wrapper h1{
    font-size: 35px;
}
.recruit-wrapper h4{
    font-size: 20px;
}

.recruit-banner{
    width: 100%;
    display: flex;
    justify-content: center;
}
.recruit-banner-box{
    width: 100%;
    height: 350px;
    background-position: center;
    background-size: cover;
    background-image: url('../../../images/academy/teachers_recruit_partnership.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-attachment: fixed;
}
.recruit-banner-content{
    width: 100%;
    z-index: 100;
}
.recruit-banner-content h1{
    margin-bottom: 20px;
}
.recruit-banner-content button:last-child{
    margin-left: 10px;
}

.recruit-process{
    width: 100%;
    padding-top: 45px;
    padding-bottom: 40px;
}
.recruit-process-box{
    width: 80%;
    margin: 0 auto;
}
.recruit-process-content-wrapper{
    width: 100%;
}
.recruit-process-content{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.recruit-process-item i{
    color: #5b9aef;
}
.recruit-process-item{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.recruit-process-item h4{
    color: #000000;
}
.recruit-process-item p{
   text-align: left;
}
.recruit-process-item .recruit-process-icon{
    color: #5b9aef;
    font-size: 36px;
}
.recruit-process-item .indent-text{
    text-indent: 20;
}


.recruit-message{
    width: 100%;
    padding-top: 45px;
    padding-bottom: 40px;
    background-color: #efefef;
}
.recruit-message-box{
    width: 65%;
    margin: 0 auto;
}
.recruit-message-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.recruit-message-content p{
    font-size: 18px;
    margin-top: 30px;
}
.recruit-message-box button{
   margin: 0 auto;
   display: flex;
   align-items: center;
   margin-top: 15px;
}


.recruit-fulltime{
    width: 100%;
    padding-top: 45px;
    padding-bottom: 40px;
}
.recruit-half-fulltime{
    width: 100%;
    padding-top: 45px;
    padding-bottom: 40px;
    background-color: #efefef;
}
.recruit-fulltime-box{
    width: 80%;
    margin: 0 auto;
}
.recruit-fulltime-content-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.recruit-fulltime-content-wrapper h4{
    margin-top: 15px;
}
.recruit-fulltime-content{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.recruit-fulltime-item{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recruit-apply{
    width: 100%;
    display: flex;
    justify-content: center;
}
.recruit-apply-box{
    width: 100%;
    height: 280px;
    background-position: center;
    background-size: cover;
    background-image: url('../../../images/academy/teachers_recruit_partnership_2.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.recruit-apply-content{
    width: 100%;
    z-index: 100;
}
.recruit-apply-content{
    margin-top: 30px;
}
.recruit-apply-content h4{
    color: #ffffff;
}

/* responsive work */
@media screen and (max-width: 991px){
    .recruit-process-box{
        width: 100%;     
    }
}
@media screen and (max-width: 767px){
    .recruit-process-box{
        width: 90%;     
    }
    .recruit-process-content{
        flex-direction: column;
        align-items: center;
    }
    .recruit-process-item{
        width:100%;
        margin-bottom: 15px;
    }
    .recruit-process-item:last-child{
        margin-bottom: 0px;
    }

    .recruit-process, .recruit-message, .recruit-fulltime, .recruit-half-fulltime{
        padding-top: 30px;
        padding-bottom: 25px;
    }
    .recruit-message-box{
        width: 90%;
    }

    .recruit-fulltime-box{
        width: 90%;
    }
    .recruit-fulltime-content{
        flex-direction: column;
        align-items: center;
    }
    .recruit-fulltime-item{
        width: 90%;
        margin-bottom: 15px;
    }
    .recruit-fulltime-item:last-child{
        margin-bottom: 0px;
    }
    .recruit-banner-content, .recruit-apply-content{
        width: 90%;
    }
}