
.container-myself{
  width: 94% !important;
  margin: auto;
}

.dashboard-teacher-tuition{
  margin-bottom: 30px;
}

.dashboard-header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.dashboard-header-img {
  max-width: 100%;
  min-width: 100%;
  float: left;
  /*width: 100px;*/
  /*float: left;*/
}

.dashboard-edit-profile-btn {
  float: right;
  cursor: pointer;
}

.dashboard-header-firsr-line {
  margin-bottom: 0;
}

.dashboard-header-name {
  font-size: 2rem;
}

.dashboard-nav-btn {
  width: 100%;
  color:#333;
}

.dash-align-right {
  text-align: right;
}

.dash-questions{
  margin-bottom: 60px;
}

.dash-p-title{
  margin: 20px 0 0px 0;
  font-size: 18px;
}

.student-list {
  background-color: #f9f9f9;
  width: 100%;
}

.dash-student-list {
  padding: 20px 0 20px 0;
}

.dash-class-infor{
  font-size: 16px;
  font-weight: 600;
  margin-right: 25px;
}

.dash-class-infor-color{
  color: #000;
}

.dashboard-name-box {
  border-bottom: 1px solid #666;
  padding: 0;
  margin-bottom: 2px;
  margin-top: 10px;
}

.dashboard-info-box{
  padding: 0;
  margin-bottom: 10px;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  vertical-align: middle;
}

.dash-subject-box {
  border: 1px solid #666;
  border-radius: 5px;
  padding: 3px;
  margin: 2px 5px 4px 2px;
  float: left;
}



.dash-img-size {
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
}

.dash-glyphicon {
  font-size: 25px;
  color: #b5b5b6;
}

.dash-icons-padding-time{
  padding-left: 20px;
}

.dash-icons-padding-email{
  padding-left: 50px;
}

.tdashboard-tab > .col-sm-4,.tdashboard-tab > .col-sm-3{
  padding: 0 2px;
}

.tdashbord-tiitle {
  height: 60px;
  padding-top: 20px;
  font-size: 18px;
}

.tdash-request-btn {
  height: 30px;
  background-color: white;
  border: 1px solid #666;
  padding: 5px 15px;
  color: #666;
}

.tdash-table {
  margin-left: 100px;
}

/* 小屏幕（平板，大于等于 768px） */
@media (max-width: 768px) {
  .dash-img-size {
    width: 40px;
    height: 40px;
  }

  .dashboard-header {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .dashboard-header-img {
    width: 120px;
  }

  /*.dashboard-header-message-area {*/
    /*margin-left: 140px;*/
  /*}*/
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 992px) {

}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 1200px) {

}
