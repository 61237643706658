.mooc-logistics-banner{
    height : 573px;
    position: relative;
}
.mooc-logistics-box{
    height: 100%;
}
.mooc-logistics-content{
    width : 70%;
    margin : 0 auto;
    padding-top: 200px;
}
.mooc-logistics-content > h2{
    color : white !important;
    font-size: 39px;
}
.mooc-logistics-content > h4{
    padding-top: 10px;
    font-size: 29px;
}

.mooc-logistics-btn{
    margin-top: 30px;
}
.mooc-logistics-btn > a{
    border : 3px solid white;
    border-radius: 15px;
    padding : 7px 22px;
    margin : 5px;
    color : white;
    font-size: 17px;
}
.mooc-logistics-btn > a:hover{
    color : white;
    font-style: none;
}
.mooc-logistics-box2{
    padding : 5px 50px;
    background-color: rgba(223,223,223,.63);
}

.mooc-logic-info{
    width : 50%;
    margin: 0 auto;
}

.mooc-logic-info p{
    text-align: left;
}


.mooc-content{
    width : 80%;
    display : flex;
    margin : 0 auto;
    margin-top: 50px;
}

.cols-gl {
    color: #66afe9 !important;
    margin-right: 10px;
}
.mooc-gray{
    color: #6c767f !important;
    font-weight: 500 !important;
    font-size: 18px;
}
.mooc-log-btn{
    border: 2px solid #5b9aef;
    border-radius: 13px;
    font-size: 17px;
    padding : 5px 15px;
    color: #333;
    margin-top: 30px;
}

.mooc-logic-cardA{
    background: white;
    padding : 5px;
}
.mooc-logic-cardA > p{ 
    color :#6c767f;
    padding : 5px;
    border-bottom: 1px solid #ccc;
}

.mooc-logic-cardAC{
    border-bottom: 1px solid #ccc;
}
.mooc-logic-cardAC ul{
    padding : 0;
    display: flex;
}
.mooc-logic-cardAC ul li{
    flex : 1;
    overflow: hidden;
    margin : 5px;
}
.mooc-logic-cardAC ul li > img{
    width : 100%;
}

.mooc-limit-h{
    width : 70%;
    line-height: 55px;
    font-weight: 500 !important;
    margin : 0 auto;
}