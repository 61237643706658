.tuition_req_index-backg{
    min-height: 600px;
    background : rgba(223,223,223,.63);
    padding: 30px;
}
.tuition-req-index-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.tuition-req-index-box{
    width: 100%;
    justify-content: center;
    display: flex;
}
.tuition-req-recruit-wrapper{
    width: 20%;
}
.tuition-req-recruit-box{
    width: 100%;
    display: flex;
    justify-content: center;
}
.tuition-req-recruit-card{
    width: 100%;
    background-color: #fff;
    box-shadow: 0 3px 3px #ccc;
    padding : 20px 0;
}
.tuition-req-recruit-main{
    padding: 0px 10px;
}
.tuition-req-recruit-main p{
    margin: 10px 0px;
}
.tuition-req-recruit-main h2{
    font-size: 30px;
    color: #5b9aef;
    font-weight: 600;
}
.tuition-req-list-wrapper{
    width: 80%;
}
.tuition-req-list-box{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0px 5px;
}
.tui_req_i-card-wapper{
    width : 94%;
    background : white;
    box-shadow: 0 3px 3px #ccc;
    padding : 10px 0;
    margin: 0 auto;
}
.tui_req_i-card-wapper2{
    width : 100%;
    padding-top : 10px;
}
.tui_req_i-card-wapper2-box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.tui_req_i-card-wapper ul.tui_req_i-top{
    padding: 0px 10px;
    padding-top: 10px;
}
.tui_req_i-card-wapper ul.tui_req_i-top h2{
    font-size: 30px;
    font-weight: 600;
    color : #5b9aef;
}
.tui_req_i-card-wapper ul.tui_req_i-top .tui-req-i-top-box{
    margin-bottom: 10px;
}
.tuition-req-common-btn{
    color : #fff;
    background : #5b9aef;
    border: 2px solid #5b9aef;
    border-radius: 13px;
    padding : 5px 15px;
    cursor: pointer;
    font-size: 17px;
}
.tuition-req-kakao-btn{
    background-color: #fde433;
    color: #46292b !important;
    border: 2px solid #fde433;
    margin-left: 5px;
}
.tuition-req-kakao-btn img{
    width: 22px;
    height: 22px;
}
.tui_req_i-toolt-tuitReqEx{
    background: white;
    width : 100%;
    padding : 10px;
    border : 1px solid #ccc;
    box-shadow: 0 3px 3px #ccc;
}
.tui_req_i-toolt-tuitReqEx > span{
    text-align: left;
    flex: 1;
    width : 100%;
    display: block;
}



/* tri-item-div */

.tri-item-div{
    width: 30%;
    margin: 10px;
    background: white;
    /* transition: all 0.2s; */
}
.tri-item-header{
    display: flex;
}
.tuition-request-pin{
    float: right;
    font-size: 20px;
}
.tri-item-div ul{
    padding : 10px;
    display: flex;
    flex-direction: column;
    height : 100%;
    box-shadow: 0 3px 3px #ccc;
    /* transition: all .03s; */
}
/* .tri-item-div ul:hover{
    box-shadow: 0 0px 3px #5b9aef;
    background: #e0edff;
} */
.tri-item-div ul li, .tri-item-div ul i {
    font-size: 14px;
    color: #6c767f;
}
.tri-item-div ul li{
    position: relative;
    box-sizing: border-box;
}

.tri-item-div ul li:nth-child(1){
    flex : 1;
}

.tri-item-div ul li:nth-child(2){
    flex : 5;
}
.tri-item-div ul li:nth-child(3){
    flex : 2;
}


/* First Part */
.tri-first-item{
    height: 100%;
    display: flex;
}
.tri-first-item img{
    width : 30px;
    height : 30px;
    margin-right: 3px;
}
.tri-first-style{
    display: inline-block;
}
.tri-first-style span{
    display: inline-block;
    font-size: 16px;
}


.tri-second-item{
    margin-top: 5px;
}
.tri-second-item span{
    letter-spacing: -.1px;
    font-size: 16px;
}
.try-sec-item-fix{
    display: inline-block;
    width : 100%;
}

/* Second Part */
.tri-bot-btns{
    margin-top: 10px;
    height: auto;
}



/* tri-call-req-paper */
.tri-call-request-checker{
    position: fixed;
    top : 0;
    left : 0;
    width : 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    /* transition: all 0.2s; */
}
.tri-call-req-paper{
    box-shadow: 0 3px 3px white;
    background: white;
    border-radius: 3px;
    width : 80%;
    min-height: 150px;
    margin : 100px auto;
    color : #6c767f;
}
.tri-call-req-paper > div{
    display: inline-block;
    width : 100%;
    padding : 10px;
}
.tri-call-req-paper > div:nth-child(1){
    font-size: 17px;
    padding-top: 20px;
}
.tri-call-req-paper > div:nth-child(1) > span{
    margin: 0 5px;
}
.tri-call-req-paper > div:nth-child(2){
    padding-top: 40px;
    min-height: 50%;
}
.tri-call-req-paper > div:nth-child(2) > div {
    width : 100%;
    float: right;
}
.tri-call-req-paper > div:nth-child(2) > div > span{
    border : 1px solid #ccc;
    border-radius: 3px;
    padding : 5px 15px;
    margin : 0 3px;
    float: right;
    color : #ccc;
    cursor: pointer;
}
.tri-call-req-paper > div:nth-child(2) > div > span:nth-child(1){
    border : 1px solid #5b9aef;
    color : #5b9aef;
}
.tri-call-req-paper > div:nth-child(2) > div > span:hover{
    background: #5b9aef;
    color : white;
}




/* PageNation */
.gurume-pagination{
    width : 100%;
    text-align: center;
}
.gurume-pagination > ul{
    display: inline-block;
    padding : 0;
    margin : 0 auto;
}
.gurume-pagination > ul > li{
    box-sizing: border-box !important;
    position: relative;
    float: left;
    font-size: 14px;
    padding: 3px 7px;
    margin: 2px;
    line-height: 1.42857143;
    color: #666;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    cursor : pointer;
}
.gurume-pagination > ul > li.active{
    /* background-color : none !important; */
    border: 0;
    background: #5b9aef;
    color : white;
    padding : 5px 12px;
    border-radius: 50%;
}
.gurume-pagination > ul > li.no_drop{
    cursor : no-drop;
}


/* common */
.tui_req_i-btn-block{
    padding : 3px 10px;
    background: #777;
    color : white;
    font-size: 0.8em;
    cursor: pointer;
    border-radius: 3px;
    margin-left: 10px;
    /*transition: all 0.3s; */
}
/* .tui_req_i-btn-block:hover{
    background: white;
    color : #777;
    border-bottom: 1px solid #777;
} */
.tri-btns-type-a{
    font-size: 17px;
    border : 2px solid #5b9aef;
    border-radius: 13px;
    padding : 5px 15px;
    margin-right: 5px;
    cursor: pointer;
}
/* .tri-btns-type-a:hover{
    border : 2px solid #777;
    color: white;
    background: #777;
} */
.tri-btns-type-b{
    border : 2px solid #5b9aef;
    background: #5b9aef;
    border-radius: 13px;
    padding : 5px 15px;
    color : white;
    cursor: pointer;
    font-size: 17px;
}
.tri-btns-type-b:hover{
    color: #777;
}

.tri-btns-type-b > span{
    color : white;
    margin-left: 5px;
}
.color-red{
    color : red;
}

.tv_lavel{
    box-sizing: border-box;
    color : white;
    background : #f0ad4e;
    height : 17px;
    font-size : 10px;
    padding : 2px 6px 3px;
    margin : 0;
    border-radius: 2.5px;
    margin-left : 3px;
}
.v_lavel{
    box-sizing: border-box;
    color : white;
    height : 17px;
    font-size : 10px;
    padding : 2px 6px 3px;
    margin : 0;
    border-radius: 2.5px;
    margin-left : 3px;
}
.s_v_lavel{
    box-sizing: border-box;
    color : white;
    background : #5bc0de;
    height : 17px;
    font-size : 10px;
    padding : 2px 6px 3px;
    margin : 0;
    border-radius: 2.5px;
    margin-left : 3px;
}

@media screen and (max-width:1435px){
    .tui_req_i-card-wapper{
        width: 95.5%;
    }
}
@media screen and (max-width:1200px){
    .tri-item-div{
        width: 46%;
    }
}
@media screen and (max-width:1073px){
    .tuition-req-index-box{
        width: 90%;
        margin: 0 auto;
    }
    .tri-item-div{
        width: 46%;
    }
}
@media screen and (max-width:991px){
    .tuition-req-index-box{
        width: 100%;
    }
}
@media screen and (max-width:850px){
    .tui_req_i-card-wapper ul.tui_req_i-top .tui-req-i-top-box{
    }
    .tui_req_i-card-wapper ul.tui_req_i-top .tui-req-i-top-box p{
        margin: 0;
    }
    .tui_req_i-card-wapper ul.tui_req_i-top .tui-req-i-top-box span{
        margin: 5px 2px;
    }
}
@media screen and (max-width:812px){
    .tri-item-div{
        width: 45%;
    }
}
@media screen and (max-width:767px) {
    .tuition_req_index-backg{
        padding: 10px;
    }
    .tuition-req-index-wrapper{
        flex-direction: column;
    }
    .tuition-req-index-box{
        width: 95%;
        flex-direction: column;
    }
    .tuition-req-recruit-wrapper{
        width: 100%;
        margin-top: 10px;
    }
    .tuition-req-recruit-card{
        margin-top: 10px;
    }
    .tuition-req-list-wrapper{
        width: 100%;
        margin-top: 20px;
    }
    .tuition-req-list-box{
        margin: 0px;
    }
    .tui_req_i-card-wapper{
        width : 100%;
    }
    .tui_req_i-card-wapper2{
        width : 100%;
    }
    .tri-item-div{
        width : 100%;
        margin: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .tri-bot-btns{
        display: flex;
        flex-direction: column;
    }
    .tri-btns-type-a{
        text-align: center;
        width : 100%;
    }
    .tri-btns-type-b{
        text-align: center;
        width : 100%;
        margin : 3px 0;
    }
}