.moocnew-pay-wrapper{
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}
.moocnew-pay-box{
    width: 85%;
    margin: 0 auto;
}

.moocnew-pay-contents{
    width: 100%;
    display: flex;
}
.moocnew-pay-left{

}
.moocnew-pay-contents > div:nth-child(1){
    flex : .3;
    min-width: 300px;
    margin-right: 15px;
}
.moocnew-pay-contents > div:nth-child(2){
    display: flex;
    margin-left : 15px;
    flex: 1;
}


.moocpay-card-item{
}
.moocnew-pay-price{
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    box-shadow: 1px 2px 3px #ddd;
    flex-direction: column;
    padding: 20px 0px;
}
.moocnew-pay-price h3 {
    font-size: 16px;
    text-align: center;
}
.moocnew-pay-price p {
    font-size: 16px;
    text-align: center;
    color: #5b9aef;
    padding: 5px 0px;
}
.moocnew-pay-credit p{
    color: #6c767f !important;
}

.moocnew-pay-right{
    display: flex;
    width: 100%;
}

.moocnew-pay-right > div{
}
.moocnew-pay-right > div:nth-child(1){
    flex : 1;
}
.moocnew-pay-right > div:nth-child(2){
    flex : .5;
    min-width : 250px;
}



.moocnew-pay-right-box{
    padding : 20px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 1px 2px 3px #ddd;
}
.moocnew-pay-right-box > div{
    padding : 10px 0;
}


.moocnew-pay-right-box > div:nth-child(1){
    border-bottom: 1px solid #ddd;
}
.moocnew-pay-right-box > div:nth-child(2){
}
.moocnew-pay-right-box > div:nth-child(2) > ul{
    padding : 0;
    font-size: 17px;
    margin-top: 5px;
}
.moocnew-pay-right-box > div:nth-child(2) > ul > li{
    padding : 5px 0;
}

@media screen and (max-width:1200px){
    .moocnew-pay-box{
        width: 90% !important;
    }
}

@media screen and (max-width:991px){
    .moocnew-pay-contents{
        flex-direction: column !important;
    }
    .moocnew-pay-contents > div:nth-child(1){
        margin-right: 0px;
    }
    .moocnew-pay-contents > div:nth-child(2) {
        margin-left: 0px;
    }
    .moocnew-pay-left{
        margin-bottom: 20px;
    }
}
@media screen and (max-width:767px){
    .moocnew-pay-right{
        flex-direction: column;
    }
    .moocnew-pay-right-box{
        margin-bottom: 20px;
    }
}