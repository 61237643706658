label {
  font-weight: 400;
}

.booking-area {
  min-height: 300px;
  background: url("../images/paypage-bg.png") no-repeat;
  background-size: cover;
  padding-top: 30px;
  padding-bottom: 30px;
}

.pay-message-area {
  padding: 0;
}

.pay-method-area {
  margin-top: 20px;
  margin-bottom: 30px;
}

.pay-method-area > div > .col-sm-9 {
  padding: 0;
}

.form-inline {
  margin-top: 10px;
}

.card-number > .form-control {
  width: 15%;
}

.up-date > .form-control {
  width: 10%;
}

.pay-icon-area {
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgb(235,235,235);
  width: 60%;
  font-size: 3rem;
  color: #666666;
}



.pay-method {
  background: url("../images/paymethod-bg.png") no-repeat;
  background-size: cover;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  font-size: 2.4rem;
  color: #666666;
}
.checkbox {
  font-size: 1.6rem;
}
.ball {
  color: rgba(128, 183, 63, 0.65);
  font-size: 3rem;
  line-height: 0.5;
  padding-right: 15px;
  margin-left: -15px;
}

.pay-message {
  padding: 20px;
  background-color: rgb(184, 208, 152);
  margin-top: 180px;
  margin-left: 15rem;
  font-size: 4rem;
  color: white;
}

.course-booked {

}

.message-area {
  font-size: 3rem;
  margin-left: 60px;
  margin-bottom: 40px;
}

.message {
  margin-top: 15px;
  color: rgba(102, 102, 102, 0.8);
}

.pay-method > .col-sm-4 {
  padding: 0;
}

.service-charge {
  padding: 10px;
  background-color: rgb(184, 208, 152);
  margin-top: 150px;
  font-size: 4rem;
  color: white;
}

.payment {
  width: 90%;
  border: 3px solid rgba(102, 102, 102, 0.64);
  margin-bottom: 30px;
  padding-top: 10px;
  font-size: 2rem;
  color: #666666;
}

.pay-next-time {
  margin-left: 30%;
  color: #666666;
  font-size: 1.3rem;
}

.pay-btn-area {
  margin-top: 70px;
}

.pay-btn {
  background-color: rgba(255, 92, 178, 0.84);
  color: white;
  width: 80px;
}
