
.credit-balance-wapper{
    min-height: 600px;
    margin-bottom: 100px;
}

.credit-balance-head{
    min-height: 20px;
    padding : 15px;
    padding-bottom: 5px;
    box-shadow: 1px 2px 3px #6c767f;
    margin-bottom: 20px;
}
.credit-balance-head > h3{
    margin-bottom: 0;
}
.credit-balance-head-won{
    height: 100%;
    display: flex;
}

.credit-balance-head-won > div{
    flex : 1;
}

.credit-balance-head-won > div:nth-child(1){
    color: #6c767f;
    font-size: 23px;
    letter-spacing: -1px;
}
.credit-balance-head-won > div:nth-child(1) > span{
    font-size: 36px;
    letter-spacing: -1px;
    font-weight: 700;
}
.credit-balance-head-won > div:nth-child(2) > div{
    width : 200px;
    height: 100%;
    float: right;
    /* display: inline-block; */
    display: flex;
}
.credit-balance-head-won > div:nth-child(2) > div > span{
    flex : 1;
}
.credit-balance-head-won > div:nth-child(2) > div > span > div:nth-child(1){
    text-indent: 2px;
}
.credit-balance-head-won > div:nth-child(2) > div > span:nth-child(1) > div > select{
    width : 80px;
    height: 33px;
    color: #555;
    font-size: 14px;
    background: white;
}




.credit-bt-bodies-date{
}
.credit-bt-bodies-date > div{
    line-height: 20px;
}
.credit-bt-bodies-date > div:nth-child(1){
    line-height: 30px;
}
.credit-bt-bodies-date > div:nth-child(2){
    font-size: 12px;
    color : #ccc;
}
