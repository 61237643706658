
/* color  */
/* #6c767f */

.tutorreg-wrapper{
    box-sizing: border-box;
    padding : 20px;
    padding-top : 0;
}

.tutorreg-inner-wrapper {
    max-width : 700px;
    margin : 0 auto;
    color : #6c767f;
}


.tutor-part-a-component{
}




.tutorreg-fix-wap{
    width : 100%;
    overflow: hidden;
}
.toggle-oversea-wapper {
    position: relative;
}
.toggle-oversea-wapper > h5{
    line-height: 35px;
    text-indent: 10px;
    color: rgba(91, 154, 239, 1);
    background: rgba(200, 200, 200, 0.2);
    border: 1px solid #ddd;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    margin : 0;
}
.toggle-oversea-wapper > div{
    padding : 20px;
    transition: all .2s;
}

.toggle-oversea-wapper > div.show-tg{
    border: 1px solid #ddd;
    border-top: 0;
    height: 582px;

}
.toggle-oversea-wapper > div.hide-tg{
    height: 0;
    padding : 0;
    overflow: hidden;
}

.toggle-oversea-wapper > div > p > img {
    width : 100%;
    margin-top: 20px;
}


.tutorreg-calendar {
    /* width : 500px; */
    position: relative;
    /* display: inline-block; */
    width : 100%;
    display: flex;
}
.tutorreg-line-fixer {
    border: .5px solid white;
    z-index: 999;
}
.react-calendar {
    position: absolute;
}

.tutorreg-input-span{
    flex : 1 auto;
}

.tutor-check-fix{
    position: relative;
    display: inline-block;
}


.tutorreg-demo-fix {
    position: relative;
    display: inline-block;
}


.tutorreg-p{
    color : #6c767f;
    font-size: 14px;
}
.tutorreg-i{
    font-size: 14px;
}

.fixed-tutor-h2{
    display: inline-block;
    margin-right : 10px;
}



.tutorreg-img-wrraper{
    width : 39%;
    margin : 0 auto;
    box-sizing: border-box;
    font-size: 14px;
}
.tutorreg-img-wrraper > div{
    text-align: center;
}
.tutorreg-img-input{
    border : 0.5px solid #ccc;
    border-radius: 5px;
    font-size: 11px;
    padding : 0 8px;
    padding-top: 1.5px;
    color : #333;
    margin-right: 10px;
}
.tutorreg-display-img{
    max-height : 230px;
    overflow : hidden;
    border-radius: 500px;
}
.tutorreg-display-img > img {
    width : 100%;
    height : 100%;
}
.tutorreg-upload-btns{
}

.tutor-cert-div {
    overflow: hidden;
    margin-top : 20px;
}
.tutor-cert-inner-div {
    display: block;
    width : 200px;
    text-align: center;
    margin : 0 auto;
    border : 1px solid #ccc;
    border-radius: 5px;
    padding : 5px;
    box-shadow: 0px 2px 3px #ccc;
}
.tutor-cert-inner-div > img {
    width : 100%;
    position: relative;
}
.tutor-cert-show {
}

.tutor-x-btn {
    width : 100%;
    margin : 0;
}
.tutor-cert-hidden {
    height: 0;
}



.tutorreg-single-textarea{
    border-radius: 8px;
    border: solid 2px #5b9aef;
    display: block;
    width: 100%;
    height: 120px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    transition: all .3s;

    margin-bottom: 29px;
}
.tutorreg-single-textarea:focus{
    height: 220px;
    line-height: 1.7;
    font-size: 17px;
    border : 1px solid #4171b1;
    transition: all .3s;
}
.tutorreg-single-textarea:hover{
    border : 1px solid #4171b1;
    transition: all .3s;
}






.reg-location {
}
.reg-location-part-a {
    display: flex;
    width : 100%;
}
.reg-location-part-a > div {
    flex : 1 auto;
}
.reg-location-part-a > div:nth-child(1){
    margin-right: 10px;
}
.reg-location-part-a > div:nth-child(2){
    margin-left: 10px;
}


.tutorreg-line-a > div {
    display: flex;
    margin-bottom: 5px;
}
.tutorreg-line-a > div > div{
    flex : 1 auto;
}

.tutorreg-term-of-use {
    text-align: center;
}
.tutorreg-term-of-use-inner {
    width : 300px;
    margin: 0 auto;
}



.tutorreg-submit-btn{
    text-align: center;
}

.tutorreg-btn-fix {
    border : 1px solid red;
    height: 42px;
}


/* FOR TOOL TIP */
.type-light ul{
    background : white !important;
    opacity: 1 !important;
    box-shadow: 0 1px 5px #ccc;
    padding : 0;
    padding : 10px;
}




/* tutorreg common */

.tutorreg-font-sz-14 {
    font-size: 14px !important;
}
.tutorreg-font-sz-15 {
    font-size: 15px;
}
.tutorreg-font-sz-24 {
    font-size: 24px;
}


textarea:focus, input:focus{
    outline: none;
    transition: all 0.2s;
}
*:focus {
    outline: none;
}
textarea:focus, input:focus{
    border : 3px solid #5b9aef;
    border-radius: 5px;
    box-shadow: 0 0 5px #5b9aef;
}