.fo-pw-btns{
    display: block;
    width : 100%;
    position: relative;
    margin-top: 20px;
}
.fo-pw-align {
    display: inline-block;
    margin: 0 auto;
    margin-left: 45%;
}
.fo-pw-align > button{
    margin: 0 auto;
}