
.tdashboard-container{
    min-height: 500px;
    background: #ebebeb;
}

.td-small-wrapper{
    position: relative;
    border-top: 1px solid white;
    margin : 0 auto;
}
.td-small-img-wrapper{
    width : 100%;
    min-height : 350px;
    position: absolute;
    z-index: 1;
}
.tdashboard-wrap{
    width : 85%;
    min-height: 500px;
    display: flex;
    margin : 0 auto;
    justify-content: center;
    margin-top : 30px;
    z-index: 1;
}

.td-blink-effect{
    -webkit-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    -o-animation: blink 1s linear infinite;
    animation: blink 1s linear infinite;
}
.td-scale-effect{    
    -webkit-animation: scaleEffect 1s linear infinite;
    -moz-animation: scaleEffect 1s linear infinite;
    -o-animation: scaleEffect 1s linear infinite;
    animation: scaleEffect 1s linear infinite;
}
.td-border-blink{
    -webkit-animation: tdBorderBlink 1s linear infinite;
    -moz-animation: tdBorderBlink 1s linear infinite;
    -o-animation: tdBorderBlink 1s linear infinite;
    animation: tdBorderBlink 1s linear infinite;
    border-width: 2px; 
    border-style: dashed; 
    border-color: red red red red;
}
@keyframes tdBorderBlink{
    0%{border-color: #fff #fff #fff #fff;}
    100%{ border-color: red red red red;}
}
@keyframes blink{
    0%{color: #fff;}
    100%{color: red;} 
}   
@keyframes scaleEffect{
    0%{
        transform: scale(0.7);
    }
        100%{transform: scale(1.1);
            transition: all .4s ease-in-out;}
}
.tdashboard-left-pannel-wapper{
    width : 25%;
    min-height: 300px;
    padding : 20px 15px;
    z-index: 1;
}
.tdashboard-left-pannel{
    border-radius: 5px;
    background: white;
    margin-bottom: 15px;
}
.td-summ-left{
    display: flex;
    flex-direction: column;
    margin : 0 auto;
    padding: 20px;
}
.td-total-fee{
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
.td-table{
    
}
.td-table table{
    width: 50%;
    margin: 0 auto;
}
.td-table table:nth-child(1){
    margin-bottom: 20px;
}
.tdash-credit-wrapper{
    padding : 15px 25px;
}
.tdash-credit-wrapper span{
    display: block;
    text-align: center;
}
.td-left-menu{
    padding : 15px 25px;
}
.td-left-menu > span{
    text-align: center;
    width : 100%;
}
.td-left-menu ul{
    margin : 0;
    padding : 0;
    margin-top: 10px;
}
.td-left-menu > ul > li{
    padding : 6px 12px;
    border-radius: 10px;
    margin-bottom: 2px;
    text-align: center;
    cursor: pointer;
    transition: all .1s;
}
.td-left-menu ul.td-left-must-read li{
    background: #ccc;
    color : #333;
}
.td-left-menu ul.td-left-must-join li{
    background: #5b9aef;
    color : white;
}
.td-left-menu ul.td-left-must-read li:hover{
    cursor: pointer;
}
.td-left-menu ul.td-left-must-join li:hover{
    color : #333;
    text-decoration: none;
}


.td-summ-left > div{
}
.td-summ-left > div:nth-child(1){
    flex : 5;
}
.td-summ-left > div:nth-child(2){
    flex : .2;
}


.td-summ-first{
    display: flex;
    flex-direction: column;
}
.td-profile-img{
    width: 120px;
    height: 120px;
    margin: 0 auto;
}
.td-profile-img img{
    width : 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.td-summ-first span:nth-child(2){
    margin: 0 auto;
    color : #000;
    font-size: 18px;
    font-weight: 600;
}
.td-summ-first span:nth-child(2) > span{
    font-size: 13.5px !important;
    color: #777 !important;
    font-weight: 600;
}
.td-summ-first span:nth-child(3){
    margin: 0 auto;
    color : #c9caca;
    font-size: 12px;
}


.td-show-profile{
    text-align: center;
    margin-top: 5px;
}
.td-show-profile-sec{
    padding-top: 5px;
}
.td-show-profile-sec ul{
    padding : 5px;
    width : 80%;
    margin : 0 auto;
}
.td-show-profile-sec ul li{
    font-size: 14px;
    color : #777;
    display: flex;
}
.td-show-profile-sec ul li:nth-last-child(1){
    color : #5b9aef;
}
.td-show-profile-sec ul li > span{
    display: inline-block;
}


.td-show-profile-sec ul li > span:nth-child(1){
    flex : .2;
    text-align: right;
    color : #5b9aef;
    margin-right: 5px;
}
.td-show-profile-sec ul li > span:nth-child(1) img{
    width : 14px;
    height : 14px;
}
.td-show-profile-sec ul li > span:nth-child(2){
    flex : 1;
    padding-left: 5px;
}

.t-dash-right-body{
    width : 70%;
    min-height: 300px;
}


.tdash-details{
    margin : 0;
    padding : 20px 0;
    width : 50%;
    margin : 0 auto;
    margin-bottom: 10px;
}
.tdash-details li{
    box-sizing: border-box;
    text-align: center;
}
.tdash-details li > span{
    width : 240px;
    box-sizing: border-box;
    text-align: right;
    vertical-align: text-bottom;
    padding : 20px 0 0 0;
    font-size: 18px !important;
}
.tdash-details li > span:nth-child(1){
    color : #5b9aef;
}
.tdash-details li > span:nth-child(2){
    font-size: 15px;
    letter-spacing: -.07px;
}


.tdash-new-btn{
    background: none;
    border-radius: 3px;
    border : 1px solid #ddd;
    border-bottom: 2px solid rgb(196, 196, 196);
    width : 100%;
    height: 90px;
    font-size: 24px;
    font-weight: 700;
    color : #5b9aef;
    background-color: #f5f5f5;
    margin: 10px 0px;
}
.tdash-new-btn:hover{
    cursor: pointer;
}
.tdash-copy-btn{
   float: right;
   display: flex;
}
.tdash-copy-btn> button{
    background: #5b9aef !important;
    border: 2px solid #fff !important;
    color: #fff !important;
}

.tdash-tapply-wapper{
    max-height: 700px;
    overflow: scroll;
    overflow-x: hidden;
}
.tdash-tapply-wapper ul{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.tdash-tapply-wapper ul li{
    width: 30%;
    margin : 7px 10px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 35px;
    overflow: hidden;
    padding : 5px 15px;
}
.tdash-tapply-wapper ul li:hover{
    border: 2px solid #5b9aef !important;
    box-shadow: 0 1px 20px #5b9aef;
    text-decoration: underline;
    cursor: pointer;
}
.tdash-tapply-wapper ul li.active{
    border: 2px solid #5b9aef !important;
}
.tdash-tapply-wapper ul li.t-apply-msgs-extra-a{
    position: relative;
    text-align: left;
}
/* .tdash-tapply-wapper ul li.t-apply-msgs-extra-a > div{
    height: 33.3%;
} */

/* common */
button.tdash-left-normal-btn{
    font-size: 12px;
    color : #000;
    background : white;
    border : 1px solid #ddd;
    border-radius: 2px;
    min-width: 80px;
    padding : 0;
}

.td-credit-cards{
    margin-top : 10px;
}

@media screen and (max-width: 1300px){
    .tdash-tapply-wapper ul li{
        margin: 7px 7px;
    }
}
@media screen and (max-width:991px){
    .tdashboard-wrap{
        width: 85%;
        flex-direction: column;
    }
    .tdashboard-left-pannel-wapper{
        width: 100%;
    }
    .td-summ-first span img{
        width: 30%;
        margin: 10px 0px;
    }
    .td-show-profile-sec ul{
        width: 35%;
    }
    .td-table table{
        width: 75%;
    }
}
@media screen and (max-width:767px){
    .tdashboard-wrap{
        width: 100%;
    }
    .td-show-profile-sec ul {
        width: 70%;
    }
    .td-table table{
        width: 90%;
    }
}
