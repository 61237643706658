.recruiting-banner{
    width: 100%;
    display: flex;
    align-items: center;
    height: 383px;
    background-image: url('../../images/recruiting.jpg');
    background-size: cover;
    background-position: top;
}
.recruiting-banner-box{
    width: 70%;
    margin: 0 auto;
}
.recruiting-banner-main{
    width: 100%;
}
.recruiting-banner-main h1{
    font-size: 36px;
}
.recruiting-banner-main h1, .recruiting-banner-main p{
    color: #ffffff;
}
.recruiting-banner-table{
    display: flex;
    width: 40%;
    justify-content: start;
    margin-bottom: 40px;
}
.recruiting-banner-table p{
    line-height: 30px;
}
.recruiting-banner-td-left{
    width: 20%;
    margin-right: 20px;
}
.recruiting-banner-td-right{
    width: 80%;
}