.TdListComponent-wrapper{
}

.td-common-wrapper{
    padding : 0px 20px;
    min-height : 350px;
}

.TdListComponent-innerWrapper{
}
.TdListComponent-innerWrapper ul{
    padding : 0;
    margin: 0;
    display: flex;
}
.TdListComponent-innerWrapper ul li{
    flex : 1;
    padding : 10px;
    color : #333;
    background: #ddd;
    text-align: center;
    cursor: pointer;
}
.TdListComponent-innerWrapper ul li:hover{
    background: rgb(245, 243, 243);
    color : #23527c;
}
.TdListComponent-innerWrapper ul li span{
    padding : 3px;
}
.TdListComponent-innerWrapper ul li span:nth-child(3){
    background: #ffa183;
    color : white;
    border-radius: 2px;
}

.TdListComponent-innerWrapper ul li.active{
    background : #5b9aef;
    color : white;
}
@media screen and (max-width : 767px){
    .TdListComponent-innerWrapper ul{
        flex-direction: column;
    }
    .TdListComponent-innerWrapper ul li{
        margin-bottom: 5px;
    }
}