.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.homepage {
  background-image: url("/images/index_carousel_1.jpg");
  background-size:cover;
  max-width: 100%;
}

.h2box-wrapper{
  width: 87%;
  display: flex;
  justify-content: flex-start;
}

.h2box {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 20px 40px 20px;
  text-align: center;
  width: 100%;
}

.h2box > h2 {
    color: white;
}

.h2box > p {
    color: white;
}
.nav-li > .dropdown-menu{
  padding : 15px 5px;
}
.nav-toggle-btn{
  float: right !important;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0px 10px;
}
.nav-toggle-btn span{
  font-size: 25px;
  color: #888;
}
.nav-toggle-btn:hover{
  cursor: pointer;
}

.bold-font{
}

.submit-btn{
  font-size: 17px;
}

.black-opaque {
  background-color: rgba(0, 0, 0, 0.5);
}
.homepage-blog {
  height : 323px;
  display: inline-block;
  width : 100%;
  
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    /* bottom, image */
    url("/images/index_blog.jpg");
  background-size:cover;
  max-width: 100%;
}

.homepage-blog-box {
  height : 100%;
  margin: 60px 0px 60px 0px;
  padding: 5px 0px 20px 0px;
  text-align: right;
}

.homepage-mooc {
  display: inline-block;
  width : 100%;
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    /* bottom, image */
    url("/images/index_mooc.jpg");
  background-size:cover;
  max-width: 100%;
}

.homepage-mooc-box {
  margin: 60px 0px 60px 0px;
  padding: 5px 10px 20px 20px;
  text-align: center;
}

.mooc_filming {
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    /* bottom, image */
    url("/images/mooc_filming1.jpg");
  background-size:cover;
  max-width: 100%;
}

.mooc_filming2 {
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    /* bottom, image */
    url("/images/mooc_filming2.jpg");
  background-size:cover;
  max-width: 100%;
}

.resources {
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    /* bottom, image */
    url("/images/resources.jpg");
  background-size:cover;
  max-width: 100%;
}

.resources-box {
  margin: 60px 0px 60px 0px;
  padding: 5px 10px 20px 20px;
  text-align: center;
}

.about {
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    /* bottom, image */
    url("/images/about-us/banner.jpg");
  background-size:cover;
    background-position: center;
}

.academy {
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    /* bottom, image */
    url("/images/academy/banner.jpg");
  background-size:cover;
  background-position: relative;
  text-align: center;
  color: white;

  padding: 200px 0px 200px 0px;
}


@media screen and (max-width:480px) {
    .academy {
        padding: 120px 0px 120px 0px;
    }
    /*   .carousel-control {
      font-size: 50px;
    }
    .carousel .item {
      height: 170px;
    } */
}

.academy2 {
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    /* bottom, image */
    url("/images/academy/academy2.jpg");
  background-size:cover;
  background-position: relative;
}

.blockquote {
border:none;
font-family:Georgia, "Times New Roman", Times, serif;
margin-bottom:-30px;
}

.blockquote h3 {
    font-size:21px;
}

.blockquote h3:before { 
    content: open-quote;
    font-weight: bold;
    font-size:100px;
    color:#889c0b;
} 

.blockquote h3:after { 
    content: close-quote;
    font-weight: bold;
    font-size:100px;
    color:#889c0b;
}

.career {
  background-image: url("/images/about-us/career/harry.jpg");
  background-size:cover;
    background-position: center;
}

.email-us {
  background-image: url("/images/email_us.jpg");
  background-size:cover;
  max-width: 100%;
}

.touchpad-bg {
  background-image: url("/images/online-tuition/touchpad-banner.jpg");
  background-size:cover;
  max-width: 100%;
  min-height: 100px;
}

.oxbridge-bg {
    background-image: url("/images/background/bg-oxbridge.jpg");
    background-size:cover;
    max-width: 100%;
    min-height: 100px;
}
.email-us{
  width : 100%;
  height: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.email-us-box{
  width: 95%;
  margin: 0 auto;
}
.email-us-content{
  width: 100%;
  display:flex;
  justify-content: center;
}
.coming-soon-btn {
  font-size: 1.7rem;
  background-color: Transparent;
  border: 2px solid white;
  color: white;
  margin-top: 10px;
  padding: 5px 15px 5px 15px;
  border-radius: 13px;
}

.career-btn {
  font-size: 30px;
  background-color: Transparent;
  border: 3px solid #6c767f;
  color: #6c767f;
  padding: 5px 15px 5px 15px;
  border-radius: 13px;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: black;
}

.index_review1 {
  background:
    /* top, transparent red, faked with gradient */
    linear-gradient(
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1)
    ),
    /* bottom, image */
    url("/images/index_review1.jpg");
  background-size:cover;
  max-width: 100%;
  padding: 150px 0px 5px 15px;
}

.index_review {
  position: relative;
}

.index_review:hover .overlay {
  opacity: 0.8;
}

.text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videowrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 10px;
}

.videowrapper iframe, embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.floor-fade {
  background: 
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.4)
    ),
    url("/images/about-us/career/gangnam_view.jpg");
  max-width: 100%;
  position: relative;
  overflow: hidden;
  margin: 20px;
}
.floor-fade > header {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px;
}
.floor-fade > header > h1 {
  margin: 0;
  color: white;
  text-shadow: 0 1px 0 black;
}
/* fixed toggle */
.hidden-contact{
  display: none;
}
.visible-contact{
  display: block;
}
.help-buttons{
  display: flex;
}
.help-button{
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
  box-shadow: 2px 2px 14px #c3c3c3;
  font-size: 24px;
  background-color: rgba(91, 154, 239, 1.0);
  color: #fff;
}
.help-contact{
  right: 80px;
}
.help-go-to-top{
  right: 20px;
}
* {
  box-sizing: border-box;
}

/*@font-face {*/
  /*font-family: MyCustomFont ;*/
  /*src: local(Droid Sans Fallback), url("../fonts/DroidSansFallback.ttf");*/
/*}*/

/*@font-face {*/
  /*font-family: MyCustomFont ;*/
  /*src: local(Arial), url("../fonts/DroidSansFallback.ttf");*/
  /*unicode-range: U+0021-007B ;*/
/*}*/

body {
  font-family: 'Spoqa Han Sans', sans-serif !important;
  letter-spacing: 0.6px;
  line-height: 1.6;
}

html, body {
  height: 100%;
}

/* Jumbotron */

.blackbox {
  width:55%;
  margin-left: auto;
  margin-right: auto;
  background: black;
  margin-top: 80px;
  margin-bottom: 60px;
  padding: 12px 22px 12px 22px;
  opacity: 0.6;
  color: white;
  text-align: center;
}

/*     .carousel {
  margin-bottom: 60px;
}

.carousel .container {
  position: relative;
  z-index: 9;
}

.carousel-control {
  height: 80px;
  margin-top: 0;
  font-size: 120px;
  text-shadow: 0 1px 1px rgba(0,0,0,.4);
  background-color: transparent;
  border: 0;
  z-index: 10;
}

.carousel .item {
  height: 500px;
}
.carousel img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 500px;
}

.carousel-caption {
  background-color: transparent;
  position: static;
  max-width: 550px;
  padding: 0 20px;
  margin-top: 200px;
}
.carousel-caption h1,
.carousel-caption .lead {
  margin: 0;
  line-height: 1.25;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0,0,0,.4);
}
.carousel-caption .btn {
  margin-top: 10px;
} */

/*nav style*/
.navbar {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
}

/* <nav class="navbar navbar-default">
<div class="container nav_container">
    <div class="navbar-header"> */
.container {
}
.navbar-header{
  width : 100%;
}


.navbar-brand {
  margin-left: 0px;
  padding-top: 5px;
}

/*针对bs的较大改动*/
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: inherit;
  background-color: inherit;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: inherit;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #b5b5b6;
}

a {
  color: #333;
}
/*footer style*/
#wrap {
  /* min-height: 40%; */
  /* margin: 0 auto 0px; */
}


.each-nav{
  background: white;
}
.each-nav > ol {
}
.each-nav > ol > li{
  z-index: 997;
}
.breadcrumb{
  width : 100%;
  margin : 0 auto;
}
.academy-wapper-c{
    width : 100%;
    max-width: 1200px;
    margin : 0 auto;
}



@media screen and (max-width:991px) {
  .h2box{
  }
}
@media screen and (min-width:576px) {
  .h2box{
    width :222.5px;
  }
}
@media screen and (min-width:1000px){
  .h2box{
    width :282.5px;
  }
}
@media screen and (min-width:1800px) {
  .h2box{
    width :390px;
  }
}

/* here tasking!!!!!!! */
/* tablet size must be matched */
@media screen and (max-width:767px){
  .main_row{
    margin: 0;
    padding-top : 0px;
  }
 .main_co_sm_6{
   padding : 0;
 }
}
.navbar {
  padding-top: 0;
  padding-bottom: 0px;
}

.accordion-title {
  margin-bottom: 0px;
}


/*面包屑导航*/
.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "\25BA";
}

.breadcrumb > li > a {
  color: #666666;
  text-decoration: none;
}

.breadcrumb {
  margin-bottom: 0;
  background-color: white;
}

.navbar {
  border-bottom-width: 0;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}

.clear-outline:focus,
.clear-outline:active:focus,
.clear-outline.active:focus,
.clear-outline.focus,
.clear-outline:active.focus,
.clear-outline.active.focus {
  outline: none;
}



.blue-color {
  background-color: #5b9aef;
}

.blue1-color {
  background-color: rgba(91, 154, 239, 0.80);
}

.blue2-color {
  background-color: rgba(91, 154, 239, 0.70);
}

.blue3-color {
  background-color: rgba(91, 154, 239, 0.50);
}

.blue4-color {
  background-color: rgba(91, 154, 239, 0.30);
}

.pink-color {
  background-color: #ffa183;
}

.orange-color {
  background-color: #f9b462;
}

.green-color {
  background-color: #bfdb9f;
}

.gray-color {
  background-color: #595757;
}

.gray1-color {
  background-color: #727171;
}

.gray2-color {
  background-color: #898989;
}

.gray3-color {
  background-color: #b5b5b6;
}

.gray4-color {
  background-color: #c9caca;
}

.gray5-color {
  background-color: #dcdddd;
}

.gray6-color {
  background-color: #efefef;
}

.gray7-color {
  background-color: #f7f8f8;
}

.black-font-color {
  color: #000;
}

.blue-font-color {
  color: #5b9aef !important;
}

.pink-font-color {
  color: #ffa183;
}

.brown-font-color {
  color: #f9b462;
}

.white-font-color {
  color: #fff;
}
.green-font-color {
  color: #bfdb9f;
}

.gray-font-color {
  color: #595757;
}

.gray1-font-color {
  color: #727171;
}

.gray2-font-color {
  color: #898989;
}


.gray3-font-color {
  color: #b5b5b6;
}

.gray4-font-color {
  color: #c9caca;
}

.gray5-font-color {
  color: #dcdddd;
}

.change-language-modal-content {
  padding: 20px;
  text-align: center;
}

.change-language-btn {
  color: white;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* 小屏幕（平板，大于等于 768px） */
@media (min-width: 768px) {

  .nav-li {
    padding-top: -1px;
    padding-bottom: -1px;
    margin-top: 5px;
    border: 1px solid rgba(0, 0, 0, 0);
    text-align: center;
    width: 115px;
    cursor: pointer;
  }

  .nav-li:hover {
    border-radius: 10px;
    border: solid 2px #5b9aef;
  }

  .nav-li > a {
    margin-top: 2px;
    padding:8px 0!important;
  }

  #wrap {
    /*min-height: 100%;*/
    /*margin: 0 auto -280px;*/
  }

  /*#push, #footer {*/
    /*height: auto;*/
  /*}*/
}

@media screen and (max-width:480px) {
  .blackbox {
    width: 90%;
  }
/*   .carousel-control {
  font-size: 50px;
}
.carousel .item {
  height: 170px;
} */
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 992px) {

}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 1200px) {

}

/* 超大大屏幕（大桌面显示器，大于等于 1800px） */
@media (min-width: 1800px) {
  .container {
    width: 1680px;
  }
}


/*备用复制区*/
/* 小屏幕（平板，大于等于 768px） */
@media (min-width: 768px) {

}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 992px) {

}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 1200px) {

}
