.tri-new-wapper{
    min-height: 600px;
    width : 50%;
    margin: 0 auto;
    padding-bottom: 50px;
}

.tri-new-partItems{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    transition: all 0.2s;
}
.tri-new-partItems > div{
    flex: 1;
    transition: all 0.2s;
}

.tri-new-partA{
    position: relative;
}
.tri-new-partA-item{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.tri-new-partA-item > div:nth-child(1){
    padding-right: 10px;
}

.tri-new-partA-item > div:nth-child(2){
    flex: 1;
    position: relative;
    box-sizing: border-box;
}
.tri-new-partA-item > div:nth-child(2){
    line-height: 35px;
}
.tri-new-partA-item > div:nth-child(2) > input{

}
.tri-new-partA-item > div:nth-child(2) > label{
    cursor: pointer;
}
.tri-new-partA-item > div:nth-child(2) > label > input{
    margin : 0 7px;
    cursor: pointer;
}
.tri-new-partA-item > div:nth-child(2) > span{
    cursor: pointer;
    display: inline-block;
    padding : 5px 15px;
    border-radius: 10px;
    margin-right: 5px;
    margin-top: 5px;
    line-height: 25px;
    color : #fff;
    background-color: #c3c3c3;
}
.tri-new-partA-item > div:nth-child(2) > span:hover{
    color: #333;
}
.tri-new-partA-item > div:nth-child(2) > .tri-span-active{
    background-color: #5b9aef;
    opacity: .7;
    color: #fff;
}
.tri-new-partA-item > div:nth-child(2) > textarea{
    width : 100%;
    height: 250px;
    padding : 10px;
    line-height: 25px;
    color : #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}


.tri-new-partB-item{
    margin-bottom: 10px;
}

.tri-new-partB-slide{
    position: relative;
    width: 90%;
    margin: 0 auto;
}
/* .tri-new-partB-item2 div.tri-new-header{
    margin-bottom: 20px;
} */
.tri-gender-radio label{
    margin-right: 15px;
}
.tri-gender-radio input{
    margin-right: 5px;
}
.tri-new-fix-location{
    padding : 5px;
}
.tri-new-location {
    display: flex;
    width : 100%;
    height: 50%;
}
.tri-new-location > div {
    flex : 1 auto;
    display: flex;
    height: 100%;
}
.tri-new-location > div > select{
    flex: 1;
    background: none;
    border : 1px solid #5b9aef;
    margin-right: 5px;
    margin-bottom: 5px;
}

.tri-new-single > input{
    width : 100%;
    height : 34px;
    border: 2px solid #5b9aef;
    border-radius: 8px;
    text-align: center;
}

.tri-new-single > label > input{
    width : 50%;
    text-align: center;
    border: 1px solid #e0e0e0;
}



.tri-new-curriculumns{
}
.tri-new-cur-header{
    display: flex;
    box-sizing: border-box;
}
.tri-new-cur-header button{
    flex : 1;
    padding : 6px 12px;
    margin-right: 5px;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    border: none;
    word-break: break-all;
}
.tri-new-cur-header button:hover{
    color : #333;
}
.tri-new-cur-header button:active{
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important;
}
.tri-new-cur-header #xTEST {
    background-color: rgba(247,175,103,.94);
}
.tri-new-cur-header #xADMISSION{
    background-color: rgba(252,155,131,.95);
}
.tri-new-cur-header #xENGLISH{
    background-color: #b5d693;
}



.tri-first-selector{
    margin : 5px 0;
    display: flex;
}
.tri-first-selector select{
    background: none;
    border : 2px solid #5b9aef;
    width : 150px;
    height: 30px;
    padding : 5px !important;
    font-size: 15px;
    transition: all 0.2s;
    border-radius: 8px;
}
.tri-arrow{
    font-size: 27px !important;
    align-items: center;
    display: flex;
    margin-left: 10px;
}
.tri-arrow i{
    opacity: .6;
}
.tri-second-selector{
    flex: 1;
    max-height: 250px;
    margin: 0 10px;
    border : 2px solid #5b9aef;
    border-radius: 8px;
    overflow: scroll;
    overflow-x: hidden;
    transition: all 0.2s;
}
.tri-second-selector ul{
    padding : 10px;
}
.tri-second-selector ul li{
    padding : 5px;
    font-size: 15px;
    cursor: pointer;
    border-radius: 3px;
    margin-bottom: 1px;
}
.tri-second-selector ul .tri-active[data-cul-key="IB"],.tri-active[data-cul-key="(i)GCSE"],.tri-active[data-cul-key="A Level"],.tri-active[data-cul-key="Pre-U"],.tri-active[data-cul-key="SAT"], .tri-active[data-cul-key="SAT Subject"], .tri-active[data-cul-key="AP"], .tri-active[data-cul-key="ACT"], .tri-active[data-cul-key="Olympiad/Competition"], .tri-active[data-cul-key="기타"], .tri-active[data-cul-key="미국계 고학년"], .tri-active[data-cul-key="영국대학입학시험"], .tri-active[data-cul-key="MYP"]{
    background-color: rgba(247,175,103,.94);
    color : white;
}
.tri-second-selector ul .tri-active[data-cul-key="Undergraduate"],.tri-active[data-cul-key="Master"],.tri-active[data-cul-key="PhD"],.tri-active[data-cul-key="학사"],.tri-active[data-cul-key="석.박사"],.tri-active[data-cul-key="커리어"]{
    background-color: rgba(252,155,131,.95);
    color : white;
}
.tri-second-selector ul .tri-active[data-cul-key="IELTS"],.tri-active[data-cul-key="TOEFL"],.tri-active[data-cul-key="FCE"],.tri-active[data-cul-key="Essay/Debate"],.tri-active[data-cul-key="Coding/Programming"],.tri-active[data-cul-key="Reading"],.tri-active[data-cul-key="Grammar"],.tri-active[data-cul-key="HSK"],.tri-active[data-cul-key="DELE"],.tri-active[data-cul-key="독일어어학시험"],.tri-active[data-cul-key="Spanish"]{
    background-color: #b5d693;
    color : white;
}


.tri-new-subm-btns a{
    margin : 0 5px;
    font-weight: bold;
}
.tri-new-btns{
    width : 100%;
    display: flex;
    margin-top: 15px;
}
.tri-new-btns > span{
    display: inline-block;
    border-radius: 13px;
    padding : 5px 10px;
    margin : 0 3px;
    border : 2px solid #5b9aef;
    cursor: pointer;
    font-size: 17px;
}
.tri-new-btns > span:nth-child(1){
    display: inline-block;
    border-radius: 13px;
    padding : 5px 15px;
    margin : 0 3px;
    border : 2px solid #5b9aef;
    background-color: #5b9aef;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    opacity: .8;
}
.tri-new-btns > span:hover{
    color :#23527c;
    text-decoration: underline;
}
.tri-new-btns > span:nth-child(1):hover{
    color: #333;
}



.tri-new-partC{
}
.tri-new-partC > div{

}
.tri-new-partC div.tri-new-partC-labels{
}
.tri-new-partC div.tri-new-partC-labels > label{
    margin-right: 10px;
    cursor: pointer;
}
.tri-new-partC div.tri-new-partC-labels > label > input{
    margin-right: 10px;
}

.tri-new-nuds{
    margin-top: 20px;
}
.tri-new-nuds > textarea {
    width : 100%;
    height: 150px;
    font-size: 14px;
    padding : 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #555;
    margin-bottom: 15px;
}




.tri-new-calenda{
    position: relative;
}
.dating{
    cursor: pointer;
}
.dating > input {
    cursor: pointer;
}

/* Common */
.tri-new-info{
    text-align: center;
    padding : 5px;
    color: #6c767f;
}
.tri-new-header{
    color: #5b9aef;
    font-size: 24px;
}

select:hover{
    box-shadow: 0 0 3px #5b9aef;
    cursor: pointer;
}
select:focus{
    outline: none;
    box-shadow: 0 0 3px #5b9aef;
}

.border-zero{
    border : 0;
}
.adjust-margin{
    margin: 30px 0;
}



.alert-tuti-req{
    transition: all 0.35s;
    overflow: hidden;
}
.show-for-alert{
    height: 53px;
}
.hide-for-alert{
    height: 0px;
}
.tuti-req-red{
    color : white;
    background : red;
    text-align: center;
    padding : 10px;
}


@media screen and (max-width:1200px) {
    .tri-new-wapper{
        width : 80%;
    }
    .tri-new-partItems{
        display: inline-block;
    }
    .tri-new-nuds > textarea {
        width : 100%;
    }
}
@media screen and (max-width: 767px){
    .tri-new-wapper{
        width: 95%;
    }
    .tri-first-selector{
        flex-direction: column;
    }
    .tri-first-selector select{
        width: 100%;
    }
    .tri-arrow{
        width: 100%;
        justify-content: center;
        margin: 10px 0px;
        font-size: 40px !important;
    }
    .tri-arrow i{
        transform: rotate(90deg);
    }
    .tri-second-selector{
        margin: 0px 0px;
    }
}