.sd-img-fix{
}
.sd-img-fix > img{
    width : 100%;
}
.sdashboard-container .container {
    font-size: 14px;
}

.sdashboard-container .btn.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.sdashboard-container .btn.btn-gurume-blue {
    color: #fff;
    background-color: #5b9aef;
    border-color: #5ca2e9;
}

.sdashboard-container .btn.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.sdashboard-container .contacts-btns .btn {
    margin: 0px 4px;
}

.sdashboard-container .btn.cancel-btn,
.sdashboard-container .btn.pop-up-btn {
    border: 2px solid #5b9aef;
    opacity: .8;
    background-color: #fff;
    font-size: 16px;
    padding: 5px 15px;
}

.sdashboard-container .contacts .kakaotalk-span {
    width: 30px;
    text-align: center;
    display: inline-block;
}

.sdashboard-container .contacts .kakaotalk-span>img {
    width: 14px;
    height: 14px;
}

.sdashboard-container .dashboard-student-questions .dashboard-blogs-intro {
    display: block;
    overflow: hidden;
    height: 60px;
}

.sdashboard-container .dashboard-student-questions .blog-btn {
    float: right;
}

.sdashboard-container .dashboard-student-questions .blog-article-thumbnail {
    height: 150px;
    width: 100%;
}

.sdashboard-container button.teacher-profile-subject-btn {
    font-size: 12px;
}

.sdashboard-container .tudent-reg-contact-pic-radius {
    border-radius: 5px;
}

.sdashboard-container .ng-curriculum-subject,
.sdashboard-container .ng-start-end-date {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sdashboard-container .panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.sdashboard-container .thumbnail {
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
    margin-bottom: 20px;
}

.sdashboard-container .select-nav {
    width: 100%;
}

.sdashboard-container .student-reg-contact-pic-radius {
    border-radius: 5px;
}

.studentreg-container .delete-sub-selected,.studentreg-container .delete-sub-btn {
    width: 25px;
}

.studentreg-container .student-reg-submit-btn {
    font-size: 1.4rem;
}
.studentreg-container .btn-hint-link{
    color: #333;
}
.studentreg-container .btn-hint-link i{
    font-size: inherit;
}
.studentreg-container .selected-subject-title{
    font-size: 1.2rem;
}