.class-line{
    width: 3%;
    border-top: 2px solid black;
    margin-top: 20px;
    margin-bottom: 50px;
}
.text-indent-2{
    text-indent: 2em;
}
.text-indent-4{
    text-indent: 4em;
}
/* banner */
.class-banner{
    width: 100%;
    height: 470px;
    background-image: url("../../../images/vacation_class/gurume_office_1.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.class-banner-box{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.class-banner-main{
    z-index: 100;
}

/* contents */
.class-content{
    width: 100%;
    text-align: center;
}
.class-content-box{
    width: 60%;
    margin: 0 auto;
}
.class-content-main{
    width: 100%;
    margin: 0 auto;
}
.class-content-title{
    margin-top: 50px !important;
    font-weight: bold;
    color: #5b9aef;
}
.class-content-main-text{
    text-align: left;
}
.class-content-main-text h3{
    margin-top: 20px;
}
.class-content-main-text span{
    color: black;
    font-size: 24px;
}

