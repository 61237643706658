.student-show{
    width: 100%;
    background-color: rgba(223,223,223,.63);
}
.student-show-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.student-show-box{
    width: 80%;
    margin: 0 auto;
}
.student-show-contents{
    width: 100%;
    display: flex;
    margin-top: 50px;
}
.student-show-left{
    width: 70%;
    margin-right: 20px;
}
.student-intro-card{
    width: 100%;
    background-color: #fff;
    padding: 20px 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    display: flex;
}
.student-intro-left{
    width: 36%;
}
.student-profile-img{
    width: 65%;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 50px;
}
.student-profile-img img{
    width: 100%;
    height: 100%;
}
.student-profile-explanation{
    width: 100%;
    margin: 0 auto;
}
.student-profile-explanation p>span{
    color: #333;
}
.student-profile-explanation h3{
    font-size: 24px;
    color: #000;
}
.student-info{
    width: 100%;
    padding-left: 20px;
}
.student-info li{
    color: #333;
}
.student-info li i{
    margin-right: 15px;
}
.student-info li img{
    margin-right: 15px;
}
.student-intro-right{
    width: 65%;
}
.student-intro-right p{
    font-weight: bold;
    color: #5b9aef;
    font-size: 22px;
}
.school-info{
    color: #6c767f;
}
.school-info .school-label{
    background: #777;
    color: white;
    padding: 2px 10px;
    border-radius: 3px;
    line-height: 12px;
    font-size: 10.7px;
    font-weight: bolder;
    margin-right: 5px;
}
/* student memo */
.student-memo-card{
    width: 100%;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
}
.student-memo-card h4{
    font-size: 18px;
}
.student-memo-card .last-updated{
 color: #c9caca;
 font-size: 12px;
}
.student-memo-card p{
    font-size: 16px;
}
.student-memo-card textarea{
    width: 100%;
    border: none;
    resize: none;
    font-size: 15px;
    color: #6c767f;
    margin-top: 5px;
    background-color: #ffffff !important;
}
.student-memo-card input{
    float: right;
}

/* student consulting summary */
.student-summary-card{
    width: 100%;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
}
.student-summary-card h4{
    font-size: 18px;
}
.student-summary-card p{
    font-size: 16px;
}
.student-summary-card textarea{
    width: 100%;
    border: none;
    resize: none;
    font-size: 15px;
    color: #6c767f;
    margin-top: 5px;
    background-color: #ffffff !important;
}
.student-summary-card input{
    float: right;
}
/* student tuition */
.student-tuition-card{
    width: 100%;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
}
.student-tuition-card h4{
    font-size: 18px;
}


.student-show-right{
    width: 30%;
}
.student-credit-card{
    width: 100%;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    text-align: center;
}
.student-credit-card h4{
    font-size: 18px;
    text-align: left;
}
.student-tuition-requests{
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
}
.student-tuition-requests-wrapper{
    width: 95%;
    margin: 0 auto;
    margin-top: 15px;
}
.student-tuition-requests-wrapper button{
    font-size: 17px !important;
    margin-top: 10px;
}
.student-tuition-request-detail-btn
{
    font-size: 17px;
    border: 2px solid #5b9aef;
    border-radius: 13px;
    padding: 5px 15px;
    cursor: pointer;
    margin-top: 15px;
}
.student-tuition-request-detail-btn > a{
    color: #6c767f;
}
.student-tuition-request-detail-btn > a:hover{
    text-decoration: none;
    color: #6c767f;
}
.student-tuition-table-wrapper{
}
.student-tuition-table-btn{
    font-size: 17px;
    border: 2px solid #5b9aef;
    border-radius: 13px;
    padding: 5px 15px;
    cursor: pointer;
    color: #6c767f;
}

/* responsive work */
@media screen and (max-width: 991px){
    .student-show-box{
        width: 85%;
    }
    .student-show-contents{
        flex-direction: column;
    }
    .student-show-left{
        width: 100%;
    }
    .student-info{
        float: left;
    }
    .student-intro-card{
        flex-direction: column;
    }
    .student-intro-left, .student-intro-right{
        width: 100%;
    }
    .student-profile-img{
        width: 30%;
    }
    .student-show-right{
        width: 100%;
    }
}
@media screen and (max-width: 767px){
    .student-show-box{
        width: 95%;
    }
}