.academy-wrapper{
    width: 100%;
    overflow: hidden;
}
/* academy banner sec */
.academy-banner{
    width: 100%;
    height: 544px;
    background-image: url('../../images/academy/banner.jpg');
    background-size: cover;
}
.academy-banner-box{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.academy-banner-main-wrapper{
    z-index: 100;
    text-align: center;
}
.academy-banner-main-wrapper h1{
    font-size: 56px;
}
.academy-banner-main-wrapper h2{
    font-size: 30px;
}

/* academy teachers sec */
.academy-teachers{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.academy-teachers-box{
    width: 60%;
    margin: 0 auto;
}
.academy-teachers-main-content{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.w-60{
    width: 60% !important;
}
.w-90{
    width: 90% !important;
}
.academy-teachers-item{
    width: 40%;
    margin: 10px;
}
.academy-teachers-item:hover{
    cursor: pointer;
    border-color: #5b9aef;
    box-shadow: 0 1px 20px #5b9aef;
}
.academy-item-title{
    padding-top: 10px;
    padding-bottom: 10px;
}
.academy-item-title h4{
    font-size: 18px;
}
.academy-item-content{
    width: 100%;
}
.academy-item-content-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.academy-item-content-img{
    width: 50%;
}
.academy-item-content-img > img{
    width: 100%;
    height: 100%;
}
.academy-item-content-text{
    width: 50%;
    background: #ffffff;
    padding: 20px 10px 0px 10px;
}
.academy-item-content-text h3{
    font-size: 24px;
}
.academy-item-content-text p, .academy-other-teachers p{
    font-size: 14px;
}
.academy-other-teachers{
    margin-top: 20px;
    width: 100%;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.academy-other-teachers a{
    font-size: 24px;
}
/* academy viedo sec */
.owl-carousel{
    width: 100% !important;
    height: 80% !important;
    position: relative !important;
}
.owl-stage-outer{
    width: 100% !important;
    height: 100% !important;
}
.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video{
    height: 50% !important;
}



/* academy ment sec */
.academy-ment{
    width: 100%;
    display: flex;
    justify-content: center;
}
.academy-ment-box{
    width: 68%;
    margin: 0 auto;
}
.academy-ment-main-wrapper{
    width: 100%;
}
.academy-ment-item{
    width: 100%;
    height: 100%;
}
.academy-ment-item h1{
    font-size: 36px;
}
.academy-ment-item h4{
    font-size: 18px;
}

/* academy expert sec */
.academy-expert{
    width: 100%;
    display: flex;
    justify-content: center;
}
.academy-expert-box{
    width: 70%;
    margin: 0 auto;
}
.academy-expert-box h1{
    font-size: 36px;
}
.academy-expert-content{
    width: 100%;
    display: flex;
    justify-content: center;
}
.academy-expert-content-item{
    width: 30%;
}
.academy-expert-content-item h4{
    font-size: 18px;
}

/* academy book sec */
.academy-book{
    width: 100%;
    display: flex;
    justify-content: center;
}
.academy-book-box{
    width: 70%;
    margin: 0 auto;
}
.academy-book-box h1{
    font-size: 36px;
}
.academy-book-content{
    width: 100%;
    display: flex;
    justify-content: center;
}
.academy-book-content-item{
    width: 50%;
    margin: 0 1%;
}

/* academy vacation sec */
.academy-vacation{
    width: 100%;
    height: 387px;
    background-image: url('../../images/academy/books_collection.jpg');
    background-size: cover;
    background-position: center;
}
.academy-vacation-box{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.academy-vacation-item{
    z-index: 100;
    text-align: center;
}
.academy-vacation-btn{   
    position: relative;
    z-index: 3; 
    font-size: 24px;
    border: 3px solid #fff;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
}
.academy-vacation-item h1{
    position: relative;
    z-index: 3;
}

/* responsive work */
@media(max-width: 1070px) {
    .academy-teachers-box{
        width: 95%;
    }
}
@media(max-width: 991px) {
    .academy-teachers-box{
        width: 60%;
    }
    .academy-teachers-item{
        width: 100%;
    }
    .academy-expert-box, .academy-book-box{
        width: 90%;
    }
}
@media(max-width: 767px) {
    .academy-teachers-box{
        width: 95%;
    }
    .academy-expert-content, .academy-book-content{
        flex-direction: column;
    }
    .academy-expert-content-item, .academy-book-content-item{
        width: 100%;
    }
}