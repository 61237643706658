.lang-wrapper{
}

.lang-pannel{
    background: white;
    border-radius: 6px;
    width : 100%;
    height: 264px;
    position: relative;
    overflow: hidden;
    margin : 0 auto;
    margin-top: 13%;
    z-index: 99999;
    box-shadow: 0 0 10px rgba(0,0,0,0.7);
}

.lang-pannel ul {
    position: absolute;
    padding : 20px 50px;
    width : 100%;
    height: 100%;
    text-align: center;
    transition: all .5s;
}
.lang-pannel ul li {
    border-radius: 4px;
    width : 100%;
    height : 34px;
    padding : 10px;
    line-height: 18px;
    margin: 0 auto;
    margin-top: 20px;
    background : #5b9aef;
    color : white;
    font-size: 1.5rem;
    font-family: inherit;
    cursor: pointer;
}
.lang-pannel ul li:nth-child(2){
    margin-top: 40px;
}
.lang-pannel ul li:nth-child(3){
    margin-top: 40px;
}

.lang-bg{
    padding: 40px;
    position: fixed;
    top : 0;
    left : 0;
    width : 100%;
    height : 100%;
    background: rgba(0,0,0,0.5);
    box-sizing: border-box;
    z-index: 999;
}

.lang-pannel ul li:hover {
    color : black;
}


@media  (max-width : 768px) {

    .lang-pannel ul {
        padding : 20px 5px;
    }
    .lang-pannel{
        height: 340px;
    }
    .lang-pannel ul li{
        padding : 30px 0 !important;
        font-size: 1.7rem;
        line-height: 7px;
        transition: all 0.5s;
    }
    .lang-pannel ul li:hover{
        width : 110%;
        transform: translateX(-4%);
    }
}
@media  (min-width : 1000px) {


    .lang-pannel ul {
      padding : 20px;
      display: flex;
    }
    .lang-pannel ul li{
        margin : 0 10px !important;
        width : 100% !important;
        flex : 1 !important;
        display: flex;
        height: auto;
        font-size: 2.5rem;
        align-items: center;
        justify-content: center;
    }
    .lang-pannel ul li:hover{
        background : #ccc;
    }
    .lang-bg{
        padding: 40px 150px;
    }
}
