


.main-mooc-wapper{
    width : 100%;
    height : 100%;
    font-family: 'Spoqa Han Sans';
    box-sizing: border-box;
    position: relative;
    text-align: center;
    min-height: 390px;
}
.main-mooc-wapper div.main-mooc-div{
    display: inline-block;
    text-align: center;
    margin-top: 90px;
    padding : 20px 50px;
    border-radius: 3px;
    transition: all 1s;
}

.main-mooc-wapper div.main-mooc-div h1{
    color : white;
    font-family: 'Spoqa Han Sans';
    font-weight: 700;
    text-align: center;
    padding : 5px;
}
.main-mooc-wapper div.main-mooc-div h1 > span{
    margin : 5px;
}
.main-mooc-wapper div.main-mooc-div h1 > span:nth-child(2){
    font-size: 2.7rem;
}

/* MOOC */

.main-mooc-wapper div.main-mooc-div h2{
    color : white;
    font-family: 'Spoqa Han Sans';
    font-weight: 500;
    text-align: center;
    padding : 5px;
}

.main-mooc-wapper div.main-mooc-div button{
    margin : 10px;
    padding : 5px 20px;
    font-size: 1.2rem;
    border-radius: 5px;
    background: #5b9aef;
    color : white;
    border : 0;
}


.main-mooc-wapper div.main-mooc-div > button:hover{
    cursor: pointer;
}


/*  Standard 1200 */
@media screen and (min-width:1200px) {
    .main-mooc-wapper div.main-mooc-div{
        padding : 20px 70px;
    }
    .main-mooc-wapper div.main-mooc-div button{
        padding : 5px 30px;
        font-size: 1.5rem;
    }
}



@media screen and (max-width:768px) {
    .main-mooc-wapper div.main-mooc-div{
        margin-top: 100px;
        width : 100%;
        padding : 20px 0;
        box-shadow: none;
    }
    .main-mooc-wapper div.main-mooc-div button{

        display: inline-block;
        float: none;
        margin : 0 auto;
        overflow: hidden;
        border-radius: 10px;
        width : 130px;
        border : 0;
    }

}
