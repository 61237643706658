.trs-main-wrapper{
    min-height: 500px;
    position: relative;
}

.trs-main-html{
    min-height: 100%;
    padding-bottom: 50px;
}
.trs-main-body{
    width: 65%;
    margin : 0 auto;
}
.academy-wapper-c{
    width: 70% !important;
}
.tuition-request-show-wrapper{
    display: flex;
    width: 80%;
    margin: 0 auto;
}
.recommend-teachers{
    width: 45%;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}
.recommend-teachers-card{
    width: 85%;
}
.recommend-teachers-card-main{
    width: 100%;
    background: #fff;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
    cursor: pointer;
    position: relative;
    box-shadow: 2px 2px 3px #ddd, -2px 0px 3px #ddd;
}
.recommend-teachers-card-header{
    width: 100%;
    display: flex;
    justify-content: center;
}
.recommend-teachers-card-header-box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recommend-teachers-card-header-img{
    width: 30%;
    margin: 0 auto;
}
.recommend-teachers-card-header-img-box{
    width: 60%;
    background-color: #fff;
    border-radius: 50px;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 5px;
}
.recommend-teachers-card-header-img img{
    width: 100%;
    height: 100%;
    border-radius: 50px;
}
.recommend-teachers-card-header-info{
    width: 70%;
    margin: 0 auto;
}
.recommend-teachers-card-header-info-box{
    width: 100%;
    padding: 10px 0px;
}
.recommend-teachers-card-header-info-box .teacher-name{
    font-size: 16px !important;
    color: #000 !important;
    font-weight: bold !important;
} 
.recommend-teachers-card-header-info-box .teacher-university{
    font-size: 14px !important;
    color: #825437 !important;
}
.recommend-teachers-card-header-info-box .teacher-major{
    font-size: 14px !important;
}

.recommend-teachers-card-subject{
    width: 100% !important;
    height: 30px !important;
    color: #fff !important;
    line-height: 2.3rem !important;
    font-size: 14px !important;
}
.recommend-teachers-card-content{
    padding: 10px 15px !important;
    font-size: 14px !important;
}
.recommend-teachers-card-footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 5px;
}
.recommend-teachers-card-footer span{
    padding-right: 5px !important; 
    font-size: 14px !important;
}

.trs-main-header{
    z-index: 999999;
    position: fixed;
    width : 100%;
    height: 100%;
    top : 0;
    left : 0;
    background: rgba(0,0,0,0.5);
    transition: all 1s;
}
.trs-main-apply-box{
    width : 70%;
    background: white;
    border : 1px solid #ccc;
    border-radius: 3px;
    margin : 0 auto;
    margin-top : 100px;
    padding : 15px 15px 50px 15px;
}
.trs-apply-top{
    border : 1px solid #5b9aef;
    border-radius: 3px;
    padding : 10px;
    margin-bottom: 10px;
}
.trs-apply-top > span {
    font-size: 18px;
    color : #6c767f;
}
.trs-apply-top > div {
}
.trs-apply-top > div > textarea {
    border : 0;
    width : 100%;
    height: 150px;
    font-size: 17px;
}
.trs-main-apply-btns{
    float: right;
    padding : 10px 0;
}





.trs-card-wrapper{
    display: flex;
    flex-direction: column;
    width : 100%;
    overflow: hidden;
    margin : 5px 0;
}

.trs-card-wrapperB{
    display: flex;
    width : 100%;
    margin : 5px 0;
}

.trs-card-picture{
    width : 30px;
    height : 30px;
}


.trs-card-partA{
    width : 100%;
}
.trs-card-teaching-subject{
    margin-top: 10px;
}
.trs-card-partA .trs-card-partA-box{
    display: flex;
    flex-direction: column;
    width : 100%;
    padding : 0px;
}
.trs-card-partA .trs-card-partA-box li{
    display: flex;
}
.trs-card-partA .trs-card-partA-box li > div{
    box-sizing: border-box;
}
.trs-card-partA .trs-card-partA-box li > div:nth-child(1){
    text-align: center;
    margin : 5px 0;
    margin-right: 5px;
}
.trs-card-partA .trs-card-partA-box li > div:nth-child(1) input{
    border: none;
}
.trs-card-partA .trs-card-partA-box li > div:nth-child(2){
    flex : 10;
    line-height: 35px;
    width : 100%;
}
.trs-card-partA .trs-card-partA-box li > div:nth-child(2) button{
    border: none;
    color: #6c767f;
}

.trs-card-partA .trs-card-partA-box li > span {
    display: inline-block;
    width : 100%;
    margin-bottom: 2px;
    padding : 0px 0;
}
.trs-card-partA .trs-card-partA-box li > span > span{
    display: inline-block;
    border-radius: 10px;
    margin-right: 3px;
    margin-bottom: 3px;
    padding : 3px 15px;
    cursor: not-allowed;
    font-size: 16px;
    min-width: 100px;
    text-align: center;
}


.trs-card-partB{
    flex : 1;
}
.trs-card-partB > div > div {
    padding : 0;
    margin : 1px 0;
    color: #6c767f;
}
.trs-card-partB > div > div > h4{
    padding : 5px 0;
    display: flex;
    align-items: center;
}
.trs-card-partB > div > div > div.trs-comm-textarea{
    width : 100%;
}
.trs-card-partB > div > div > div.trs-comm-textarea > textarea{
    width : 100%;
    border: 0;
    font-size: 14px;
    resize: none;
    overflow: hidden;
    background-color: #ffffff !important;
}
.trs-card-partB > div > div > div.trs-comm-textarea > textarea:disabled{
    color: #6c767f !important;
    opacity: 1;
    -webkit-text-fill-color: inherit;
}
.trs-buttons-wrapper{
    width: 100%;
    display: flex;
    margin-top: 20px;
}
.trs-buttons-wrapper button{
    padding: 5px 15px;
    border-radius: 13px;
    font-size: 17px;
    cursor: pointer;
    margin-right: 5px;
}


.trs-agree-div{
    background: white;
}
.trs-agree-div > span{
}
.trs-agree-div > span > label{
    color : #333;
    cursor: pointer;
}
.trs-agree-div > span > label input{
    margin-right: 5px;
}
.trs-all-agree{
    margin-top: 10px;
}




/* Common */
.trs-err {
    height: auto;
    color : white;
    transition: all .5s;
}
.trs-err-hide{
    height: 0;
    overflow: hidden;
}
.trs-err-show{
    height: 20px;
    color : rgba(252,155,131,.95);
}

.trs-comm-size20{
    font-size : 14px;
    color: #6c767f;
    letter-spacing: -.1px;
}
.trs-comm-blue{
    margin-bottom: 10px;
}
.trs-comm-blue > h4{
    color : #5b9aef;
    text-align: center;
}
.trs-comm-blue > h3{
    color : #5b9aef;
    text-align: center;
}

.trs-comm-reverse{
    margin-bottom: 10px;
}
.trs-comm-reverse > h4 > input{
    margin-left: 5px;
}
.color-white-ele{
    background: white;
}
.color-white-ele > span{
    font-weight: 900;
}

.trs-comm-blue-color{
    color : #5b9aef;
}
.trs-comm-pink-color{
    color : rgba(252,155,131,.95);
}

.trs-comm-light-pink{
    color : white;
    background: rgba(252,155,131,.95);
    border : 1px solid rgba(252,155,131,.95);
}
.trs-comm-light-pink-border{
    color : #6c767f;
    box-sizing: border-box;
    border : 2px solid rgba(252,155,131,.95);
}
.trs-comm-light-org{
    color : white;
    background: #f9b462;
    border : 1px solid #f9b462;
}
.trs-comm-light-org-border{
    color : #6c767f;
    border : 2px solid #f9b462;
    box-sizing: border-box;
}
.trs-comm-light-green{
    color : white;
    background: #b5d693;
    border : 1px solid #b5d693;
}
.trs-comm-light-green-border{
    color : #6c767f;
    box-sizing: border-box;
    border : 2px solid #b5d693;
}



.trs-comm-org{
    background-color : white;
    border: 2px solid #f9b462;
}
/* .trs-comm-org:hover{
    color : #f9b462;
    background: white;
} */
.trs-comm-org:active{
    box-shadow: 3px 3px 3px rgba(223, 223, 223, .3);
    text-shadow: -1px -1px 0 rgba(223, 223, 223, .3), 1px -1px 0 rgba(223, 223, 223, .3), -1px 1px 0 rgba(223, 223, 223, .3), 1px 1px 0 rgba(223, 223, 223, .3);
}

.trs-comm-green{
    background-color : white;
    border: 2px solid #b5d693;
}
/* .trs-comm-green:hover{
    color : #b5d693;
    background: white;
} */
.trs-comm-green:active{
    box-shadow: 3px 3px 3px rgba(223, 223, 223, .3);
    text-shadow: -1px -1px 0 rgba(223, 223, 223, .3), 1px -1px 0 rgba(223, 223, 223, .3), -1px 1px 0 rgba(223, 223, 223, .3), 1px 1px 0 rgba(223, 223, 223, .3);
}

.trs-comm-str-org{
    background-color : white;
    border: 2px solid rgb(252, 155, 131);
}
.trs-comm-str-org:hover{
    color: rgb(252, 155, 131);
    background : white;
}
.trs-comm-str-org:active{
    box-shadow: 3px 3px 3px rgba(223, 223, 223, .3);
    text-shadow: -1px -1px 0 rgba(223, 223, 223, .3), 1px -1px 0 rgba(223, 223, 223, .3), -1px 1px 0 rgba(223, 223, 223, .3), 1px 1px 0 rgba(223, 223, 223, .3);
}

.trs-comm-back{
    background-color : white;
    border: 2px solid #5b9aef;
}
/* .trs-comm-back:hover{
    color : #5b9aef;
    background: white;
} */
.trs-comm-back:active{
    box-shadow: 3px 3px 3px rgba(223, 223, 223, .3);
    text-shadow: -1px -1px 0 rgba(223, 223, 223, .3), 1px -1px 0 rgba(223, 223, 223, .3), -1px 1px 0 rgba(223, 223, 223, .3), 1px 1px 0 rgba(223, 223, 223, .3);
}

.trs-extra-fix {
    cursor: pointer;
    transition: all .2s;
    display: inline-block;
    text-align: center;
    min-width : 70px;
    margin-right: 5px;
    padding : 3px 10px;
}

@media screen and (max-width:991px) {
    .trs-card-wrapper{
        flex-direction: column;
    }
    .tuition-request-show-wrapper{
        width: 95%;
    }
    .trs-card-partA{
        width : 100%;
        margin : 1px 0;
    }
    .trs-card-partB div > ul{
        width : 95%;
        flex-direction: column;
    }

    .trs-card-partB div > ul li > span > span{
        font-size: 17px;
    }
    .academy-wapper-c{
        width: 90% !important;
    }
    .trs-buttons-wrapper button{
        font-size: 15px !important;
    }
}


@media screen and (max-width:767px) {
    .trs-main-body{
        width: 100%;
    }
    .recommend-teachers{
        width: 100%;
        margin-left: 0px;
        margin-top: 15px;
    }
    .recommend-teachers-card {
        width: 100%;
    }
    .tuition-request-show-wrapper{
        flex-direction: column;
    }
    .trs-card-partB div > ul{
        width : 100%;
        padding : 10px 0;
    }
    .academy-wapper-c{
        width: 95% !important;
    }
    .trs-buttons-wrapper button{
        font-size: 14px !important;
    }
}