.dropdown-toggle::after { 
    content: none !important; 
} 
.nav-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.nav-box{
    width: 80%;
    display: flex;
    margin: 0 auto;
}
.nav-logo{
    width: 30%;
    display: flex;
    justify-content: flex-start;
}
.nav-menu{
    width: 70%;
    display: flex;
}
.nav-menu-list{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.nav-menu-item{
    width: 15%;
    text-align: center;
    cursor: pointer;
}
.nav-menu-item:hover{
    border-radius: 10px;
    border: 2px solid #5b9aef;
}
.nav-menu-item a{
    color: #6c767f;
}
.nav-menu-item:hover a{
    color: #333333 !important;
}
.nav-menu-item > .nav-item a:hover{
    color: #333333 !important;
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus{
    color: #262626 !important;
    background-color: #f5f5f5 !important;
}

@media screen and (max-width:991px){
    .nav-box{
        width: 95%;
    }
    .nav-logo{
        width: 20%;
    }
    .nav-menu{
        width: 80%;
    }
    .nav-menu-item{
        width: 20%;
    }
}