.demo-class{
    width: 100%;
}
.demo-class-common-box{
    width: 80%;
    margin: 0 auto;
}
.demo-class-common-box h3{
    color: #5b9aef;
    font-size: 24px;
}
/* demo class banner sec */
.demo-class-banner{
    width: 100%;
    height: 318px;
    background-image: url('../../images/index_mooc.jpg');
    background-size: cover;
    background-position: top;
}
.demo-class-banner-box{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.demo-class-banner-main{
    z-index: 100;
    text-align: center;
}
.demo-class-banner-main h1, .demo-class-banner-main h4{
    color: #fff;
}
.demo-class-banner-main h1{
    font-size: 36px;
}
.demo-class-banner-main h4{
    font-size: 18px;
}
.demo-class-example{
    width: 100%;
    margin-top: 20px;
}
.demo-class-youtube{
    width: 100%;
}
.demo-class-youtube-box{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}
.demo-class-youtube-item{
    width: 50%;
}
.demo-class-benefit{
    width: 100%;
    margin-top: 20px;
}
.demo-class-publicity{
    width: 100%;
    margin-top: 20px;
}
.demo-class-publicity-box{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.demo-class-publicity-box h3{
    color: #5b9aef;
    font-size: 24px;
}
.demo-class-publicity-image{
    width: 100%;
    display: flex;
    justify-content: center;
}
.demo-class-publicity-image-item{
    width: 50%;
}
.demo-class-publicity-image-item:nth-child(1){
    margin-right: 20px;
}
.demo-class-publicity-image-item img{
    width: 100%;
    height: 100%;
}
.demo-class-preparation{
    width: 100%;
    margin-top: 20px;
}
.demo-class-filming{
    width: 100%;
    margin-top: 20px;
}
.demo-class-dresscode{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width:991px){
    .demo-class-youtube-box{
        width: 100%;
    }
    .demo-class-youtube-item{
        width: 100%;
    }
    .demo-class-publicity-box{
        width: 100%;
    }
}
@media screen and (max-width:767px){
    .demo-class-common-box{
        width: 95%;
    }
}