.ms-drop > ul > li > label > input[type="checkbox"] {
  display: none;
}

.ms-drop {
  position: static;
  border: none;
  border-radius: 10px;
  margin-top: 3px;
}

.ms-drop ul {
  padding: 0 8px 3px 8px;
}

.ms-drop ul li {
  text-align: center;
  padding: 2px 0;
  margin: 3px 0;
  border-radius: 7px;
}

.ms-drop ul li label {
  margin: 0;
}

.ms-choice {
  display: none;
}

.tutorreg-Admission-selected-border {
  color: #333;
  border: 2px solid rgba(252, 155, 131, 0.95);
}

.tutorreg-Test-selected-border {
  color: #333;
  border: 2px solid rgba(247, 175, 103, 0.94);
}

.tutorreg-English-selected-border {
  color: #333;
  border: 2px solid #b5d693;
}

.tutorreg-Admission-selected {
  padding: 0px 5px;
  color: white !important;
  background-color: rgba(252, 155, 131, 0.95);
}

.tutorreg-Test-selected {
  padding: 0px 5px;
  color: white !important;
  background-color: rgba(247, 175, 103, 0.94);
}

.tutorreg-English-selected {
  padding: 0px 5px;
  color: white !important;
  background-color: #b5d693;
}



.tutorreg-subject-ul {
  padding: 0;
}

.tutorreg-subject-li {
  /*text-align: center;*/
  padding: 2px 6px 2px 6px;
  margin: 3px 0;
  border-radius: 7px;
  list-style-type: none;
  cursor: pointer;
}

.btn:focus {
  outline: none;
}

.navbar {
  border: none;
}

.breadcrumb {
  background-color: white;
}


.subtitle1 {
  font-size: 2.4rem;
}

.tutor-header-title {
  margin: 40px 0 150px 40px;
  color: #666666;
}

.education-bg {

}

.education-bg-detail-content > hr {
  margin: 0;
}

.add-new-item-box {
  color: #b8b8b8;
  font-size: large;
  border: 2px #5b9aef solid;
  padding: 30px;
  text-align: center;
}

.option-btn-area {
  height: 50%;
}

.education-bg-time {
  float: right;
  font-size: 1.2rem;
  color: #747474;
}

.education-bg-title {
  font-size: 1.6rem;
}

.education-bg-detail-p {
  margin-right: 40px;
  margin-bottom: 2px;
}

.education-bg-detail-content {
  word-break: break-all;
  margin-top: 6px;
  color: #747474;
}

.pic-upload {
  text-align: center;
}

.pic-up-btn {
  margin-top: 20px;
  background-color: rgba(91, 154, 239, 0.80);
  color: white;
  border-radius: 10px;
}

.education-background-subbtn {
  margin-top: -13px;
}

.education-background-addbtn {
  margin-top: -4px;
  height: 25px;
}

.degree-btn {
  font-size: 13px;
  white-space: normal;
  background-color: #c3c3c3;
  padding: 3px 13px 3px 13px;
  border-radius: 13px;
  color: white;
}

.degree-btn-selected {
  background-color: #5b9aef;
  opacity: 0.7;
  color: white;
}



.tutorreg-arrow-right {
  background-image: url("../images/arrow-buttom.png");
  background-size: cover;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 35px;
  height: 30px;
}

.subject-teach .col-md-5 .col-sm-4, .subject-teach .col-md-5 .col-sm-3, .subject-teach .col-md-5 .col-sm-8 {
  padding: 2px;
}

.base-select {
  width: 100%;
  border: 2px solid #459df2;

  border-radius: 10px;
}

.base-btn {
  word-break: break-all;
  opacity: 0.85;
  width: 100%;
  color: white;
  border-radius: 10px;
}

.test-btn {
  background-color: rgba(247, 175, 103, 0.94);
}

.admission-btn {
  background-color: rgba(252, 155, 131, 0.95);
}

.english-btn {
  background-color: #b5d693;
}

.select-area {
  /*margin-top: 5px;*/
}

.selected-area {
  /*margin-top: 20px;*/
  /*padding: 5px 5px;*/
  border-radius: 10px;
  /*min-height: 80px;*/
  border: solid 2px #459df2;
}

.one-selected .col-xs-4, .one-selected .col-xs-8 {
  padding: 0;
}

.one-selected {
  margin-top: 5px;
}

.selected-course-title {
  padding-top: 2px;
  padding-left: 5px;
  font-size: 1.6rem;
}

.ms-parent {
  position: static;
}


.tutorreg-selfintro-area {
  border-color: #5b9aef;
  border-radius: 0;
  width: 100%;
}

.self-intro > textarea {
  border: solid 2px #5b9aef;
  resize: none;
}


.teach-approach-area {
  margin-bottom: 20px;
}

.teach-approach-btn {
  margin-right: 5px;
  white-space: normal;
  background-color: #c3c3c3;
  padding: 5px 15px 5px 15px;
  margin-top: 5px;
  border-radius: 10px;
  color: white;
  font-size: 13px;
}

.teach-approach-btn-selected {
  background-color: #5b9aef;
  opacity: 0.7;
  color: white;
}

.teach-approach-custom {
  margin-top: 20px;
}

.teach-approach-custom-btn {
  color: #5b9aef;
  border: solid 2px #5b9aef;
  border-radius: 10px;
}


.teach-exp-detail-p {
  margin-bottom: 0;
}

.teach-exp-detail-title {
  margin-left: 2%;
  font-size: 2.5rem;
}

.teach-exp-detail-time {
  margin-left: 50%;
}

.teach-exp-detail-content {
  margin-top: -15px;
  margin-left: 2%;
  font-size: 1.8rem;
  margin-right: 134px;
}

.teach-exp-divider {
  vertical-align: middle;
  margin: 0;
  height: 5px;
  border-bottom: solid 2px rgba(118, 118, 118, 0.63);
  border-top: none;
}

.teach-exp-btn-area {
  padding: 0 10px;
  float: right;
}

.teach-exp-subbtn {
  margin-top: -13px;
}

.teach-exp-addbtn {
  margin-top: -13px;
}


.charge-area {
  margin-top: 30px;
  color: #5b9aef;
  margin-bottom: 30px;
}

.charge-area > h1 {
  font-size: 3rem;
}

.charge-area > p {
  font-size: 1.6rem;
}

.charge-area > .btn {
  color: white;
  width: 12%;
  padding: 5px 0;
  margin-right: 10px;
}

.chargebtn {
  font-size: 1.8rem;
  background-color: #c7c7c7;
  opacity: 0.9;
  border-radius: 5px;
}

.chargebtn-selected {
  background-color: #5b9aef;
  opacity: 0.7;
}


.tutorreg-btn-sub {
  font-size: 1.7rem;
  background-color: #5b9aef;
  opacity: 0.8;
  color: white;
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 8px 0;
  width: 150px;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 100px auto;
  }
}

.education-bg-modal-oneline {
  margin-bottom: 8px;
  padding-left: 15px;
}

.education-bg-modal-oneline .btn {
  padding: 2px 10px;
}


@media (max-width: 768px) {
  .teach-approach-btn {
    padding: 3px 10px 3px 10px;
  }
}

@media(min-width: 991px) {
  .tutorreg-arrow-right {
    background-image: url("../images/arrow-right.png");
    background-size: cover;
    margin-top: 130px;
    width: 21px;
    height: 25px;
    opacity: 0.8;
  }
}


@media (min-width: 767px) {
  .admission-btn {
    padding-left: 0;
    padding-right: 0;
  }

  .selected-area {
    /*margin-top: 107px;*/
    padding: 5px 5px;
    border-radius: 10px;
    /*min-height: 80px;*/
    border: solid 2px #5b9aef;
  }

  .tutorreg-subject-md-5 {
    width: 47%;
  }

  .tutorreg-subject-md-1 {
    width: 3%;
    padding: 0;
  }

  .tutorreg-subject-md-6 {
    width: 50%;
  }

  .education-bg-time {
    float: right;
    font-size: 1.6rem;
    margin-top: 12px;
  }

  .education-bg-title {
    font-size: 2.3rem;
  }
}
