.breadcrumb {
  background-color: white;
}

.teacherprofile-header {
  min-height: 300px;

  background: url(/images/index_carousel_2.jpg) no-repeat 0 0;
  background-size: cover;
}

@media (max-width: 768px) {
  .teacherprofile-header {
    min-height: 270px;
  }
}


.teacherprofile-article {
  background-color: rgba(223, 223, 223, 0.63);
}

.teacher-profile-tutor-pic {
  margin-top:10px;
  background-position: 50% 50%;
  border: 5px solid rgba(255,255,255,0.7);

  background-size: 120px;
  width: 120px;
  height: 120px;
}

.teach-approach {
  margin-right: 10px;
  margin-bottom: 7px;
  background-color: #c3c3c3;
  border-radius: 13px;
  color: white;
}

.tutor-name-mobile {
  text-align: center;
  font-size: 4rem;
}

.evaluate-area {
  margin-top: 5px;
  text-align: center;
}

.tutor-function-area {
  z-index:100;
  position: absolute;
  top: 22px;
  right: 32px;
  /*margin-top: 10px;*/
  /*margin-bottom: 10px;*/
  text-align: right;
}

.tutor-function-area button {

}
.book-tutor {
  padding-left: 10px;
  min-width: 80px;
  border-radius: 10px;
}

.teacherprofile-btn {
  width: 90px;
}

.teacher-major-detail {
  width: 75%;
  float: right;
}

.teacher-major-btn-detail {
  width: 80%;
  float: right;
}

.teacher-major-content {
  font-size: 1.6rem;
}

/*教学风格、经历、介绍*/
.teacher-teach-style {
  margin-top: 20px;
  margin-bottom: 20px;
}

.teacher-style-detail {
  font-size: 2rem;

}

.teacherprofile-intro {
  margin-top: 20px;
  margin-bottom: 30px;
}

.teacher-profile-teaching-exp-box {
  padding-top: 20px;
  padding-left: 0;
  padding-right: 0;
}

.teacher-experience-content {
  margin-top: 5px;
  font-size: 2rem;
}

.teacherprofile-detail-content {
  font-size: 1.6rem;
  margin-top: 20px;
  margin-bottom: 0px;
}

.unslider-nav ol li {
  width: 16px;
  height: 16px;
}

.evaluate-slider-section {
  background-color: #ededed;
  min-height: 80px;
}

.teacher-profile-slide-container {
  margin: 20px 5px 10px 5px;
}

.teacherprofile-detail-title {
  font-size: 1.8rem;
  text-align: center;
}

.teacher-profile-major-btn {
  color: white;
  padding: 0;
  min-width: 100px;
  min-height: 30px;
  border-radius: 10px;
}

.teacher-profile-subject-btn {
  background-color: rgba(237, 237, 237, 0);
  font-size: 1.2rem;
  padding: 3px 5px;
  margin: 3px 5px 1px 0px;
  cursor: default;
  border : 1px solid #ccc;
}

.pink-color-border {
  border: solid 2px #ffa183;
  border-radius: 5px;
}

.orange-color-border {
  border: solid 2px #f9b462;
  border-radius: 5px;
}

.green-color-border {
  border: solid 2px #bfdb9f;
  border-radius: 5px;
}

/*学生评价*/
.evaluate-good-pic {
  width: 30px;
}

.evaluate-bad-pic {
  margin-left: 40px;
  width: 30px;
}

.evaluate-good-num {
  padding-top: 20px;
}

.evaluate-bad-num {

}

.tutor-profile {
  margin-top: 20px;
  font-size: 1.5rem;
}

.education-detail {
  color: #666666;
  font-size: 1.5rem;
}

.evaluate {
  background: url("../images/teacherprofile-bg.png") no-repeat;
  background-size: cover;
  min-height: 200px;
}

.evaluate-detail {
  margin-top: 40px;
}

.evaluate-detail-content {
  margin-top: 10px;
  font-size: 2rem;
}

.evaluate-more {
  font-size: 1.8rem;
  margin-top: 160px;
}

.evaluate-more > a {
  text-decoration: none;
}

/*我的资源分享*/
.resource-share {
  padding-top: 40px;
  padding-bottom: 40px;
}

.resource-detail {
  padding: 0px 40px;
  margin-top: 20px;
  font-size: 1.8rem;
}

.share-resource {
  border: solid rgba(102, 102, 102, 0.63) 5px;
  padding: 10px;
}

.share-resource > p {
  margin-top: 15px;
}

.share-resource > h1 {
  color: rgba(102, 102, 102, 0.64);
}

.resource-detail-time {
  color: rgba(102, 102, 102, 0.63);
}

/*站内信*/
.site-message {
  margin-bottom: 20px;
}

.site-message-bg {
  min-height: 100px;
  border-radius: 25px;
  opacity: 0.9;
}

.site-message-p {
  margin-top: 20px;
  text-align: center;
  font-size: 1.8rem;
  color: white;
}

.site-message-p > span {
  font-size: 2.5rem;
}

.site-message-bg > div > .col-sm-1 {
  padding-left: 0;
}

.site-message-input {
  margin: 20px 20px;
}

.site-message-input > textarea {
  resize: none;
}

.site-message-btn {
  margin-bottom: 10px;
  background-color: #666666;
  color: white;
  font-size: 1.8rem;
}

.site-message-bg > .row > .col-sm-3{
  padding: 0;
}

.show-site-message-btn {
  position: fixed;
  text-align: center;
  right: 13px;
  bottom: 18px;
  font-size: 1.8rem;
  padding: 15px 0 0 0;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: rgba(91, 154, 239, 0.76);
  border: 2px solid white;
}

.teacher-profile-evaluate-pic {
  width: 80px;
  margin-bottom: 20px;
}

.evaluate-slider-section {
  font-size: 1.6rem;
}

.teacher-profile-evaluate-content {
  padding: 0 20px;
  margin-bottom: 30px;
}

.unslider-nav ol {
  padding-bottom: 10px;
}

.tutor-intro {
  font-size: 1.6rem;
}

/* 小屏幕（平板，大于等于 768px） */
@media (min-width: 768px) {

  .site-message-p {
    text-align: right;
  }

  .teacher-profile-teaching-exp-box {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 30px;
  }

  .teacher-profile-major-btn {
    min-height: 28px;
  }

  .teacher-profile-tutor-pic {
    margin-top:20px;
    background-size: 140px;
    width: 140px;
    height: 140px;
  }

  .tutor-profile .row .col-sm-3, .tutor-profile .row .col-sm-9 {
    padding: 0;
  }

  .teacher-profile-subtitle1 {
    font-size: 1.4rem;
  }

  .teacher-profile-subtitle1-en {

    font-size: 1.6rem;
  }

  .tutor-img {
    margin-top: 40px;
    max-width: 400px;
  }

  .site-message-btn {
    margin-top: 40px;
  }

  .custom-col-1 {
    float: left;
    width: 23%;
  }

  .teacher-profile-education-bg-detail-title {
    text-align: left;
    font-size: 1rem;
    margin-bottom: 0;
  }

  .teacher-profile-education-bg-detail-content {
    margin-top: 0;
    text-align: left;
    font-size: 1.2rem;
  }

  .teacher-profile-location-title {
    font-size: 1.6rem;
  }

  .teacher-profile-teaching-style-title {
    margin-bottom: 0;
    font-size: 1.6rem;
  }

}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 992px) {

  .teacher-profile-education-bg-detail-title {
    font-size: 1.8rem;
  }

  .teacher-profile-education-bg-detail-content {
    font-size: 1.6rem;
  }

  .teacher-profile-subtitle1-en {
    /*margin-top: 10px;*/
    font-size: 1.8rem;
  }

  .teacher-profile-location-title {
    font-size: 1.8rem;
  }

  .teacher-profile-tutor-pic {
    margin-top:20px;
    background-size: 180px;
    width: 180px;
    height: 180px;
  }

  .tutor-profile {
    margin-top: 20px;
  }


}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 1200px) {

  .teacher-profile-education-bg-detail-title {
    font-size: 2.3rem;
  }

  .teacher-profile-education-bg-detail-content {
    font-size: 2rem;
  }

  .teacher-profile-subtitle1-en {
    font-size: 2.2rem;
  }

  .teacher-profile-location-title {
    font-size: 2rem;
  }
}
