.sitemessage-box {
  height: 600px;
  border: solid #e0e0e0 2px;
  margin-bottom: 60px;
}

.chat-list-area {
  float: left;
  width: 350px;
  height: 100%;
  font-size: 1.5rem;
}

.phone-chat-list-box {
  width: 200px;
  height: 590px;
  overflow-y: scroll;
  background-color: #f2f2f2;
}

.phone-chat-list-area {
  position: fixed;
}

.phone-chat-list-pic {
  width: 60px;
  height: 60px;
  padding: 10px;
}

.phone-chat-list-btn {
  margin-left: 9px;
  border-color: #ddd;
  padding: 9px 10px;
  background-color: #e0e0e0;
}

.phone-chat-list-btn .icon-bar {
  background-color: white;
}

.chat-box-header {
  padding: 5px;
  height: 40px;
  border-bottom: solid 2px #e0e0e0;
}

.chat-box-header .chat-list-btn {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.chat-box-header .icon-bar {
  background-color: white;
}

.chat-box-header-content {
  font-size: 2rem;
  margin-left: 60px;
}

.chat-box-article {
  overflow-y: scroll;
  height: 380px;
  border-bottom: solid 1px #dadada;
}

.chat-box-footer-textarea {
  border: none;
  width: 100%;
  height: 80px;
  resize: none;
}

.chat-list {
  width: 349px;
  height: 550px;
  overflow-y: scroll;
}

.chat-list-tutor-pic {
  float: left;
}

.chat-list-search-input-container {
  padding: 10px;
}

.chat-list-search-input {
  width: 100%;
}

.chat-box-footer-textarea :active {
  outline: none;
}

.site-message-book-tutor-btn {
  background-color: white;
  border: solid 1px #c7c7c7;
  margin-right: 10px;
  padding: 0 10px;
  color: #848484;
}

.site-message-send-btn {
  margin-right: 20px;
  padding: 0 10px;
  color: white;
}

.chat-list-detail-box:hover {
  background-color: #dcdddd;
}

.site-message-current-chat-tutor-pic {
  float: left;
}

.site-message-current-chat-student-pic {
  float: right;
}

.site-message-current-chat-tutor {
  margin-left: 65px;
}

.site-message-current-chat-tutor-msg {
  display: inline-block;
  margin-top: 10px;
  border-radius: 5px;
  max-width: 80%;
}

.site-message-current-chat-student-msg {
  float: right;
  margin-top: 5px;
  border-radius: 5px;
  max-width: 70%;
}

.site-message-current-chat-student {
  margin-right: 10px;
}

.site-message-current-chat-student-pic {
  float: right;
  margin-right: 10px;
  text-align: center;
  font-size: 1rem;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 60px;
  border: 2px solid white;
}

/* 小屏幕（平板，大于等于 768px） */
@media (min-width: 768px) {

  .site-message-current-chat-tutor-msg {
    margin-top: 20px;
    max-width: 70%;
  }

  .site-message-current-chat-student-msg {
    margin-top: 20px;
    max-width: 60%;
  }

  .phone-chat-list-pic {
    width: 80px;
    height: 80px;
    padding: 10px;
  }

  .site-message-current-chat-tutor {
    margin-left: 80px;
  }

  .site-message-current-chat-student-pic {
    font-size: 2rem;
    width: 64px;
    height: 64px;
  }

  .current-chat-area {
    margin-left: 350px;
  }

  .chat-box-header-content {
    margin-left: 20px;
  }

}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 992px) {
  .site-message-current-chat-tutor-msg {
    max-width: 70%;
  }

  .site-message-current-chat-student-msg {
    margin-top: 20px;
    max-width: 70%;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 1200px) {

}
