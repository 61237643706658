#footer {
    position: relative;
  }
  
  /* Footer START */
  /* This will be Number One in priority in CSS */
  /* If this wanna change, do here. */
  #footer {
    width: 100%;
    overflow: hidden;
    background-color: #333333;
  }
  #footer > div {
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
  
  }
  #footer > div > div > div{
    margin : 10px;
  }
  #footer > div > div > div > a {
    display : inline-block;
    width : 36px;
    height : 36px;
    margin : 5px 7px;
  }
  #footer > div > div > div > a > img {
    height : 100%;
  }
  
  #footer > div > div > h4 {
    font-size : 18px;
  }
  
  #footer > div > div > p {
    margin : 0px 0px 10px;
  }
  /* Footer END */
  
  
  
  .footer-part1 {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }
  
  .footer-text1 {
    color: #6c767f;
  }
  
  .footer-text1 > a, p, h1, h2, h3, h4, h5, h6, li {
    color: #6c767f;
  }
  
  .footer-text1 > a:hover {
    text-decoration: none;
    color: white;
  }