.bi-fafa{
    position: relative;
}
.bi-header{
    text-align: center;
    padding : 100px;
}
.bi-bg{
    position: absolute;
    width : 100%;
    height: 400px;
    top : 0;
    left : 0;
    z-index: -1;
    overflow: hidden;
    text-align: center;
}
.bi-bg > img{
    height : 500px;
}
.bi-header h1{
    font-size: 90px;
    color : white;
    font-weight: 700;
    box-shadow: 1px 2px 5px #ddd;
    background: rgba(0,0,0,.3);
}
.bi-header h3{
    color : white;
    box-shadow: 1px 2px 5px #ddd;
    background: rgba(0,0,0,.3);
}



.bi-ba-part{
    display: flex;
    padding : 70px;
}
.bi-ba-part .bi-ba-part-a{
    flex: 10;
}
.bi-ba-part .bi-ba-part-b{
    flex: 3;
}
.bi-ba-part-a > h3{
    font-weight: 800;
    font-size: 40px;
    color : black;
    padding : 5px;
    padding-left: 0;
}
.bi-ba-part-a > div{
    font-size: 22px;
    color : #888;
}
.bi-ba-part-a > div > span{
    color : #f9b462;
}
.bi-ba-part .bi-ba-part-a ul{
    margin: 0;
    text-align: center;
    font-weight: 700;
}
.bi-ba-part .bi-ba-part-a ul li{
}
.bi-ba-part .bi-ba-part-a ul li span{
    color : #f9b462;
}



.bi-ba-part-right{
    display: flex;
    padding : 70px;
}
.bi-ba-part-right .bi-ba-part-a{
    flex: 3;
}
.bi-ba-part-right .bi-ba-part-b{
    flex: 10;
}

.bi-ba-part-right .bi-ba-part-b > h3{
    font-weight: 800;
    font-size: 40px;
    color : black;
    padding : 5px;
    padding-left: 0;
}
.bi-ba-part-right .bi-ba-part-b > div{
    font-size: 22px;
    color : #888;
}
.bi-ba-part-right .bi-ba-part-b > div > span{
    color : #f9b462;
}


.bi-ba-part-c{
    padding : 70px;
    padding-top: 0;
    padding-bottom: 140px;
}
.bi-ba-part-c > h3{
    font-weight: 800;
    font-size: 40px;
    color : black;
    padding : 5px;
    padding-left: 0;
}
.bi-ba-part-c > div{
    font-size: 22px;
    color : #888;
}
.bi-ba-part-c > div > span{
    color : #f9b462;
}





.bi-ba-blog-part{
    padding : 15px;
}
.bi-ba-blog-part ul{
    padding : 0;
    display: flex;
}
.bi-ba-blog-part ul li{
    flex : 1;
}

.blog-span-header{
    display: block;
    font-weight: 500;
    font-size: 27px;
}
.b-color{
    color : black;
}
.gspot{
    color : #5b9aef;
    font-size: 25px !important;
}