.event_thumbnail_text{
    height : 120px !important;
}

.event11{
    position: relative !important;
    background-size: cover !important;
}

/* .videowrapper{
    margin : 0 !important;
    padding : 0 !important;
    display: inline-block !important;
    width : 100% !important;
    min-height: 200px !important;
    border : 1px solid white !important;
} */
.vwrapper{
    height: 200px;
}
.good-player{
}
.items-container{
    width : 100% !important;
    margin : 0 auto;
    margin-top: 30px;
}
.modal-content{
    z-index: 999;
}
@media screen and (max-width:767px){
    .items-container > div{
        margin-bottom: 30px;
    }
}