.userlogin-title {
  margin-top: 30px;
  padding: 10px 20px;
  text-align: center;
  font-size: 3rem;
  border-radius: 5px;
  border: solid 1px #c2c2c3;
}

.userlogin-panel {
  margin-top: 2px;
  background-color: rgba(239, 239, 239, 0.6);
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 5px;
  border: solid 1px #c2c2c3;
}

.userlogin-input-email {
  margin-top: 20px;
}

.userlogin-input-email input {
  background-color: rgba(239, 239, 239, 0);
}

.userlogin-input-psw {
  margin-top: 20px;
}

.userlogin-input-psw input {
  background-color: rgba(239, 239, 239, 0);
}

.userlogin-fblogin-btn {
  padding: 1px 20px;
  background-color: #466db1;
  color: white;
  min-width: 200px;
  min-height: 30px;
}

.userlogin-wechatlogin-btn {
  margin-top: 10px;
  padding: 1px 20px;
  background-color: #40bc38;
  color: white;
  width: 200px;
  min-height: 30px;
}

.userlogin-login-btn {
  min-width: 150px;
  color: white;
}
