.TdClassComponent{
}
.tdClass-TdClassComponent{
    padding-bottom: 10px;
}
.tdClass-TdClassComponent ul{
    margin : 0;
    padding : 0;
}
.tdClass-TdClassComponent ul li{
    padding : 7px;
    text-align: center;
}
.tdClass-TdClassComponent ul li:nth-child(3){
    padding : 0px;
}
.tdClass-TdClassComponent ul li:nth-child(4){
    padding : 0px;
}
.tdClass-TdClassComponent ul li > h3{
    font-weight: 700;
}
.tdClass-TdClassComponent ul li:last-child{
    font-style: italic;
}
.tdClass-so-TdClassComponent{
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    border-bottom: 1px solid #ddd;
}
.tdClass-so-TdClassComponent ul{
    margin : 0;
    padding : 0;
    display: flex;
    width: 100%;
}
.tdClass-so-TdClassComponent ul li{
    flex: 1;
    text-align: center;
    color : #333333;
}
.tdClass-so-TdClassComponent ul li:nth-child(1){
}
.tdClass-so-TdClassComponent ul li:nth-child(2){
}
.tdClass-so-TdClassComponent ul li:nth-child(3){
    flex : 0.8;
}
.tdClass-so-TdClassComponent ul li:nth-child(4){
    flex : 0.5;
}
.tdClass-so-TdClassComponent ul li:nth-child(5){
    flex : 0.8;
}

.tdClass-ext{
}
.tdClass-ext > button{
    background: none;
    border-radius: 3px;
    border : 1px solid #ddd;
    border-bottom: 2px solid rgb(196, 196, 196);
    width : 100%;
    height: 90px;
    font-size: 24px;
    font-weight: 700;
    color : #5b9aef;
    background-color: #f5f5f5;
}

.tdClass-sq-TdClassComponent{
}
.tdClass-sq-TdClassComponent > ul{
    padding : 0;
    margin : 0;
}
.tdClass-sq-TdClassComponent > ul > li{
    margin-top: 5px;
    border-bottom: 1px solid #c8dffd;
}

li.td-op-type-b-li{
    padding: 10px 0px;
    height: auto;
    display: flex;
    align-items: center;
}
li.td-op-type-b-li > div{
    flex: 1;
    display: flex;
    flex-direction: column;
}
li.td-op-type-b-li > div:nth-child(1){

}
li.td-op-type-b-li > div:nth-child(1) ul{
    padding : 0;
    height : 100%;
    display: flex;
    flex-direction: column;
    padding : 10px 0;
}
li.td-op-type-b-li > div:nth-child(1) ul li{
    flex: 15;
    text-align: center;
}
li.td-op-type-b-li > div:nth-child(1) ul li:nth-child(1){
    flex : 30;
}
li.td-op-type-b-li > div:nth-child(1) ul li img{
    width : 55px;
    height: 55px;
}

li.td-op-type-b-li > div:nth-child(1) ul li:nth-child(2){
    flex : 1;
}
li.td-op-type-b-li > div:nth-child(1) ul li button{
    border : 2px solid #5b9aef;
    color : #777;
    font-size: 17px;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;

}
li.td-op-type-b-li > div:nth-child(1) ul li button:hover{
    cursor: pointer;
}
li.td-op-type-b-li > div:nth-child(1) ul li button:active{
    background : #5b9aef;
    color : white;
}

li.td-op-type-b-li > div:nth-child(1) > span{
}

.tdclass-tool-tip-size{
}
.tdclass-tool-tip-size > span{
}
.tdclass-tool-tip-size > span > div{
    max-width: 400px;
    box-shadow: 0 1px 3px #ccc;
}
li.td-op-type-b-li > div:nth-child(2n){
}
li.td-op-type-b-li > div:nth-child(4){
    flex : 0.5;
}
li.td-op-type-b-li > div:nth-child(3){

}
li.td-op-type-b-li > div:nth-child(5){
    flex : 0.8;
}

li.td-op-type-b-li > div.td-optional-type-a{
    text-align: center;
}
div.td-optional-type-a > span{

}
div.td-optional-type-a > button{
    color : #777;
    border : 2px solid #5b9aef;
    border-radius: 10px;
    margin: 0 auto;
    font-size: 17px;
    padding: 5px 15px 5px 15px;
}
div.td-optional-type-a > button:hover{
    background: #5b9aef;
    color : white;
}
.td-compon-type-a{
    min-height: 64px;
}
.td-compon-type-a > button {
    border : 2px solid #5b9aef;
    border-radius: 10px;
    background: white;
    font-size: 17px;
    padding: 5px 15px 5px 15px;
    color: #777;
    text-align: center;
    margin: 0 auto;
    display: flex;
    margin-top: 5px;
}
.td-compon-type-a > button:hover{
    background: #5b9aef;
    color : white;
}

li.td-op-type-b-li > div > span{
    flex : 1;
    display: block;
    text-align: center;
    margin : 5px;
}
li.td-op-type-b-li > div > span.td-span-type-b{
    /* line-height: 35px; */
    letter-spacing: -0.3px;
}

li.td-op-type-b-li > div > span.td-span-type-a{
    margin : 0;
}
.td-span-type-a > button {
    border : 2px solid #5b9aef;
    border-radius: 10px !important;
}
li.td-op-type-b-li > div > span > button{
    border : 2px solid #5b9aef;
    border-radius: 10px;
    background: white;
    font-size: 17px;
    padding: 5px 15px 5px 15px;
    color: #777;
    text-align: center;
    margin: 0 auto;
    display: flex;
    margin-top: 5px;
}

li.td-op-type-b-li > div > div{
    /* background: #b5d693; */
    color : #6c767f;
    font-size: 15px;
    font-weight: 600;
    padding : 10px;
}
.td-com-text{
    letter-spacing: -.1px;
}
.td-com-text > span{
    margin : 2px;
}

.td-color-black{
    color : black;
}

li.td-op-type-b-li > div > div > span.td-component-block{
    display: block;
    text-align: center;
    letter-spacing: -.07px;
    margin: 0 auto;
}


li.td-op-type-b-li > div > p.temp-mine{
    padding : 7px;
    width : 70%;
    margin : 0 auto;
    margin-top: 40px;
    border-radius: 10px;
    border : 3px solid #5b9aef;
    font-size: 17px;
}

.td-op-type-a{
    overflow: hidden;
}
.td-op-type-a > span {
    margin : 0 5px;
}
.td-op-type-a > span > button{
    border-radius: 10px;
    padding : 3px 15px !important;
}
.td-op-type-a > span:nth-child(1) > button{
    border : 2px solid #b5d693;
}
.td-op-type-a > span:nth-child(2) > button{
    border : 2px solid #fc9b83;
}

.comm-blue-td{
    color : #5b9aef;
}

button{
    background: none;
    margin : 0 auto;
    border-radius: 5px;
    padding : 5px;
}

.tdClass_btn_additional{
    color : #5b9aef;
}
.tdashboard-explanation{
    width: 100%;
    text-align: center;
    margin-top: 5px;
}
.tdashboard-explanation span{
    margin-right: 5px;
}



@media screen and (max-width : 1226px) {


    .td-op-type-a{
        overflow: hidden;
    }
    .td-op-type-a > span {
        margin : 0;
    }
    .td-op-type-a > span > button{
    }
}