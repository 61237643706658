.td-blog-component{
    min-height: 50px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.td-blog-component button{
    background: none;
    border : 0;
    border-right: 15px solid #5b9aef;
    border-bottom: 15px solid #FC9B83;
    border-radius: 50%;
    display: inline-block;
    min-width: 150px;
    min-height : 150px;
    font-size: 20px;
    padding : 10px 20px;
    background: none;
    color : #5b9aef;
    letter-spacing: -1px;
    transition: all .25s;
    position: relative;
    z-index: 2;
    overflow: hidden;
}
.td-blog-component button > div{
    position: absolute;
    width : 3px;
    height : 200%;
    left : -5px;
    top : -50%;
    transition: all 0.19s;
    z-index: 1;
    background: #5b9aef;
}
.td-blog-component button:hover{
    background: #5b9aef;
    color : white;
    border-left: 15px solid #FC9B83;
    border-right: 0;
    box-shadow: 1px 2px 5px #5b9aef;
}
.td-blog-component button:hover > div{
    left : 150px;
    background: #ddd;
}



.stupid-computer{
    padding : 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
}
.stupid-computer > li{
    margin-top: 10px;
}
.stupid-computer > li.td-blog-list{
    width: 150px;
    height : 250px;
    margin : 10px;
    cursor: pointer;
    background: none;
    border : 0;
    border-right: 15px solid #5b9aef;
    border-bottom: 15px solid #FC9B83;
    border-radius: 50%;
    display: inline-block;
    padding : 10px 20px;
    background: none;
    color : #5b9aef;
    font-family: 'Stylish', sans-serif;
    text-align: center;
    line-height: 120px;
    transition: all .25s;
    position: relative;
    z-index: 2;
    overflow: hidden;
    box-shadow: 1px 1px 3px #ddd;
}
.stupid-computer > li.td-blog-list:nth-child(1){
    width : 230px;
    height : 230px;
    line-height: 200px;
}

.stupid-computer > li.td-blog-list:hover{
    background: #5b9aef;
    color : white;
    border-left: 15px solid #FC9B83;
    border-right: 0;
    box-shadow: 1px 2px 5px #5b9aef;
}


.stupid-computer > li.td-blog-list > span{
    font-size: 1.2rem !important;
    width : 100px;
    height : 20px;
}

.td-blog-component-third > button{
    background: none;
    width : 100%;
    height : 100px;
    font-size: 20px;
    background: #5b9aef;
    color : white;
    border-radius: 5px;
    border-top: 1px solid white;
    border-bottom: 2px solid #ddd;
    transition: all .2s;
}
.td-blog-component-third > button:hover{
    background: white;
    color : #5b9aef;
}


.td-blog-edit{
    min-width: 250px;
    min-height: 300px;
    border : 1px solid #ddd;
    padding : 3px;
    flex : 1;
    margin : 5px;
    cursor: pointer;
}
.td-blog-edit:hover{
    border : 1px solid #5b9aef;
    box-shadow: 0px 0px 10px #5b9aef;
}

.td-blog-inner-edit{
    display: inline-block;
    height : 100%;
    border : 1px solid #ddd;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    padding : 5px 15px;
}
.td-blog-inner-edit > button:hover {
    background: #5b9aef;
    color : white;
}

.td-blog-inner-edit span{
    display: block;
    flex : 1;
}
.td-blog-inner-edit span:nth-child(1){
    overflow: hidden;
    flex : 2.7;
}
.td-blog-inner-edit span:nth-child(1) > img{
    display: inline-block;
    width : 100%;
    height : 150px;
    transition: all ease .5s;
}
.td-blog-inner-edit span:nth-child(1) > img:hover{
    transform: scale(1.2);
}
.td-blog-inner-edit span:nth-child(2){
    text-align: right;
    flex : 0.5;
    font-size: 11px;
    font-weight: 300;
}

.td-blog-inner-edit span:nth-child(3){
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    text-indent: 10px;
    letter-spacing: -.08px;
}
.td-blog-inner-edit span:nth-child(4){
    text-align: right;
    flex : 0;
}

.td-blog-inner-edit span:nth-child(5){
    position: absolute;
    padding : 0 25px;
    top : 8px;
    right : -22px;
    text-align: center;
    transform: rotateZ(50deg);
    background: #FC9B83;
    color : white;
}



