/* createHtml */
.tuitions_confirm-html-wapper {
    width : 60%;
    min-height: 500px;
    margin : 0 auto;
    margin-bottom: 30px;
}



.tuition_confirm-part-single-wapper{
    position: relative;
}
.tuition_confirm-part-single-wapper > h4 {
    font-family: 'Spoqa Han Sans',sans-serif!important;
}
.tuition_confirm-part-single-wapper > h3 {
    font-family: 'Spoqa Han Sans',sans-serif!important;
    font-size: 24px;
}
.tuition_confirm-part-single-wapper > h2 {
    font-family: 'Spoqa Han Sans',sans-serif!important;
    font-size: 30px;
}
small{
    color : #6c767f;
    font-size: 18px !important;
}


/* teacher */
.tuition_confirm_th{
}
.tuition_confirm_th h4{
    display: inline-block;
    text-align: center;
}
.tuition_confirm_th h4 span{
    display: flex;
}

.tuition_confirm_th h4 span img{
    margin-right: 5px;
}

.tuition_confirm_th h4 span span{
    display: inline-block;
    height : 100%;
    line-height: 29px;
    font-size: 18px;
}

/* body A */

.tuition_confirm-part-body-A{
    padding : 0;
    list-style-type: decimal !important;
    display: inline-block;
}
.tuition_confirm-part-body-A li{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 3px;
    text-align: left;
    display: flex;
}

/* .tuition_confirm-part-body-A li span:nth-child(1){
    display: inline-block;
    width : 20px;
    height: 100%;
    line-height: 23px;
    border-radius: 50%;
    color : white;
    background: #66afe9;
    font-size: 13px;
    font-weight: 300;
    text-align: center;
} */
.tuition_confirm-part-body-A li span:nth-child(1){
   font-size: 18px;
}


.tuition_confirm-part-date{
    font-size: 18px;
    color : #6c767f;
}
.tuition_confirm-part-display > table{
    width: 100% !important;
}
.tuition-c-revese{
    color : #5b9aef !important;
    font-size: 24px;
    padding : 8px !important;
    border-top: 1px solid #fff !important;
}
.tuition-c-td{
}
.tuition-c-td td{
    font-size: 17px;
    color : #6c767f;
}



.tuition_confirm-payment-way{
}
.tuition_confirm-p-way{
    width : 100%;
    margin: 7px auto;
    padding : 7px 10px;
    border : 1px solid #ccc;
    border-radius: 5px;
    font-size: 15px;
    color : #6c767f;
}
.tuition_confirm-p-way input{
    margin-right: 7px;
}

.tuition_confirm-payment-way2{
    width : 100%;
    margin : 0 auto;
    margin : 10px auto;
}

.tuition_confirm-payment-way2 > div:nth-child(1){
    width : 100%;
    display: flex;
    margin : 0 auto;
}
.tuition_confirm-payment-way2 div:nth-child(1) > span{
    margin-right: 7px;
    text-align: center;
}

.tuition_confirm-payment-way2 div:nth-child(1) > span:nth-child(2){
    flex: 1;
    color: #6c767f;
}

.tuitions_confirm-memo{
    width : 100%;
    margin : 0 auto;
    color: #6c767f;
}


.tuition_confirm-close-anim{
}
.tuition_confirm-close-anim-start{
    height: auto;
    animation: tuition_confirm-close-anim .3s ease-in-out 1;
}
.tuition_confirm-close-anim-end{
    height: 0;
    overflow: hidden;
}
.tuition_confirm-show-anim-start{
    height: auto;
    animation: tuition_confirm-show-anim .3s ease-in-out 1;
    overflow: hidden;
}

.tuition_confirm-with-credit{
    padding : 5px;
}
.tuition_confirm-with-credit > div{
}
.tuition_pay_msg{
    width : 50%;
    color: #333;
    margin : 0 auto;
}
.creditBtn{
    padding: 5px 15px;
    border-radius: 3px;
    width : 51%;
    cursor: pointer;
    margin: 7px 0;
}

.credit-btn-agree{
    padding: 5px 15px 5px 15px !important;
    font-size: 17px !important;
    opacity: .8 !important;
    border-radius: 13px !important;
    margin-top: 20px !important;
    cursor: pointer;
}
.credit-btn-submit{
    font-size: 17px !important;
    background-color: #fff !important;
    border: 2px solid #b5d693 !important;
    opacity: .9 !important;
    padding: 5px 15px 5px 15px !important;
    border-radius: 13px !important;
    margin-top: 20px !important;
    margin-right: 5px;
}

/* Common Color Name */
.gurume-red-c{
    color : #fcbb7f;
}
@media screen and (max-width: 991px){
    .tuitions_confirm-html-wapper {
        width: 80%;
    }
}
@media screen and (max-width: 767px){
    .tuitions_confirm-html-wapper {
        width: 95%;
    }
}
@keyframes tuition_confirm-show-anim{
    0%{
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(1);
    }
}
@keyframes tuition_confirm-close-anim{
    0%{
        transform: scaleY(1);
    }
    100%{
        transform: scaleY(0);
    }
}