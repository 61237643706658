#multiple-overlay{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000;
    opacity: 0.4;
    z-index: 800;
    display: block;
}
.search-wrapper {
    width: 100%;
}
.search-banner {
    width: 100%;
    height: 376px;
    background-image: url("../../../images/header-bg/teacher-list-header-bg.jpg");
    background-position: 50% 50%;
    background-size: cover;
}
.search-banner-box {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-banner-main {
    z-index: 100;
    text-align: center;
}
.search-banner-main h1,
.search-banner-main button {
    color: #fff;
}
.search-banner-main button {
    font-size: 24px !important;
    font-weight: bold;
    border: 3px solid #fff !important;
    margin: 10px 0px;
}
.search-contents {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(223, 223, 223, 0.63);
    padding-bottom: 30px;
}
.search-contents-box {
    width: 80%;
    margin: 0 auto;
}
/* teacher search box */
.search-section {
    width: 100%;
    margin: 0 auto;
}
.teacher-search-card {
    width: 100%;
    margin: 30px 0px;
}
.teacher-search-card h4 {
    font-size: 18px;
}
.teacher-search-card-box {
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 15px 10px;
    display: flex;
}
.search-major {
    width: 15%;
    justify-content: center;
    align-items: center;
}
.search-major-box {
    width: 100%;
}
.search-major-btn {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.search-major-btn-wrap {
    padding: 2px;
    width: 100%;
}
.search-form {
    width: 20% !important;
}
.search-form-wrap {
    width: 100% !important;
    display: flex;
    justify-content: center;
    position: relative;
}
.search-form-wrap label {
    padding: 0px 2px !important;
    font-size: 14px !important;
}
.search-form-wrap input {
    margin-right: 4px !important;
    position: relative !important;
    margin-left: 0px !important;
}
.search-form-wrap:last-child {
    margin-top: 15px;
}
.search-rate {
    width: 30% !important;
}
#hourly-rate-slider {
    width: 100% !important;
}
.slider-tick-label {
    font-size: 14px !important;
}
.base-btn {
    word-break: break-all;
    opacity: 0.85;
    width: 100%;
    color: white;
    border-radius: 10px;
    font-size: 14px !important;
}
.test-btn {
    background-color: rgba(247, 175, 103, 0.94);
}
.admission-btn {
    background-color: rgba(252, 155, 131, 0.95);
}
.english-btn {
    background-color: #b5d693;
}

.curr-select-area {
    margin: 0px 10px !important;
    width: 25% !important;
}
.base-select {
    width: 100% !important;
    border: 2px solid #459df2 !important;
    border-radius: 10px !important;
    font-size: 14px !important;
}
.tutorreg-subject-ul {
    padding: 0 !important;
}
.tutorreg-subject-ul > li.active {
    background: #5b9aef;
    color: white;
}

.tutorreg-subject-li {
    padding: 2px 6px 2px 6px !important;
    margin: 3px 0 !important;
    border-radius: 7px !important;
    list-style-type: none !important;
    cursor: pointer !important;
}
.search-filter-btn {
    width: 10%;
    display: flex;
    justify-content: center;
}
.search-filter-btn button {
    color: #fff;
    background: #5b9aef;
    border: 2px solid #5b9aef;
    border-radius: 13px;
    margin-top: 10px;
    padding: 5px 20px;
    cursor: pointer;
    font-size: 17px;
}
/* search help card */
.search-help-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
    font-size: 14px !important;
}
.search-help-card-box {
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #5b9aef;
    border-radius: 4px;
    border: 1px solid #ddd;
}
.search-help-card-main {
    padding-top: 20px;
    width: 100%;
}
.search-help-caption {
    width: 100%;
    color: #6c767f;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-top: 60px;
}
.search-help-teacher-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.search-help-teacher-box {
    width: 60px;
    height: 60px;
    margin: 0px 10px;
}
.search-help-teacher {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
#search-manager-1 {
    width: 100%;
    background: url("../../../images/about-us/team/jessica_oh.jpeg");
    background-position: center;
    background-size: cover;
}
#search-manager-2 {
    width: 100%;
    background: url("../../../images/about-us/team/jimmy_choi.jpeg");
    background-position: center;
    background-size: 90%;
}
#search-manager-3 {
    width: 100%;
    background: url("../../../images/about-us/team/songyi_kang.jpeg");
    background-position: center;
    background-size: 90%;
}
.search-marquee {
    margin-left: 7px;
}
.search-marquee-img {
    width: 12%;
    padding: 2.5px 0px;
    margin-left: 3px;
    margin-right: -4px;
}
/* teacher profile box */
.profile-section {
    width: 100%;
    margin: 0 auto;
    display: flex;
}
.teacher-profile-card {
    width: 100%;
    margin-top: 33px;
    display: flex;
    flex-direction: column;
}
.teacher-profile-card-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-left: 40px;
}
.teacher-premium-card {
    width: 30%;
    display: flex;
    flex-direction: column;
}
.premium-title {
    text-align: center !important;
    font-size: 20px !important;
    color: #6c767f !important;
}
.teacher-premium-card-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.teacher-profile-card-main {
    width: 30%;
    background: #fff;
    margin: 0px 10px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
}
.teacher-premium-card-main {
    width: 95%;
    background: #fff;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    border: 4px double #5ecac6;
}
.teacher-profile-card-main:hover {
    border: #5b9aef;
    box-shadow: 0 1px 20px #5b9aef;
    cursor: pointer;
}
.teacher-profile-card-header {
    width: 100%;
    display: flex;
    justify-content: center;
}
.teacher-card-header-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.teacher-card-header-img {
    width: 35%;
    margin: 0 auto;
}
.teacher-card-header-img-box {
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50px;
    margin: 0 auto;
}
.teacher-card-header-img-box img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-position: center;
    object-fit: cover;
}
.teacher-card-header-info {
    padding: 0px 10px;
    width: 65%;
    margin: 0 auto;
}
.teacher-card-header-info-box {
    width: 100%;
    padding: 10px 0px;
}
.teacher-card-header-info-box .teacher-name {
    font-size: 16px !important;
    color: #000 !important;
    font-weight: bold !important;
}
.teacher-card-header-info-box .teacher-university {
    font-size: 14px !important;
    color: #825437 !important;
}
.teacher-card-header-info-box .teacher-major {
    font-size: 14px !important;
}
/* teacher card header ibdp sec */
.teacher-card-header-ibdp-1 {
    width: 70%;
}
.teacher-card-header-ibdp {
    width: 30%;
    height: 50%;
}
.teacher-card-header-ibdp-box {
    width: 100%;
    height: 100%;
}
.teacher-card-header-ibdp-img {
    width: 70px;
    position: absolute;
    top: 0;
    right: 0;
}
.teacher-card-header-ibdp-img > img {
    width: 100%;
    height: 100%;
}
.teacher-profile-card-subject {
    width: 100% !important;
    height: 30px !important;
    color: #fff !important;
    line-height: 2.3rem !important;
    font-size: 14px !important;
    display: flex !important;
}
.teacher-profile-card-content {
    padding: 10px 15px !important;
    font-size: 14px !important;
    margin-bottom: 20px;
}
.teacher-profile-card-time {
    width: 100%;
    overflow: hidden;
    padding-top: 7px;
}
.teacher-profile-card-time p {
    color: black !important;
}
.teacher-profile-card-footer {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
}
.teacher-profile-footer-bookmark {
    width: 15%;
    display: flex;
    padding-left: 10px;
}
.teacher-profile-footer-bookmark .bookmark-teacher {
    font-size: 20px;
    color: #b5d693;
    border: none;
}
.teacher-profile-footer-info {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5px;
    padding-bottom: 5px;
}
.teacher-profile-card-hidden {
    color: #6c767f;
    text-align: center;
    border: 1px solid red;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    bottom: 0;
}
.teacher-profile-card-footer span {
    padding-right: 5px !important;
    font-size: 14px !important;
}

/* responsive work */
@media (max-width: 1500px) {
    .search-contents-box {
        width: 90%;
    }
}
@media (max-width: 1300px) {
    .search-contents-box {
        width: 95%;
    }
    .teacher-profile-card-main {
        width: 30%;
    }
    .search-marquee-img {
        width: 14%;
    }
}
@media (max-width: 1100px) {
    .teacher-profile-card-main {
        width: 45%;
    }
}
@media (max-width: 991px) {
    .search-section {
        width: 100%;
    }
    .search-contents-box {
        width: 90%;
    }
    .search-marquee-img {
        width: 17%;
    }
}
@media (max-width: 767px) {
    .search-help-card {
        display: none;
    }
    .search-contents-box {
        width: 95%;
        flex-direction: column;
    }
    .search-section {
        width: 95%;
    }
    .teacher-search-card-box {
        flex-direction: column;
        padding: 20px 10px;
    }
    .search-major {
        width: 100%;
        display: flex;
    }
    .search-major-btn {
        flex-direction: row;
    }
    .curr-select-area {
        width: 100% !important;
        margin-top: 10px !important;
    }
    .search-form {
        width: 100% !important;
    }
    .search-form-wrap {
        justify-content: flex-start;
    }
    .search-form-wrap:last-child {
        margin-top: 0px;
    }
    .search-rate {
        width: 100% !important;
    }
    .search-filter-btn {
        width: 100% !important;
    }
    .search-marquee-img {
        width: 7%;
    }
    .profile-section {
        width: 100%;
        flex-direction: column;
    }
    .teacher-profile-card-main {
        width: 100%;
        margin: 7px 0px;
    }
    .teacher-search-card {
        margin-bottom: 15px;
    }
    .teacher-profile-card {
        width: 95%;
        margin: 0 auto;
    }
    .teacher-premium-card {
        display: none;
        width: 100%;
        margin-top: 20px;
    }
    .teacher-profile-card-box {
        margin-left: 0px;
    }
}
@media (max-width: 500px) {
    .search-marquee-img {
        width: 10%;
    }
}
