
/*!**/
 /** Util style ******/
 /**!*/

/*.max-width-10 {*/
  /*max-width: 10% !important;*/
/*}*/

/*.max-width-20 {*/
  /*max-width: 20% !important;*/
/*}*/

/*.max-width-30 {*/
  /*max-width: 30% !important;*/
/*}*/

/*.max-width-40 {*/
  /*max-width: 40% !important;*/
/*}*/

/*.max-width-45 {*/
  /*max-width: 45% !important;*/
/*}*/

/*.max-width-50 {*/
  /*max-width: 50% !important;*/
/*}*/

/*.max-width-60 {*/
  /*max-width: 60% !important;*/
/*}*/

/*.max-width-70 {*/
  /*max-width: 70% !important;*/
/*}*/

/*.max-width-80 {*/
  /*max-width: 80% !important;*/
/*}*/

/*.max-width-90 {*/
  /*max-width: 90% !important;*/
/*}*/

/*.max-width-100 {*/
  /*max-width: 100% !important;*/
/*}*/

/*.z-index-1 {*/
  /*z-index: 1 !important;*/
/*}*/

/*.z-index-2 {*/
  /*z-index: 2 !important;*/
/*}*/

/*.z-index-3 {*/
  /*z-index: 3 !important;*/
/*}*/

/*.z-index-4 {*/
  /*z-index: 4 !important;*/
/*}*/

/*.z-index-5 {*/
  /*z-index: 5 !important;*/
/*}*/

/*.z-index-6 {*/
  /*z-index: 6 !important;*/
/*}*/

/*.z-index-7 {*/
  /*z-index: 7 !important;*/
/*}*/

/*.z-index-8 {*/
  /*z-index: 8 !important;*/
/*}*/

/*.z-index-9 {*/
  /*z-index: 9 !important;*/
/*}*/

/*.z-index-10 {*/
  /*z-index: 10 !important;*/
/*}*/

/*.left-10p {*/
  /*left: 20%;*/
/*}*/

/*.left-20p {*/
  /*left: 20%;*/
/*}*/

/*.left-20p {*/
  /*left: 20%;*/
/*}*/

/*.left-20p {*/
  /*left: 20%;*/
/*}*/

/*.left-20p {*/
  /*left: 20%;*/
/*}*/

/*.left-20p {*/
  /*left: 20%;*/
/*}*/

/*.hidden-overflow {*/
  /*overflow: hidden;*/
/*}*/

/*.bottom-gradient {*/

  /*position: absolute;*/
  /*bottom: 0px;*/
  /*left: 0px;*/
  /*width: 100%;*/
  /*height: 50px;*/
  /*background: -moz-linear-gradient(top, rgba(137, 255, 241, 0) 0%, rgba(0, 0, 0, 1) 100%); !* FF3.6+ *!*/
  /*background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(137, 255, 241, 0)), color-stop(100%, rgba(0, 0, 0, 1))); !* Chrome,Safari4+ *!*/
  /*background: -webkit-linear-gradient(top, rgba(137, 255, 241, 0) 0%, rgba(0, 0, 0, 1) 100%); !* Chrome10+,Safari5.1+ *!*/
  /*background: -o-linear-gradient(top, rgba(137, 255, 241, 0) 0%, rgba(0, 0, 0, 1) 100%); !* Opera 11.10+ *!*/
  /*background: -ms-linear-gradient(top, rgba(137, 255, 241, 0) 0%, rgba(0, 0, 0, 1) 100%); !* IE10+ *!*/
  /*background: linear-gradient(to bottom, rgba(137, 255, 241, 0) 0%, rgba(0, 0, 0, 1) 100%); !* W3C *!*/
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0089fff1', endColorstr='#000000', GradientType=0); !* IE6-9 *!*/
/*}*/

/*.content-middle {*/
  /*vertical-align: middle;*/
  /*display: inline-block;*/
/*}*/

/*.border-radius-10 {*/
  /*-webkit-border-radius: 10px !important;*/
  /*-moz-border-radius: 10px !important;*/
  /*border-radius: 10px !important;*/
/*}*/

/*.height-auto {*/
  /*height: auto;*/
/*}*/

/*.min-height-10 {*/
  /*min-height: 10px !important;*/
/*}*/

/*.min-height-20 {*/
  /*min-height: 20px !important;*/
/*}*/

/*.min-height-30 {*/
  /*min-height: 30px !important;*/
/*}*/

/*.min-height-40 {*/
  /*min-height: 40px !important;*/
/*}*/

/*.min-height-50 {*/
  /*min-height: 50px !important;*/
/*}*/

/*.min-height-60 {*/
  /*min-height: 60px !important;*/
/*}*/

/*.min-height-70 {*/
  /*min-height: 70px !important;*/
/*}*/

/*.min-height-80 {*/
  /*min-height: 80px !important;*/
/*}*/

/*.min-height-90 {*/
  /*min-height: 90px !important;*/
/*}*/

/*.min-height-100 {*/
  /*min-height: 100px !important;*/
/*}*/

/*.min-height-120 {*/
  /*min-height: 120px !important;*/
/*}*/

/*.min-height-140 {*/
  /*min-height: 140px !important;*/
/*}*/

/*.min-height-160 {*/
  /*min-height: 160px !important;*/
/*}*/

/*.min-height-180 {*/
  /*min-height: 180px !important;*/
/*}*/

/*.min-height-200 {*/
  /*min-height: 200px !important;*/
/*}*/

/*.min-height-220 {*/
  /*min-height: 220px !important;*/
/*}*/

/*.min-height-240 {*/
  /*min-height: 240px !important;*/
/*}*/

/*.min-height-260 {*/
  /*min-height: 260px !important;*/
/*}*/

/*.min-height-280 {*/
  /*min-height: 280px !important;*/
/*}*/

/*.min-height-300 {*/
  /*min-height: 300px !important;*/
/*}*/

/*@media (max-width: 768px) {*/
  /*.height-auto-on-mobile {*/
    /*height: auto !important;*/
    /*min-height: auto !important;*/
    /*max-height: auto !important;*/
  /*}*/
/*}*/

/*.max-height-10 {*/
  /*max-height: 10px !important;*/
/*}*/

/*.max-height-20 {*/
  /*max-height: 20px !important;*/
/*}*/

/*.max-height-30 {*/
  /*max-height: 30px !important;*/
/*}*/

/*.max-height-40 {*/
  /*max-height: 40px !important;*/
/*}*/

/*.max-height-50 {*/
  /*max-height: 50px !important;*/
/*}*/

/*.max-height-60 {*/
  /*max-height: 60px !important;*/
  /*overflow-y: hidden;*/
/*}*/

/*.max-height-70 {*/
  /*max-height: 70px !important;*/
/*}*/

/*.max-height-80 {*/
  /*max-height: 80px !important;*/
  /*!*overflow-y: hidden;*!*/
/*}*/

/*.max-height-90 {*/
  /*max-height: 90px !important;*/
/*}*/

/*.max-height-100 {*/
  /*max-height: 100px !important;*/
  /*overflow-y: hidden;*/
/*}*/

/*.max-height-120 {*/
  /*max-height: 120px !important;*/
  /*!*overflow-y: ;*!*/
/*}*/

/*.max-height-140 {*/
  /*max-height: 140px !important;*/
/*}*/

/*.max-height-160 {*/
  /*max-height: 160px !important;*/
/*}*/

/*.max-height-180 {*/
  /*max-height: 180px !important;*/
/*}*/

/*.max-height-200 {*/
  /*max-height: 200px !important;*/
/*}*/

/*.max-height-220 {*/
  /*max-height: 220px !important;*/
/*}*/

/*.max-height-240 {*/
  /*max-height: 240px !important;*/
/*}*/

/*.max-height-260 {*/
  /*max-height: 260px !important;*/
/*}*/

/*.max-height-280 {*/
  /*max-height: 280px !important;*/
/*}*/

/*.max-height-300 {*/
  /*max-height: 300px !important;*/
/*}*/

/*.height-10 {*/
  /*height: 10px !important;*/
/*}*/

/*.height-20 {*/
  /*height: 20px !important;*/
/*}*/

/*.height-30 {*/
  /*height: 30px !important;*/
/*}*/

/*.height-40 {*/
  /*height: 40px !important;*/
/*}*/

/*.height-50 {*/
  /*height: 50px !important;*/
/*}*/

/*.height-60 {*/
  /*height: 60px !important;*/
/*}*/

/*.height-70 {*/
  /*height: 70px !important;*/
/*}*/

/*.height-80 {*/
  /*height: 80px !important;*/
/*}*/

/*.height-90 {*/
  /*height: 90px !important;*/
/*}*/

/*.height-100 {*/
  /*height: 100px !important;*/
/*}*/

/*.height-120 {*/
  /*height: 120px !important;*/
/*}*/

/*.height-140 {*/
  /*height: 140px !important;*/
/*}*/

/*.height-160 {*/
  /*height: 160px !important;*/
/*}*/

/*.no-list-style {*/
  /*list-style-type: none;*/
  /*-webkit-padding-start: 0px;*/
  /*-moz-padding-start: 0px;*/
/*}*/

/*.transform--45 {*/
  /*-webkit-transform: rotate(-45deg);*/
  /*-moz-transform: rotate(-45deg);*/
  /*-ms-transform: rotate(-45deg);*/
  /*-o-transform: rotate(-45deg);*/
  /*transform: rotate(-45deg);*/
/*}*/

/*.no-transform {*/
  /*-webkit-transform: rotate(0deg);*/
  /*-moz-transform: rotate(0deg);*/
  /*-ms-transform: rotate(0deg);*/
  /*-o-transform: rotate(0deg);*/
  /*transform: rotate(0deg);*/
/*}*/

/*.hover-bg-shadow:hover {*/
  /*-webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 3px;*/
  /*-moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 3px;*/
  /*box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 3px;*/
/*}*/

/*.hover-shadow:hover {*/
  /*text-shadow: rgba(0, 0, 0, .2) -1px -1px 0px;*/
/*}*/

/*.center {*/
  /*text-align: center !important;*/
/*}*/

/*.position-relative {*/
  /*position: relative;*/
/*}*/

/*.position-absolute {*/
  /*position: absolute;*/
/*}*/

/*.position-fixed {*/
  /*position: fixed;*/
/*}*/

/*.opacity-10 {*/
  /*opacity: 0.1;*/
/*}*/

/*.opacity-20 {*/
  /*opacity: 0.2;*/
/*}*/

/*.opacity-30 {*/
  /*opacity: 0.3;*/
/*}*/

/*.opacity-40 {*/
  /*opacity: 0.4;*/
/*}*/

/*.opacity-50 {*/
  /*opacity: 0.5;*/
/*}*/

/*.opacity-60 {*/
  /*opacity: 0.6;*/
/*}*/

/*.opacity-70 {*/
  /*opacity: 0.7;*/
/*}*/

/*.opacity-80 {*/
  /*opacity: 0.8;*/
/*}*/

/*.opacity-90 {*/
  /*opacity: 0.9;*/
/*}*/

/*.opacity-100 {*/
  /*opacity: 1;*/
/*}*/

/*.border-bottom-1-dotted {*/
  /*border-bottom: 1px dotted rgba(100, 100, 100, 0.5);*/
/*}*/

/*.border-top-1-dotted {*/
  /*border-top: 1px dotted rgba(100, 100, 100, 0.5);*/
/*}*/

/*.block {*/
  /*display: block;*/
/*}*/

/*.inline-block {*/
  /*display: inline-block;*/
/*}*/

/*.cropit-preview-image {*/
  /*border-radius: 50%;*/
  /*!*border: 2px solid #3f3f3f;*!*/
  /*!*border-style:inset;*!*/
/*}*/

/*.no-border-radius {*/
  /*-webkit-border-radius: 0px !important;*/
  /*-moz-border-radius: 0px !important;*/
  /*border-radius: 0px !important;*/
/*}*/

/*.border-radius-4 {*/
  /*-webkit-border-radius: 4px !important;*/
  /*-moz-border-radius: 4px !important;*/
  /*border-radius: 4px !important;*/
/*}*/

/*.border-blue-1 {*/
  /*border: 1px solid #459df2;*/
/*}*/

/*.border-blue-1:hover {*/
  /*border: 2px solid #459df2;*/
/*}*/

/*.border-blue-2 {*/
  /*border: 2px solid #459df2;*/
/*}*/

/*.border-red-1 {*/
  /*border: 1px solid #ff4e44;*/
/*}*/

/*.border-red-1:hover {*/
  /*border: 2px solid #ff4e44;*/
/*}*/

/*.border-red-2 {*/
  /*border: 2px solid #ff4e44;*/
/*}*/

/*.border-green-1 {*/
  /*border: 1px solid #b5d693;*/
/*}*/

/*.border-green-1:hover {*/
/*}*/

/*.border-green-2 {*/
  /*border: 2px solid #b5d693;*/
/*}*/

/*.border-brown-1 {*/
  /*border: 1px solid #f9b462;*/
/*}*/

/*.border-brown-2 {*/
  /*border: 2px solid #f9b462;*/
/*}*/

/*.font-size-8 {*/
  /*font-size: 8px !important;*/
/*}*/

/*.font-size-10 {*/
  /*font-size: 10px !important;*/
/*}*/

/*.font-size-12 {*/
  /*font-size: 12px !important;*/
/*}*/

/*.font-size-14 {*/
  /*font-size: 14px !important;*/
/*}*/

/*.font-size-16 {*/
  /*font-size: 16px !important;*/
/*}*/

/*.font-size-18 {*/
  /*font-size: 18px !important;*/
/*}*/

/*.font-size-20 {*/
  /*font-size: 20px !important;*/
/*}*/

/*.font-size-22 {*/
  /*font-size: 22px !important;*/
/*}*/

/*.font-size-24 {*/
  /*font-size: 24px !important;*/
/*}*/

/*.font-size-26 {*/
  /*font-size: 26px !important;*/
/*}*/

/*.font-size-28 {*/
  /*font-size: 28px !important;*/
/*}*/

/*.font-size-30 {*/
  /*font-size: 30px !important;*/
/*}*/

/*.font-size-32 {*/
  /*font-size: 32px !important;*/
/*}*/

/*.font-size-34 {*/
  /*font-size: 34px !important;*/
/*}*/

/*.font-size-36 {*/
  /*font-size: 36px !important;*/
/*}*/

/*.font-size-38 {*/
  /*font-size: 38px !important;*/
/*}*/

/*.font-size-40 {*/
  /*font-size: 40px !important;*/
/*}*/

/*.font-size-42 {*/
  /*font-size: 42px !important;*/
/*}*/

/*.font-size-44 {*/
  /*font-size: 44px !important;*/
/*}*/

/*.font-size-46 {*/
  /*font-size: 46px !important;*/
/*}*/

/*.font-size-48 {*/
  /*font-size: 48px !important;*/
/*}*/

/*.font-size-50 {*/
  /*font-size: 50px !important;*/
/*}*/

/*.font-size-52 {*/
  /*font-size: 52px !important;*/
/*}*/

/*.font-size-54 {*/
  /*font-size: 54px !important;*/
/*}*/

/*.font-size-56 {*/
  /*font-size: 56px !important;*/
/*}*/

/*.font-size-58 {*/
  /*font-size: 58px !important;*/
/*}*/

/*.font-size-60 {*/
  /*font-size: 60px !important;*/
/*}*/

/*.font-size-62 {*/
  /*font-size: 62px !important;*/
/*}*/

/*.font-size-64 {*/
  /*font-size: 64px !important;*/
/*}*/

/*.font-size-66 {*/
  /*font-size: 66px !important;*/
/*}*/

/*.font-size-68 {*/
  /*font-size: 68px !important;*/
/*}*/

/*.font-size-70 {*/
  /*font-size: 70px !important;*/
/*}*/

/*.font-size-72 {*/
  /*font-size: 72px !important;*/
/*}*/

/*.font-size-74 {*/
  /*font-size: 74px !important;*/
/*}*/

/*.font-size-76 {*/
  /*font-size: 76px !important;*/
/*}*/

/*.font-size-78 {*/
  /*font-size: 78px !important;*/
/*}*/

/*.font-size-80 {*/
  /*font-size: 80px !important;*/
/*}*/

/*.font-size-82 {*/
  /*font-size: 82px !important;*/
/*}*/

/*.font-size-84 {*/
  /*font-size: 84px !important;*/
/*}*/

/*.font-size-86 {*/
  /*font-size: 86px !important;*/
/*}*/

/*.font-size-88 {*/
  /*font-size: 88px !important;*/
/*}*/

/*.font-size-90 {*/
  /*font-size: 90px !important;*/
/*}*/

/*.font-size-92 {*/
  /*font-size: 92px !important;*/
/*}*/

/*.font-size-94 {*/
  /*font-size: 94px !important;*/
/*}*/

/*.font-size-96 {*/
  /*font-size: 96px !important;*/
/*}*/

/*.font-size-98 {*/
  /*font-size: 98px !important;*/
/*}*/

/*.font-size-100 {*/
  /*font-size: 100px !important;*/
/*}*/

/*!* Font weight : font-weight *!*/
/*.thinist-font {*/
  /*font-weight: 200;*/
/*}*/

/*.very-thin-font {*/
  /*font-weight: 300;*/
/*}*/

/*.thin-font {*/
  /*font-weight: 400;*/
/*}*/

/*.normal-font {*/
  /*font-weight: 500;*/
/*}*/

/*.bold-font {*/
  /*font-weight: 700;*/
/*}*/

/*.very-bold-font {*/
  /*font-weight: 800;*/
/*}*/

/*.boldest-font {*/
  /*font-weight: 900;*/
/*}*/


/*.width-10 {*/
  /*width: 10%;*/
/*}*/

/*.width-20 {*/
  /*width: 20%;*/
/*}*/

/*.width-30 {*/
  /*width: 30%;*/
/*}*/

/*.width-40 {*/
  /*width: 40%;*/
/*}*/

/*.width-50 {*/
  /*width: 50%;*/
/*}*/

/*.width-60 {*/
  /*width: 60%;*/
/*}*/

/*.width-70 {*/
  /*width: 70%;*/
/*}*/

/*.width-80 {*/
  /*width: 80%;*/
/*}*/

/*.width-90 {*/
  /*width: 90%;*/
/*}*/

/*.width-100 {*/
  /*width: 100%;*/
/*}*/


/*.margin-5 {*/
  /*margin: 5px !important;*/
/*}*/

/*.margin-10 {*/
  /*margin: 10px !important;*/
/*}*/

/*.margin-15 {*/
  /*margin: 15px !important;*/
/*}*/

/*.margin-20 {*/
  /*margin: 20px !important;*/
/*}*/

/*.margin-25 {*/
  /*margin: 25px !important;*/
/*}*/

/*.margin-30 {*/
  /*margin: 30px !important;*/
/*}*/

/*.margin-35 {*/
  /*margin: 35px !important;*/
/*}*/

/*.margin-40 {*/
  /*margin: 40px !important;*/
/*}*/

/*.margin-left-5p {*/
  /*margin-left: 5%;*/
/*}*/

/*.margin-left-10p {*/
  /*margin-left: 10%;*/
/*}*/

/*.margin-left-15p {*/
  /*margin-left: 15%;*/
/*}*/

/*.margin-left-20p {*/
  /*margin-left: 20%;*/
/*}*/

/*.margin-left-25p {*/
  /*margin-left: 25%;*/
/*}*/

/*.margin-left-30p {*/
  /*margin-left: 30%;*/
/*}*/

/*.margin-left-35p {*/
  /*margin-left: 35%;*/
/*}*/

/*.margin-left-40p {*/
  /*margin-left: 40%;*/
/*}*/

/*.margin-left-45p {*/
  /*margin-left: 45%;*/
/*}*/

/*.margin-left-50p {*/
  /*margin-left: 50%;*/
/*}*/

/*.margin-left-55p {*/
  /*margin-left: 55%;*/
/*}*/

/*.margin-left-60p {*/
  /*margin-left: 60%;*/
/*}*/

/*.margin-left-65p {*/
  /*margin-left: 65%;*/
/*}*/

/*.margin-left-70p {*/
  /*margin-left: 70%;*/
/*}*/

/*.margin-left-75p {*/
  /*margin-left: 75%;*/
/*}*/

/*.margin-left-80p {*/
  /*margin-left: 80%;*/
/*}*/

/*.margin-left-85p {*/
  /*margin-left: 85%;*/
/*}*/

/*.margin-left-90p {*/
  /*margin-left: 90%;*/
/*}*/

/*.margin-left-95p {*/
  /*margin-left: 95%;*/
/*}*/

/*.margin-left-100p {*/
  /*margin-left: 100%;*/
/*}*/

/*.margin-right-5 {*/
  /*margin-right: 5px !important;*/
/*}*/

/*.margin-right-10 {*/
  /*margin-right: 10px !important;*/
/*}*/

/*.margin-right-15 {*/
  /*margin-right: 15px !important;*/
/*}*/

/*.margin-right-20 {*/
  /*margin-right: 20px !important;*/
/*}*/

/*.margin-right-30 {*/
  /*margin-right: 30px !important;*/
/*}*/

/*.margin-right-40 {*/
  /*margin-right: 40px !important;*/
/*}*/

/*.margin-right-50 {*/
  /*margin-right: 50px !important;*/
/*}*/

/*.margin-right-60 {*/
  /*margin-right: 60px !important;*/
/*}*/

/*.margin-right-70 {*/
  /*margin-right: 70px !important;*/
/*}*/

/*.margin-right-80 {*/
  /*margin-right: 80px !important;*/
/*}*/

/*.margin-right-90 {*/
  /*margin-right: 90px !important;*/
/*}*/

/*.margin-left-5 {*/
  /*margin-left: 5px !important;*/
/*}*/

/*.margin-left-10 {*/
  /*margin-left: 10px !important;*/
/*}*/

/*.margin-left-20 {*/
  /*margin-left: 20px !important;*/
/*}*/

/*.margin-left-30 {*/
  /*margin-left: 30px !important;*/
/*}*/

/*.margin-left-40 {*/
  /*margin-left: 40px !important;*/
/*}*/

/*.margin-left-50 {*/
  /*margin-left: 50px !important;*/
/*}*/

/*.margin-left-60 {*/
  /*margin-left: 60px !important;*/
/*}*/

/*.margin-left-70 {*/
  /*margin-left: 70px !important;*/
/*}*/

/*.margin-left-80 {*/
  /*margin-left: 80px !important;*/
/*}*/

/*.margin-left-90 {*/
  /*margin-left: 90px !important;*/
/*}*/

/*.margin-bottom-5 {*/
  /*margin-bottom: 5px !important;*/
/*}*/

/*.margin-bottom-10 {*/
  /*margin-bottom: 10px !important;*/
/*}*/

/*.margin-bottom-20 {*/
  /*margin-bottom: 20px !important;*/
/*}*/

/*.margin-bottom-30 {*/
  /*margin-bottom: 30px !important;*/
/*}*/

/*.margin-bottom-40 {*/
  /*margin-bottom: 40px !important;*/
/*}*/

/*.margin-bottom-50 {*/
  /*margin-bottom: 50px !important;*/
/*}*/

/*.margin-bottom-60 {*/
  /*margin-bottom: 60px !important;*/
/*}*/

/*.margin-bottom-70 {*/
  /*margin-bottom: 70px !important;*/
/*}*/

/*.margin-bottom-80 {*/
  /*margin-bottom: 80px !important;*/
/*}*/

/*.margin-bottom-90 {*/
  /*margin-bottom: 90px !important;*/
/*}*/

/*.margin-bottom-100 {*/
  /*margin-bottom: 100px !important;*/
/*}*/

/*.margin-bottom-110 {*/
  /*margin-bottom: 110px !important;*/
/*}*/

/*.margin-top--5 {*/
  /*margin-top: -5px !important;*/
/*}*/

/*.margin-top--10 {*/
  /*margin-top: -10px !important;*/
/*}*/

/*.margin-top--20 {*/
  /*margin-top: -20px !important;*/
/*}*/

/*.margin-top--30 {*/
  /*margin-top: -30px !important;*/
/*}*/

/*.margin-top--35 {*/
  /*margin-top: -35px !important;*/
/*}*/

/*.margin-top--40 {*/
  /*margin-top: -40px !important;*/
/*}*/

/*.margin-top--45 {*/
  /*margin-top: -45px !important;*/
/*}*/

/*.margin-top--50 {*/
  /*margin-top: -50px !important;*/
/*}*/

/*.margin-top--60 {*/
  /*margin-top: -60px !important;*/
/*}*/

/*.margin-top--70 {*/
  /*margin-top: -70px !important;*/
/*}*/

/*.margin-top--80 {*/
  /*margin-top: -80px !important;*/
/*}*/

/*.margin-top--90 {*/
  /*margin-top: -90px !important;*/
/*}*/

/*.margin-top--100 {*/
  /*margin-top: -100px !important;*/
/*}*/

/*.margin-top--110 {*/
  /*margin-top: -110px !important;*/
/*}*/

/*.margin-top-5 {*/
  /*margin-top: 5px !important;*/
/*}*/

/*.margin-top-10 {*/
  /*margin-top: 10px !important;*/
/*}*/

/*.margin-top-15 {*/
  /*margin-top: 15px !important;*/
/*}*/

/*.margin-top-20 {*/
  /*margin-top: 20px !important;*/
/*}*/

/*.margin-top-25 {*/
  /*margin-top: 25px !important;*/
/*}*/

/*.margin-top-30 {*/
  /*margin-top: 30px !important;*/
/*}*/

/*.margin-top-35 {*/
  /*margin-top: 35px !important;*/
/*}*/

/*.margin-top-40 {*/
  /*margin-top: 40px !important;*/
/*}*/

/*.margin-top-45 {*/
  /*margin-top: 45px !important;*/
/*}*/

/*.margin-top-50 {*/
  /*margin-top: 50px !important;*/
/*}*/

/*.margin-top-55 {*/
  /*margin-top: 55px !important;*/
/*}*/

/*.margin-top-60 {*/
  /*margin-top: 60px !important;*/
/*}*/

/*.margin-top-65 {*/
  /*margin-top: 65px !important;*/
/*}*/

/*.margin-top-70 {*/
  /*margin-top: 70px !important;*/
/*}*/

/*.margin-top-75 {*/
  /*margin-top: 75px !important;*/
/*}*/

/*.margin-top-80 {*/
  /*margin-top: 80px !important;*/
/*}*/

/*.margin-top-85 {*/
  /*margin-top: 85px !important;*/
/*}*/

/*.margin-top-90 {*/
  /*margin-top: 90px !important;*/
/*}*/

/*.margin-top-95 {*/
  /*margin-top: 95px !important;*/
/*}*/

/*.margin-top-100 {*/
  /*margin-top: 100px !important;*/
/*}*/

/*.margin-top-105 {*/
  /*margin-top: 105px !important;*/
/*}*/

/*.margin-top-110 {*/
  /*margin-top: 110px !important;*/
/*}*/

/*@media (max-width: 768px) {*/
  /*.no-margin-top-xs {*/
    /*margin-top: 0px !important;*/
  /*}*/

  /*.margin-top-5-xs {*/
    /*margin-top: 5px !important;*/
  /*}*/

  /*.margin-top-10-xs {*/
    /*margin-top: 10px !important;*/
  /*}*/

  /*.margin-top-15-xs {*/
    /*margin-top: 15px !important;*/
  /*}*/

  /*.margin-top-20-xs {*/
    /*margin-top: 20px !important;*/
  /*}*/

  /*.margin-top-25-xs {*/
    /*margin-top: 25px !important;*/
  /*}*/

  /*.margin-top-30-xs {*/
    /*margin-top: 30px !important;*/
  /*}*/

  /*.margin-top-35-xs {*/
    /*margin-top: 35px !important;*/
  /*}*/

  /*.margin-top-40-xs {*/
    /*margin-top: 40px !important;*/
  /*}*/

  /*.margin-top-45-xs {*/
    /*margin-top: 45px !important;*/
  /*}*/

  /*.margin-top-50-xs {*/
    /*margin-top: 50px !important;*/
  /*}*/

  /*.no-margin-left-xs {*/
    /*margin-left: 0px !important;*/
  /*}*/

  /*.margin-left-5-xs {*/
    /*margin-left: 5px !important;*/
  /*}*/

  /*.margin-left-10-xs {*/
    /*margin-left: 10px !important;*/
  /*}*/

  /*.margin-left-15-xs {*/
    /*margin-left: 15px !important;*/
  /*}*/

  /*.margin-left-20-xs {*/
    /*margin-left: 20px !important;*/
  /*}*/

  /*.margin-left-25-xs {*/
    /*margin-left: 25px !important;*/
  /*}*/

  /*.margin-left-30-xs {*/
    /*margin-left: 30px !important;*/
  /*}*/

  /*.margin-left-35-xs {*/
    /*margin-left: 35px !important;*/
  /*}*/

  /*.margin-left-40-xs {*/
    /*margin-left: 40px !important;*/
  /*}*/

  /*.margin-left-45-xs {*/
    /*margin-left: 45px !important;*/
  /*}*/

  /*.margin-left-50-xs {*/
    /*margin-left: 50px !important;*/
  /*}*/

  /*.no-margin-right-xs {*/
    /*margin-right: 0px !important;*/
  /*}*/

  /*.margin-right-5-xs {*/
    /*margin-right: 5px !important;*/
  /*}*/

  /*.margin-right-10-xs {*/
    /*margin-right: 10px !important;*/
  /*}*/

  /*.margin-right-15-xs {*/
    /*margin-right: 15px !important;*/
  /*}*/

  /*.margin-right-20-xs {*/
    /*margin-right: 20px !important;*/
  /*}*/

  /*.margin-right-25-xs {*/
    /*margin-right: 25px !important;*/
  /*}*/

  /*.margin-right-30-xs {*/
    /*margin-right: 30px !important;*/
  /*}*/

  /*.margin-right-35-xs {*/
    /*margin-right: 35px !important;*/
  /*}*/

  /*.margin-right-40-xs {*/
    /*margin-right: 40px !important;*/
  /*}*/

  /*.margin-right-45-xs {*/
    /*margin-right: 45px !important;*/
  /*}*/

  /*.margin-right-50-xs {*/
    /*margin-right: 50px !important;*/
  /*}*/

  /*.no-margin-bottom-xs {*/
    /*margin-bottom: 0px !important;*/
  /*}*/

  /*.margin-bottom-5-xs {*/
    /*margin-bottom: 5px !important;*/
  /*}*/

  /*.margin-bottom-10-xs {*/
    /*margin-bottom: 10px !important;*/
  /*}*/

  /*.margin-bottom-15-xs {*/
    /*margin-bottom: 15px !important;*/
  /*}*/

  /*.margin-bottom-20-xs {*/
    /*margin-bottom: 20px !important;*/
  /*}*/

  /*.margin-bottom-25-xs {*/
    /*margin-bottom: 25px !important;*/
  /*}*/

  /*.margin-bottom-30-xs {*/
    /*margin-bottom: 30px !important;*/
  /*}*/

  /*.margin-bottom-35-xs {*/
    /*margin-bottom: 35px !important;*/
  /*}*/

  /*.margin-bottom-40-xs {*/
    /*margin-bottom: 40px !important;*/
  /*}*/

  /*.margin-bottom-45-xs {*/
    /*margin-bottom: 45px !important;*/
  /*}*/

  /*.margin-bottom-50-xs {*/
    /*margin-bottom: 50px !important;*/
  /*}*/
/*}*/

/*.no-margin {*/
  /*margin: 0px 0px 0px 0px !important;*/
/*}*/

/*.no-margin-left {*/
  /*margin-left: 0px !important;*/
/*}*/

/*.no-margin-right {*/
  /*margin-right: 0px !important;*/
/*}*/

/*.no-margin-top {*/
  /*margin-top: 0px !important;*/
/*}*/

/*.no-margin-bottom {*/
  /*margin-bottom: 0px !important;*/
/*}*/

/*.padding-5 {*/
  /*padding: 5px !important;*/
/*}*/

/*.padding-10 {*/
  /*padding: 10px !important;*/
/*}*/

/*.padding-15 {*/
  /*padding: 15px !important;*/
/*}*/

/*.padding-20 {*/
  /*padding: 20px !important;*/
/*}*/

/*.padding-25 {*/
  /*padding: 25px !important;*/
/*}*/

/*.padding-30 {*/
  /*padding: 30px !important;*/
/*}*/

/*.padding-35 {*/
  /*padding: 35px !important;*/
/*}*/

/*.padding-40 {*/
  /*padding: 40px !important;*/
/*}*/

/*.padding-45 {*/
  /*padding: 45px !important;*/
/*}*/

/*.padding-50 {*/
  /*padding: 50px !important;*/
/*}*/

/*.padding-55 {*/
  /*padding: 55px !important;*/
/*}*/

/*.padding-60 {*/
  /*padding: 60px !important;*/
/*}*/

/*.padding-left-20p {*/
  /*padding-left: 20% !important;*/
/*}*/

/*.padding-right-20p {*/
  /*padding-right: 20% !important;*/
/*}*/

/*.padding-left-5 {*/
  /*padding-left: 5px !important;*/
/*}*/

/*.padding-left-10 {*/
  /*padding-left: 10px !important;*/
/*}*/

/*.padding-left-15 {*/
  /*padding-left: 15px !important;*/
/*}*/

/*.padding-left-20 {*/
  /*padding-left: 20px !important;*/
/*}*/

/*.padding-left-25 {*/
  /*padding-left: 25px !important;*/
/*}*/

/*.padding-left-30 {*/
  /*padding-left: 30px !important;*/
/*}*/

/*.padding-left-35 {*/
  /*padding-left: 35px !important;*/
/*}*/

/*.padding-left-40 {*/
  /*padding-left: 40px !important;*/
/*}*/

/*.padding-left-45 {*/
  /*padding-left: 45px !important;*/
/*}*/

/*.padding-left-50 {*/
  /*padding-left: 50px !important;*/
/*}*/

/*.padding-left-55 {*/
  /*padding-left: 55px !important;*/
/*}*/

/*.padding-left-60 {*/
  /*padding-left: 60px !important;*/
/*}*/

/*.padding-right-5 {*/
  /*padding-right: 5px !important;*/
/*}*/

/*.padding-right-10 {*/
  /*padding-right: 10px !important;*/
/*}*/

/*.padding-right-15 {*/
  /*padding-right: 15px !important;*/
/*}*/

/*.padding-right-20 {*/
  /*padding-right: 20px !important;*/
/*}*/

/*.padding-right-25 {*/
  /*padding-right: 25px !important;*/
/*}*/

/*.padding-right-30 {*/
  /*padding-right: 30px !important;*/
/*}*/

/*.padding-right-35 {*/
  /*padding-right: 35px !important;*/
/*}*/

/*.padding-right-40 {*/
  /*padding-right: 40px !important;*/
/*}*/

/*.padding-right-45 {*/
  /*padding-right: 45px !important;*/
/*}*/

/*.padding-right-50 {*/
  /*padding-right: 50px !important;*/
/*}*/

/*.padding-right-55 {*/
  /*padding-right: 55px !important;*/
/*}*/

/*.padding-right-60 {*/
  /*padding-right: 60px !important;*/
/*}*/

/*.padding-top-5 {*/
  /*padding-top: 5px !important;*/
/*}*/

/*.padding-top-10 {*/
  /*padding-top: 10px !important;*/
/*}*/

/*.padding-top-15 {*/
  /*padding-top: 15px !important;*/
/*}*/

/*.padding-top-20 {*/
  /*padding-top: 20px !important;*/
/*}*/

/*.padding-top-25 {*/
  /*padding-top: 25px !important;*/
/*}*/

/*.padding-top-30 {*/
  /*padding-top: 30px !important;*/
/*}*/

/*.padding-top-35 {*/
  /*padding-top: 35px !important;*/
/*}*/

/*.padding-top-40 {*/
  /*padding-top: 40px !important;*/
/*}*/

/*.padding-top-45 {*/
  /*padding-top: 45px !important;*/
/*}*/

/*.padding-top-50 {*/
  /*padding-top: 50px !important;*/
/*}*/

/*.padding-top-55 {*/
  /*padding-top: 55px !important;*/
/*}*/

/*.padding-top-60 {*/
  /*padding-top: 60px !important;*/
/*}*/

/*.padding-top-100 {*/
  /*padding-top: 100px !important;*/
/*}*/

/*.padding-bottom-90 {*/
  /*padding-bottom: 90px !important;*/
/*}*/

/*.padding-bottom-100 {*/
  /*padding-bottom: 100px !important;*/
/*}*/

/*.padding-bottom-5 {*/
  /*padding-bottom: 5px !important;*/
/*}*/

/*.padding-bottom-10 {*/
  /*padding-bottom: 10px !important;*/
/*}*/

/*.padding-bottom-15 {*/
  /*padding-bottom: 15px !important;*/
/*}*/

/*.padding-bottom-20 {*/
  /*padding-bottom: 20px !important;*/
/*}*/

/*.padding-bottom-25 {*/
  /*padding-bottom: 25px !important;*/
/*}*/

/*.padding-bottom-30 {*/
  /*padding-bottom: 30px !important;*/
/*}*/

/*.padding-bottom-35 {*/
  /*padding-bottom: 35px !important;*/
/*}*/

/*.padding-bottom-40 {*/
  /*padding-bottom: 40px !important;*/
/*}*/

/*.padding-bottom-45 {*/
  /*padding-bottom: 45px !important;*/
/*}*/

/*.padding-bottom-50 {*/
  /*padding-bottom: 50px !important;*/
/*}*/

/*.padding-bottom-55 {*/
  /*padding-bottom: 55px !important;*/
/*}*/

/*.padding-bottom-60 {*/
  /*padding-bottom: 60px !important;*/
/*}*/

/*.no-padding {*/
  /*padding: 0px 0px 0px 0px !important;*/
/*}*/

/*.no-padding-left {*/
  /*padding-left: 0px !important;*/
/*}*/

/*.no-padding-right {*/
  /*padding-right: 0px !important;*/
/*}*/

/*.no-padding-top {*/
  /*padding-top: 0px !important;*/
/*}*/

/*.no-padding-bottom {*/
  /*padding-bottom: 0px !important;*/
/*}*/


/*.margin-left-20p {*/
  /*margin-left: 20% !important;*/
/*}*/

/*.margin-right-20p {*/
  /*margin-right: 20% !important;*/
/*}*/

/*@media (max-width: 768px) {*/
  /*.margin-left-10p-on-mobile {*/
    /*margin-left: 10% !important;*/
  /*}*/

  /*.margin-right-10p-on-mobile {*/
    /*margin-right: 10% !important;*/
  /*}*/
/*}*/
