
/*** HELLENA ***/
.career{
    height : 589px;
}

.career-wrapper
{
    overflow: hidden;
}
/* career video sec */
.career-video{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.career-video-box{
    width: 100%;
    height: 100%;
}
.career-video-content{
    width: 100%;
    height: 100%;
}
.career-video-item{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* career ment sec */
.career-ment{
    width: 100%;
    display: flex;
    justify-content: center;
}
.career-ment-box{
    width: 60%;
    margin: 0 auto;
}
.career-ment-content{
    width: 100%;
}
.career-ment-content h1{
    font-size: 2.2rem;
}
.career-ment-content button{
    font-size: 1.8rem;
}

/* career intro sec */
.career-intro{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.career-intro h1{
    font-size: 2.2rem;
}
.career-intro-box{
    width: 87%;
}
.career-intro-content{
    display: flex;
    width: 100%;
    justify-content: center;
}
.career-intro-item{
    width: 29vw;
    margin: 0% 1.3%;
}
.career-intro-item .card{
    width: 100%;
}
.career-intro-item .card-img-top{
    margin: 0 auto;
    padding: 3% 0 3% 0;
    width: 30%;
}
.career-intro-item .bg-gurume-blue h4{
    font-size: 1.1rem;
    padding-top: 3%;
}
.career-intro-item .card-body{
    padding-bottom: 4%;
}
.career-intro-item .card-body p{
    font-size: 0.9rem;
}

/* career office sec */
.career-office{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.career-office h4{
    font-size: 1.2rem;
}
.career-office-box{
    width: 90%;
    margin: 0 auto;
}
.career-office-content{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.career-office-content-title h1{
    font-size: 2.2rem;
}
.career-office-content-item{
    width: 100%;
    display: flex;
    flex-wrap: wrap;

}
.career-office-item{
    width: 31.5%;
    margin: 0% 0.8%;
}

/* career waiting sec */
.career-waiting{
    width: 100%;
    display: flex;
    justify-content: center;
}
.career-waiting-box{
    width: 80%;
    margin: 0 auto;
    padding-bottom: 3%;
}
.career-waiting-title{
    width: 100%;
}
.career-waiting-title h1{
    font-size: 2.2rem;
}
.career-waiting-content{
    width: 100%;
}
.career-waiting-item{
    width: 100%;
}
.career-waiting-content span{
    color: #5b9aef;
    font-size: 2em;
}
.career-waiting-content p{
    font-size: 1.5rem;
    color: #2E2E2E;
}
.career-waiting-content .career-arrow-btn{
    border: none;
    font-size: 2rem;
    color: #ffffff;
    background: transparent;
}

/* slick arrow */
.slick-next{
    padding-right: 10% !important;
}
.slick-prev{
    padding-left: 10% !important;
}

@media screen and (max-width:1435px) {
    .slick-next{
        padding-right: 0% !important;
    }
    .slick-prev{
        padding-left: 0% !important;
    }
}
@media screen and (max-width:767px) {
    .career-ment-box{
        width: 95%;
    }
    .career-intro-box{
        width: 95%;
    }
    .career-intro-content{
        flex-direction: column;
    }
    .career-intro-item{
        width: 100%;
        margin: 1.3% 0%;
    }
    .career-intro-item .card-img-top{
        width: 60%;
    }
    .career-intro-item .bg-gurume-blue h4{
        padding-top: 1.5%;
    }
    .career-office-box{
        width: 95%;
    }
    .career-office-content{
        width: 100%;
    }
    .career-office-item-wrapper{
        width: 100% !important;
        flex-direction: column !important;
        margin: 0 auto !important;
    }
    .career-office-item{
        width: 100%;
        margin: 1% 0%;    
    }
    .career-waiting-box{
        width: 95%;
    }
    .career-waiting-content{
        width: 93%;
        margin: 0 auto;
    }
}