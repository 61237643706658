@charset "UTF-8";
/******************************************************
 * @version 1.0
 * @author Sachin Yadav (www.facebook.com/sachinyadav.designer)
 * @copyright	Copyright (C) December 2015 ShardaEnterprises .All rights reserved.
*******************************************************/
#messages-main .ms-user:after, #messages-main:after {
    clear: both
}

.actions {
    list-style: none;
    padding: 0;
    z-index: 2;
    margin: 0
}

.actions > li {
    display: inline-block;
    vertical-align: baseline
}

.actions > a, .actions > li > a {
    width: 30px;
    height: 30px;
    line-height: 35px;
    display: inline-block;
    text-align: center;
    position: relative
}

.actions > a > i, .actions > li > a > i {
    -webkit-transition: color;
    -o-transition: color;
    transition: color;
    -webkit-transition-duration: 1.3s;
    transition-duration: 1.3s;
    color: #adadad;
    font-size: 20px
}

.actions.open > a > i, .actions > a:hover > i, .actions > li.open > a > i, .actions > li > a:hover > i {
    color: #000
}

.actions > a:before, .actions > li > a:before {
    left: 0;
    top: 0;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: scale3d(0, 0, 0);
    -moz-transform: scale3d(0, 0, 0);
    -ms-transform: scale3d(0, 0, 0);
    -o-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, .1);
    z-index: 0;
    border-radius: 50%;
    opacity: 0;
    filter: alpha(opacity=0)
}

.card, .listview, .listview .lv-item, .lv-header-alt {
    position: relative
}

.actions > a:hover:before, .actions > li > a:hover:before {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
    filter: alpha(opacity=100)
}

.actions.open > a:before, .actions > li.open > a:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100)
}

.actions.actions-alt > li.open > a > i, .actions.actions-alt > li > a > i, .actions.actions-alt > li > a > i:hover {
    color: #fff
}

.actions.open {
    z-index: 3
}

.line-wrap {
    width: 18px;
    height: 12px;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    margin: 10px 20px
}

.line-wrap .line {
    width: 18px;
    height: 2px;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.line-wrap .line.center {
    margin: 3px 0
}

.open .line-wrap {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg)
}

.open .line-wrap .line.top {
    width: 12px;
    transform: translateX(8px) translateY(1px) rotate(45deg);
    -webkit-transform: translateX(8px) translateY(1px) rotate(45deg)
}

.open .line-wrap .line.bottom {
    width: 12px;
    transform: translateX(8px) translateY(-1px) rotate(-45deg);
    -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg)
}

.listview:not(.lv-lg):not(.lv-message) .lv-item {
    padding: 12px 20px
}

@media (min-width: 480px) {
    .listview.lv-lg .lv-item {
        padding: 17px 35px 17px 25px
    }
}

@media (max-width: 767px) {
    .listview.lv-lg .lv-item {
        padding: 17px 35px 17px 20px
    }
}

.listview.lv-lg .lv-item:hover {
    background-color: #FFFFDB
}

.listview .lv-item {
    display: block;
    -webkit-transition: background-color;
    -o-transition: background-color;
    transition: background-color;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.listview .lv-item .lv-small {
    font-size: 12px;
    color: #A9A9A9;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 100%
}

.listview .lv-item .checkbox, .listview .lv-item.media {
    margin: 0
}

.lvh-search-close:hover {
    background: #333
}

.lv-header-alt {
    background: #f8f8f8;
    padding: 15px
}

.lv-header-alt .lv-actions {
    z-index: 3;
    float: right;
    margin-top: 3px;
    position: relative
}

.lv-header-alt .lv-actions > li > a {
    margin: 0 3px
}

.lvh-label {
    color: #818181;
    display: inline-block;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    padding: 0 6px;
    line-height: 33px;
    vertical-align: middle
}

.lv-footer {
    display: block;
    text-align: center;
    padding: 7px 10px 8px;
    border-top: 1px solid #F0F0F0;
    line-height: 100%;
    font-size: 11px;
    margin-top: 20px;
    color: #828282
}

#messages-main .ms-user:after, #messages-main .ms-user:before, #messages-main:after, #messages-main:before {
    content: " ";
    display: table
}

/* .card {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .15);
    margin-bottom: 30px
} */

#messages-main .ms-block {
    padding: 23px 20px 0
}

#messages-main .ms-menu {
    position: absolute;
    left: 0;
    top: 0;
    background: #F8F8F8;
    border-right: 1px solid #EEE;
    padding-bottom: 50px;
    height: 100%;
    width: 240px;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms
}

#messages-main .ms-menu .lv-item.active {
    background: #fff
}

#messages-main .ms-menu .lv-item:not(.active):hover {
    background: #F2F2F2;
    cursor: pointer
}

@media (min-width: 768px) {
    #messages-main .ms-body {
        padding-left: 240px
    }

    #ms-menu-trigger {
        display: none
    }
}

@media (max-width: 767px) {
    #messages-main .ms-menu {
        height: calc(100% - 58px);
        -webkit-transform: translate3d(-240px, 58px, 0);
        transform: translate3d(-240px, 58px, 0);
        opacity: 0;
        filter: alpha(opacity=0);
        z-index: 1
    }

    #messages-main .ms-menu.toggled {
        -webkit-transform: translate3d(0, 58px, 0);
        transform: translate3d(0, 58px, 0);
        opacity: 1;
        filter: alpha(opacity=100)
    }

    #messages-main .ms-body {
        overflow: hidden
    }
}

#messages-main .ms-user > img {
    border-radius: 50%;
    width: 40px;
    float: left
}

.lv-avatar, .lv-avatar > img {
    width: 35px;
    height: 35px;
    border-radius: 50%
}

#messages-main .ms-user > div {
    overflow: hidden;
    padding: 7px 5px 7px 15px;
    font-size: 11px
}

#messages-main #ms-compose {
    position: fixed;
    bottom: 120px;
    z-index: 1;
    right: 30px;
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28)
}

#ms-menu-trigger {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: left;
    margin: 1px 0 0 -7px
}

#ms-menu-trigger .line-wrap .line {
    background-color: #717171
}

.lv-message .lv-item {
    padding: 20px
}

.lv-message .lv-item.right {
    text-align: right
}

.lv-message .lv-item.right .lv-avatar {
    margin-right: 0;
    margin-left: 15px
}

.lv-message .lv-item:not(.right) .ms-item {
    background-color: #f5f5f5;
    color: #7c7c7c;
    box-shadow: 0 1.5px .5px rgba(0, 0, 0, .13)
}

.lv-message .lv-item.right .ms-item {
    background: #ecf0f1;
    box-shadow: 0 1.5px .5px rgba(0, 0, 0, .13);
    color: #7c7c7c
}

.lv-avatar {
    color: #FFF;
    text-align: center;
    line-height: 34px;
    font-size: 15px;
    margin-right: 15px;
    padding: 0 !important;
    text-transform: uppercase
}

.lv-avatar > img {
    vertical-align: top
}

.ms-item {
    padding: 13px 19px 15px;
    border-radius: 2px;
    display: inline-block
}

@media (min-width: 768px) {
    .ms-item {
        max-width: 70%
    }
}

.ms-date {
    display: block;
    color: #B3B3B3;
    margin-top: 7px;
    margin-left: 3px
}

.ms-date > i {
    font-size: 14px;
    vertical-align: bottom;
    line-height: 100%
}

.ms-reply {
    background-color: #f5f5f5;
    position: relative;
    margin: 0 !important
}

.ms-reply textarea {
    width: 94%;
    resize: none;
    border-style: none;
    border-color: Transparent;
    overflow: auto;
    font-size: 15px;
    padding: 10px;
    font-style: normal;
}

.ms-reply button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    height: 100%;
    width: 60px;
    font-size: 25px;
    background: #F5F5F5;
    color: #999
}

.ms-reply button:hover {
    background: #f2f2f2;
    color: #7c7c7c
}

textarea:focus {
    outline: 0 !important;
    border-color: #719ECE
}

.widget .panel-body {
    padding: 0px;
}

.widget .list-group {
    margin-bottom: 0;
}

.widget .panel-title {
    display: inline
}

.widget .label-info {
    float: right;
}

.widget li.list-group-item {
    border-radius: 0;
    border: 0;
    border-top: 1px solid #ddd;
}

.widget li.list-group-item:hover {
    background-color: rgba(86, 61, 124, .1);
}

.widget .mic-info {
    color: #666666;
    font-size: 11px;
}

.widget .action {
    margin-top: 5px;
}

.widget .comment-text {
    font-size: 12px;
}

.widget .btn-block {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.ui-group-buttons .or {
    position: relative;
    float: left;
    width: .3em;
    height: 1.3em;
    z-index: 3;
    font-size: 12px
}

.ui-group-buttons .or:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    background-color: #5a5a5a;
    margin-top: -.1em;
    margin-left: -.9em;
    width: 1.8em;
    height: 1.8em;
    line-height: 1.55;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    border-radius: 500px;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.ui-group-buttons .or:after {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    width: .3em;
    height: 2.84em;
    background-color: rgba(0, 0, 0, 0);
    border-top: .6em solid #5a5a5a;
    border-bottom: .6em solid #5a5a5a
}

.ui-group-buttons .or.or-lg {
    height: 1.3em;
    font-size: 16px
}

.ui-group-buttons .or.or-lg:after {
    height: 2.85em
}

.ui-group-buttons .or.or-sm {
    height: 1em
}

.ui-group-buttons .or.or-sm:after {
    height: 2.5em
}

.ui-group-buttons .or.or-xs {
    height: .25em
}

.ui-group-buttons .or.or-xs:after {
    height: 1.84em;
    z-index: -1000
}

.ui-group-buttons {
    display: inline-block;
}

.ui-group-buttons:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden
}

.ui-group-buttons .btn {
    float: left;
    border-radius: 0
}

.ui-group-buttons .btn:first-child {
    margin-left: 0;
    border-top-left-radius: .25em;
    border-bottom-left-radius: .25em;
    padding-right: 15px
}

.ui-group-buttons .btn:last-child {
    border-top-right-radius: .25em;
    border-bottom-right-radius: .25em;
    padding-left: 15px
}

.conversation-wrap {
    box-shadow: -2px 0 3px #ddd;
    padding: 0;
    max-height: 400px;
    overflow: auto;
}

.conversation {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    margin: 0;

}

.message-wrap {
    box-shadow: 0 0 3px #ddd;
    padding: 0;

}

.msg {
    padding: 5px;
    /*border-bottom:1px solid #ddd;*/
    margin: 0;
}

.msg-wrap {
    padding: 10px;
    max-height: 400px;
    overflow: auto;
}

.time {
    color: #bfbfbf;
}

.send-wrap {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 10px;
    /*background: #f8f8f8;*/
}

.send-message {
    resize: none;
}

.highlight {
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
}

.send-message-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;

    border-bottom-right-radius: 0;
}

.btn-panel {
    background: #f7f7f9;
}

.btn-panel .btn {
    color: #b8b8b8;

    transition: 0.2s all ease-in-out;
}

.btn-panel .btn:hover {
    color: #666;
    background: #f8f8f8;
}

.btn-panel .btn:active {
    background: #f8f8f8;
    box-shadow: 0 0 1px #ddd;
}

.btn-panel-conversation .btn, .btn-panel-msg .btn {

    background: #f8f8f8;
}

.btn-panel-conversation .btn:first-child {
    border-right: 1px solid #ddd;
}

.msg-wrap .media-heading {
    color: #5b9aef;
    font-weight: 700;
}

.msg-date {
    background: none;
    text-align: center;
    color: #aaa;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
}
::-webkit-scrollbar{
    width: 12px;
}
body::-webkit-scrollbar {
    width: 12px;
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    /*        -webkit-border-radius: 10px;
            border-radius: 10px;*/
}

/* Handle */
::-webkit-scrollbar-thumb {
    /*        -webkit-border-radius: 10px;
            border-radius: 10px;*/
    background: #ddd;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #ddd;
}
