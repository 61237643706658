.credit-buy-wapper{
    padding : 10px;
    padding-bottom: 100px;
}
.credit-buy-wapper > h3{
    padding : 5px 0;
}

.credit-buy-input{
    width : 120px;
    border : 1px solid #6c767f;
    border-radius: 5px;
    padding : 5px 9px;
    font-size: 15px;
    text-align: right;
    transition: all ease .3s;
}

.credit-buy-content-wapper{
    padding : 10px 10px;
    margin-bottom: 10px;
    box-shadow: 1px 2px 3px #ccc;
}

.credit-buy-content-radio{
    border : 1px solid #ddd;
    border-radius: 5px;
    padding : 5px 0;
    padding-top: 10px;
}
.credit-buy-content-radio > label {
    padding-left: 20px;
    cursor: pointer;
}
.credit-buy-content-radio > label:hover{
    color : #6c767f;
}

.credit-buy-content-ul{
    border : 1px solid #ddd;
    border-radius: 5px;
    padding : 0;
    margin : 0;
}

.credit-buy-content-ul ul{
    padding : 0;
    margin : 0;
    display: flex;
    flex-direction: column;
}
.credit-buy-content-ul ul li{
    font-size: 14px;
    display: flex;
    border-bottom: 1px solid #ddd;
}


.credit-buy-content-ul ul li > span{
    flex : 1;
    padding : 10px;
}
.credit-buy-content-ul ul li > span:nth-child(1){
    max-width: 100px;
    font-weight: 700;
    text-indent: 15px;
}
.credit-buy-content-ul ul li > span:nth-child(2){
}


.credit-buy-divider{
    display: flex;
}
.credit-buy-divider > div{
    flex : 1;
    min-height: 100px;
}
.credit-buy-divider > div:nth-child(1){
    display: flex;
    flex-direction: column;
}
.credit-buy-divider > div:nth-child(1) > span{
    flex : 1;
    color : #6c767f;
    padding : 10px;
}

.credit-buy-divider > div:nth-child(2){
}

.credit-buy-divider > div:nth-child(2) > span{
    display: inline-block;
    width : 100%;
    font-size: 24px;
    font-weight: 500;
    padding : 10px;
    color : #6c767f;
    text-align: right;
}

.credit-buy-divider > div:nth-child(2) > button{
    float : right;
    padding : 5px 15px;
    border-radius: 5px;
}



.credit-buy-pannel{
    position: absolute;
    width : 100%;
    height: 100%;
    top : 0;
    left : 0;
    background : rgba(0,0,0,0.6);
    z-index: 99999999;
}

.credit-buy-pannel-card{
    width : 80%;
    max-width: 900px;
    background : white;
    margin : 0 auto;
    margin-top : 100px;
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    padding : 15px;
    border-radius: 6px;
    position: relative;
}
.credit-buy-pannel-card > h5{
    font-size: 18px;
    font-weight: 400;
    padding : 10px;
    padding-right: 0;
    border-bottom: 1px solid #ddd;
}

.credit-buy-pannel-content{
    min-height: 100px;
    padding : 10px;
    color : #6c767f;
    display: flex;
    flex-direction: column;
}
.credit-buy-pannel-content > div{
    flex : 1;
}
.credit-buy-pannel-content > div:nth-child(3) > button{
    float: right;
    margin-left: 10px;
}



.credit-buy-info{
    margin-bottom: 10px;
}
.credit-buy-info ul{
    padding : 0;
    margin : 0;
    padding : 10px 30px;
    border-bottom: 1px solid #ddd;
}

.credit-buy-info ul li{
    display: flex;
}
.credit-buy-info ul li > span{
    flex : 1;
    padding : 5px;
    font-size: 17px;
    text-align: center;
}
.credit-buy-info ul li > span:nth-child(1){
}

.credit-buy-info ul li:nth-child(4){
    margin-bottom: 20px;
}
.credit-buy-info ul li:nth-child(5){
    border-top: 1px solid #ddd;
}
.credit-buy-info ul li:nth-child(5) > span:nth-child(1){
    font-size: 32px;
}
.credit-buy-info ul li:nth-child(5) > span:nth-child(2){
    text-align: right;
    font-size: 32px;
    font-weight: 700;
}