/* sd mooc card item */
.expired-mooc-card h3, .expired-mooc-card p, .expired-mooc-card span{
    color: #A7A7A7 !important;
}
.expired-mooc-card .sd-mooc-card-top{
    background-color: #979696 !important;
    border: 4px solid #979696 !important;
}
.expired-mooc-card .sd-mooc-card-bottom{
    background-color: #B5B4B4 !important;
}
.expired-mooc-card .sd-mooc-tutor-pic-box, .expired-mooc-card .mooc-apply-btn{
    opacity: .3;
}
.sd-mooc-card-wrapper{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
}
.sd-mooc-card-item{
    width: 30%;
    margin: 15px 10px;
}
.sd-mooc-card{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}
.sd-mooc-card-top{
    width: 100%;
    padding: 20px 10px;
    background-color: #fff;
    border: 4px solid #5b9aef;
}
.sd-mooc-card h3{
    font-size: 20px;
    color: #5b9aef;
    text-align: center;
    font-weight: bold;
}
.sd-mooc-tutor{
    width: 95%;
    margin: 0 auto;
    display: flex;
}
.sd-mooc-tutor-name{
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: -30px;
}
.sd-mooc-tutor-name p{
    font-size: 16px;
    color: #5b9aef;
    font-weight: bold;
}
.sd-mooc-tutor-pic{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -50px;
    z-index: 1000;
}
.sd-mooc-tutor-pic-box{
    width: 70px;
    height: 70px;
}
.sd-mooc-tutor-pic-box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
.sd-mooc-card-bottom{
    width: 100%;
    padding: 20px 0px;
    background-color: #fff;
}
.sd-mooc-price-box{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 5px 0px;
}
.sd-mooc-original-price{
    color: #5b9aef;
    font-size: 14px;
}
.sd-mooc-now-price{
    color: #5b9aef;
    font-size: 16px;
}
.sd-mooc-time{
    text-align: center;
    color: #666;
    padding-top: 5px;
}


.fix-top-margin{
    margin-top: 17px;
}
.fix-s-model-body{
    margin-bottom: 10px;
}

.pink-color-border {
    border: solid 2px #ffa183;
    border-radius: 5px;
  }
  
  .orange-color-border {
    border: solid 2px #f9b462;
    border-radius: 5px;
  }
  
  .green-color-border {
    border: solid 2px #bfdb9f;
    border-radius: 5px;
  }
.pink-font-color{
    color: #ffa183 !important;
}
.brown-font-color{
    color: #f9b462 !important;
}
.green-font-color{
    color: #bfdb9f !important;
}

/* bookmark tutors sec */
.bookmark-tutors{
    width: 100%;
    display: flex;
}
.bookmark-tutors-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.bookmark-tutors-box{
    width: 95%;
    margin: 0 auto;
}
.bookmark-tutors-main{
    width: 100%;
    display: flex;
}
.bookmark-tutors-left{
    display: flex;
    width: 30%;
    flex-direction: column;
}
.bookmark-tutors-img{
    width: 90%;
}
.bookmark-tutors-img img{
    width: 100%;
    height: 100%;
}

.bookmark-tutors-right{
    display: flex;
    width: 70%;
    flex-direction: column;
    padding-left: 10px;
}

@media screen and (max-width:991px){
    .sd-mooc-card-item{
        width: 29%;
    }
    .sd-mooc-card-wrapper{
        margin-left: 0px;
    }
}
@media screen and (max-width:767px){
    .sd-mooc-card-item{
        width: 100%;
    }
}