.site-remote-wrapper{
    padding-bottom: 70px;
}
.sitieusage-wapper{
    box-sizing: border-box;
    width : 80%;
    margin: 0 auto;
    padding-top: 30px;
}

.site-remote-blue{
    color : #5b9aef;
}

.site-type-a{
    box-shadow: 0px -2px 2px #5b9aef;
    border : 1px solid #5b9aef;
}
.message-item .message-inner{
    border : 0 !important;
}
.message-item .message-head{
    border-bottom: 0.2px solid #5b9aef !important;
}
.message-item .message-inner:before {
    border : 0 !important;
    color: rgba(0,0,0,0);
    content: "";
    display: block;
    height: 0;
    position: absolute;
    left: 0px !important;
    top: 6px;
    width: 0;
}
.message-item .message-inner:after{
    border-left: 10px solid #fff !important;
    border-bottom: 10px solid #fff !important;
    border-style: solid;
    border-width: 10px;
    color: rgba(0,0,0,0);
    content: "";
    display: block;
    height: 0;
    position: absolute;
    left: 50% !important;
    top: 100% !important;
    width: 0;
    border : 1px solid #5b9aef;
}
.message-item:after {
    background: #fff;
    border: 0 !important;
    border-radius: 50%;
    box-shadow: 0 0 !important;
    content: "";
    height: 15px;
    left: -36px;
    position: absolute;
    top: 10px;
    width: 15px;
    display: none;
}
.message-item:before {
    background: #fff;
    border-radius: 2px;
    bottom: -30px;
    box-shadow: 0 0!important;
    content: "";
    height: 100%;
    left: -30px;
    position: absolute;
    width: 3px;
    display: none;
}

.site-type-b{
    padding : 10px;
}
.site-type-c{
    color : #ffa183;
    padding : 15px 5px;
}

.site-component-head{
    border-bottom: 0.5px solid white;
}

.site-normal-component{
    padding : 30px 10px;
    margin-bottom: 15px;
    text-align: center;
}
.site-normal-component-sec{
    padding : 10px 10px;
    margin-bottom: 15px;
    text-align: center;
}
.site-type-component-a{
    padding : 10px;
    margin-bottom: 20px;
}

.site-comp-info{
    font-size: 1.2rem;
    font-style: italic;
    color : #f66f6f;
    text-align: center;
    padding : 2px;
    position: relative;
    overflow: hidden;
    cursor: crosshair;
    margin-bottom: 10px;
}
.site-comp-info > span{
    position: absolute;
    display: block;
    overflow: hidden;
    top : 0;
    left : 50%;
    width : 0;
    height : 0;
    border : 2px solid #5b9aef;
    transition: all .13s ease-out;
}
.site-comp-info:hover * {
    border : 0;
    background: #f66f6f;
    width : 100%;
    height : 100%;
    left : 0;
    transition: all 0.5s;
}
.site-comp-info:hover{
    color : white;
}

.site-comp-memo {
    width: 200px;
    height: 55px;
    padding: 5px;
    border: 1px solid #ddd;
    background-size: cover;
      background-clip: content-box;
    background-color: #f66f6f;
    box-sizing: border-box;
    position: relative;
    margin: auto;
    top: 0px; 
    
    font-size: 1.7rem;
    line-height: 45px;
    text-align: center;
    font-style: italic;
    color: #fbfbfb;
    text-indent: 20px;
    margin-bottom: 20px;
  }
  
  .site-comp-memo:after {
    content: "";
    display: block;
    position: absolute;
    border: 25px solid transparent;
    border-bottom: 25px solid #fefefe;
    bottom: -33px;
    right: -38px;
    box-shadow: 0px 7px 6px -9px black;
    transform: rotate(135deg);
  }
  
  .site-comp-memo:before {
    content: "";
    display: block;
    position: absolute;
    border: 50px solid transparent;
    border-top: 50px solid #fefefe;
    top: -60px;
    left: -65px;
    box-shadow: 0px -7px 6px -9px black;
    transform: rotate(135deg);
  }


.site-memo-color-blue {
    width: 200px;
    height: 55px;
    padding: 5px;
    border: 1px solid #ddd;
    background-size: cover;
    background-clip: content-box;
    background-color: #5b9aef;
    box-sizing: border-box;
    position: relative;
    margin : 25px auto !important;
    top: 0px; 
    
    font-size: 1.7rem;
    line-height: 45px;
    text-align: center;
    font-style: italic;
    color: #fbfbfb;
    text-indent: 20px;
  }
  
  .site-memo-color-blue:after {
    content: "";
    display: block;
    position: absolute;
    border: 25px solid transparent;
    border-bottom: 25px solid #fefefe;
    bottom: -33px;
    right: -38px;
    box-shadow: 0px 7px 6px -9px black;
    transform: rotate(135deg);
  }
  
  .site-memo-color-blue:before {
    content: "";
    display: block;
    position: absolute;
    border: 50px solid transparent;
    border-top: 50px solid #fefefe;
    top: -60px;
    left: -65px;
    box-shadow: 0px -7px 6px -9px black;
    transform: rotate(135deg);
  }


.site-padding-15{
    padding : 15px;
}
.qa-message-list{
}
.qa-message-list > div {
    margin : 0;
    display: inline-block;
    margin : 20px auto;
    width : 100%;
}



.site-remote-contr{
    height : 350px;
    box-sizing: border-box;
    width : 100%;
}

.site-remote-contr ul{
    width : 35%;
    height: 350px;
    box-sizing: border-box;
    float :left;
    display: flex;
    flex-direction: column;
    padding : 0;
}
.site-remote-contr ul li{
    flex: 1 1;
    margin-bottom: 3px;
    text-align: right;
    padding : 5%;
    padding-top : 5.3%;
    box-sizing: border-box;
    border-radius: 6.5px;
    cursor: pointer;
    background: #337ab7;
    color : white;
}
.site-remote-contr ul li:nth-last-child(1){
    padding-top: 3.5%;
}
.site-remote-contr ul li:hover{
    background : #f66f6f;
}
.site-remote-contr ul li.active{
    background : #f66f6f;
}


.site-remote-cont2{
    display: inline-block;
    width : 65%;
    height : 350px;
    box-sizing: border-box;
    padding : 15px 25px;
    overflow: scroll;
    overflow-x: hidden;
}

.tab-pane > p {
    text-indent: 7.5px;
}




.site-white-consulting{
    font-size: 14px;
    padding : 15px;    
    color: #333;
    background-color: #f5f5f5;
    border : 1px solid #ddd;
    border-bottom: 0;
    border-radius: 4px;
}

.site-border-white{
    border : 1px solid #ddd;
    border-radius: 4px;

}

.site-remote-ex{
    padding : 20px 0;
    padding-bottom: 50px;
}