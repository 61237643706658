
.mooc-index-catalog-container {
  text-align: center;
  margin-bottom: 20px;
}

.mooc-index-info {
  position: relative;
  height: 250px;
}

.mooc-info-title {
  margin-top: 80px;
  width: 97%;
  position: absolute;
  text-align: center;
  background-color: grey;
  opacity: 0.8;
}

.mooc-info-title h4 {
  color: white;
}

.mooc-index-catalog-button {
  font-size: 1.7rem;
  background-color: white;
  border: 2px solid #5b9aef;
  opacity: 0.8;
  margin: 5px 10px;
  padding: 5px 15px 5px 15px;
  border-radius: 13px;
}

.mooc-index-catalog-button-active {
  font-size: 1.7rem;
  background-color: #5b9aef;
  border: 2px solid #5b9aef;
  opacity: 0.8;
  color: white;
  margin: 5px 10px;
  padding: 5px 15px 5px 15px;
  border-radius: 13px;
}

.mooc-show-header{
  border-radius: 10px;
  text-align: center;
  background-color: #a6a6a6;
  height: 70px;
  margin-bottom: 10px;
}

.mooc-show-header>p{
  margin: 0;
}

.mooc-show-header>p>a{
  color: inherit;
  float: right;
  margin-right: 15px;
}

.mooc-show-header-title {
  font-size: 24px;
  padding-top: 10px;
}

.mooc-show-tutor {
  background-color: #d7d7d7;
  margin-bottom: 20px;
}

.mooc-show-tutor-name{
  font-size: 12px;
  text-align: center;
}

.mooc-show-tutor-school{
  display: inline-block;
}

.mooc-btn{
  margin: 5px 0.5% 0 0.5%;
  height: 25px;
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 11px;
}

.mooc-show-keywords {
  width: 100%;
}

.mooc-add-btn {
  color: white;
  padding: 3px 5px;
  /*margin: 20px 15px 30px 15px; */
  min-width: 80px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.mooc-new-keyword:first-child {
  margin-left: 0px;
}


.mooc-new-keyword {
  margin: 3px 5px 3px 5px;
  height: 25px;
  background-color: white;
  color: #777;
  border: 1px solid #ccc;
  padding: 2px 9px;
}

.mooc-new-keyword-wrapper {
  min-height: 60px;
}

.mooc-video{
  width: 90%;
  /*height: max-content !important;*/
  margin: 0 5%;
}


.mooc-video iframe {
  height: 100%;
}
.mooc-vedio-btn{
  float: left;
}

/* 小屏幕（平板，大于等于 768px） */
@media (min-width: 768px) {
  .modal-dialog{
    width: 75%;
    margin: 30px auto;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 992px) {

}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 1200px) {

}
