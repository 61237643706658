/* studyroom banner sec */
.studyroom-banner{
    width: 100%;
    height: 340px;
    background-image: url('../../images/gurume-office-lounge-1.jpg');
    background-attachment: fixed;
    background-position: bottom;
}
.studyroom-banner-box{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.studyroom-banner-main{
    z-index: 100;
    text-align: center;
}
.studyroom-banner-main h1{
    color: #ffffff;
}
.studyroom-banner-main h4{
    font-size: 18px;
    color: #ffffff;
}

/* studyroom content sec */
.studyroom-content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.studyroom-content-box{
    width: 30%;
    margin: 0 auto;
    text-align: center;
}
.studyroom-content-box > p{
    font-size: 18px;
    margin-top: 40px;
}
.studyroom-content-main{
    margin-top: 30px;
}
.studyroom-content-main h4{
    font-size: 18px;
}

/* studyroom pic sec */
.studyroom-pic{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 0px;
}
.studyroom-pic-box{
    width: 25%;
}
.studyroom-pic-box img{
    width: 100%;
    height: 100%;
}

/* studyroom reserve */
.studyroom-reserve{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.studyroom-reserve-box{
    width: 70%;
    margin: 0 auto;
    text-align: center;
}
.studyroom-reserve-notice{
    width: 100%;
}
.studyroom-reserve-table{
    width: 100%;
}
.studyroom-reserve-table iframe{
    width: 100%;
    height: 800px;
}
.studyroom-reserve-title{
    width: 100%;
}
.studyroom-reserve-title h2{
    color: #5b9aef;
    font-size: 30px;
    font-weight: bold;
}
.studyroom-reserve-title h4{
    font-size: 18px;
}
.studyroom-reserve-content{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.studyroom-reserve-content h4{
    color: #5b9aef;
    font-size: 18px;
}
.studyroom-reserve-header{
    width: 100%;
    height: 40px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}
.studyroom-reserve-header h4{
    font-size: 16px !important;
    padding-top: 8px;
}
.studyroom-reserve-explanation{
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 0px 20px;
}
.studyroom-reserve-explanation p{
    word-break: break-all;
}

@media(max-width: 991px) {
    .studyroom-pic{
        flex-wrap: wrap;
    }
    .studyroom-pic-box{
        width: 50%;
    }
}
@media(max-width: 767px) {
    .studyroom-content-box{
        width: 95%;
    }
    .studyroom-pic-box{
        width: 100%;
    }
    .studyroom-reserve-box{
        width: 95%;
    }
}