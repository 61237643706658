.question-index-header {
  background-image: url("../images/qabanner.jpg");
  background-size: cover;
}

.question-index-main {
  padding-bottom: 20px;
}

.question-index-title {
  font-size: 20px;
}

.question-index-status {
  padding-top: 8px;
}

.gallery-box {
  width: 200px;
  padding: 5px;
  margin: 7px;
  float: left;
  min-height: 200px;
  border: 1px solid #ddd;
  overflow: hidden;
}

.gallery-box .thumbnail {
  height: 188px;
  width: 188px;
  line-height: 20px;
  margin-bottom: 5px;
  overflow: hidden;
  word-break: normal;
}

.gallery-box .progress {
  margin-bottom: 0;
}

.question-index-header-title {
  text-align: center;
  font-size: 3rem;
  color: white;
  margin-top: 90px;
  background-color: rgba(0, 0, 0, 0.27);
}

.question-index-search-area {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px;
}

.question-index-search-title {
  margin-top: 5px;
  margin-bottom: 3px;
  font-size: 1.8rem;
}

.question-index-search-subtitle {
  margin-top: 3px;
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.question-index-btns .col-sm-4 {
  margin-top: 5px;
  padding: 0 2px;
}

.question-index-btns .col-sm-4 .btn {
  padding: 3px 0;
  width: 100%;
  color: white;
}

.question-index-search-hr {
  margin-top: 15px;
  margin-bottom: 5px;
  border-top: 1px solid #b5b5b6;
}

.question-index-search-btn {
  padding: 2px;
  width: 40%;
  margin-top: 20px;
  color: white;
}

.question-index-search-selects select {
  margin-top: 15px;
  background-color: inherit;
}

.question-index-search-new-question-btn {
  width: 100%;
  margin-top: 25px;
  color: white;
}

.question-index-main-one-question {
  margin-top: 20px;
}

.question-index-main-one-question-line1 {
  margin-bottom: 3px;
}

.question-index-main-one-question-float-right {
  float: right;
}

.question-index-main-one-question-divider {
  margin-top: 3px;
  margin-bottom: 3px;
  border-top: 1px solid #b5b5b6;
}

.question-index-main-one-question-line2 .btn {
  padding: 2px;
  min-width: 80px;
  background-color: rgba(181, 181, 182, 0);
}

.uploaded-pic-container {
  line-height: 200px;
  height: 200px;
  border: 1px solid #ccc;
  margin-top: 20px;
}

.question-show-question-box textarea {
  resize: none;
}

.question-show-question-answer-btn-box {
  margin-top: 20px;
  color: white;
  padding: 45px 0;
}

.question-show-answer-submit-btn {
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
}

/* 小屏幕（平板，大于等于 768px） */
@media (min-width: 768px) {
  .question-index-header-title {
    margin-top: 190px;
  }

  .question-index-search-pic {
    width: 30%;
    margin-bottom: -40px;
  }

  .question-index-search-new-question-btn {
    width: 68%;
    font-size: 1rem;
  }

  .question-index-main-question-pic {
    width: 80px;
    height: 80px;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 992px) {
  .question-index-main-question-pic {
    width: 120px;
    height: 120px;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 1200px) {
  .question-index-header-title {
    margin-top: 260px;
  }
}
