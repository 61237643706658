.school-input-group-wrapper{
}
.school-input-group-sub-wrapper{
}
.line-wrapper{
    display: inline-block;
    margin-bottom: 10px;
}
.school-input-group-part-a-label {
    width : 100%;
}
.school-input-group-part-b-label {
    width : 100%;
}

.school-input-group-part-b-label > label {
    display: inline-block;
    margin-left: 10px;
}
.school-input-group-part-b-label > label > span:nth-child(1){
    bottom: 0px;
}
.school-input-group-part-b-label > label > span:nth-child(2){
    line-height: 25px;
    margin-left: 3px;
}

.school-input-group-part-a{
    display: inline-block;
    position: relative;
    width : 100%;
    padding : 5px;

}
.group-part-a-input{
    display: inline-block;
    width : 100%;
    position: relative;
}
.group-part-a-input > div{
    width : 49%;
    float: left;
}
.group-part-a-input > div:nth-child(1){
    margin-right: 1%;
}
/* .group-part-a-input > div:nth-child(1) > input {
    height: 34px;
} */

.group-part-a-input > div:nth-child(2){
    margin-left: 1%;
}
.group-part-a-input > div:nth-child(2) > span {
    border : 0.5px solid #ccc;
}
/* .group-part-a-input > div:nth-child(2) > input {
    height : 34px;
} */
.group-part-a-input > select {
    height: 34px;
}
.group-part-a-input > select > option {
}







/* btns */

.school-btns-wrapper {
}
.school-btn {
}
.school-btn ul {
    margin : 0;
    padding : 0;
}
.school-btn ul li{
    height : 48px;
    text-align: center;
    line-height: 48px;
    margin-bottom: 10px;
    border-radius: 5px;
    color : rgba(91, 154, 239, 1);
    cursor: pointer;
}
.school-btn ul li i {
    margin-right: 5px;
}

.school-btn ul li.active{
    background : rgba(200, 200, 200, 0.2);
    border : 1px solid #ddd;
    box-shadow: inset 0 -1px 2px rgba(0,0,0,.075);
}
.school-btn ul li.extra{
    height : 38px;
    text-align: left;
    margin : 0;
    background : rgba(200, 200, 200, 0.2);
    border : 1px solid #ddd;
    box-shadow: inset 0 -1px 2px rgba(0,0,0,.075);
}
.school-btn ul li.extra > h5 {
    line-height: 35px;
    text-indent: 10px;
}

.school-btn ul li.none{
    background : rgba(200, 200, 200, 0.1);
    border : 1px solid #ddd;
    box-shadow: inset 0 -1px 2px rgba(0,0,0,.075);
    color : rgba(91, 154, 239, 0.6);
}

.height34 {
    height: 34px !important;
}
.height35 {
    height: 35px !important;
}
.fontsz14 {
    font-size: 14px !important;
}
.fontsz17 {
    font-size: 17px !important;
}
.colorBl {
    color : #333;
}
.marginBottom10{
    margin-bottom: 10px;
}
.marginBottom30{
    margin-bottom: 35px;
}
.marginTop30{
    margin-top : 30px;
    padding-top : 10px;
}

.display-inlineb{
    display: inline-block;
}




@media screen and (min-width:991px) {

    .school-btn ul li.none{
        background : rgba(200, 200, 200, 0.1);
        border : 1px solid #ddd;
        box-shadow: inset 0 -1px 2px rgba(0,0,0,.075);
        color : rgba(91, 154, 239, 0.6);
    }
    .school-btn ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items : stretch;
    }
    .school-btn ul li {
        flex : 1;
        margin: 0 1%;

    }
    .school-input-group-part-a {
    }
    .line-wrapper{
    }
    .school-input-group-part-a > label {
        width : 100%;
    }
    .group-part-a-input{
        width : 49%;
        float: left;
    }
    .group-part-a-input > div{
        width : 48%;
        float: left;
    }
    .school-fix{
        width :24%;
    }
}