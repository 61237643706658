.TdMoocComponent-wrapper{
    width: 30%;
    margin: 15px 10px;
}

.TdMoocComponent-wrapper > button{
    width: 100%;
    background : #f5f5f5;
    border : 1px solid #5b9aef;
    border-radius: 5px;
    box-shadow: 1px 3px 3px #ddd;
    color : #5b9aef;
}

.TdMoocComponent-wrapper > button > span{
    display: block;
    width : 100%;
    font-size: 23px;
}
.TdMoocComponent-wrapper > button > span:nth-child(1){
    font-size: 43px;
    padding : 0 !important;
}
@media screen and (max-width:991px){
    .TdMoocComponent-wrapper{
        width: 27%;
    }
}
@media screen and (max-width:767px){
    .TdMoocComponent-wrapper{
        width: 100%;
    }
}