.about{
    display: inline-block;
    width : 100%;
}
.about > div {
}
.about > div > div {
    margin : 0 auto;
}
.greybackground{
}
.container{
}
.text-center {
}
.col-md-8 {
    margin : 0 auto;
}


/* wrap */
.about-wrapper{
    width: inherit;
    display: flex;
    flex-direction: column;
}

/* about banner section*/
.about-banner{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-banner-box{
    width: 100%;
}
.about-banner-main-wrapper{
    width: 100%;
}
.about-banner-main-wrapper h1{
    font-size: 36px;
}
.about-banner-main-wrapper h4{
    font-size: 18px;
}

/* about service section*/
.about-service{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-service-box{
    width: 65%;
}
.about-service-main-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 15px;
}
.about-service-main-wrapper h1{
    font-size: 36px;
}
.about-service-main-content{
    display: flex;
    width: 100%;
    justify-content: center;
}
.about-service-item{
    width: 30%;
    margin: 1%;
}
.about-service-item .card{
    width: 100%;
}
.about-service-item .card-img-top{
    width: 60%;
    margin: 0 auto;
    padding: 3% 0 3% 0;
}
.about-service-item .bg-gurume-blue h4{
    font-size: 18px;
    padding: 5px 0px;
}
.about-service-item .card-body{
    padding-bottom: 4%;
}
.about-service-item .card-body p{
    font-size: 14px;
}

/* about story section */
.about-story{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-pull-right{
    box-sizing: border-box;
    font-size : 14px;
    bottom: 10px;
    width : 100%;
    text-align: right;
}

.about-story-box{
    width: 60%;
    display: flex;
    position: relative;
    /* THIS WILL BE BETTER FOR LOOKING IT */
    text-align: justify;

}
.about-story-main-wrapper{
    padding-top: 15px;
}
.about-story-main-wrapper h1{
    font-size: 36px;
}
.about-story-main-wrapper h4{
    font-size: 18px;
}
.about-story-main-wrapper p{
    font-size: 14px;
}

/* about team section */
.about-team{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-team-box{
    width: 60%;
    display: flex;
    margin: 0 auto;
}
.about-team-main-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding-top: 1%;
}
.about-team-main-wrapper h4{
    font-size: 18px;
}
.about-team-main-wrapper p{
    font-size: 14px;
}
.about-team-main-content{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0;
    margin-left: 25px;
}
.about-team-item{
    width: 45%;
    margin: 0px 15px;
    margin-bottom: 30px;
}
.about-team-item-img-box{
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 5px;
}
.about-team-item-img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
/* gurume member profile pictures*/
#member-1{
    width: 100%;
    background: url('../../images/about-us/team/harry_lee.jpg');
    background-position: top;
    background-size: cover;
}
#member-2{
    width: 100%;
    background: url('../../images/about-us/team/patrick_chung.jpg');
    background-position: center;
    background-size: cover;
}
#member-3{
    width: 100%;
    background: url('../../images/about-us/team/mark.jpg');
    background-position: center;
    background-size: cover;
}
#member-4{
    width: 100%;
    background: url('../../images/about-us/team/heera_shin.jpeg');
    background-position: 50% 30%;
    background-size: cover;
}
#member-5{
    width: 100%;
    background: url('../../images/about-us/team/helena_park.jpeg');
    background-position: center;
    background-size: cover;
}
#member-6{
    width: 100%;
    background: url('../../images/about-us/team/soo_lee.jpeg');
    background-position: 0% 50%;
    background-size: cover;
}
#member-7{
    width: 100%;
    background: url('../../images/about-us/team/jessica_oh.jpeg');
    background-position: center;
    background-size: cover;
}
#member-8{
    width: 100%;
    background: url('../../images/about-us/team/diana_lee.jpeg');
    background-position: top;
    background-size: cover;
}
#member-9{
    width: 100%;
    background: url('../../images/about-us/team/jimmy_choi.jpeg');
    background-position: center;
    background-size: cover;
}
#member-10{
    width: 100%;
    background: url('../../images/about-us/team/lisa.jpeg');
    background-position: center;
    background-size: cover;
}
#member-11{
    width: 100%;
    background: url('../../images/about-us/team/melissa.png');
    background-position: center;
    background-size: cover;
}
#member-12{
    width: 100%;
    background: url('../../images/about-us/team/songyi_kang.jpeg');
    background-position: top;
    background-size: cover;
}
#member-13{
    width: 100%;
    background: url('../../images/about-us/team/henry_park.jpeg');
    background-position: center;
    background-size: cover;
}
.about-team-item .card{
    width: 100%;
}
.about-team-item .card p{
    font-size: 14px;
}
.about-team-item .card li{
    list-style: disc;
    font-size: 12px;
}


@media screen and (max-width:1074px) {
    .about-service-box{
        width: 70%;
    }
    .about-service-item{
        margin: 0px 10px;
    }
    .about-team-main-content{
        margin-left: 10px;
    }
}

@media screen and (max-width:991px) {
    .about-banner-box{
        width: 80%;
        margin: 0 auto;
    }
    .about-service-box{
        width: 85%;
    }
    .about-service-item{
        margin: 15px;
    }
    .about-story-box{
        width: 80%;
        margin: 0 auto;
    }
    .about-team-box{
        width: 83%;
        margin: 0 auto;
    }
    .about-team-main-content{
        margin-left: 5px;
    }
}

@media screen and (max-width:767px) {
    .about-banner-box{
        width: 90%;
        margin: 0 auto;
    }
    .about-service-box{
        width: 90%;
    }
    .about-banner-main-wrapper{
        width: 100%;
    }
    .about-service-main-content{
        flex-direction: column;
    }
    .about-service-item{
        margin: 0px;
        width: 100%;
        margin-bottom: 30px;
    }
    .about-service-item .bg-gurume-blue h4{
        padding-top: 1.5%;
    }
    .about-service-item .card-body{
        padding-bottom: 2%;
    }
    .about-story-box{
        width: 90%;
        margin: 0 auto;
    }

    .about-team-box{
        width: 95%;
        margin: 0 auto;
    }
    .about-team-main-content{
        margin-left: 0px;
    }
    .about-team-item{
        width: 100%;
        margin: 0px 0px;
        margin-bottom: 30px;
    }
} 