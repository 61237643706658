
.infi-wapper{
    width : 100%;
    min-height: 100px;
    max-height: 1000px;
    overflow: scroll;
    overflow-x: hidden;
    position: relative;
}




