.jumbotron .blog {
  background-image: url("/images/index_carousel_2.jpg");
  background-size: cover;
  height:100%;
}

.recruiting {
  background-image: url("/images/recruiting.jpg");
  background-size:cover;
  max-width: 100%;
}

.blog-catalogue-btn-selected {
  color: white !important;
  min-width: 70px;
  background: #5b9aef !important;
}

.blog-catalogue-btn {
  color: #6c767f;
  min-width: 70px;
  border: solid 2px #5b9aef;
  background: white;
  margin-bottom: 3px;
  margin-right: 2px;
  padding: 0px 3px 0px 3px;
}

.blog-filter-btn {
  font-size: 1.7rem;
  background-color: #5b9aef;
  border: 2px solid #5b9aef;
  opacity: 0.8;
  color: white;
  margin-top: 5px;
  padding: 3px 8px 3px 8px;
  border-radius: 13px;
}

.blog-index-catalogue-arrow {
  width: 15px;
  vertical-align: middle;
}

.blog-index-catalogue-name {
  font-size: 14px;
  color: #6c767f;
  cursor: pointer;
}

.blog-index-catalogue-detail {
  margin-left: 20px;
}

.blog-index-catalogue-name-number {
  float: right;
}

.blog-show-cover {
  background-size: cover;
}

.blog-show-header-title {
  text-align: center;
  font-size: 3rem;
  color: white;
  margin-top: 90px;
  margin-bottom: 0;
  background-color: rgba(239, 239, 239, 0.27);
}

.blog-show-author-area {
  padding: 10px;
}

.blog-show-author-name {
  margin: 10px 0 0 0;
}

.blog-show-author-intro {
  margin-bottom: 0;
  -ms-word-break: break-all;
  word-break: break-all;
}

.blog-show-author-pic {
  width: 100%;
  max-width: 90px;
}

.blog-show-footer {
  margin-bottom: 20px;
}

.blog-new-subtitle {
  margin-left: 20px;
}

.blog-btn{
  width: 100px;
  height: 25px;
  background-color: white;
  border: 1px solid #666;
  padding: 2px 12px;
}

.blog-p-inline{
  display: inline-block;
}
.blog-date-inline{
  justify-content: flex-end;
  width: 100%;
  display: flex;
  margin-bottom: 0px !important;
}
.blog-p-title{
  margin-top: 0;
}

.blog-p-padding{
  padding-top: 20px;;
}

/*.blog-article{
  height: 390px;
}
*/
.blog-info-height{
  height: auto;
  /*margin-bottom: 20px;*/
  overflow: hidden;
}

/*@media(max-width: 768px) {*/
  /*.blog-info-height {*/
    /*height: auto;*/
  /*}*/
/*}*/
.clear{
  clear: both;
}

.secret {
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
.secret img {
  max-width: 100%;
}
/* 手机 */
@media (max-width: 768px) {
  .blog-catalogue-btn {
    font-size: 12px;
    min-width: 70px;
  }
}

/* 小屏幕（平板，大于等于 768px） */
@media (min-width: 768px) {
  .blog-show-header-title {
    margin-top: 120px;
  }

  .blog-btn{
    width: 80px;
  }

  .caption>p{
    font-size: 12px;
  }

  .caption>h4{
    font-size: 14px;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 992px) {
  .blog-show-header-title {
    margin-top: 180px;
  }

  .caption>p{
    font-size: 14px;
  }

  .caption>h4{
    font-size: 18px;
  }

  .blog-btn{
    width: 100px;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 1200px) {
  .blog-show-header-title {
    margin-top: 220px;
  }
}
