.banner-img {
  padding: 0;
}

.step-intro {
  margin-top: 20px;
  margin-bottom: 40px;
}

.step-number {
  width: 50px;
  height: 50px;
  background: #299CD3;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  font-size: 33px;
  color: white;
}

.work-intro {
  height: 300px;
  background-color: #666666;
  opacity: 0.5;
}

.free-data {
  padding-bottom: 40px;
}

.free-data-img {
  width: 180px;
  margin-top: 90px;
}

.free-data-detail {
  background-color: #299CD3;
  opacity: 0.75;
  color: white;
  width: 80%;
  margin-top: 50px;
}

.free-data-detail-line1 {
  padding-left: 20px;
  padding-top: 10px;
}

.free-data-detail-line2 {
  text-align: right;
  padding-right: 20px;
  padding-bottom: 10px;
}

