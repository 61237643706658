/* mooc card item */
.mooc-card-item {
    width: 260px;
    margin: 15px;
}
.mooc-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}
.mooc-card:hover {
    box-shadow: 1px 2px 3px #ccc;
}
.mooc-card-top {
    width: 100%;
    padding: 20px 10px;
    background-color: #fff;
    border: 4px solid #5b9aef;
}
.mooc-card h3 {
    font-size: 20px;
    color: #5b9aef;
    text-align: center;
    font-weight: bold;
}
.mooc-tutor {
    width: 95%;
    margin: 0 auto;
}
.mooc-tutor-name {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: -30px;
}
.mooc-tutor-name p {
    font-size: 16px;
    color: #5b9aef;
    font-weight: bold;
}
.mooc-tutor-pic {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -50px;
    z-index: 1000;
}
.mooc-tutor-pic-box {
    width: 70px;
    height: 70px;
}
.mooc-tutor-pic-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
.mooc-card-bottom {
    width: 100%;
    padding: 20px 0px;
    background-color: #fff;
}
.mooc-price {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 5px 0px;
}
.original-price {
    color: #5b9aef;
    font-size: 14px;
}
.now-price {
    color: #5b9aef;
    font-size: 16px;
}
.mooc-apply-btn {
    background-color: #5b9aef;
    color: white;
    border-radius: 5px;
    border: none;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    width: 70%;
}
.mooc-time {
    text-align: center;
    color: #666;
    padding-top: 5px;
}

.mooc-index-header {
    text-align: center;
    font-size: 0;
}
.mooc-index-header:after,
.mooc-index-header span {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
}
.mooc-index-header:after {
    content: "";
}
.mooc-index-header div {
    display: inline-block;
    vertical-align: middle;
}

.tutor-header {
    background: url("../../../images/reg-bg.png") no-repeat;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 330px;
    transition: all 1s;
}
.tutor-header.moocindex-header {
    position: relative;
    z-index: 1;
    margin-top: -45px;
}
.moocindex-header .moocindex-header-title {
    border: solid 2px white;
    margin: 10px;
}
.moocindex-header .moocindex-header-title h1 {
    background-color: rgba(132, 197, 246, 0.27);
    margin: 15px 20px -10px -20px;
    padding: 15px 90px;
    color: white;
}

.mooc-catalog-list {
    margin: 20px 0px;
}
.mooc-catalog-list > .mooc-index-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding: 15px 0px;
}
.mooc-catalog-list > .mooc-index-title span {
    text-align: center;
    font-size: 18px;
    width: 80%;
    display: flex;
    justify-content: center;
}
.mooc-catalog-list > .mooc-index-title .mooc-subjects-select {
    width: 10%;
    display: flex;
    justify-content: flex-start;
}
.mooc-catalog-list ul {
    margin: 0;
    padding: 0;
    width: 70%;
    display: flex;
    margin: 0 auto;
}
.mooc-catalog-list ul li {
    flex: 1;
    text-align: center;
    margin: 5px 5px;
    padding: 5px 15px !important;
    border: 2px solid #5b9aef;
    border-radius: 13px;
    font-size: 17px;
    transition: all 0.3s;
    cursor: pointer;
}
.mooc-catalog-list ul li:hover {
    background: #5b9aef;
    color: white;
}
.mooc-catalog-list ul li.active {
    background: #5b9aef;
    color: white;
}

.mooc-catalog-item-list {
    margin-bottom: 150px;
    max-width: 1200px;
    margin: 0 auto;
}

.mooc-catalog-item-list > ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 40px;
    text-align: center;
}
.mooc-item-pic {
    width: 100%;
    height: 60%;
    overflow: hidden;
    position: relative;
}
.mooc-item-pic > img {
    width: 100%;
    height: 100%;
    transition: all ease 0.3s;
}
.mooc-item-pic > img:hover {
    transform: scale(1.2);
}
.mooc-item-pic > span:hover {
    transform: scale(1.2);
}
.mooc-item-pic > span {
    z-index: 99;
    position: absolute;
    top: 55%;
    left: 0;
    width: 100%;
    padding: 3px 0;
    color: white;
    background: rgba(0, 0, 0, 0.3);
    font-size: 15px;
    transition: all 0.2s;
    text-align: center;
}

.mooc-item-profile {
    height: 40%;
    display: flex;
    flex-direction: column;
}
.mooc-item-profile > div {
    flex: 1;
}
.mooc-item-profile > div:nth-child(1) {
    overflow: hidden;
    display: flex;
}
.mooc-item-profile > div:nth-child(1) > span {
    padding: 10px;
    font-size: 15px;
}
.mooc-item-profile > div:nth-child(1) > span:nth-child(1) {
    flex: 0.2;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
}
.mooc-item-profile > div:nth-child(1) > span:nth-child(2) {
    flex: 1;
    text-align: left;
}

.mooc-item-profile > div:nth-child(1) > span > img {
    width: 100%;
    border-radius: 50%;
    margin-top: 7px;
}

.mooc-item-profile > div:nth-child(2) {
    flex: 0.5;
}
.mooc-item-profile > div:nth-child(2) > button {
    padding: 5px 15px;
    border-radius: 3px;
    color: #333;
    border: 1px solid #ccc;
}
.mooc-item-profile > div:nth-child(2) > button:hover {
    color: white;
    background: #888;
}

.mooc-catalog-no-data {
    min-height: 300px;
}
.mooc-catalog-no-data > span {
    display: inline-block;
    width: 100%;
    padding: 50px;
    text-align: center;
    font-size: 20px;
}

@media (max-width: 939px) {
    .mooc-catalog-list {
    }
    .mooc-catalog-list ul li {
        margin: 5px 5px;
        padding: 5px 18px;
        border: 2px solid #5b9aef;
        font-size: 13px !important;
    }
}

@media (max-width: 1100px) {
    .mooc-catalog-list ul li {
        margin: 5px 8px;
        padding: 5px 16px;
        border: 2px solid #5b9aef;
        font-size: 15px;
        letter-spacing: -0.1px;
    }
}

@media (max-width: 1164px) {
    .mooc-catalog-item-list > ul {
    }
    .mooc-card-item {
        width: 30%;
    }
}

@media (max-width: 905px) {
    .mooc-catalog-item-list > ul {
    }
    .mooc-card-item {
        width: 45%;
    }
}
@media (max-width: 767px) {
    .mooc-catalog-list ul {
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .mooc-card-item {
        width: 100%;
    }
    .mooc-item-profile > div:nth-child(1) > span:nth-child(1) {
        overflow: visible;
        flex: 0.1;
    }
    .mooc-item-profile > div:nth-child(1) {
        overflow: visible;
    }
    .mooc-item-profile > div:nth-child(2) {
    }
}

@media (min-width: 1200px) {
    .tutor-header {
        height: 400px;
    }
}
