.event1 {
  background: /* top, transparent red, faked with gradient */ linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
    /* bottom, image */ url("/images/event1_thumbnail.jpg");
  background-size: cover;
  max-width: 100%;
}

.event3 {
  background: /* top, transparent red, faked with gradient */ linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
    /* bottom, image */ url("/images/event3_thumbnail.jpg");
  background-size: cover;
  max-width: 100%;
}

.event4 {
  background: /* top, transparent red, faked with gradient */ linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
    /* bottom, image */ url("/images/event4_thumbnail.jpg");
  background-size: cover;
  max-width: 100%;
}

.event5 {
  background: /* top, transparent red, faked with gradient */ linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
    /* bottom, image */ url("/images/event5_thumbnail.jpg");
  background-size: cover;
  max-width: 100%;
}

.event6 {
  background: /* top, transparent red, faked with gradient */ linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
    /* bottom, image */ url("/images/event6_thumbnail.jpg");
  background-size: cover;
  max-width: 100%;
}

.event7 {
  background: /* top, transparent red, faked with gradient */ linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
    /* bottom, image */ url("/images/event7_thumbnail.jpg");
  background-size: cover;
  max-width: 100%;
}

.event8 {
  background: /* top, transparent red, faked with gradient */ linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
    /* bottom, image */ url("/images/event8_thumbnail.jpg");
  background-size: cover;
  max-width: 100%;
}

.event9 {
  background: /* top, transparent red, faked with gradient */ linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
    /* bottom, image */ url("/images/event8_thumbnail.jpg");
  background-size: cover;
  max-width: 100%;
}

.event10 {
  background: url("/images/event10_thumbnail.png");
  background-size: cover;
  max-width: 100%;
}

.event11 {
  background: url("/images/event11_thumbnail.png");
  background-size: cover;
  max-width: 100%;
}

.event12 {
  background: url("/images/events_dwight.jpg");
  background-size: cover;
  max-width: 100%;
}

.event13 {
  background: url("/images/events_chadwick_thumbnail.jpg");
  background-size: cover;
  max-width: 100%;
}

.event14 {
  background: url("/images/events_jeju_thumbnail.png");
  background-size: cover;
  max-width: 100%;
}

.event_thumbnail_text {
  color: white;
  padding: 50px 0px 50px 0px;
  margin-top: 0px;
  text-align: center;
}
