#wrap{
    /* min-height: 36%; */
    display: inline-block;
    width : 100%;
}
#ib-publication-recruiting-modal{
}


.top_nav {
    height: 45px;
}

.nav-li a{
    text-decoration : none;
}

.top_bar_nav{
}


.nav_wrapeer{
    height : 100%;
    position : relative;
}
.nav_ul {
    height : 100%;
}
.nav_ul > li > a {
    font-weight: 700;
    color : #6c767f;
    display: inline-block;
}


.navbar-default{
}
.navbar{
    height: auto;
}
.navbar-toggle-container{
    margin-bottom: 0px !important;
}
.nav_container{
    position: relative;
}
.nav_container > div {
}
.navbar-header{
    float : left;
    padding: 10px 0px;
}
.nav_layout{
    width : 100%;
    position :absolute;
    top : 0;
}
.nav_layout ul.navbar-right{
    position : absolute;
    width : 45%;
    right : 0;
    padding-top: 7px;
}
.nav_layout > ul.navbar-right > li{ 
    position: absolute;
    font-size : 14px;
    box-sizing: border-box;
    height : 40px;
    /* line-height: 40px; */

}
.nav_layout > ul.navbar-right > li > a{
    color : #777;

}
.nav_layout > ul.navbar-right > li:nth-child(1){
    left : 0%;
}
.nav_layout > ul.navbar-right > li:nth-child(2){
    left : 27%;
}
.nav_layout > ul.navbar-right > li:nth-child(3){
    left : 54%;
}
.nav_layout > ul.navbar-right > li:nth-child(4){
    left : 81%;
}

.navbar-right{
    margin-right : 20px;
    z-index : 999999 !important;
}
.navbar-right li{
    position: relative;

}
.navbar-right li > a{
    display: inline-block;
    width : 100%;
    height : 100%;
    color: #6c767f;
}


.nav_layout > ul > li > ul {
    width : 160px;
    
}

.nav_layout > ul > li > ul > li {
    font-size : 14px;
    padding : 3px 20px;
    height : 26px;
    line-height:  24px;
    color : #333;
}
.nav_layout > ul > li > ul > li > a {

}
.homepage{
    height: 415px;
}

.after-login{
    z-index: 9999999999;
}
.after-login > ul.after-login-dropdown {
    border: 1px solid rgba(0,0,0,.15);
    box-shadow: 0 0 6px rgba(0,0,0,.3);
}
.after-login > ul.after-login-dropdown > li{
    overflow: hidden;
    height : 42px;
    line-height:  15px;
    box-sizing: border-box;
    cursor: pointer;
}
.after-login > ul.after-login-dropdown > li:hover{
    background : rgb(243, 243, 243);
}

@media screen and (max-width:767px){
    .navbar-right li{
        margin-bottom: 10px;
    }
    .nav-li > .dropdown-menu{
        padding: 0px 7px;
        border: none;
        margin-top: 15px;
    }
    .navbar-right li > a:link{
        color: #333;
    }
}