.common-body-wrapper{
    width : 80%;
    margin : 0 auto;
    padding : 10px;
}

.common-body-inner-wrap{
    position: relative;
}
.common-body-padding {
    padding : 10px;
}
.common-body-margin-top {
    margin-top : 20px;
}
.common-body-margin-bot {
    margin-bottom: 10px;
}

.common-body-h3-positive {
    color : #5b9aef;
    font-family: 'Spoqa Han Sans',sans-serif!important;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.1;

}
.common-body-h3-negative {
    color : #ffa183;

    font-family: 'Spoqa Han Sans',sans-serif!important;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.1;
    
}

.common-body-red-color{
    color : #ffa183
}
.common-body-blue-color{
    color : #5b9aef
}