.adconUk{
    width : 100%;
    margin : 20px;
}
.adconUk > img{
    width : 100%;
}


.adcUk-sen{
    text-align: center;
}
.adcUk-sen ul{
    padding : 0;
}
.adcUk-sen ul li{
    font-size: 18px;
    font-weight: 300;
}

.ine-type-a{
}
.ine-type-a ul{
    margin : 0;
    padding : 0;
    display: flex;
}
.ine-type-a ul li{
    flex : 1;
    padding : 15px;
}
.ine-type-a ul li > img{
    width : 100%;
}

.ine-type-a ul li > div{
    margin: 20px;
    font-size: 18px;
}

.ine-type-title{
    font-weight: 700;
    color: #000;
    font-size: 24px;
}

.ine-type-ss{
    height: 600px;
}
.ine-type-ss > img{
    height: 100%;
}

.ine-type-afs{
    padding : 0 30px;
}
.ine-type-afs ul{
    padding : 0;
    margin : 0;
    display: flex;
}
.ine-type-afs ul li{
    flex : 1;
    width : 300px;
    height : 130px;
    margin : 10px;
    border : 1px solid #ccc;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
}
.ine-type-afs ul li span{
    flex : 1;
    line-height: 30px;
}
.ine-type-afs ul li span:nth-child(1){
    width : 100%;
    font-size: 18px;
    font-weight: 700;
}
.ine-type-afs ul li span:nth-child(2){
}
.ine-type-afs ul li span:nth-child(3) > a{
    padding : 10px;
    border : 1px solid #66afe9;
    border-radius: 3px;
}

.inner-adc {
    padding : 10px 50px;
}