.main-review-item-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main-review-item-wrapper .main-review-item{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.main-review-item li{
    width: 28%;
    position: relative;
    margin: 15px;
}
.main-review-item li > div {
    width: 100%;
}
.main-review-item li > div img{
    width: 100%;
    height: 100%;
}
.main-review-tutor-info{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.main-review-top{
    width: 100%;
    background: transparent;
    height: 55%;
    position: absolute;
    top: 0;
}
.main-review-bottom{
    width: 100%;
    height: 45%;
    position: absolute;
    top: 55%;
    padding-left: 15px;
}
.main-review-tutor-info p{
    color: #fff;
    text-shadow: rgba(0,0,0,.5) 2px 2px 2px;
    font-weight: 700;
}
.main-review-tutor-info p:nth-child(1){
    font-size: 24px;
}
.main-review-tutor-info p:nth-child(2){
    font-size: 17px;
}

.main-review-text{
    display: none;
}
.main-review-item li:hover .main-review-text{
    display: block;
    cursor: pointer;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(102, 175, 233, 0.60);
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
    color: #fff;
    text-shadow: rgba(0, 0, 0, .8) 1px 1px 1px;
    font-size: 15px;
    margin: 0 auto;
    padding: 30px;
}
.main-review-more-btn{
    width: 97%;
}

.main-review-item-wrapper ul li:nth-child(2){
    margin : 0 20px;
}

@media screen and (max-width:1100px){
    .main-review-item li:hover .main-review-text{
      padding: 20px;
    }
  }
@media screen and (max-width:991px){
    .main-review-item li{
        width: 43%;
      }
      .main-review-item-wrapper .main-review-item{
        justify-content: flex-start !important;
        margin-left: 30px;
      }
  }
@media screen and (max-width:767px) {
    .main-review-item li{
        width: 100% !important;
      }
      .main-review-item-wrapper .main-review-item{
        margin-left: 0px !important;
      }
      .main-review-item li:hover .main-review-text{
        padding: 30px;
      }
    .main-review-item-wrapper ul{
        flex-direction: column;
        height: 1050px;
        margin : 0 auto;
    }
    .main-review-item-wrapper ul li{
        flex : 1;
        max-width: 100%;
        height: 350px;
        margin : 0;
    }
    .main-review-item-wrapper ul li > div:nth-child(1){
        width : 100%;
    }
    .main-review-item-wrapper ul li > div:nth-child(1) > img{
        width : 100%;
        height: 350px;
        margin : 0 auto;
    }
    .main-review-item-wrapper ul li:nth-child(2){
        margin : 10px 0;
    }
    .main-review-top{
        height: 75%;
    }
    .main-review-bottom{
        height: 25%;
        top: 75%;
    }
    
}
@media screen and (max-width:570px) {
    .main-review-top{
        height: 60%;
    }
    .main-review-bottom{
        height: 40%;
        top: 60%;
    }
}