/*header part*/

.teacher-search-arrow-right {
  background-image: url("../images/arrow-buttom.png");
  background-size: cover;
  margin-top: 50px;
  width: 35px;
  height: 30px;
}

.teachersearch-header {
  background: url("../images/ts-bg.png") no-repeat;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 30px;
}

.teachersearch-header-h1 {
  color: #666666;
  font-size: 35px;
  margin: 5px 0;
  padding-left: 40px;
}

.teacheragreement-header {
  background: url("../images/ts-bg.png") no-repeat;
  background-size: cover;
  padding-top: 20px;
  padding-bottom: 30px;
}

/*search part*/
.phone-filter-trggle {
  float: right;
  display: inline-block;
  text-align: center;
  font-size: 1.8rem;
  padding: 15px 0 0 0;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: rgba(91, 154, 239, 0.76);
  border: 2px solid white;
}

.phone-search-major-area {
  padding-left: 15px;
  padding-right: 15px;
}

.phone-search-major-area .col-xs-4 {
  padding: 2px;
}

.search {
  padding: 10px;
  margin-top: 40px;
  /*background: url("../images/searchpage-aside-bg.png") no-repeat;*/
  background-color: #e8e9e8;
  background-size: cover;
  margin-bottom: 40px;
}

.search > h2 {
  margin: 0;
}

.search-part {
  margin-top: 20px;
  color: white;
}

.search-part > .btn {
  border-radius: 0;
  margin-left: 1%;
  width: 29%;
}

.search-change-btn-area .col-sm-4 {
  padding: 0 3px;
}

.search-change-btn {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.gray-color {
  color: #666666;
}

.teachersearch-btn {
  min-width: 98px;
  color: white;
}

.disabled-btn {
  display: none;
}

.search-menu {
  width: 98%;
  margin-left: 1%;
  margin-top: 10px;
  min-height: 150px;

}

.search-menu-part {
  background-image: url("../images/searchmeun-bg1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-color: #f9b462;
  opacity: 0.75;
}

.flipper > div > div > div > .btn {
  margin-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(255, 255, 255, 0.36);
}

.search-btn {
  padding: 0;
  text-decoration: none;
  border: 0;
  background-color: inherit;
  outline: none;
  float: right;
}

.search-selected-btn {
  color: white;
  min-width: 80px;
  padding-left: 2px;
  padding-right: 2px;
}

.search-form {
  margin-top: 10px;
}

.search-input {
  border-radius: 10px;
  height: 43px;
  width: 100%;
  border: 2px solid #666666;
  outline: none;
  background-color: #e2e4e2;
}

.search-area {
  min-height: 200px;
  padding: 10px 5px;
  margin-top: 10px;
  border-radius: 10px;
  border: 2px solid #666666;
}

.search-deatil {
  margin-bottom: 2px;
}

.search-detail-content {
  margin-left: 8px;
}

.tutor-title {
  font-size: 1.8rem;
  text-align: center;
}

.tutor-pic {
  height: 100%;
  width: 100%;
}


.tutor-major {
  margin-top: 10px;
}

.tutor-major > p > span {
  margin-left: 10px;
}

.search-filter-title {
  font-size: 2rem;
}

.teaching-style-title {
  color: #666666;
  border: solid #666666 2px;
  border-radius: 10px;
  padding: 8px 10px;
}

.teachersearch-btn-no-open {
  background-image: url('../images/arrow-buttom.png');
  background-size: 7%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: right;
}

.teachersearch-btn-open {
  background-image: url('../images/arrow-up.png');
  background-size: 7%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: right;
}

.teacher-search-filter-area {
  min-height: 50px;
  border: solid 2px #666666;
  border-radius: 10px;
  padding: 0 5px 5px 5px;
}

.teacher-search-filter-btn {
  width: 100%;
  background-color: inherit;
  color: #666666;
  border: solid #666666 2px;
  border-radius: 15px;
  margin-top: 5px;
  padding: 3px 7px;
  white-space: normal;
  text-align: center;
  margin-right: 5px;
}

.teacher-search-filter-btn-selected {
  background-color: #5b9aef;
  color: white;
  border: solid 2px rgba(0, 179, 238, 0);
}

.hourly-rate-btn-clicked {
  color: white;
  background-color: #299cd3;
}

.teacher-search-subject-btn {
  color: white;
  min-width: 70px;
  font-size: 13px;
  margin-right: 5px;
  padding: 0px 0px 0px 0px;
  margin-top: 3px;
}

.school-select {
  background-color: inherit;
  border-radius: 10px;
  height: 43px;
  width: 100%;
  border: 2px solid #299cd3;
  outline: none;
}

.school-select > option {
  font-size: 1.8rem;
  padding-left: 20px;
  border-bottom: solid #666666;
}

.submit-area {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .teacher-search-subject-btn {
  min-width: 70px;
  font-size: 11px;
  }
}

/*page list part*/
.page-list {
  text-align: center;
  margin-bottom: 20px;
}

.page-list > a {
  text-decoration: none;
  color: #666666;
}

.page-list > .active {
  color: #299CD3;
}

/*search engine */
/* entire container, keeps perspective */
.flip-container {
  perspective: 1000;
}

/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  width: 99.2%;
  margin-right: 1%;
  height: 230px;
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;
}

/* hide back of pane during swap */
.front, .back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  z-index: 2;
}

/* back, initially hidden pane */
.back {
  background-color: #f5b65b;
  opacity: 0.75;
  transform: rotateY(180deg);
}




/* 小屏幕（平板，大于等于 768px） */
@media (min-width: 768px) {
  .tutor-title {
    font-size: 2.2rem;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 992px) {
  .tutor-title {
    font-size: 2.1rem;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 1200px) {
  .tutor-title {
    font-size: 3rem;
  }
}
