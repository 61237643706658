
.acc-act-underline {
    text-decoration: underline;
}

.acc-red-color {
    color : #ffa183;
}

.active_anim{
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active_anim > div {
  width: 100px;
  height: 100px;
  position: relative;
  perspective: 800px;
}

.active_anim > div > span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-left: 5px solid #5b9aef;
    border-top: 10px transparent solid;
    border-right: 5px solid #5b9aef;

    animation: accAnim 1.5s infinite linear;
}
.active_anim > div > span:nth-child(1) {
    animation: accAnim 1.5s infinite linear;
}
.active_anim > div > span:nth-child(2) {
    animation: accAnim2 1.5s infinite linear;
}
.active_anim > div > span:nth-child(3) {
    animation: accAnim3 1.5s infinite linear;
}

@keyframes accAnim {
    from {
        transform: rotateZ(360deg) rotateX(70deg) rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg) rotateX(70deg) rotateZ(360deg);
    }
}

@keyframes accAnim2 {
    from {
        transform: rotateZ(40deg) rotateX(70deg) rotateZ(0deg);
    }
    to {
        transform: rotateZ(40deg) rotateX(70deg) rotateZ(360deg);
    }
}

@keyframes accAnim3 {
    from {
        transform: rotateZ(90deg) rotateX(70deg) rotateZ(0deg);
    }
    to {
        transform: rotateZ(90deg) rotateX(70deg) rotateZ(360deg);
    }
}