.mooc-explain-wrapper{
    width: 100%;
}

/* mooc explain banner sec */
.mooc-explain-banner{
    width: 100%;
    height: 287px;
    background-image: url('../../../images/index_carousel_1.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
}
.mooc-explain-banner-box{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mooc-explain-banner-main{
    z-index: 100;
}
.mooc-explain-banner-main h2,.mooc-explain-banner-main h4{
    color: #ffffff;
    text-align: center;
}

/* mooc explain content sec */
.mooc-explain-content{
    width: 80%;
    margin: 0 auto;
}
.mooc-explain-content-box{
    width: 55%;
    margin: 0 auto;
    margin-top: 30px;
}
.mooc-explain-contents{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
}
.mooc-explain-content-main{
    margin-bottom: 20px;
}
.mooc-explain-content-main h2{
    color: #5b9aef;
    font-weight: bold;
    font-size: 30px;
}
.mooc-explain-content-main h4{
    font-size: 18px;
}
.mooc-explain-content-button{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.mooc-explain-content-button button{
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 50px
}

@media(max-width: 991px) {
    .mooc-explain-content{
        width: 75%;
    }
    .mooc-explain-content-box{
        width: 100%;
    }
}
@media(max-width: 767px) {
    .mooc-explain-content{
        width: 90%;
    }
}