.r-img-loader-wapper{
    /* text-align: center; */
    /* padding : 10px; */
    display: inline-block;
}
.r-img-loader-btn-area{
    margin-bottom: 10px;
    display: flex;
}
.r-upload-file-name{
    color : #6c767f;
}

.r-img-display-area{
}
.r-img-display-area > ul{
}
.r-img-display-area > ul > li{
    position: relative;
    border-radius: 7px;
    background-color: rgba(0,0,0,.05);
    display: inline-block;
    margin : 0 auto;
    border : 1px solid #ccc;
    box-shadow: 0px 2px 3px #ccc;
    overflow: hidden;
    transition: all 0.3s;
    transition-delay: .5s;
    height: 0px;
    padding : 0px;
}


.r-img-display-card{
    position: relative;
    border-radius: 7px;
    background-color: rgba(0,0,0,.05);
    display: inline-block;
    margin : 0 auto;
    border : 1px solid #ccc;
    box-shadow: 0px 2px 3px #ccc;
    overflow: hidden;
    transition: all 0.3s;
}
.r-img-display-card > button {
    position : absolute;
    top : 3px;
    right : 3px;
    color : #6c767f;
    width : 25px;
    height: 25px;
    border : none;
    border-bottom : 1px solid #ccc;
    border-radius: 50%;
    padding-top: 1px;
    background-color: white;
}
.r-img-display-card > button:hover{
    color : rgb(255, 144, 144);
    border-bottom: 1px solid rgb(255, 144, 144);
}
.r-img-display-card > button:active{
    color : rgb(179, 67, 67);
    border-bottom: 0;
}
.r-img-display-card > img{
    height: 100%;
    max-height: 170px;
}




.r-display-card-show {
    height: 210px !important;
    padding : 20px 10px !important;
}
.r-display-card-hide {
    height: 0px;
    padding : 0px;
}

