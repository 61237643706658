.u-ac-se-wrapper {
    width : 80%;
    margin: 0 auto;
    margin-bottom: 20px;
    min-height: 600px;
}


.u-ac-se-col-type-a {
    display: flex;
    margin-bottom: 8px;
}
.u-ac-se-col-type-a > div {
    box-sizing: border-box;
    width : 33%;
    float: left;
}
.u-ac-se-col-type-a > div.ac-label {
    text-align: right;
    color : #6c767f;
    /* font-size: 2rem; */
    font-size: 20px;
    padding-right: 10px;
}

.u-ac-se-col-type-a > div.ac-input {
    width : 30%;
    margin : 0 5px;
}


.u-ac-se-col-type-b {
    display: flex;
    margin-bottom: 8px;
}

.u-ac-se-col-type-b > div {
    width : 33%;
}
.u-ac-se-col-type-b > div:nth-child(2){
    width : 61.2%;
}

.u-ac-se-col-type-b > div.ac-label {
    text-align: right;
    color : #6c767f;
    /* font-size: 2rem; */
    font-size: 20px;
    padding-right: 10px;
}

.u-ac-se-col-type-b > div.ac-input {
    margin : 0 5px;
}



.u-ac-se-col-type-c {
    display: flex;
    margin-bottom: 8px;
}
.u-ac-se-col-type-c > div {
    width : 33%;
}
.u-ac-se-col-type-c > div.ac-label {
    text-align: right;
    color : #6c767f;
    /* font-size: 2rem; */
    font-size: 20px;
    padding-right: 10px;
}

.u-ac-se-col-type-c > div.ac-input {
    margin : 0 5px;
}



.ac-input-radio{
    display: inline-block;
    width : 70px;
    margin-left: 12px;
    margin-right : 10px;
}
.ac-input-radio > label{
    cursor: pointer;
}
.ac-input-radio > label > span {
    font-size: 14px;
    margin-top: 15px;
}
.ac-input-radio > span {
    float : right;
}





@media  (min-width: 100px) and (max-width : 767px) {
    .u-ac-se-wrapper {
        width : 100%;
        margin-top: 20px;
    }
    .ac-header{
    }
    .u-ac-se-col-type-a {
        display: inline-block;
        margin-bottom: 8px;
        width : 100%;

    }
    .u-ac-se-col-type-a > div.ac-label {
        width : 100%;
        text-align: left;
        padding-left : 10px;
        margin-bottom: 5px;
    }
    .u-ac-se-col-type-a > div.ac-input {
        width : 47%;
        margin : 0 3px;
        box-sizing: border-box;
    }

    .u-ac-se-col-type-b {
        display: inline-block;
        margin-bottom: 8px;
        width : 100%;
    }
    .u-ac-se-col-type-b > div.ac-label {
        width : 100%;
        text-align: left;
        padding-left : 10px;
        margin-bottom: 5px;
    }
    .u-ac-se-col-type-b > div.ac-input {
        width : 95%;
        margin : 0 3px;
        box-sizing: border-box;
    }
    .u-ac-se-col-type-b > div > span {
        width : 95%;
        margin : 0 3px;
        box-sizing: border-box;
    }

    .u-ac-se-col-type-c {
        display: inline-block;
        margin-bottom: 8px;
        width : 100%;
    }

    .u-ac-se-col-type-c > div.ac-label {
        width : 100%;
        text-align: left;
        padding-left : 10px;
        margin-bottom: 5px;
    }

    .u-ac-se-col-type-c > div.ac-input {
        margin : 0 5px;
    }


}





/* FOR THIS CHECK BOX START */
.ac-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
/* Hide the browser's default radio button */
.ac-container > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
  
/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 1px 1px 2px #2196F3;
}
  
/* On mouse-over, add a grey background color */
.ac-container:hover input ~ .checkmark {
    background-color: #ccc;
}
  
/* When the radio button is checked, add a blue background */
.ac-container input:checked ~ .checkmark {
    background-color: #2196F3;
}
  
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the indicator (dot/circle) when checked */
.ac-container input:checked ~ .checkmark:after {
    display: block;
}
  
/* Style the indicator (dot/circle) */
.ac-container .checkmark:after {
    top: 4px;
    left: 4px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
}
/* FOR THIS CHECK BOX END */
