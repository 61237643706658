/* Smartphones (portrait and landscape) --  --  --  --  -- - */

@media screen and (min-width: 304px) and (max-width: 667px) {
    /* Background Color CSS */
    #tim .bg-1 {
        /*background-color: #247BA0;*/
        /*background-repeat: repeat;*/
        /*border-top: 5px solid #16536d;*/
        /*border-bottom: 5px solid #16536d;*/
        padding-top: 40px;
        display: block;
        height: auto;
        width: 100%;
        padding-bottom: 10px;
    }

    #tim .bg-2 {
        background-color: #00171F;
        background-repeat: repeat;
        border-top: 5px solid #145065;
        border-bottom: 5px solid #145065;
        padding-top: 40px;
        display: block;
        height: auto;
        width: 100%;
    }

    #tim .bg-3 {
        background-color: #F25F5C;
        background-repeat: repeat;
        border-top: 5px solid #b13c39;
        border-bottom: 5px solid #b13c39;
        padding-top: 40px;
        display: block;
        height: auto;
        width: 100%;
    }

    #tim .bg-4 {
        background-color: #3D315B;
        background-repeat: repeat;
        border-top: 5px solid #7c5ec7;
        border-bottom: 5px solid #7c5ec7;
        padding-top: 40px;
        display: block;
        height: auto;
        width: 100%;
    }

    #tim .bg-5 {
        background-color: #F6511D;
        background-repeat: repeat;
        border-top: 5px solid #bd3a11;
        border-bottom: 5px solid #bd3a11;
        padding-top: 40px;
        display: block;
        height: auto;
        width: 100%;
    }

    /* Space 50 */
    #tim .space-50 {
        margin-top: 50px;
    }

    /* Team Button */
    #tim .btn-circle {
        width: 100px;
        height: 100px;
        text-align: center;
        padding: 30px 0;
        font-size: 25px;
        line-height: 1.42;
        border-radius: 50%;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        border: transparent;
        background-color: #fff;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    /* Team Images Photo CSS */
    #tim .team-photo .photo-1 img,
    #tim .team-photo .photo-2 img,
    #tim .team-photo .photo-3 img {
        border: 2px solid #fff;
        position: absolute;
    }

    /* Team photo */
    #tim .team-photo {
        margin: 0% 10%;
    }

    /* Photo Position */
    #tim .team-photo .photo-1 {
        margin: 30px 0px !important;
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
    }

    #tim .team-photo .photo-1-plus {
        margin: 30px 0 0;
    }

    #tim .team-photo .photo-2 {
        margin-top: 20px !important;
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
    }

    #tim .team-photo .photo-2-plus {
        margin: 20px 0 0;
    }

    #tim .team-photo .photo-3 {
        margin: 30px 0px !important;
        position: relative !important;
        top: 0 !important;
        right: 0 !important;
    }

    #tim .team-photo .photo-3-plus {
        margin: 85px 0 10px !important;
    }

    #tim .fa.fa-circle, #tim .border-2r, #tim .border-3 {
        display: none !important;
    }

    /* Bob */
    @-webkit-keyframes hvr-bob {
        0% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
        50% {
            -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
        }
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    @keyframes hvr-bob {
        0% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
        50% {
            -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
        }
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    @-webkit-keyframes hvr-bob-float {
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    @keyframes hvr-bob-float {
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    .hvr-bob {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-bob:hover,
    .hvr-bob:focus,
    .hvr-bob:active {
        -webkit-animation-name: hvr-bob-float, hvr-bob;
        animation-name: hvr-bob-float, hvr-bob;
        -webkit-animation-duration: .3s, 1.5s;
        animation-duration: .3s, 1.5s;
        -webkit-animation-delay: 0s, .3s;
        animation-delay: 0s, .3s;
        -webkit-animation-timing-function: ease-out, ease-in-out;
        animation-timing-function: ease-out, ease-in-out;
        -webkit-animation-iteration-count: 1, infinite;
        animation-iteration-count: 1, infinite;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-direction: normal, alternate;
        animation-direction: normal, alternate;
    }

    /* Pulse */
    @-webkit-keyframes hvr-pulse {
        25% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
        75% {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
    @keyframes hvr-pulse {
        25% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
        75% {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
    .hvr-pulse {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-pulse:hover,
    .hvr-pulse:focus,
    .hvr-pulse:active {
        -webkit-animation-name: hvr-pulse;
        animation-name: hvr-pulse;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    /* Push */
    @-webkit-keyframes hvr-push {
        50% {
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    @keyframes hvr-push {
        50% {
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    .hvr-push {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-push:hover,
    .hvr-push:focus,
    .hvr-push:active {
        -webkit-animation-name: hvr-push;
        animation-name: hvr-push;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }

    /* Buzz Out */
    @-webkit-keyframes hvr-buzz-out {
        10% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        20% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        30% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        40% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        50% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        60% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        70% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        80% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        90% {
            -webkit-transform: translateX(1px) rotate(0);
            transform: translateX(1px) rotate(0);
        }
        100% {
            -webkit-transform: translateX(-1px) rotate(0);
            transform: translateX(-1px) rotate(0);
        }
    }
    @keyframes hvr-buzz-out {
        10% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        20% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        30% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        40% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        50% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        60% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        70% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        80% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        90% {
            -webkit-transform: translateX(1px) rotate(0);
            transform: translateX(1px) rotate(0);
        }
        100% {
            -webkit-transform: translateX(-1px) rotate(0);
            transform: translateX(-1px) rotate(0);
        }
    }
    .hvr-buzz-out {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-buzz-out:hover,
    .hvr-buzz-out:focus,
    .hvr-buzz-out:active {
        -webkit-animation-name: hvr-buzz-out;
        animation-name: hvr-buzz-out;
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }

    /* Float */
    .hvr-float {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    .hvr-float:hover,
    .hvr-float:focus,
    .hvr-float:active {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    /* Team Social Icon */
    #tim .social-icon-1 li a i {
        color: #fff;
        font-size: 25px;
        padding: 10px 0px;
    }

    #tim .social-icon-2 li a i {
        color: #fff;
        font-size: 25px;
        padding: 10px 0px;
    }

    #tim .social-icon-3 li a i {
        color: #fff;
        font-size: 25px;
        padding: 20px 0px;
    }

    /* Hover Animation First Team Style */
    #tim .ih-item {
        position: relative;
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item,
    #tim .ih-item * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    #tim .ih-item a {
        color: #333;
    }

    #tim .ih-item a:hover {
        text-decoration: none;
    }

    #tim .ih-item img {
        width: 100%;
        height: 100%;
    }

    #tim .ih-item.circle {
        position: relative;
        width: 220px;
        height: 220px;
        border-radius: 50%;
        margin: 0 auto;
    }

    #tim .ih-item.circle .img {
        position: relative;
        width: 220px;
        height: 220px;
        border-radius: 50%;
    }

    #tim .ih-item.circle .img:before {
        position: absolute;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: inset 0 0 0 16px rgba(255, 255, 255, 0.6), 0 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle .img img {
        border-radius: 50%;
    }

    #tim .ih-item.circle .info {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        border-radius: 50%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    #tim .ih-item.square {
        position: relative;
        width: 316px;
        height: 216px;
        border: 8px solid #fff;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.square .info {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        font-family: 'Open Sans', sans-serif;
    }

    #tim .ih-item.circle.effect1 .spinner {
        width: 230px;
        height: 230px;
        border: 10px solid #FFE066;
        border-right-color: #F25F5C;
        border-bottom-color: #F25F5C;
        border-radius: 50%;
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;
    }

    #tim .ih-item.circle.effect1 .img {
        position: absolute;
        top: 10px;
        bottom: 0;
        left: 10px;
        right: 0;
        width: auto;
        height: auto;
    }

    #tim .ih-item.circle.effect1 .img:before {
        display: none;
    }

    #tim .ih-item.circle.effect1.colored .info {
        background: #1a4a72;
        background: rgba(26, 74, 114, 0.6);
    }

    #tim .ih-item.circle.effect1 .info {
        top: 10px;
        bottom: 0;
        left: 10px;
        right: 0;
        background: #333333;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;
    }

    #tim .ih-item.circle.effect1 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 25px;
        padding: 75px 0 0 0;
        height: 110px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect1 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        font-family: 'Open Sans', sans-serif;
    }

    #tim .ih-item.circle.effect1 a:hover .spinner {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    #tim .ih-item.circle.effect1 a:hover .info {
        opacity: 1;
    }

    /* Hover Animation Second Team Style */
    #tim .ih-item.circle.effect10 .img {
        z-index: 11;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect10.colored .info {
        background: #1a4a72;
    }

    #tim .ih-item.circle.effect10 .info {
        background: #00A8E8;
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect10 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect10 .info p {
        color: #fff;
        font-style: italic;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect10 a:hover .info {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    #tim .ih-item.circle.effect10.top_to_bottom .info h3 {
        margin: 0 30px;
        padding: 50px 0 0 0;
        height: 78px;
    }

    #tim .ih-item.circle.effect10.top_to_bottom .info p {
        margin: 0 30px;
        padding: 5px;
    }

    #tim .ih-item.circle.effect10.top_to_bottom a:hover .img {
        -webkit-transform: translateY(50px) scale(0.5);
        -moz-transform: translateY(50px) scale(0.5);
        -ms-transform: translateY(50px) scale(0.5);
        -o-transform: translateY(50px) scale(0.5);
        transform: translateY(50px) scale(0.5);
    }

    #tim .ih-item.circle.effect10.bottom_to_top .info h3 {
        margin: 95px 30px 0;
        padding: 25px 0 0 0;
        height: 78px;
    }

    #tim .ih-item.circle.effect10.bottom_to_top .info p {
        margin: 0 30px;
        padding: 5px;
    }

    #tim .ih-item.circle.effect10.bottom_to_top a:hover .img {
        -webkit-transform: translateY(-50px) scale(0.5);
        -moz-transform: translateY(-50px) scale(0.5);
        -ms-transform: translateY(-50px) scale(0.5);
        -o-transform: translateY(-50px) scale(0.5);
        transform: translateY(-50px) scale(0.5);
    }

    /* Hover Animation Third Team Style */
    #tim .ih-item.circle.effect19.colored .info {
        background: #1a4a72;
        background: rgba(26, 74, 114, 0.6);
    }

    #tim .ih-item.circle.effect19 .info {
        background: #333333;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect19 .info h3 {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 30px;
        padding: 105px 0 0 0;
        height: 140px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect19 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect19 a:hover .info {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    /* Hover Animation Fourth Team Style */
    #tim .ih-item.circle.effect20 {
        -webkit-perspective: 900px;
        -moz-perspective: 900px;
        perspective: 900px;
    }

    #tim .ih-item.circle.effect20 .img {
        -webkit-transition: all 0.35s linear;
        -moz-transition: all 0.35s linear;
        transition: all 0.35s linear;
        -webkit-transform-origin: 50% 0%;
        -moz-transform-origin: 50% 0%;
        -ms-transform-origin: 50% 0%;
        -o-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
    }

    #tim .ih-item.circle.effect20.colored .info .info-back {
        background: #1a4a72;
    }

    #tim .ih-item.circle.effect20 .info {
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    #tim .ih-item.circle.effect20 .info .info-back {
        opacity: 1;
        visibility: hidden;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background: #444B6E;
        -webkit-transition: all 0.35s linear;
        -moz-transition: all 0.35s linear;
        transition: all 0.35s linear;
        -webkit-transform-origin: 50% 0%;
        -moz-transform-origin: 50% 0%;
        -ms-transform-origin: 50% 0%;
        -o-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    #tim .ih-item.circle.effect20 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 30px;
        padding: 75px 0 0 0;
        height: 110px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect20 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect20 a:hover .img {
        opacity: 0;
    }

    #tim .ih-item.circle.effect20 a:hover .info .info-back {
        opacity: 1;
        visibility: visible;
    }

    #tim .ih-item.circle.effect20.top_to_bottom .info .info-back {
        -webkit-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -moz-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -ms-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -o-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
    }

    #tim .ih-item.circle.effect20.top_to_bottom a:hover .img {
        -webkit-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -moz-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -ms-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -o-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
    }

    #tim .ih-item.circle.effect20.top_to_bottom a:hover .info .info-back {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        -moz-transform: rotate3d(1, 0, 0, 0deg);
        -ms-transform: rotate3d(1, 0, 0, 0deg);
        -o-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg);
    }

    #tim .ih-item.circle.effect20.bottom_to_top .info .info-back {
        -webkit-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -moz-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -ms-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -o-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
    }

    #tim .ih-item.circle.effect20.bottom_to_top a:hover .img {
        -webkit-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -moz-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -ms-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -o-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
    }

    #tim .ih-item.circle.effect20.bottom_to_top a:hover .info .info-back {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        -moz-transform: rotate3d(1, 0, 0, 0deg);
        -ms-transform: rotate3d(1, 0, 0, 0deg);
        -o-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg);
    }

    /* Hover Animation Five Team Style */
    #tim .ih-item.circle.effect6 .img {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect6.colored .info {
        background: #1a4a72;
    }

    #tim .ih-item.circle.effect6 .info {
        background: #0D2C54;
        opacity: 0;
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect6 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 30px;
        padding: 75px 0 0 0;
        height: 110px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect6 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect6.scale_up .info {
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    #tim .ih-item.circle.effect6.scale_up a:hover .img {
        opacity: 0;
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }

    #tim .ih-item.circle.effect6.scale_up a:hover .info {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    /* Styles */
}

/* Smartphones (landscape) --  --  --  --  -- - */

@media screen and (min-width: 321px) {
    /* Styles */
    /*#tim .fa.fa-circle, #tim .border-2r, #tim .border-3 {
    display: none !important;
    }*/
}

/* Smartphones (portrait) --  --  --  --  -- - */

@media screen and (max-width: 320px) {
    /* Styles */
}

/* iPads (portrait and landscape) --  --  --  --  -- - */

@media screen and (min-width: 668px) and (max-width: 1223px) {
    /* Styles */
    /* Background Color CSS */
    #tim .bg-1 {
        /*background-color: #247BA0;*/
        /*background-repeat: repeat;*/
        /*border-top: 5px solid #16536d;*/
        /*border-bottom: 5px solid #16536d;*/
        padding-top: 40px;
        display: block;
        height: 695px;
        width: 100%;
    }

    #tim .bg-2 {
        background-color: #00171F;
        background-repeat: repeat;
        border-top: 5px solid #145065;
        border-bottom: 5px solid #145065;
        padding-top: 40px;
        display: block;
        height: 695px;
        width: 100%;
    }

    #tim .bg-3 {
        background-color: #F25F5C;
        background-repeat: repeat;
        border-top: 5px solid #b13c39;
        border-bottom: 5px solid #b13c39;
        padding-top: 40px;
        display: block;
        height: 695px;
        width: 100%;
    }

    #tim .bg-4 {
        background-color: #3D315B;
        background-repeat: repeat;
        border-top: 5px solid #7c5ec7;
        border-bottom: 5px solid #7c5ec7;
        padding-top: 40px;
        display: block;
        height: 695px;
        width: 100%;
    }

    #tim .bg-5 {
        background-color: #F6511D;
        background-repeat: repeat;
        border-top: 5px solid #bd3a11;
        border-bottom: 5px solid #bd3a11;
        padding-top: 40px;
        display: block;
        height: 695px;
        width: 100%;
    }

    /* Space 50 */
    #tim .space-50 {
        margin-top: 50px;
    }

    /* Team Button */
    #tim .btn-circle {
        width: 100px;
        height: 100px;
        text-align: center;
        padding: 30px 0;
        font-size: 25px;
        line-height: 1.42;
        border-radius: 50%;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        border: transparent;
        background-color: #fff;
        text-transform: uppercase;
    }

    /* Border CSS */
    #tim .border-1 {
        border: 1px solid #000;
        width: 1px;
        height: 250px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 29%;
        left: 380px;
    }

    #tim .border-2 {
        border: 1px solid #000;
        width: 1px;
        height: 250px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: 29%;
        right: 380px;
    }

    #tim .border-3 {
        border: 1px solid #000;
        width: 1px;
        height: 110px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        top: 100%;
        right: 485px;
        font-family: 'Open Sans', sans-serif;
    }

    /* Team Images Photo CSS */
    #tim .team-photo .photo-1 img,
    #tim .team-photo .photo-2 img,
    #tim .team-photo .photo-3 img {
        border: 2px solid #fff;
        position: absolute;
    }

    /* Photo Position */
    #tim .team-photo .photo-1 {
        position: absolute;
        top: 203px;
        left: 100px;
    }

    #tim .team-photo .photo-2 {
        position: absolute;
        top: 340px;
        left: 370px;
    }

    #tim .team-photo .photo-3 {
        position: absolute;
        top: 203px;
        right: 100px;
    }

    /* Bob */
    @-webkit-keyframes hvr-bob {
        0% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
        50% {
            -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
        }
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    @keyframes hvr-bob {
        0% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
        50% {
            -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
        }
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    @-webkit-keyframes hvr-bob-float {
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    @keyframes hvr-bob-float {
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    .hvr-bob {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-bob:hover,
    .hvr-bob:focus,
    .hvr-bob:active {
        -webkit-animation-name: hvr-bob-float, hvr-bob;
        animation-name: hvr-bob-float, hvr-bob;
        -webkit-animation-duration: .3s, 1.5s;
        animation-duration: .3s, 1.5s;
        -webkit-animation-delay: 0s, .3s;
        animation-delay: 0s, .3s;
        -webkit-animation-timing-function: ease-out, ease-in-out;
        animation-timing-function: ease-out, ease-in-out;
        -webkit-animation-iteration-count: 1, infinite;
        animation-iteration-count: 1, infinite;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-direction: normal, alternate;
        animation-direction: normal, alternate;
    }

    /* Pulse */
    @-webkit-keyframes hvr-pulse {
        25% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
        75% {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
    @keyframes hvr-pulse {
        25% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
        75% {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
    .hvr-pulse {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-pulse:hover,
    .hvr-pulse:focus,
    .hvr-pulse:active {
        -webkit-animation-name: hvr-pulse;
        animation-name: hvr-pulse;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    /* Push */
    @-webkit-keyframes hvr-push {
        50% {
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    @keyframes hvr-push {
        50% {
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    .hvr-push {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-push:hover,
    .hvr-push:focus,
    .hvr-push:active {
        -webkit-animation-name: hvr-push;
        animation-name: hvr-push;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }

    /* Buzz Out */
    @-webkit-keyframes hvr-buzz-out {
        10% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        20% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        30% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        40% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        50% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        60% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        70% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        80% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        90% {
            -webkit-transform: translateX(1px) rotate(0);
            transform: translateX(1px) rotate(0);
        }
        100% {
            -webkit-transform: translateX(-1px) rotate(0);
            transform: translateX(-1px) rotate(0);
        }
    }
    @keyframes hvr-buzz-out {
        10% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        20% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        30% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        40% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        50% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        60% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        70% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        80% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        90% {
            -webkit-transform: translateX(1px) rotate(0);
            transform: translateX(1px) rotate(0);
        }
        100% {
            -webkit-transform: translateX(-1px) rotate(0);
            transform: translateX(-1px) rotate(0);
        }
    }
    .hvr-buzz-out {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-buzz-out:hover,
    .hvr-buzz-out:focus,
    .hvr-buzz-out:active {
        -webkit-animation-name: hvr-buzz-out;
        animation-name: hvr-buzz-out;
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }

    /* Float */
    .hvr-float {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    .hvr-float:hover,
    .hvr-float:focus,
    .hvr-float:active {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    /* Team Social Icon */
    #tim .social-icon-1 li a i {
        color: #fff;
        font-size: 25px;
        padding: 10px 0px;
    }

    #tim .social-icon-2 li a i {
        color: #fff;
        font-size: 25px;
        padding: 10px 0px;
    }

    #tim .social-icon-3 li a i {
        color: #fff;
        font-size: 25px;
        padding: 20px 0px;
    }

    /* Hover Animation First Team Style */
    #tim .ih-item {
        position: relative;
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item,
    #tim .ih-item * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    #tim .ih-item a {
        color: #333;
    }

    #tim .ih-item a:hover {
        text-decoration: none;
    }

    #tim .ih-item img {
        width: 100%;
        height: 100%;
    }

    #tim .ih-item.circle {
        position: relative;
        width: 220px;
        height: 220px;
        border-radius: 50%;
    }

    #tim .ih-item.circle .img {
        position: relative;
        width: 220px;
        height: 220px;
        border-radius: 50%;
    }

    #tim .ih-item.circle .img:before {
        position: absolute;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: inset 0 0 0 16px rgba(255, 255, 255, 0.6), 0 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle .img img {
        border-radius: 50%;
    }

    #tim .ih-item.circle .info {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        border-radius: 50%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    #tim .ih-item.square {
        position: relative;
        width: 316px;
        height: 216px;
        border: 8px solid #fff;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.square .info {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        font-family: 'Open Sans', sans-serif;
    }

    #tim .ih-item.circle.effect1 .spinner {
        width: 230px;
        height: 230px;
        border: 10px solid #FFE066;
        border-right-color: #F25F5C;
        border-bottom-color: #F25F5C;
        border-radius: 50%;
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;
    }

    #tim .ih-item.circle.effect1 .img {
        position: absolute;
        top: 10px;
        bottom: 0;
        left: 10px;
        right: 0;
        width: auto;
        height: auto;
    }

    #tim .ih-item.circle.effect1 .img:before {
        display: none;
    }

    #tim .ih-item.circle.effect1.colored .info {
        background: #1a4a72;
        background: rgba(26, 74, 114, 0.6);
    }

    #tim .ih-item.circle.effect1 .info {
        top: 10px;
        bottom: 0;
        left: 10px;
        right: 0;
        background: #333333;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;
    }

    #tim .ih-item.circle.effect1 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 25px;
        padding: 75px 0 0 0;
        height: 110px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect1 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        font-family: 'Open Sans', sans-serif;
    }

    #tim .ih-item.circle.effect1 a:hover .spinner {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    #tim .ih-item.circle.effect1 a:hover .info {
        opacity: 1;
    }

    /* Hover Animation Second Team Style */
    #tim .ih-item.circle.effect10 .img {
        z-index: 11;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect10.colored .info {
        background: #1a4a72;
    }

    #tim .ih-item.circle.effect10 .info {
        background: #00A8E8;
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect10 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect10 .info p {
        color: #fff;
        font-style: italic;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect10 a:hover .info {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    #tim .ih-item.circle.effect10.top_to_bottom .info h3 {
        margin: 0 30px;
        padding: 50px 0 0 0;
        height: 78px;
    }

    #tim .ih-item.circle.effect10.top_to_bottom .info p {
        margin: 0 30px;
        padding: 5px;
    }

    #tim .ih-item.circle.effect10.top_to_bottom a:hover .img {
        -webkit-transform: translateY(50px) scale(0.5);
        -moz-transform: translateY(50px) scale(0.5);
        -ms-transform: translateY(50px) scale(0.5);
        -o-transform: translateY(50px) scale(0.5);
        transform: translateY(50px) scale(0.5);
    }

    #tim .ih-item.circle.effect10.bottom_to_top .info h3 {
        margin: 95px 30px 0;
        padding: 25px 0 0 0;
        height: 78px;
    }

    #tim .ih-item.circle.effect10.bottom_to_top .info p {
        margin: 0 30px;
        padding: 5px;
    }

    #tim .ih-item.circle.effect10.bottom_to_top a:hover .img {
        -webkit-transform: translateY(-50px) scale(0.5);
        -moz-transform: translateY(-50px) scale(0.5);
        -ms-transform: translateY(-50px) scale(0.5);
        -o-transform: translateY(-50px) scale(0.5);
        transform: translateY(-50px) scale(0.5);
    }

    /* Hover Animation Third Team Style */
    #tim .ih-item.circle.effect19.colored .info {
        background: #1a4a72;
        background: rgba(26, 74, 114, 0.6);
    }

    #tim .ih-item.circle.effect19 .info {
        background: #333333;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect19 .info h3 {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 30px;
        padding: 105px 0 0 0;
        height: 140px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect19 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect19 a:hover .info {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    /* Hover Animation Fourth Team Style */
    #tim .ih-item.circle.effect20 {
        -webkit-perspective: 900px;
        -moz-perspective: 900px;
        perspective: 900px;
    }

    #tim .ih-item.circle.effect20 .img {
        -webkit-transition: all 0.35s linear;
        -moz-transition: all 0.35s linear;
        transition: all 0.35s linear;
        -webkit-transform-origin: 50% 0%;
        -moz-transform-origin: 50% 0%;
        -ms-transform-origin: 50% 0%;
        -o-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
    }

    #tim .ih-item.circle.effect20.colored .info .info-back {
        background: #1a4a72;
    }

    #tim .ih-item.circle.effect20 .info {
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    #tim .ih-item.circle.effect20 .info .info-back {
        opacity: 1;
        visibility: hidden;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background: #444B6E;
        -webkit-transition: all 0.35s linear;
        -moz-transition: all 0.35s linear;
        transition: all 0.35s linear;
        -webkit-transform-origin: 50% 0%;
        -moz-transform-origin: 50% 0%;
        -ms-transform-origin: 50% 0%;
        -o-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    #tim .ih-item.circle.effect20 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 30px;
        padding: 75px 0 0 0;
        height: 110px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect20 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect20 a:hover .img {
        opacity: 0;
    }

    #tim .ih-item.circle.effect20 a:hover .info .info-back {
        opacity: 1;
        visibility: visible;
    }

    #tim .ih-item.circle.effect20.top_to_bottom .info .info-back {
        -webkit-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -moz-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -ms-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -o-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
    }

    #tim .ih-item.circle.effect20.top_to_bottom a:hover .img {
        -webkit-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -moz-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -ms-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -o-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
    }

    #tim .ih-item.circle.effect20.top_to_bottom a:hover .info .info-back {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        -moz-transform: rotate3d(1, 0, 0, 0deg);
        -ms-transform: rotate3d(1, 0, 0, 0deg);
        -o-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg);
    }

    #tim .ih-item.circle.effect20.bottom_to_top .info .info-back {
        -webkit-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -moz-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -ms-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -o-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
    }

    #tim .ih-item.circle.effect20.bottom_to_top a:hover .img {
        -webkit-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -moz-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -ms-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -o-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
    }

    #tim .ih-item.circle.effect20.bottom_to_top a:hover .info .info-back {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        -moz-transform: rotate3d(1, 0, 0, 0deg);
        -ms-transform: rotate3d(1, 0, 0, 0deg);
        -o-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg);
    }

    /* Hover Animation Five Team Style */
    #tim .ih-item.circle.effect6 .img {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect6.colored .info {
        background: #1a4a72;
    }

    #tim .ih-item.circle.effect6 .info {
        background: #0D2C54;
        opacity: 0;
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect6 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 30px;
        padding: 75px 0 0 0;
        height: 110px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect6 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect6.scale_up .info {
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    #tim .ih-item.circle.effect6.scale_up a:hover .img {
        opacity: 0;
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }

    #tim .ih-item.circle.effect6.scale_up a:hover .info {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    #tim .border-0l {
        border: 1px solid #fff;
        width: 1px;
        height: 360px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        top: -133%;
        left: 363PX;
    }

    #tim .fa.fa-circle, #tim .border-2r, #tim .border-3, #tim .border-0l, #tim .border-0r, #tim .border-1l, #tim .cr, #tim .cr1 {
        display: none !important;
    }

    #tim .border-1 {
        border: 1px solid #000;
        width: 1px;
        height: 250px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 29%;
        left: 447px;
    }

    #tim .border-2 {
        border: 1px solid #000;
        width: 1px;
        height: 250px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: 29%;
        right: 447px;
    }

}

/* iPads (landscape) --  --  --  --  -- - */

/*@media and (min-width:*/

/*668px*/

/*) and*/

/*(max-width:*/

/*1223px*/

/*) and*/

/*(orientation: landscape*/

/*) {*/
/*}*/
/* iPads (portrait) --  --  --  --  -- - */
@media screen and (min-width: 668px) and (max-width: 1223px) and (orientation: portrait) {
    /* Styles */
    /* Photo Position */
    #tim .team-photo .photo-1 {
        position: absolute;
        top: 203px;
        left: 50px;
    }

    #tim .team-photo .photo-2 {
        position: absolute;
        top: 340px;
        left: 265px;
    }

    #tim .team-photo .photo-3 {
        position: absolute;
        top: 203px;
        right: 50px;
    }

    #tim .border-1 {
        left: 270px;
    }

    #tim .border-2 {
        right: 270px;
    }

    #tim .border-3 {
        right: 375px;
    }
}

/* Desktops and laptops --  --  --  --  -- - */
@media screen and (min-width: 1224px) {
    /* Styles */
    /* Background Color CSS */
    #tim .bg-1 {
        /*background-color: #247BA0;*/
        /*background-repeat: repeat;*/
        /*border-top: 5px solid #16536d;*/
        /*border-bottom: 5px solid #16536d;*/
        padding-top: 40px;
        display: block;
        height: 695px;
        width: 100%;
    }

    #tim .bg-2 {
        background-color: #00171F;
        background-repeat: repeat;
        border-top: 5px solid #145065;
        border-bottom: 5px solid #145065;
        padding-top: 40px;
        display: block;
        height: 695px;
        width: 100%;
    }

    #tim .bg-3 {
        background-color: #F25F5C;
        background-repeat: repeat;
        border-top: 5px solid #b13c39;
        border-bottom: 5px solid #b13c39;
        padding-top: 40px;
        display: block;
        height: 695px;
        width: 100%;
    }

    #tim .bg-4 {
        background-color: #3D315B;
        background-repeat: repeat;
        border-top: 5px solid #7c5ec7;
        border-bottom: 5px solid #7c5ec7;
        padding-top: 40px;
        display: block;
        height: 695px;
        width: 100%;
    }

    #tim .bg-5 {
        background-color: #F6511D;
        background-repeat: repeat;
        border-top: 5px solid #bd3a11;
        border-bottom: 5px solid #bd3a11;
        padding-top: 40px;
        display: block;
        height: 695px;
        width: 100%;
    }

    /* Space 50 */
    #tim .space-50 {
        margin-top: 50px;
    }

    /* Team Button */
    #tim .btn-circle {
        width: 100px;
        height: 100px;
        text-align: center;
        padding: 30px 0;
        font-size: 25px;
        line-height: 1.42;
        border-radius: 50%;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        border: transparent;
        background-color: #fff;
        text-transform: uppercase;
    }

    /* Border CSS */
    #tim .border-0l {
        border: 1px solid #fff;
        width: 1px;
        height: 360px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        top: -133%;
        left: 363PX;
    }

    #tim .cl {
        position: relative;
        right: 331px;
        top: -2px;
        color: #fff;
    }

    #tim .cl1 {
        position: relative;
        right: 234px;
        top: -2px;
        color: #fff;
    }

    #tim .border-0r {
        border: 1px solid #fff;
        width: 1px;
        height: 360px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        top: -134%;
        right: 355px;
    }

    #tim .cr {
        position: relative;
        left: 351px;
        top: -3px;
        color: #fff;
    }

    #tim .cr1 {
        position: relative;
        left: 240px;
        top: -2px;
        color: #fff;
    }

    #tim .border-1 {
        border: 1px solid #000;
        width: 1px;
        height: 157px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 83px;
        left: 447px;
    }

    #tim .border-1l {
        border: 1px solid #000;
        width: 1px;
        height: 160px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        top: 48%;
        left: 292px;
    }

    #tim .border-2 {
        border: 1px solid #000;
        width: 1px;
        height: 157px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: 83px;
        right: 447px;
    }

    #tim .border-2r {
        border: 1px solid #000;
        width: 1px;
        height: 163px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        top: 45%;
        right: 274px;
    }

    #tim .border-3 {
        border: 1px solid #000;
        width: 1px;
        height: 110px;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        display: block;
        top: 100%;
        right: 581px;
        font-family: 'Open Sans', sans-serif;
    }

    /* Team Images Photo CSS */
    #tim .team-photo .photo-1 img,
    #tim .team-photo .photo-2 img,
    #tim .team-photo .photo-3 img {
        border: 2px solid #fff;
        position: absolute;
    }

    /* Photo Position */
    #tim .team-photo .photo-1 {
        position: absolute;
        top: 208px;
        left: 100px;
        background-color: #bfdb9f;
        width: 300px;
        height: 300px;
    }

    #tim .team-photo .photo-2 {
        position: absolute;
        top: 208px;
        left: 438px;
        width: 300px;
        height: 300px;
        background-color: #f9b462;
    }

    #tim .team-photo .photo-3 {
        position: absolute;
        top: 208px;
        right: 100px;
        width: 300px;
        height: 300px;
        background-color: #ffa183;
    }

    /* Bob */
    @-webkit-keyframes hvr-bob {
        0% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
        50% {
            -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
        }
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    @keyframes hvr-bob {
        0% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
        50% {
            -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
        }
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    @-webkit-keyframes hvr-bob-float {
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    @keyframes hvr-bob-float {
        100% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
        }
    }
    .hvr-bob {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-bob:hover,
    .hvr-bob:focus,
    .hvr-bob:active {
        -webkit-animation-name: hvr-bob-float, hvr-bob;
        animation-name: hvr-bob-float, hvr-bob;
        -webkit-animation-duration: .3s, 1.5s;
        animation-duration: .3s, 1.5s;
        -webkit-animation-delay: 0s, .3s;
        animation-delay: 0s, .3s;
        -webkit-animation-timing-function: ease-out, ease-in-out;
        animation-timing-function: ease-out, ease-in-out;
        -webkit-animation-iteration-count: 1, infinite;
        animation-iteration-count: 1, infinite;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-direction: normal, alternate;
        animation-direction: normal, alternate;
    }

    /* Pulse */
    @-webkit-keyframes hvr-pulse {
        25% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
        75% {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
    @keyframes hvr-pulse {
        25% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
        75% {
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
    .hvr-pulse {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-pulse:hover,
    .hvr-pulse:focus,
    .hvr-pulse:active {
        -webkit-animation-name: hvr-pulse;
        animation-name: hvr-pulse;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    /* Push */
    @-webkit-keyframes hvr-push {
        50% {
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    @keyframes hvr-push {
        50% {
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    .hvr-push {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-push:hover,
    .hvr-push:focus,
    .hvr-push:active {
        -webkit-animation-name: hvr-push;
        animation-name: hvr-push;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }

    /* Buzz Out */
    @-webkit-keyframes hvr-buzz-out {
        10% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        20% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        30% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        40% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        50% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        60% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        70% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        80% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        90% {
            -webkit-transform: translateX(1px) rotate(0);
            transform: translateX(1px) rotate(0);
        }
        100% {
            -webkit-transform: translateX(-1px) rotate(0);
            transform: translateX(-1px) rotate(0);
        }
    }
    @keyframes hvr-buzz-out {
        10% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        20% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        30% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        40% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
        50% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        60% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        70% {
            -webkit-transform: translateX(2px) rotate(1deg);
            transform: translateX(2px) rotate(1deg);
        }
        80% {
            -webkit-transform: translateX(-2px) rotate(-1deg);
            transform: translateX(-2px) rotate(-1deg);
        }
        90% {
            -webkit-transform: translateX(1px) rotate(0);
            transform: translateX(1px) rotate(0);
        }
        100% {
            -webkit-transform: translateX(-1px) rotate(0);
            transform: translateX(-1px) rotate(0);
        }
    }
    .hvr-buzz-out {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
    }

    .hvr-buzz-out:hover,
    .hvr-buzz-out:focus,
    .hvr-buzz-out:active {
        -webkit-animation-name: hvr-buzz-out;
        animation-name: hvr-buzz-out;
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }

    /* Float */
    .hvr-float {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    .hvr-float:hover,
    .hvr-float:focus,
    .hvr-float:active {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    /* Team Social Icon */
    #tim .social-icon-1 li a i {
        color: #fff;
        font-size: 25px;
        padding: 10px 0px;
    }

    #tim .social-icon-2 li a i {
        color: #fff;
        font-size: 25px;
        padding: 10px 0px;
    }

    #tim .social-icon-3 li a i {
        color: #fff;
        font-size: 25px;
        padding: 20px 0px;
    }

    /* Hover Animation First Team Style */
    #tim .ih-item {
        position: relative;
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item,
    #tim .ih-item * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    #tim .ih-item a {
        color: #333;
    }

    #tim .ih-item a:hover {
        text-decoration: none;
    }

    #tim .ih-item img {
        width: 100%;
        height: 100%;
    }

    #tim .ih-item.circle {
        position: relative;
        width: 220px;
        height: 220px;
        border-radius: 50%;
    }

    #tim .ih-item.circle .img {
        position: relative;
        width: 220px;
        height: 220px;
        border-radius: 50%;
    }

    #tim .ih-item.circle .img:before {
        position: absolute;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: inset 0 0 0 16px rgba(255, 255, 255, 0.6), 0 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle .img img {
        border-radius: 50%;
    }

    #tim .ih-item.circle .info {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        border-radius: 50%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    #tim .ih-item.square {
        position: relative;
        width: 316px;
        height: 216px;
        border: 8px solid #fff;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.square .info {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        font-family: 'Open Sans', sans-serif;
    }

    #tim .ih-item.circle.effect1 .spinner {
        width: 230px;
        height: 230px;
        border: 10px solid #FFE066;
        border-right-color: #F25F5C;
        border-bottom-color: #F25F5C;
        border-radius: 50%;
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;
    }

    #tim .ih-item.circle.effect1 .img {
        position: absolute;
        top: 10px;
        bottom: 0;
        left: 10px;
        right: 0;
        width: auto;
        height: auto;
    }

    #tim .ih-item.circle.effect1 .img:before {
        display: none;
    }

    #tim .ih-item.circle.effect1.colored .info {
        background: #1a4a72;
        background: rgba(26, 74, 114, 0.6);
    }

    #tim .ih-item.circle.effect1 .info {
        top: 10px;
        bottom: 0;
        left: 10px;
        right: 0;
        background: #333333;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;
    }

    #tim .ih-item.circle.effect1 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 25px;
        padding: 75px 0 0 0;
        height: 110px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect1 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        font-family: 'Open Sans', sans-serif;
    }

    #tim .ih-item.circle.effect1 a:hover .spinner {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    #tim .ih-item.circle.effect1 a:hover .info {
        opacity: 1;
    }

    /* Hover Animation Second Team Style */
    #tim .ih-item.circle.effect10 .img {
        z-index: 11;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect10.colored .info {
        background: #1a4a72;
    }

    #tim .ih-item.circle.effect10 .info {
        background: #00A8E8;
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect10 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect10 .info p {
        color: #fff;
        font-style: italic;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect10 a:hover .info {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    #tim .ih-item.circle.effect10.top_to_bottom .info h3 {
        margin: 0 30px;
        padding: 50px 0 0 0;
        height: 78px;
    }

    #tim .ih-item.circle.effect10.top_to_bottom .info p {
        margin: 0 30px;
        padding: 5px;
    }

    #tim .ih-item.circle.effect10.top_to_bottom a:hover .img {
        -webkit-transform: translateY(50px) scale(0.5);
        -moz-transform: translateY(50px) scale(0.5);
        -ms-transform: translateY(50px) scale(0.5);
        -o-transform: translateY(50px) scale(0.5);
        transform: translateY(50px) scale(0.5);
    }

    #tim .ih-item.circle.effect10.bottom_to_top .info h3 {
        margin: 95px 30px 0;
        padding: 25px 0 0 0;
        height: 78px;
    }

    #tim .ih-item.circle.effect10.bottom_to_top .info p {
        margin: 0 30px;
        padding: 5px;
    }

    #tim .ih-item.circle.effect10.bottom_to_top a:hover .img {
        -webkit-transform: translateY(-50px) scale(0.5);
        -moz-transform: translateY(-50px) scale(0.5);
        -ms-transform: translateY(-50px) scale(0.5);
        -o-transform: translateY(-50px) scale(0.5);
        transform: translateY(-50px) scale(0.5);
    }

    /* Hover Animation Third Team Style */
    #tim .ih-item.circle.effect19.colored .info {
        background: #1a4a72;
        background: rgba(26, 74, 114, 0.6);
    }

    #tim .ih-item.circle.effect19 .info {
        background: #333333;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect19 .info h3 {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 30px;
        padding: 105px 0 0 0;
        height: 140px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect19 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect19 a:hover .info {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    /* Hover Animation Fourth Team Style */
    #tim .ih-item.circle.effect20 {
        -webkit-perspective: 900px;
        -moz-perspective: 900px;
        perspective: 900px;
    }

    #tim .ih-item.circle.effect20 .img {
        -webkit-transition: all 0.35s linear;
        -moz-transition: all 0.35s linear;
        transition: all 0.35s linear;
        -webkit-transform-origin: 50% 0%;
        -moz-transform-origin: 50% 0%;
        -ms-transform-origin: 50% 0%;
        -o-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
    }

    #tim .ih-item.circle.effect20.colored .info .info-back {
        background: #1a4a72;
    }

    #tim .ih-item.circle.effect20 .info {
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    #tim .ih-item.circle.effect20 .info .info-back {
        opacity: 1;
        visibility: hidden;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background: #444B6E;
        -webkit-transition: all 0.35s linear;
        -moz-transition: all 0.35s linear;
        transition: all 0.35s linear;
        -webkit-transform-origin: 50% 0%;
        -moz-transform-origin: 50% 0%;
        -ms-transform-origin: 50% 0%;
        -o-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    #tim .ih-item.circle.effect20 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 30px;
        padding: 75px 0 0 0;
        height: 110px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect20 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect20 a:hover .img {
        opacity: 0;
    }

    #tim .ih-item.circle.effect20 a:hover .info .info-back {
        opacity: 1;
        visibility: visible;
    }

    #tim .ih-item.circle.effect20.top_to_bottom .info .info-back {
        -webkit-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -moz-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -ms-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -o-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
    }

    #tim .ih-item.circle.effect20.top_to_bottom a:hover .img {
        -webkit-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -moz-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -ms-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -o-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
    }

    #tim .ih-item.circle.effect20.top_to_bottom a:hover .info .info-back {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        -moz-transform: rotate3d(1, 0, 0, 0deg);
        -ms-transform: rotate3d(1, 0, 0, 0deg);
        -o-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg);
    }

    #tim .ih-item.circle.effect20.bottom_to_top .info .info-back {
        -webkit-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -moz-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -ms-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        -o-transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
        transform: translate3d(0, 280px, 0) rotate3d(1, 0, 0, -90deg);
    }

    #tim .ih-item.circle.effect20.bottom_to_top a:hover .img {
        -webkit-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -moz-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -ms-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        -o-transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
        transform: translate3d(0, 0, -220px) rotate3d(1, 0, 0, 90deg);
    }

    #tim .ih-item.circle.effect20.bottom_to_top a:hover .info .info-back {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        -moz-transform: rotate3d(1, 0, 0, 0deg);
        -ms-transform: rotate3d(1, 0, 0, 0deg);
        -o-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg);
    }

    /* Hover Animation Five Team Style */
    #tim .ih-item.circle.effect6 .img {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect6.colored .info {
        background: #1a4a72;
    }

    #tim .ih-item.circle.effect6 .info {
        background: #0D2C54;
        opacity: 0;
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
    }

    #tim .ih-item.circle.effect6 .info h3 {
        color: #fff;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 0 30px;
        padding: 75px 0 0 0;
        height: 110px;
        text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    #tim .ih-item.circle.effect6 .info p {
        color: #bbb;
        padding: 10px 5px;
        font-style: italic;
        margin: 0 30px;
        font-size: 14px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    #tim .ih-item.circle.effect6.scale_up .info {
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    #tim .ih-item.circle.effect6.scale_up a:hover .img {
        opacity: 0;
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
    }

    #tim .ih-item.circle.effect6.scale_up a:hover .info {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

/* Large screens --  --  --  --  -- - */
@media screen and (min-width: 1824px) {
    /* Styles */
}

