
.main-recommend-wrapper{
    width: 100%;
    display: flex;
    position: relative;
    margin : 0 auto;
}
.main-recommend-inner-wapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.main-recommend-inner-wapper .main-recommend-contents{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}
.main-recommend-inner-wapper .main-recommend-contents li.liwpaer{
    width: 22%;
    margin : 10px;
    background: white;
    display: flex;
    flex-direction: column;
    padding : 15px;
    overflow: hidden;
    cursor: pointer;
}
.main-recommend-inner-wapper .main-recommend-contents li.liwpaer:hover{
    box-shadow: 0 1px 20px #5b9aef;
}
li.liwpaer > div:nth-child(1){
    margin-bottom: 7px;
    height : 69px;
}
.recommend-teacher-img{
    width : 60px;
    height: 60px;
    float: left;
    margin-right: 5px;
}
.recommend-teacher-img img{
    width : 100%;
    height : 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
li.liwpaer > div:nth-child(1) > div:nth-child(2){
    width : 65%;
    float: left;
}
li.liwpaer > div:nth-child(1) > div:nth-child(2) > ul{
    padding : 0;
    margin : 0;
    display: flex;
    flex-direction: column;
}
li.liwpaer > div:nth-child(1) > div:nth-child(2) > ul li:nth-child(1){
    color : #333;
    font-weight: 700;
    font-size: 16px;
}
li.liwpaer > div:nth-child(1) > div:nth-child(2) > ul li:nth-child(2){
    font-size: 14px;
    font-weight: 400;
    color : #825437;
    height: 25px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
li.liwpaer > div:nth-child(1) > div:nth-child(2) > ul li:nth-child(3){
    font-size: 14px;
    font-weight: 400;
    color : #727171;
    letter-spacing: -.5px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}


li.liwpaer > div:nth-child(2){
    height: 27px;
    position: relative;
    margin-bottom: 9px;
}
li.liwpaer > div:nth-child(2) > div{
    position: absolute;
    color : white;
    background : #66afe9;
    line-height: 29px;
    width : 120%;
    left : 0;
    transform: translate(-9%);
    text-align: center;
    height: 100%;
    font-size: 16px;
    font-weight: 300;
    display: flex;
    overflow: hidden;
}
li.liwpaer > div:nth-child(2) > div > span{
    flex: 1;
    white-space: nowrap;
    box-sizing: border-box;
    transition: all 1s ease-out;
    /* color : #66afe9; */
}
li.liwpaer > div:nth-child(2) > div > span.sSpand{
}
li.liwpaer > div:nth-child(2) > div > span.sdesa{
}


li.liwpaer > div:nth-child(3){
    flex : 5;
}
li.liwpaer > div:nth-child(4){
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}
li.liwpaer > div:nth-child(4) > span:hover{
    color : black;
}
li.liwpaer > div:nth-child(4) > span > i{
}
li.liwpaer > div:nth-child(4) > span:nth-child(1) > i{
    /* animation: strankStart 1s ease-out infinite; */
}

li.liwpaer:nth-child(1) > div:nth-child(4) > span:nth-child(1) > i{
    /* animation: strankStart 1s ease-out infinite 1s; */
}

@keyframes strankStart{
    0%{
        transform: rotateZ(0deg);
    }
    20%{
        transform: rotateZ(10deg);
    }
    50%{
        transform: rotateZ(-10deg);
    }
    100%{
        transform: rotateZ(0deg);
    }
}

@keyframes xMoment{
    0%{
        transform: translateY(-30px);
        color : white;
        opacity: 0;
    }
    10%{
        opacity: 0.5;
        font-weight: 500;
        transform: translateY(0px);
    }
    11%{
        opacity: 1;
    }
    80%{
        opacity: 1;
        font-weight: 500;
        transform: translateY(0px);
    }
    81%{
        opacity: 0.5;
    }
    100%{
        opacity: 0;
        color : white;
        transform: translateY(30px);
    }
}

/* @media screen and (max-width:1200px){
    .main-recommend-contents li.liwpaer{
        width: 21%;
      }
} */
@media screen and (max-width:991px){
    .main-recommend-inner-wapper .main-recommend-contents li.liwpaer{
        width: 45%;
      }
    .main-recommend-box{
        width: 95% !important;
      }
      li.liwpaer > div:nth-child(1) > div:nth-child(1){
        width: 15%;
      }
}
@media screen and (max-width:767px) {
    .main-recommend-inner-wapper .main-recommend-contents li.liwpaer{
        width: 100%;
      }
      li.liwpaer > div:nth-child(1) > div:nth-child(1){
        width: 10% !important;
      }

}