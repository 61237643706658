.credit-history-wapper{
    padding-bottom: 100px;
}

.credit-history-head{
    min-height: 20px;
    padding : 15px;
    padding-bottom: 5px;
    box-shadow: 1px 2px 3px #6c767f;
    margin-bottom: 20px;
}
.credit-history-head > h3{
    margin-bottom: 0;
}
.credit-history-head-won{
    height: 100%;
    display: flex;
}

.credit-history-head-won > div{
    flex : 1;
}

.credit-history-head-won > div:nth-child(1){
    color: #6c767f;
    font-size: 23px;
    letter-spacing: -1px;
}
.credit-history-head-won > div:nth-child(1) > span{
    font-size: 36px;
    letter-spacing: -1px;
    font-weight: 700;
}
.credit-history-head-won > div:nth-child(2) > div{
    width : 200px;
    height: 100%;
    float: right;
    display: flex;
}
.credit-history-head-won > div:nth-child(2) > div > span{
    flex : 1;
}
.credit-history-head-won > div:nth-child(2) > div > span > div:nth-child(1){
    text-indent: 2px;
}
.credit-history-head-won > div:nth-child(2) > div > span:nth-child(1) > div > select{
    width : 100%;
    height: 33px;
    color: #555;
    font-size: 14px;
    background: white;
}
