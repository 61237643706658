.login-container{
    min-height: 600px;
}
.container{
}
.login-wrap{
    width : 100%;
}
.login-sub-wrap{
    display: block;
    max-width: 470px;
    max-height : 460px;
    margin : 0 auto;
}

.login-h2{
    display: block;
    margin-top: 30px;
    padding: 10px 20px;
    text-align: center;
    font-size: 30px;
    border-radius: 5px;
    border: 1px solid #c2c2c3;
    color: #5b9aef!important;
    background-color: #efefef;
    word-break: break-all;
    box-sizing: border-box;
}

.boot-add-on {
    width : 40px;
    height : 34px;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    margin : 0;
}
.boot-add-on > span {
    position: absolute;
    top : 10px;
    left : 0;
    width : 100%;
    height : 100%;
}


.login-input-email{
    height : 34px !important;
    padding: 6px 12px;
    font-size: 14px;

    background-color: rgba(239,239,239,0);

    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);

    line-height: 1.42857143;
    color: #555;
}
.login-container input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-appearance: textfield;
    background-color: white;
    -webkit-rtl-ordering: logical;
    cursor: text;
}

.login-panel{
    margin-top: 2px;
    background-color: rgba(239, 239, 239, 0.6);
    padding: 20px;
    margin-bottom: 40px;
    border-radius: 5px;
    box-sizing: border-box;
    word-break: break-all;
    margin-top: 2px;
    border: 1px solid #c2c2c3;
}

.login-btn > button {
    display: block;
    margin : 0 auto;
    height : 34px;
    font-size: 14px;
}
.p-width{
    display: inline-block;
    width : 100%;
}
.login-color-font{
    color: #333;

}
.input-group > input{
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    /* font-family: 'Spoqa Han Sans',sans-serif!important; */
}


  


@media  (min-width: 100px) and (max-width : 767px) {
    .login-wrap{
        width : 100%;
        padding : 15px;
    }
    .login-sub-wrap{
        display: block;
        max-width: 100%;
        max-height : 460px;
        margin : 0 auto;
    }
    .login-panel{
        width : 100%;
        margin-top: 2px;
        background-color: rgba(239, 239, 239, 0.6);
        padding: 20px;
        margin-bottom: 40px;
        border-radius: 5px;
        box-sizing: border-box;
        word-break: break-all;

        margin-top: 2px;
        border: 1px solid #c2c2c3;
    }

}


.font-size-remember-me{
    /* margin-top: 15px; */
    padding : 10px;
}

.login-extra-info{
    font-size: 14px;
}