.contact-us a{
    color: #333333 !important;
}
.contact-us{
    width: 100%;
    display: flex;
    justify-content: center;
}
.contact-us-box{
    width: 43%;
    margin: 0 auto;
}
.contact-us-box h4{
    font-size: 1.3rem;
} 
.contact-us-box p{
    color: #6c767f;
}
.contact-us-box a{
    color: #6c767f;
    text-decoration: none;
}

@media screen and (max-width: 991px) {
    .contact-us-box{
        width: 63%;
    }
}
@media screen and (max-width: 767px) {
    .contact-us-box{
        width: 83%;
    }
}